import { Component, OnInit, ViewChildren, QueryList } from "@angular/core";
import { CartoService } from "../../services/carto.service";
import * as XLSX from "xlsx";
import {
  SortableDirective,
  SortEvent,
} from "../../directives/sortable.directive";
import * as FileSaver from "file-saver";
import { BusinessCaseService } from 'src/app/services/business-case.service';

@Component({
  selector: "app-parking-list",
  templateUrl: "./parking-list.component.html",
  styleUrls: ["./parking-list.component.scss"],
})
export class ParkingListComponent implements OnInit {
  private parkingLotHeaders: {} = {
    fid: "parkeer_id",
    adrs_name: "locatie",
    address: "adres",
    area: "oppervlakte_m2",
    cap_kwp: "vermogen_kwp",
  };

  private parkingLotHeadersDrenthe: {} = {
    fid: "parkeer_id",
    adrs_name: "locatie",
    address: "adres",
    area: "oppervlakte_m2",
    cap_kwp: "vermogen_kwp",
    sunny_area: "zonnig_oppervlakte_m2",
    pc_sn_area: "percentage_zonnig_oppervlakte",
    griddist_m: "afstand_middenspanningkabel_m",
    protected: "beschermd_stadsgezicht",
    centre_lat: "centre_latitude",
    centre_lon: "centre_longitude"
  };

  private provinceNhCartoId: number = 8; // Used for RES Viewer custom export
  private parkingLotHeadersNoordHollandResViewer: {} = {
    fid: "parkeer_id",
    adrs_name: "locatie",
    address: "adres",
    area: "oppervlakte_m2",
    cap_kwp: "vermogen_kwp",
    centre_lat: "centre_lat",
    centre_lon: "centre_lon"
  };

  pageProvince: number = 1;
  pageMunicipality: number = 1;
  pageSize: number = 7;

  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;

  constructor(
    public cartoService: CartoService,
    public businessService: BusinessCaseService
    )
  {
    this.cartoService.municipalityDataSorted = this.cartoService.municipalityDataFiltered;
    this.cartoService.provinceDataSorted = this.cartoService.provinceDataFiltered;
  }

  ngOnInit() {
    this.cartoService.municipalityDataSearched = this.cartoService.municipalityDataFiltered
  }

  private columnsToSelect(columnToSelectObject: {}): string {
    let columnsString: string = ""
    Object.keys(columnToSelectObject).forEach(column => {
      if (columnToSelectObject[column] === column) {
        // Do not rename column
        columnsString += `${this.cartoService.dataset}.${column}, `
      } else {
        // Rename column
        columnsString += `${this.cartoService.dataset}.${column} AS ${columnToSelectObject[column]}, `
      }
    })
    columnsString += `${this.cartoService.dataset}.the_geom`
    return columnsString
  }

  exportExcel() {
    // Need to fix this to make sure that we can await getting the export data
    //  and then make the worksheet and actually export the Excel.

    // Log event
    let prov = this.cartoService.provCodeToName(this.cartoService.selectedProvinceId);
    let mun = this.cartoService.munCodeToName(this.cartoService.selectedMunicipalityId + this.cartoService.cartoMunicipalityStartId);
    this.cartoService.analytics.logEvent('export_list_xls', {
      gemeente: mun,
      province: prov,
      custom_event_type: 'xls_export'
    })

    // Create a workbook
    let wb = XLSX.utils.book_new();

    // Bridge details sheet
    let ws = XLSX.utils.json_to_sheet(this.formatParkingData(), {
      header: Object.values(this.parkingLotHeaders),
    });
    XLSX.utils.book_append_sheet(wb, ws, "parkeerplaatsen ");

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    var todayDate = mm + "/" + dd + "/" + yyyy;

    // Save workbook
    if (this.cartoService.showParkingList) {
      XLSX.writeFile(
        wb,
        "parkeerplaatsen_" +
        this.cartoService.municipalities[
          this.cartoService.selectedMunicipalityId
        ].name.toLowerCase() +
        "_" +
        todayDate +
        ".xlsx"
      );
    } else if (this.cartoService.showParkingListProv) {
      XLSX.writeFile(
        wb,
        "parkeerplaatsen_" +
        this.cartoService.provinces[this.cartoService.selectedProvinceId]
          .name.toLowerCase() +
        "_" +
        todayDate +
        ".xlsx"
      );
    }
  }

  formatParkingData() {
    let formattedParkingData = [];

    if (this.cartoService.showParkingList) {
      this.cartoService.municipalityDataFiltered.forEach((parkingLot) => {
        this.formatParkingFunction(parkingLot, formattedParkingData)
      })

    } else if (this.cartoService.showParkingListProv) {
      this.cartoService.provinceDataFiltered.forEach((parkingLot) => {
        this.formatParkingFunction(parkingLot, formattedParkingData)
      })
    }

    return formattedParkingData;
  }

  formatParkingFunction(parkingLot, formattedParkingData) {
    let row = {};

    Object.keys(this.parkingLotHeaders).forEach((header) => {
      row[this.parkingLotHeaders[header]] = parkingLot[header];
    });
    row[this.parkingLotHeaders["area"]] = Math.round(row[this.parkingLotHeaders["area"]] * 10) / 10
    row[this.parkingLotHeaders["cap_kwp"]] = Math.round((row[this.parkingLotHeaders["cap_kwp"]] * this.cartoService.correctionKwpDatabase) * 10) / 10

    formattedParkingData.push(row);
  }

  exportGeoJson() {
    // Log event
    let prov = this.cartoService.provCodeToName(this.cartoService.selectedProvinceId);
    let mun = this.cartoService.munCodeToName(this.cartoService.selectedMunicipalityId + this.cartoService.cartoMunicipalityStartId);
    this.cartoService.analytics.logEvent('export_list_gis', {
      gemeente: mun,
      province: prov,
      custom_event_type: 'export_list_gis'
    })

    // "https://username.carto.com/api/v2/sql?format=GeoJSON&q=SELECT * FROM table_name"
    var query;
    if (this.cartoService.municipalityData != undefined) {
      query = `SELECT ${this.columnsToSelect(this.parkingLotHeaders)} FROM ${this.cartoService.dataset}, ${this.cartoService.regionDataset} WHERE (ST_Intersects(ST_BUFFER(${this.cartoService.dataset}.the_geom, 0), ${this.cartoService.regionDataset}.the_geom) AND ${this.cartoService.regionDataset}.rubriek='gemeente' AND ${this.cartoService.regionDataset}.cartodb_id='${this.cartoService.getCartoMunicipalityId()}' AND ${this.cartoService.dataset}.mun_hidden='0' AND ${
        this.cartoService.getFiltersQuery()}) &format=GeoJSON`;
    } else if (this.cartoService.provinceData != undefined) {
      query = `SELECT ${this.columnsToSelect(this.parkingLotHeaders)} FROM ${this.cartoService.dataset}, ${this.cartoService.regionDataset} WHERE (ST_Intersects(ST_BUFFER(${this.cartoService.dataset}.the_geom, 0), ${this.cartoService.regionDataset}.the_geom) AND ${this.cartoService.regionDataset}.rubriek='provincie' AND ${this.cartoService.regionDataset}.cartodb_id='${this.cartoService.getCartoProvinceId()}' AND ${this.cartoService.dataset}.mun_hidden='0' AND ${
        this.cartoService.getFiltersQuery()}) &format=GeoJSON`;
    }

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    var todayDate = mm + "/" + dd + "/" + yyyy;

    this.cartoService.getCartoData(query).subscribe((data) => {
      data['features'].forEach(feature => {
        feature.properties[this.parkingLotHeaders["area"]] = Math.round(feature.properties[this.parkingLotHeaders["area"]] * 10) / 10
        feature.properties[this.parkingLotHeaders["cap_kwp"]] = Math.round(feature.properties[this.parkingLotHeaders["cap_kwp"]] * this.cartoService.correctionKwpDatabase * 10) / 10
      })
      var json = JSON.stringify(data);
      var blob = new Blob([json], { type: "application/geojson" });
      if (this.cartoService.showParkingList) {
        FileSaver.saveAs(blob, "parkeerplaatsen_" +
          this.cartoService.municipalities[
            this.cartoService.selectedMunicipalityId
          ].name.toLowerCase() +
          "_" +
          todayDate + ".geojson");
      } else if (this.cartoService.showParkingListProv) {
        FileSaver.saveAs(blob, "parkeerplaatsen_" +
          this.cartoService.provinces[this.cartoService.selectedProvinceId]
            .name.toLowerCase() +
          "_" +
          todayDate +
          ".geojson"
        );
      }
    });
  }

  exportGeoJsonDrenthe() {
    // "https://username.carto.com/api/v2/sql?format=GeoJSON&q=SELECT * FROM table_name"
    var query = `SELECT ${this.columnsToSelect(this.parkingLotHeadersDrenthe)} FROM ${this.cartoService.dataset}, ${this.cartoService.regionDataset} WHERE (ST_Intersects(ST_BUFFER(${this.cartoService.dataset}.the_geom, 0), ${this.cartoService.regionDataset}.the_geom) AND ${this.cartoService.regionDataset}.rubriek='provincie' AND ${this.cartoService.regionDataset}.cartodb_id='3' AND ${this.cartoService.dataset}.mun_hidden='0' AND ${  this.cartoService.getFiltersQuery()}) &format=GeoJSON`;

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    var todayDate = mm + "/" + dd + "/" + yyyy;

    this.cartoService.getCartoData(query).subscribe((data) => {
      data['features'].forEach(feature => {
        feature.properties[this.parkingLotHeadersDrenthe["area"]] = Math.round(feature.properties[this.parkingLotHeadersDrenthe["area"]] * 10) / 10
        feature.properties[this.parkingLotHeadersDrenthe["cap_kwp"]] = Math.round(feature.properties[this.parkingLotHeadersDrenthe["cap_kwp"]] * this.cartoService.correctionKwpDatabase * 10) / 10
        // Extra specific columns that need to be calculated frontend side.
         this.cartoService.parkingData = {
          id: feature.id,
          address: feature.properties[this.parkingLotHeadersDrenthe["address"]],
          area: feature.properties[this.parkingLotHeadersDrenthe["area"]],
          pc_sn_area: feature.properties[this.parkingLotHeadersDrenthe["pc_sn_area"]],
          sunny_area: feature.properties[this.parkingLotHeadersDrenthe["sunny_area"]],
          suit_area: feature.properties[this.parkingLotHeadersDrenthe["suit_area"]],
          cap_kwp: feature.properties[this.parkingLotHeadersDrenthe["cap_kwp"]],
          energy_mwh: feature.properties[this.parkingLotHeadersDrenthe["energy_mwh"]],
          protected: feature.properties[this.parkingLotHeadersDrenthe["protected"]],
          griddist_m: feature.properties[this.parkingLotHeadersDrenthe["griddist_m"]],
        };
    
        feature.properties.oppervlakte_solar_carport_m2 = this.businessService.roundTens(this.businessService.getCarportArea(),0)
        feature.properties.energie_jaarlijks_kwh = this.businessService.roundTens(this.businessService.getYearlyEnergy(3),1)
        feature.properties.geschiktheid = this.businessService.totalSuitabilityPopovers[this.businessService.getTotalSuitability()-1].title
        feature.properties.eenmalige_investering_euro =  this.businessService.roundTens(this.businessService.getFixedCosts() - this.businessService.getFixedRevenue(), 2)
        feature.properties.jaarlijks_resultaat_euro = this.businessService.roundTens(this.businessService.getYearlyRevenue() - this.businessService.getYearlyCosts(), 0)
        feature.properties.terugverdientijd_jaren = this.businessService.roundTens(this.businessService.getPaybackTime(),0)
      })
      var json = JSON.stringify(data);
      var blob = new Blob([json], { type: "application/geojson" });
     
      FileSaver.saveAs(blob, "parkeerplaatsen_drenthe_" +
        todayDate + ".geojson"
      );
    });
  }

  exportNoordHollandResViewerGisFile() {
    const query: string = `SELECT ${this.columnsToSelect(this.parkingLotHeadersNoordHollandResViewer)} FROM ${this.cartoService.dataset}, ${this.cartoService.regionDataset} WHERE (ST_Intersects(ST_BUFFER(${this.cartoService.dataset}.the_geom, 0), ${this.cartoService.regionDataset}.the_geom) AND ${this.cartoService.regionDataset}.rubriek='provincie' AND ${this.cartoService.regionDataset}.cartodb_id='${this.provinceNhCartoId}' AND ${this.cartoService.dataset}.mun_hidden='0' AND ${
      this.cartoService.getFiltersQuery()}) &format=GeoJSON`;

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    var todayDate = mm + "/" + dd + "/" + yyyy;

    this.cartoService.getCartoData(query).subscribe((data) => {
      data['features'].forEach(feature => {
        feature.properties[this.parkingLotHeaders["area"]] = Math.round(feature.properties[this.parkingLotHeaders["area"]] * 10) / 10
        feature.properties[this.parkingLotHeaders["cap_kwp"]] = Math.round(feature.properties[this.parkingLotHeaders["cap_kwp"]] * this.cartoService.correctionKwpDatabase * 10) / 10
        feature.properties.pts_link = `https://parkthesun.com/app?lat=${feature.properties.centre_lat}&lon=${feature.properties.centre_lon}`
        delete feature.properties.centre_lat
        delete feature.properties.centre_lon
      })
      var json = JSON.stringify(data);
      var blob = new Blob([json], { type: "application/geojson" });
      FileSaver.saveAs(blob, "parkeerplaatsen_res_viewer_noord-holland_" +
        todayDate +
        ".geojson"
      );
    });
  }

  public onSortMunicipality({ column, direction }: SortEvent) {
    this.cartoService.municipalityDataSorted = this.cartoService.sort(
      this.headers,
      column,
      direction,
      this.cartoService.municipalityDataFiltered,
      this.cartoService.municipalityDataSorted
    );
  }

  public onSortProvince({ column, direction }: SortEvent) {
    this.cartoService.provinceDataSorted = this.cartoService.sort(
      this.headers,
      column,
      direction,
      this.cartoService.provinceDataFiltered,
      this.cartoService.provinceDataSorted
    );
  }


  closeParkingListMuni() {
    this.cartoService.showParkingList = !this.cartoService.showParkingList
    this.cartoService.dataSortedIsDataFilteredMuni()
  }

  closeParkingListProv() {
    this.cartoService.showParkingListProv = !this.cartoService.showParkingListProv
    this.cartoService.dataSortedIsDataFilteredProv()
  }
}
import { Component, OnInit } from '@angular/core';
import { BusinessCaseService } from "../../services/business-case.service";

@Component({
  selector: 'app-advanced-options',
  templateUrl: './advanced-options.component.html',
  styleUrls: ['./advanced-options.component.css']
})
export class AdvancedOptionsComponent implements OnInit {
  editingSolarPanelPower: boolean = false;

  constructor(
    public businessService: BusinessCaseService
  ) { }

  ngOnInit() {
  }

  public toggleSolarPanelPower() {
    if (!this.editingSolarPanelPower) {
      this.editingSolarPanelPower = !this.editingSolarPanelPower;
    } else {
      this.editingSolarPanelPower = !this.editingSolarPanelPower;
    }
  }

}

<div class="mobile-search" [ngClass]="isOpen && 'open'">
  <div class="container contents">
    <div class="row mobile-search-row title-row">
      <span class="title" (click)="toggleMenu()">Zoeken</span>
    </div>
    <div class="row mobile-search-row">
      <app-basemap-toggle></app-basemap-toggle>
    </div>
    <div class="row mobile-search-row">
      <app-searchbar></app-searchbar>
    </div>
    <div class="closeTriangle"
         (click)="closeMenu()">
    </div>
  </div>
</div>

<div class="menu-button" [ngClass]="isOpen && 'open'" (click)="toggleMenu()">
  <div class="container">
    <div class="row justify-content-center">
      <span class="title">Zoeken</span>
    </div>
  </div>
</div>


export const verifiedMunicipalityIds: number[] = [
  16877, // Gemeente Amersfoort, Provincie Utrecht
  16878, // Gemeente Baarn, Provincie Utrecht
  16879, // Gemeente De Bilt, Provincie Utrecht
  16880, // Gemeente Bunnik, Provincie Utrecht
  16881, // Gemeente Bunschoten, Provincie Utrecht
  16882, // Gemeente Eemnes, Provincie Utrecht
  16883, // Gemeente Houten, Provincie Utrecht
  16884, // Gemeente Leusden, Provincie Utrecht
  16885, // Gemeente Lopik, Provincie Utrecht
  16886, // Gemeente Montfoort, Provincie Utrecht
  16887, // Gemeente Renswoude, Provincie Utrecht
  16888, // Gemeente Rhenen, Provincie Utrecht
  16889, // Gemeente Soest, Provincie Utrecht
  16890, // Gemeente Utrecht, Provincie Utrecht
  16891, // Gemeente Veenendaal, Provincie Utrecht
  16892, // Gemeente Woudenberg, Provincie Utrecht
  16893, // Gemeente Wijk bij Duurstede, Provincie Utrecht
  16894, // Gemeente IJsselstein, Provincie Utrecht
  16895, // Gemeente Zeist, Provincie Utrecht
  16896, // Gemeente Nieuwegein, Provincie Utrecht
  16961, // Gemeente Oudewater, Provincie Utrecht
  16989, // Gemeente De Ronde Venen, Provincie Utrecht
  17108, // Gemeente Stichtse Vecht, Provincie Utrecht
  17060, // Gemeente Utrechtse Heuvelrug, Provincie Utrecht
  17123, // Gemeente Vijfheerenlanden, Provincie Utrecht
  16974, // Gemeente Woerden, Provincie Utrecht
  16917, // Gemeente ???, Provincie Noord-Holland
  16924, // Gemeente ???, Provincie Noord-Holland
  17062, // Gemeente ???, Provincie Noord-Holland
  16920, // Gemeente ???, Provincie Noord-Holland
  16922, // Gemeente ???, Provincie Noord-Holland
  17034, // Gemeente ???, Provincie Noord-Holland
  16950, // Gemeente ???, Provincie Noord-Holland
  16897, // Gemeente ???, Provincie Noord-Holland
  16904, // Gemeente ???, Provincie Noord-Holland
  16898, // Gemeente ???, Provincie Noord-Holland
  16899, // Gemeente ???, Provincie Noord-Holland
  16902, // Gemeente ???, Provincie Noord-Holland
  16903, // Gemeente ???, Provincie Noord-Holland
  16900, // Gemeente ???, Provincie Noord-Holland
  16901, // Gemeente ???, Provincie Noord-Holland
  16905, // Gemeente ???, Provincie Noord-Holland
  16906, // Gemeente ???, Provincie Noord-Holland
  16907, // Gemeente ???, Provincie Noord-Holland
  16908, // Gemeente ???, Provincie Noord-Holland
  16909, // Gemeente ???, Provincie Noord-Holland
  16927, // Gemeente ???, Provincie Noord-Holland
  16910, // Gemeente ???, Provincie Noord-Holland
  16916, // Gemeente ???, Provincie Noord-Holland
  16911, // Gemeente ???, Provincie Noord-Holland
  16912, // Gemeente ???, Provincie Noord-Holland
  16913, // Gemeente ???, Provincie Noord-Holland
  16914, // Gemeente ???, Provincie Noord-Holland
  16915, // Gemeente ???, Provincie Noord-Holland
  16918, // Gemeente ???, Provincie Noord-Holland
  16919, // Gemeente ???, Provincie Noord-Holland
  16921, // Gemeente ???, Provincie Noord-Holland
  16923, // Gemeente ???, Provincie Noord-Holland
  16925, // Gemeente ???, Provincie Noord-Holland
  16926, // Gemeente ???, Provincie Noord-Holland
  16928, // Gemeente ???, Provincie Noord-Holland
  16929, // Gemeente ???, Provincie Noord-Holland
  16930, // Gemeente ???, Provincie Noord-Holland
  16931, // Gemeente ???, Provincie Noord-Holland
  16932, // Gemeente ???, Provincie Noord-Holland
  16933, // Gemeente ???, Provincie Noord-Holland
  16934, // Gemeente ???, Provincie Noord-Holland
  16935, // Gemeente ???, Provincie Noord-Holland
  16939, // Gemeente ???, Provincie Noord-Holland
  17024, // Gemeente ???, Provincie Noord-Holland
  17077, // Gemeente ???, Provincie Noord-Holland
  17109, // Gemeente ???, Provincie Noord-Holland
  17115, // Gemeente ???, Provincie Noord-Holland
  16964, // Gemeente Rotterdam, Provincie Zuid-Holland
  16773, // Gemeente Groningen, Provincie Groningen
  16978, // Gemeente Borsele Provincie Zeeland
  16979, // Gemeente Goes, Provincie Zeeland
  16981, // Gemeente Hulst, Provincie Zeeland
  16982, // Gemeente Kapelle, Provincie Zeeland
  16983, // Gemeente Middelburg, Provincie Zeeland
  16984, // Gemeente Reimerswaal, Provincie Zeeland
  16985, // Gemeente Terneuzen, Provincie Zeeland
  16986, // Gemeente Tholen, Provincie Zeeland
  16987, // Gemeente Veere, Provincie Zeeland
  16988, // Gemeente Vlissingen, Provincie Zeeland
  17073, // Gemeente Schouwen-Duiveland, Provincie Zeeland
  17076, // Gemeente Noord-Beveland, Provincie Zeeland
  17083, // Gemeente Sluis, Provincie Zeeland
  16840, // Gemeente Apeldoorn, Provincie Gelderland
  // 16946, // Gemeente 's-Gravenhage, Provincie Zuid-Holland
];

export const rotterdamCoords: number[] = [4.477687, 51.924451]

export const municipalities = [
  { name: "Appingedam", cartodb_id: 16771 },
  { name: "Delfzijl", cartodb_id: 16772 },
  { name: "Groningen", cartodb_id: 16773, coordinates: [6.574319, 53.217779] },
  { name: "Loppersum", cartodb_id: 16774 },
  { name: "Stadskanaal", cartodb_id: 16775 },
  { name: "Veendam", cartodb_id: 16776 },
  { name: "Ooststellingwerf", cartodb_id: 16777 },
  { name: "Assen", cartodb_id: 16778 },
  { name: "Pekela", cartodb_id: 16779 },
  { name: "Aa en Hunze", cartodb_id: 16780 },
  { name: "Borger-Odoorn", cartodb_id: 16781 },
  { name: "Noordenveld", cartodb_id: 16782 },
  { name: "Tynaarlo", cartodb_id: 16783 },
  { name: "Midden-Drenthe", cartodb_id: 16784 },
  { name: "Oldambt", cartodb_id: 16785 },
  { name: "Westerwolde", cartodb_id: 16786 },
  { name: "Midden-Groningen", cartodb_id: 16787 },
  { name: "Het Hogeland", cartodb_id: 16788 },
  { name: "Westerkwartier", cartodb_id: 16789 },
  { name: "Achtkarspelen", cartodb_id: 16790 },
  { name: "Ameland", cartodb_id: 16791 },
  { name: "Heerenveen", cartodb_id: 16792 },
  { name: "Leeuwarden", cartodb_id: 16793 },
  { name: "Opsterland", cartodb_id: 16794 },
  { name: "Schiermonnikoog", cartodb_id: 16795 },
  { name: "Smallingerland", cartodb_id: 16796 },
  { name: "Weststellingwerf", cartodb_id: 16797 },
  { name: "Coevorden", cartodb_id: 16798 },
  { name: "Emmen", cartodb_id: 16799 },
  { name: "Hoogeveen", cartodb_id: 16800 },
  { name: "Meppel", cartodb_id: 16801 },
  { name: "Dalfsen", cartodb_id: 16802 },
  { name: "Hardenberg", cartodb_id: 16803 },
  { name: "Kampen", cartodb_id: 16804 },
  {
    name: "Noordoostpolder",
    cartodb_id: 16805,
    coordinates: [5.609380806426324, 52.68914164179529],
  },
  { name: "Ommen", cartodb_id: 16806 },
  { name: "Staphorst", cartodb_id: 16807 },
  {
    name: "Tubbergen",
    cartodb_id: 16808,
    coordinates: [6.7700295072624295, 52.36090150714809],
  },
  { name: "Zwolle", cartodb_id: 16809 },
  { name: "Hattem", cartodb_id: 16810 },
  { name: "Oldebroek", cartodb_id: 16811 },
  {
    name: "Dronten",
    cartodb_id: 16812,
    coordinates: [5.6041823331659035, 52.49429114653794],
  },
  { name: "Tytsjerksteradiel", cartodb_id: 16813 },
  { name: "De Wolden", cartodb_id: 16814 },
  {
    name: "Twenterand",
    cartodb_id: 16815,
    coordinates: [6.605780135616967, 52.44651457366408],
  },
  { name: "Westerveld", cartodb_id: 16816 },
  { name: "Steenwijkerland", cartodb_id: 16817 },
  { name: "Dantumadiel", cartodb_id: 16818 },
  { name: "Zwartewaterland", cartodb_id: 16819 },
  { name: "De Fryske Marren", cartodb_id: 16820 },
  { name: "Noardeast-Fryslân", cartodb_id: 16821 },
  {
    name: "Almere",
    cartodb_id: 16822,
    coordinates: [5.250299521643244, 52.36886386246634],
  },
  {
    name: "Zeewolde",
    cartodb_id: 16823,
    coordinates: [5.456206532815491, 52.350216196805476],
  },
  { name: "Harlingen", cartodb_id: 16824 },
  { name: "Terschelling", cartodb_id: 16825 },
  { name: "Vlieland", cartodb_id: 16826 },
  {
    name: "Almelo",
    cartodb_id: 16827,
    coordinates: [6.660067980126023, 52.33987696631963],
  },
  {
    name: "Borne",
    cartodb_id: 16828,
    coordinates: [6.7435069751777394, 52.31234579229395],
  },
  {
    name: "Deventer",
    cartodb_id: 16829,
    coordinates: [6.236791750189483, 52.268072520813334],
  },
  {
    name: "Enschede",
    cartodb_id: 16830,
    coordinates: [6.872483228876605, 52.220504956803495],
  },
  {
    name: "Haaksbergen",
    cartodb_id: 16831,
    coordinates: [6.66457088299793, 52.17299448382388],
  },
  {
    name: "Hellendoorn",
    cartodb_id: 16832,
    coordinates: [6.4166828234537086, 52.35025722237061],
  },
  {
    name: "Hengelo",
    cartodb_id: 16833,
    coordinates: [6.776742754141291, 52.25284664862761],
  },
  {
    name: "Losser",
    cartodb_id: 16834,
    coordinates: [7.008488244157164, 52.30665024124126],
  },
  {
    name: "Oldenzaal",
    cartodb_id: 16835,
    coordinates: [6.9110879776160665, 52.307714355880044],
  },
  {
    name: "Raalte",
    cartodb_id: 16836,
    coordinates: [6.28311499214142, 52.34176785530412],
  },
  {
    name: "Urk",
    cartodb_id: 16837,
    coordinates: [5.6106520780978615, 52.66716614046563],
  },
  {
    name: "Wierden",
    cartodb_id: 16838,
    coordinates: [6.559319598816685, 52.330315350483055],
  },
  {
    name: "Aalten",
    cartodb_id: 16839,
    coordinates: [6.556879480636517, 51.91927614022712],
  },
  {
    name: "Apeldoorn",
    cartodb_id: 16840,
    coordinates: [5.92587534545488, 52.179845146894564],
  },
  {
    name: "Arnhem",
    cartodb_id: 16841,
    coordinates: [5.893112776446028, 52.00101827371191],
  },
  {
    name: "Barneveld",
    cartodb_id: 16842,
    coordinates: [5.558100010356993, 52.17493271816181],
  },
  {
    name: "Beuningen",
    cartodb_id: 16843,
    coordinates: [5.740307070832845, 51.86272474392834],
  },
  {
    name: "Brummen",
    cartodb_id: 16844,
    coordinates: [6.121040867796673, 52.10908275026719],
  },
  {
    name: "Buren",
    cartodb_id: 16845,
    coordinates: [5.406930451033334, 51.938484309371226],
  },
  { name: "Culemborg", cartodb_id: 16846 },
  {
    name: "Doesburg",
    cartodb_id: 16847,
    coordinates: [6.1462863364737705, 52.0185748445016],
  },
  {
    name: "Doetinchem",
    cartodb_id: 16848,
    coordinates: [6.2903329003514665, 51.958752084679354],
  },
  {
    name: "Druten",
    cartodb_id: 16849,
    coordinates: [5.612896100303211, 51.87503079355364],
  },
  {
    name: "Duiven",
    cartodb_id: 16850,
    coordinates: [6.0177765287382154, 51.94662502276697],
  },
  {
    name: "Ede",
    cartodb_id: 16851,
    coordinates: [5.735696744646016, 52.07417213303904],
  },
  {
    name: "Elburg",
    cartodb_id: 16852,
    coordinates: [5.871188213655605, 52.369311566104074],
  },
  {
    name: "Epe",
    cartodb_id: 16853,
    coordinates: [5.962506754252834, 52.32197483925727],
  },
  {
    name: "Ermelo",
    cartodb_id: 16854,
    coordinates: [5.592113830354699, 52.300330922238096],
  },
  { name: "Harderwijk", cartodb_id: 16855 },
  {
    name: "Heerde",
    cartodb_id: 16856,
    coordinates: [6.051318652678337, 52.369476194747186],
  },
  {
    name: "Heumen",
    cartodb_id: 16857,
    coordinates: [5.816566663390205, 51.777532663459574],
  },
  {
    name: "Lochem",
    cartodb_id: 16858,
    coordinates: [6.349262235222864, 52.17733495803678],
  },
  {
    name: "Maasdriel",
    cartodb_id: 16859,
    coordinates: [5.323894881457245, 51.78463000131115],
  },
  {
    name: "Nijkerk",
    cartodb_id: 16860,
    coordinates: [5.48684585103276, 52.21039663405064],
  },
  {
    name: "Nijmegen",
    cartodb_id: 16861,
    coordinates: [5.836228373551361, 51.83825779244599],
  },
  {
    name: "Putten",
    cartodb_id: 16862,
    coordinates: [5.565400042845462, 52.245926942355744],
  },
  {
    name: "Renkum",
    cartodb_id: 16863,
    coordinates: [5.792116175467905, 51.98920019499076],
  },
  {
    name: "Rheden",
    cartodb_id: 16864,
    coordinates: [6.05185375329018, 52.035067167282634],
  },
  {
    name: "Rozendaal",
    cartodb_id: 16865,
    coordinates: [5.96941067116861, 52.04267244002199],
  },
  {
    name: "Scherpenzeel",
    cartodb_id: 16866,
    coordinates: [5.500474551921286, 52.08932403649871],
  },
  {
    name: "Tiel",
    cartodb_id: 16867,
    coordinates: [5.406058834329284, 51.884711981204546],
  },
  {
    name: "Voorst",
    cartodb_id: 16868,
    coordinates: [6.109908643610471, 52.20636133162569],
  },
  {
    name: "Wageningen",
    cartodb_id: 16869,
    coordinates: [5.659785013258135, 51.9737834885168],
  },
  {
    name: "Westervoort",
    cartodb_id: 16870,
    coordinates: [5.974620442814713, 51.95847827373613],
  },
  {
    name: "Winterswijk",
    cartodb_id: 16871,
    coordinates: [6.662680578481967, 51.965870132865255],
  },
  {
    name: "Wijchen",
    cartodb_id: 16872,
    coordinates: [5.698518104409208, 51.81941056883505],
  },
  {
    name: "Zaltbommel",
    cartodb_id: 16873,
    coordinates: [5.154434710274136, 51.792132209325395],
  },
  {
    name: "Zevenaar",
    cartodb_id: 16874,
    coordinates: [6.085761747852771, 51.92688220581562],
  },
  {
    name: "Zutphen",
    cartodb_id: 16875,
    coordinates: [6.235083987256687, 52.13608433938371],
  },
  {
    name: "Nunspeet",
    cartodb_id: 16876,
    coordinates: [5.83130357344239, 52.32635827777313],
  },
  {
    name: "Amersfoort",
    cartodb_id: 16877,
    coordinates: [5.387054709581239, 52.18635391387588],
  },
  {
    name: "Baarn",
    cartodb_id: 16878,
    coordinates: [5.267632486832303, 52.20463885547686],
  },
  {
    name: "De Bilt",
    cartodb_id: 16879,
    coordinates: [5.17106876071815, 52.17144011327264],
  },
  {
    name: "Bunnik",
    cartodb_id: 16880,
    coordinates: [5.183288, 52.062445],
  },
  {
    name: "Bunschoten",
    cartodb_id: 16881,
    coordinates: [5.3577174287028555, 52.23922162322129],
  },
  {
    name: "Eemnes",
    cartodb_id: 16882,
    coordinates: [5.279180887604503, 52.249976227622135],
  },
  {
    name: "Houten",
    cartodb_id: 16883,
    coordinates: [5.185788, 52.006347],
  },
  {
    name: "Leusden",
    cartodb_id: 16884,
    coordinates: [5.415736608723701, 52.12212799381598],
  },
  {
    name: "Lopik",
    cartodb_id: 16885,
    coordinates: [4.948538951948092, 51.9858859233478],
  },
  {
    name: "Montfoort",
    cartodb_id: 16886,
    coordinates: [4.940785215005643, 52.04731262346869],
  },
  {
    name: "Renswoude",
    cartodb_id: 16887,
    coordinates: [5.534170363047318, 52.06892022900539],
  },
  {
    name: "Rhenen",
    cartodb_id: 16888,
    coordinates: [5.560610594390117, 51.980747290516106],
  },
  {
    name: "Soest",
    cartodb_id: 16889,
    coordinates: [5.297023757098801, 52.17558574963126],
  },
  {
    name: "Utrecht",
    cartodb_id: 16890,
    coordinates: [5.077274477103225, 52.090733279184],
  },
  {
    name: "Veenendaal",
    cartodb_id: 16891,
    coordinates: [5.555612542976463, 52.02368212996667],
  },
  {
    name: "Woudenberg",
    cartodb_id: 16892,
    coordinates: [5.419224264332413, 52.08181854920569],
  },
  {
    name: "Wijk bij Duurstede",
    cartodb_id: 16893,
    coordinates: [5.330540462382994, 51.98944016393395],
  },
  {
    name: "IJsselstein",
    cartodb_id: 16894,
    coordinates: [5.028660129809241, 52.02803348595305],
  },
  {
    name: "Zeist",
    cartodb_id: 16895,
    coordinates: [5.244191, 52.086560],
  },
  {
    name: "Nieuwegein",
    cartodb_id: 16896,
    coordinates: [5.09558239068931, 52.03133542571501],
  },
  {
    name: "Aalsmeer",
    cartodb_id: 16897,
    coordinates: [4.754924604905294, 52.258020502945406],
  },
  {
    name: "Alkmaar",
    cartodb_id: 16898,
    coordinates: [4.810408307293691, 52.56445791484636],
  },
  {
    name: "Amstelveen",
    cartodb_id: 16899,
    coordinates: [4.851118786556309, 52.288129292100315],
  },
  {
    name: "Amsterdam",
    cartodb_id: 16900,
    coordinates: [4.9004448267497445, 52.36916954159353],
  },
  {
    name: "Beemster",
    cartodb_id: 16901,
    coordinates: [4.916362325298319, 52.55249576665236],
  },
  {
    name: "Bergen (NH.)",
    cartodb_id: 16902,
    coordinates: [4.632673322982204, 52.58396699881726],
  },
  {
    name: "Beverwijk",
    cartodb_id: 16903,
    coordinates: [4.658863472766718, 52.48133541899499],
  },
  {
    name: "Blaricum",
    cartodb_id: 16904,
    coordinates: [5.261490289046602, 52.27944796282155],
  },
  {
    name: "Bloemendaal",
    cartodb_id: 16905,
    coordinates: [4.584836999641408, 52.38031802209005],
  },
  {
    name: "Castricum",
    cartodb_id: 16906,
    coordinates: [4.687248157269672, 52.55665501114435],
  },
  {
    name: "Diemen",
    cartodb_id: 16907,
    coordinates: [4.986923111215878, 52.336455630868315],
  },
  {
    name: "Edam-Volendam",
    cartodb_id: 16908,
    coordinates: [5.021342001533521, 52.53574306907002],
  },
  {
    name: "Enkhuizen",
    cartodb_id: 16909,
    coordinates: [5.271821587749677, 52.69240507833502],
  },
  {
    name: "Haarlem",
    cartodb_id: 16910,
    coordinates: [4.647575888713365, 52.38352659237788],
  },
  {
    name: "Haarlemmermeer",
    cartodb_id: 16911,
    coordinates: [4.687977053090869, 52.307327046186806],
  },
  {
    name: "Heemskerk",
    cartodb_id: 16912,
    coordinates: [4.648169630906218, 52.511937833180944],
  },
  {
    name: "Heemstede",
    cartodb_id: 16913,
    coordinates: [4.615435532909894, 52.3447648481542],
  },
  {
    name: "Heerhugowaard",
    cartodb_id: 16914,
    coordinates: [4.839505800150573, 52.66435162806282],
  },
  {
    name: "Heiloo",
    cartodb_id: 16915,
    coordinates: [4.710917478262857, 52.585135093146995],
  },
  { name: "Den Helder", cartodb_id: 16916 },
  {
    name: "Hilversum",
    cartodb_id: 16917,
    coordinates: [5.163604966961298, 52.22857392492792],
  },
  {
    name: "Hoorn",
    cartodb_id: 16918,
    coordinates: [5.071496976641276, 52.655960789813854],
  },
  {
    name: "Huizen",
    cartodb_id: 16919,
    coordinates: [5.231640437991955, 52.29219072717912],
  },
  {
    name: "Landsmeer",
    cartodb_id: 16920,
    coordinates: [4.923542236113004, 52.451397015496276],
  },
  {
    name: "Langedijk",
    cartodb_id: 16921,
    coordinates: [4.786344304117046, 52.68126726599233],
  },
  {
    name: "Laren",
    cartodb_id: 16922,
    coordinates: [5.217067942273228, 52.24717251998152],
  },
  {
    name: "Medemblik",
    cartodb_id: 16923,
    coordinates: [5.046881913048133, 52.6854950579825],
  },
  {
    name: "Oostzaan",
    cartodb_id: 16924,
    coordinates: [4.8761129953337194, 52.45193744273005],
  },
  {
    name: "Opmeer",
    cartodb_id: 16925,
    coordinates: [4.94691183343365, 52.68685424076561],
  },
  {
    name: "Ouder-Amstel",
    cartodb_id: 16926,
    coordinates: [4.915236033738596, 52.291505558278146],
  },
  {
    name: "Purmerend",
    cartodb_id: 16927,
    coordinates: [4.971121204095423, 52.502587325966296],
  },
  {
    name: "Schagen",
    cartodb_id: 16928,
    coordinates: [4.733137219998063, 52.69478006441334],
  },
  { name: "Texel", cartodb_id: 16929 },
  {
    name: "Uitgeest",
    cartodb_id: 16930,
    coordinates: [4.731732934720908, 52.52373146463584],
  },
  {
    name: "Uithoorn",
    cartodb_id: 16931,
    coordinates: [4.791584663274853, 52.23717434539967],
  },
  {
    name: "Velsen",
    cartodb_id: 16932,
    coordinates: [4.624887894172541, 52.45012710571686],
  },
  {
    name: "Weesp",
    cartodb_id: 16933,
    coordinates: [5.059617338142581, 52.30270796364456],
  },
  {
    name: "Zandvoort",
    cartodb_id: 16934,
    coordinates: [4.543475390470908, 52.35740366279443],
  },
  {
    name: "Zaanstad",
    cartodb_id: 16935,
    coordinates: [4.77073899866432, 52.464343639765225],
  },
  {
    name: "Alblasserdam",
    cartodb_id: 16936,
    coordinates: [4.665788519572582, 51.86463131684645],
  },
  {
    name: "Alphen aan den Rijn",
    cartodb_id: 16937,
    coordinates: [4.640754286972265, 52.11444312480819],
  },
  {
    name: "Barendrecht",
    cartodb_id: 16938,
    coordinates: [4.525552803260675, 51.85064706203417],
  },
  {
    name: "Drechterland",
    cartodb_id: 16939,
    coordinates: [5.164884246635454, 52.66433681382601],
  },
  {
    name: "Brielle",
    cartodb_id: 16940,
    coordinates: [4.1849332196637254, 51.88856489625224],
  },
  {
    name: "Capelle aan den IJssel",
    cartodb_id: 16941,
    coordinates: [4.57763272917049, 51.93286480416171],
  },
  {
    name: "Delft",
    cartodb_id: 16942,
    coordinates: [4.366452576800435, 51.99625824114067],
  },
  {
    name: "Dordrecht",
    cartodb_id: 16943,
    coordinates: [4.730115469781134, 51.78816394383124],
  },
  {
    name: "Gorinchem",
    cartodb_id: 16944,
    coordinates: [4.976034160253852, 51.83969924414369],
  },
  {
    name: "Gouda",
    cartodb_id: 16945,
    coordinates: [4.706211052402162, 52.01530732877964],
  },
  {
    name: "'s-Gravenhage",
    cartodb_id: 16946,
    coordinates: [4.299049222046397, 52.06617542044379],
  },
  {
    name: "Hardinxveld-Giessendam",
    cartodb_id: 16947,
    coordinates: [4.845299357619705, 51.836855697641646],
  },
  {
    name: "Hellevoetsluis",
    cartodb_id: 16948,
    coordinates: [4.15115411325051, 51.839726511720805],
  },
  {
    name: "Hendrik-Ido-Ambacht",
    cartodb_id: 16949,
    coordinates: [4.638697186958356, 51.84197946692701],
  },
  {
    name: "Stede Broec",
    cartodb_id: 16950,
    coordinates: [5.2259986496247945, 52.69063693905399],
  },
  {
    name: "Hillegom",
    cartodb_id: 16951,
    coordinates: [4.575886675615761, 52.296466388789774],
  },
  {
    name: "Katwijk",
    cartodb_id: 16952,
    coordinates: [4.4207286716437455, 52.190278923130236],
  },
  {
    name: "Krimpen aan den IJssel",
    cartodb_id: 16953,
    coordinates: [4.601678989671811, 51.91462469348509],
  },
  {
    name: "Leiden",
    cartodb_id: 16954,
    coordinates: [4.488867224089184, 52.169786096104076],
  },
  {
    name: "Leiderdorp",
    cartodb_id: 16955,
    coordinates: [4.532682263160653, 52.168826561167045],
  },
  {
    name: "Lisse",
    cartodb_id: 16956,
    coordinates: [4.5458812675364575, 52.255828225974575],
  },
  {
    name: "Maassluis",
    cartodb_id: 16957,
    coordinates: [4.248854140965962, 51.92517617442671],
  },
  {
    name: "Nieuwkoop",
    cartodb_id: 16958,
    coordinates: [4.7755985727292005, 52.1846545449857],
  },
  {
    name: "Noordwijk",
    cartodb_id: 16959,
    coordinates: [4.482670158725497, 52.26751474135788],
  },
  {
    name: "Oegstgeest",
    cartodb_id: 16960,
    coordinates: [4.471234780287427, 52.185614287794166],
  },
  {
    name: "Oudewater",
    cartodb_id: 16961,
    coordinates: [4.864182722359539, 52.02832785143245],
  },
  {
    name: "Papendrecht",
    cartodb_id: 16962,
    coordinates: [4.703857572222832, 51.83422975878119],
  },
  {
    name: "Ridderkerk",
    cartodb_id: 16963,
    coordinates: [4.595850286223404, 51.86881770581144],
  },
  {
    name: "Rotterdam",
    cartodb_id: 16964,
    coordinates: [4.29695534873563, 51.92282511606637],
  },
  {
    name: "Rijswijk",
    cartodb_id: 16965,
    coordinates: [4.3261245500634695, 52.03639695360864],
  },
  {
    name: "Schiedam",
    cartodb_id: 16966,
    coordinates: [4.3848823818961815, 51.92802367056126],
  },
  {
    name: "Sliedrecht",
    cartodb_id: 16967,
    coordinates: [4.772416085984322, 51.83067689114149],
  },
  {
    name: "Albrandswaard",
    cartodb_id: 16968,
    coordinates: [4.436380408592936, 51.85185973185333],
  },
  {
    name: "Westvoorne",
    cartodb_id: 16969,
    coordinates: [4.082045866660918, 51.8958198794603],
  },
  {
    name: "Vlaardingen",
    cartodb_id: 16970,
    coordinates: [4.328051173909504, 51.917589484709],
  },
  {
    name: "Voorschoten",
    cartodb_id: 16971,
    coordinates: [4.438618721535066, 52.124801283802185],
  },
  {
    name: "Waddinxveen",
    cartodb_id: 16972,
    coordinates: [4.640900811312639, 52.04199484690701],
  },
  {
    name: "Wassenaar",
    cartodb_id: 16973,
    coordinates: [4.3808077764468445, 52.16881765252387],
  },
  {
    name: "Woerden",
    cartodb_id: 16974,
    coordinates: [4.901532216139218, 52.10656671795],
  },
  {
    name: "Zoetermeer",
    cartodb_id: 16975,
    coordinates: [4.489815903360511, 52.05998998281861],
  },
  {
    name: "Zoeterwoude",
    cartodb_id: 16976,
    coordinates: [4.513857056826488, 52.11481714136329],
  },
  {
    name: "Zwijndrecht",
    cartodb_id: 16977,
    coordinates: [4.6048046891789784, 51.822420539531656],
  },
  { name: "Borsele", cartodb_id: 16978 },
  { name: "Goes", cartodb_id: 16979 },
  {
    name: "West Maas en Waal",
    cartodb_id: 16980,
    coordinates: [5.496340746883387, 51.84994020648024],
  },
  { name: "Hulst", cartodb_id: 16981 },
  { name: "Kapelle", cartodb_id: 16982 },
  { name: "Middelburg", cartodb_id: 16983 },
  { name: "Reimerswaal", cartodb_id: 16984 },
  { name: "Terneuzen", cartodb_id: 16985 },
  { name: "Tholen", cartodb_id: 16986 },
  { name: "Veere", cartodb_id: 16987 },
  { name: "Vlissingen", cartodb_id: 16988 },
  {
    name: "De Ronde Venen",
    cartodb_id: 16989,
    coordinates: [4.919967966598125, 52.22769401901249],
  },
  { name: "Asten", cartodb_id: 16990 },
  { name: "Baarle-Nassau", cartodb_id: 16991 },
  { name: "Bergen op Zoom", cartodb_id: 16992 },
  { name: "Best", cartodb_id: 16993 },
  { name: "Boekel", cartodb_id: 16994 },
  { name: "Boxmeer", cartodb_id: 16995 },
  { name: "Boxtel", cartodb_id: 16996 },
  { name: "Breda", cartodb_id: 16997 },
  { name: "Deurne", cartodb_id: 16998 },
  { name: "Dongen", cartodb_id: 16999 },
  { name: "Eersel", cartodb_id: 17000 },
  { name: "Eindhoven", cartodb_id: 17001 },
  { name: "Etten-Leur", cartodb_id: 17002 },
  { name: "Geertruidenberg", cartodb_id: 17003 },
  { name: "Gilze en Rijen", cartodb_id: 17004 },
  { name: "Goirle", cartodb_id: 17005 },
  { name: "Grave", cartodb_id: 17006 },
  { name: "Haaren", cartodb_id: 17007 },
  { name: "Helmond", cartodb_id: 17008 },
  { name: "'s-Hertogenbosch", cartodb_id: 17009 },
  { name: "Heusden", cartodb_id: 17010 },
  { name: "Hilvarenbeek", cartodb_id: 17011 },
  { name: "Loon op Zand", cartodb_id: 17012 },
  { name: "Mill en Sint Hubert", cartodb_id: 17013 },
  { name: "Nuenen, Gerwen en Nederwetten", cartodb_id: 17014 },
  { name: "Oirschot", cartodb_id: 17015 },
  { name: "Oisterwijk", cartodb_id: 17016 },
  { name: "Oosterhout", cartodb_id: 17017 },
  {
    name: "Oss",
    cartodb_id: 17018,
    coordinates: [5.526506114428561, 51.78179662478993],
  },
  { name: "Rucphen", cartodb_id: 17019 },
  { name: "Sint-Michielsgestel", cartodb_id: 17020 },
  { name: "Someren", cartodb_id: 17021 },
  { name: "Son en Breugel", cartodb_id: 17022 },
  { name: "Steenbergen", cartodb_id: 17023 },
  {
    name: "Waterland",
    cartodb_id: 17024,
    coordinates: [5.017142529053721, 52.454094653155884],
  },
  { name: "Tilburg", cartodb_id: 17025 },
  { name: "Uden", cartodb_id: 17026 },
  { name: "Valkenswaard", cartodb_id: 17027 },
  { name: "Veldhoven", cartodb_id: 17028 },
  { name: "Vught", cartodb_id: 17029 },
  { name: "Waalre", cartodb_id: 17030 },
  { name: "Waalwijk", cartodb_id: 17031 },
  { name: "Woensdrecht", cartodb_id: 17032 },
  { name: "Zundert", cartodb_id: 17033 },
  {
    name: "Wormerland",
    cartodb_id: 17034,
    coordinates: [4.8562336000887285, 52.501276600541914],
  },
  { name: "Landgraaf", cartodb_id: 17035 },
  { name: "Beek", cartodb_id: 17036 },
  { name: "Beesel", cartodb_id: 17037 },
  { name: "Bergen (L.)", cartodb_id: 17038 },
  { name: "Brunssum", cartodb_id: 17039 },
  { name: "Gennep", cartodb_id: 17040 },
  { name: "Heerlen", cartodb_id: 17041 },
  { name: "Kerkrade", cartodb_id: 17042 },
  { name: "Maastricht", cartodb_id: 17043 },
  { name: "Meerssen", cartodb_id: 17044 },
  { name: "Mook en Middelaar", cartodb_id: 17045 },
  { name: "Nederweert", cartodb_id: 17046 },
  { name: "Roermond", cartodb_id: 17047 },
  { name: "Simpelveld", cartodb_id: 17048 },
  { name: "Stein", cartodb_id: 17049 },
  { name: "Vaals", cartodb_id: 17050 },
  { name: "Venlo", cartodb_id: 17051 },
  { name: "Venray", cartodb_id: 17052 },
  { name: "Voerendaal", cartodb_id: 17053 },
  { name: "Weert", cartodb_id: 17054 },
  { name: "Valkenburg aan de Geul", cartodb_id: 17055 },
  {
    name: "Lelystad",
    cartodb_id: 17056,
    coordinates: [5.467000236395573, 52.47680022181304],
  },
  { name: "Horst aan de Maas", cartodb_id: 17057 },
  {
    name: "Oude IJsselstreek",
    cartodb_id: 17058,
    coordinates: [6.404677539198813, 51.90670045387374],
  },
  {
    name: "Teylingen",
    cartodb_id: 17059,
    coordinates: [4.511908048789248, 52.21403041042103],
  },
  {
    name: "Utrechtse Heuvelrug",
    cartodb_id: 17060,
    coordinates: [5.393026242552828, 52.03308450259326],
  },
  {
    name: "Oost Gelre",
    cartodb_id: 17061,
    coordinates: [6.57491664882356, 52.00970398924828],
  },
  {
    name: "Koggenland",
    cartodb_id: 17062,
    coordinates: [4.94798849269896, 52.64475655477491],
  },
  {
    name: "Lansingerland",
    cartodb_id: 17063,
    coordinates: [4.50600023581509, 52.00391261632859],
  },
  { name: "Leudal", cartodb_id: 17064 },
  { name: "Maasgouw", cartodb_id: 17065 },
  { name: "Gemert-Bakel", cartodb_id: 17066 },
  { name: "Halderberge", cartodb_id: 17067 },
  { name: "Heeze-Leende", cartodb_id: 17068 },
  { name: "Laarbeek", cartodb_id: 17069 },
  { name: "Reusel-De Mierden", cartodb_id: 17070 },
  { name: "Roerdalen", cartodb_id: 17071 },
  { name: "Roosendaal", cartodb_id: 17072 },
  {
    name: "Schouwen-Duiveland",
    cartodb_id: 17073,
    coordinates: [3.859515325020333, 51.735295593455085],
  },
  { name: "Cuijk", cartodb_id: 17074 },
  { name: "Landerd", cartodb_id: 17075 },
  { name: "Noord-Beveland", cartodb_id: 17076 },
  {
    name: "Wijdemeren",
    cartodb_id: 17077,
    coordinates: [5.083990595054309, 52.22707059143658],
  },
  { name: "Sint Anthonis", cartodb_id: 17078 },
  {
    name: "Lingewaard",
    cartodb_id: 17079,
    coordinates: [5.949225219616543, 51.90065263836348],
  },
  { name: "Cranendonck", cartodb_id: 17080 },
  { name: "Moerdijk", cartodb_id: 17081 },
  { name: "Echt-Susteren", cartodb_id: 17082 },
  { name: "Sluis", cartodb_id: 17083 },
  {
    name: "Drimmelen",
    cartodb_id: 17084,
    coordinates: [4.788108963380717, 51.74090178440236],
  },
  { name: "Bernheze", cartodb_id: 17085 },
  { name: "Alphen-Chaam", cartodb_id: 17086 },
  { name: "Bergeijk", cartodb_id: 17087 },
  { name: "Bladel", cartodb_id: 17088 },
  { name: "Gulpen-Wittem", cartodb_id: 17089 },
  {
    name: "Overbetuwe",
    cartodb_id: 17090,
    coordinates: [5.777431171326348, 51.924657790797916],
  },
  {
    name: "Hof van Twente",
    cartodb_id: 17091,
    coordinates: [6.554057605221806, 52.22543758406059],
  },
  {
    name: "Neder-Betuwe",
    cartodb_id: 17092,
    coordinates: [5.58616078439477, 51.916047631739325],
  },
  {
    name: "Rijssen-Holten",
    cartodb_id: 17093,
    coordinates: [6.433538351604114, 52.289319996348816],
  },
  { name: "Geldrop-Mierlo", cartodb_id: 17094 },
  {
    name: "Olst-Wijhe",
    cartodb_id: 17095,
    coordinates: [6.1473762165045835, 52.33866812850759],
  },
  {
    name: "Dinkelland",
    cartodb_id: 17096,
    coordinates: [6.880298647016447, 52.33956286234715],
  },
  {
    name: "Westland",
    cartodb_id: 17097,
    coordinates: [4.215635338423057, 51.99784158799569],
  },
  {
    name: "Midden-Delfland",
    cartodb_id: 17098,
    coordinates: [4.307451097143735, 51.96653470880408],
  },
  {
    name: "Berkelland",
    cartodb_id: 17099,
    coordinates: [6.541442591214288, 52.096770200111905],
  },
  {
    name: "Bronckhorst",
    cartodb_id: 17100,
    coordinates: [6.301427661266683, 52.0480117210618],
  },
  { name: "Sittard-Geleen", cartodb_id: 17101 },
  {
    name: "Kaag en Braassem",
    cartodb_id: 17102,
    coordinates: [4.629816981345786, 52.19361124322144],
  },
  {
    name: "Zuidplas",
    cartodb_id: 17103,
    coordinates: [4.608452563876075, 51.99801875975119],
  },
  { name: "Peel en Maas", cartodb_id: 17104 },
  { name: "Súdwest-Fryslân", cartodb_id: 17105 },
  {
    name: "Bodegraven-Reeuwijk",
    cartodb_id: 17106,
    coordinates: [4.762207434440099, 52.065669884654845],
  },
  { name: "Eijsden-Margraten", cartodb_id: 17107 },
  {
    name: "Stichtse Vecht",
    cartodb_id: 17108,
    coordinates: [5.008739022682134, 52.20005204305644],
  },
  {
    name: "Hollands Kroon",
    cartodb_id: 17109,
    coordinates: [4.929651, 52.819855 ]
  },
  {
    name: "Leidschendam-Voorburg",
    cartodb_id: 17110,
    coordinates: [4.41849534156565, 52.0896202849896],
  },
  {
    name: "Goeree-Overflakkee",
    cartodb_id: 17111,
    coordinates: [4.036253902102023, 51.783128875199374],
  },
  {
    name: "Pijnacker-Nootdorp",
    cartodb_id: 17112,
    coordinates: [4.418957300170402, 52.018505828040325],
  },
  {
    name: "Nissewaard",
    cartodb_id: 17113,
    coordinates: [4.282272795823356, 51.8319364119184],
  },
  {
    name: "Krimpenerwaard",
    cartodb_id: 17114,
    coordinates: [4.739122782966031, 51.9508066946709],
  },
  {
    name: "Gooise Meren",
    cartodb_id: 17115,
    coordinates: [5.123025308132935, 52.30656843557866],
  },
  {
    name: "Berg en Dal",
    cartodb_id: 17116,
    coordinates: [5.954327376657811, 51.81689865430348],
  },
  { name: "Meierijstad", cartodb_id: 17117 },
  { name: "Waadhoeke", cartodb_id: 17118 },
  { name: "Beekdaelen", cartodb_id: 17119 },
  {
    name: "Montferland",
    cartodb_id: 17120,
    coordinates: [6.208163334780256, 51.91959920663953],
  },
  {
    name: "Altena",
    cartodb_id: 17121,
    coordinates: [4.926524650189946, 51.76875794769935],
  },
  {
    name: "West Betuwe",
    cartodb_id: 17122,
    coordinates: [5.159881142031466, 51.86344432049321],
  },
  {
    name: "Vijfheerenlanden",
    cartodb_id: 17123,
    coordinates: [5.055604283446766, 51.93699077204454],
  },
  {
    name: "Hoeksche Waard",
    cartodb_id: 17124,
    coordinates: [4.433759518171511, 51.77574925235356],
  },
  {
    name: "Molenlanden",
    cartodb_id: 17125,
    coordinates: [4.84638868964338, 51.88385639469949],
  },
];

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UnverifiedProvinceTemplateComponent } from './templates/unverified-province-template/unverified-province-template.component';
import { LoadingTemplateComponent } from './templates/loading-template/loading-template.component';
import { MunicTemplateComponent } from './templates/munic-template/munic-template.component';
import { ProvinceTemplateComponent } from './templates/province-template/province-template.component';
import { MobileFiltersComponent } from './mobile-filters/mobile-filters.component';
import { CartoService } from 'src/app/services/carto.service';
import { RegionalDetailsService } from 'src/app/services/regional-details.service';
import { LayoutService } from 'src/app/layout/layout.service';

enum SheetType {None, Loading, Unverified, Municipality, Province };

@Component({
  selector: 'app-mobile-info',
  templateUrl: './mobile-info.component.html',
  styleUrls: ['./mobile-info.component.scss']
})
export class MobileInfoComponent implements OnInit, OnDestroy{
  @ViewChild(MobileFiltersComponent) mobileFilters : MobileFiltersComponent;

  private cartoListenerId;
  private listenPeriod = 100;
  private currentSheetType: SheetType = SheetType.None;
  private sheetIsShowing: boolean = false;
  private sheetIsMinimised: boolean = false;
  public sheetIsAvailable: boolean = false;

  constructor(private matBottomSheet: MatBottomSheet, public cartoService: CartoService, private regionalDetails: RegionalDetailsService, public layoutService: LayoutService) { }

  ngOnInit(): void {
    this.cartoListener(true);
  }

  ngOnDestroy(): void {
    this.cartoListener(false);
    this.matBottomSheet.dismiss();
  }

  //The mobile info panels must be instantiated with the open methods below, this listener checks the 
  //relevant bools on cartoservice at an interval to re-create the way the info pop-ups are currently 
  //called for in regional-details.html. Maybe re-working it so both these pop-ups and the regional-details ones
  // run off push notifications from cartoservice would be preferable.
  cartoListener(shouldListen:boolean){
    if(shouldListen){
      if(this.cartoListenerId)
        clearInterval(this.cartoListenerId);
      this.cartoListenerId = setInterval(() => this.sheetUpdate(), this.listenPeriod);
    }
    else{
      if(this.cartoListenerId)
        clearInterval(this.cartoListenerId);
    }
  }

  //Each interval period sheetupdate checks the relevant cartoservice bools, emulating the *ngIf statements for the
  //original data popups in regional-details.html
  sheetUpdate(){
    if(this.cartoService.loadingProvinceData || this.cartoService.loadingMunicipalityData){
      this.sheetIsAvailable = true;
      if(!this.sheetIsShowing || this.currentSheetType != SheetType.Loading)
        this.showSheet(SheetType.Loading); // this.showLoadingSheet();
    }
    else if(!this.regionalDetails.provinceVerified() && !this.cartoService.hideProvMuniCard && !this.cartoService.municipalityData)
    {
      this.sheetIsAvailable = true;
      if(!this.sheetIsShowing || this.currentSheetType != SheetType.Unverified)
        this.showSheet(SheetType.Unverified); // this.showUnverifiedSheet();
    }
    else if(this.regionalDetails.provinceVerified() && this.cartoService.provinceData && !this.cartoService.municipalityData && !this.cartoService.hideProvMuniCard){
      this.sheetIsAvailable = true;
      if(!this.sheetIsShowing || this.currentSheetType != SheetType.Province)
        this.showSheet(SheetType.Province); // this.showProvSheet();
    }
    else if(this.cartoService.municipalityData && !this.cartoService.hideProvMuniCard){
      this.sheetIsAvailable = true;
      if(!this.sheetIsShowing || this.currentSheetType != SheetType.Municipality)
        this.showSheet(SheetType.Municipality); // this.showMunicSheet();
    }
    else
      this.dismissSheet()
  }

  showSheet(sheetType: SheetType){
    let sheetTemplateComponent = this.getSheetComponent(sheetType);
    if(!sheetTemplateComponent){
      console.log("Trying to initialise BottomSheet with an undefined sheet template: aborting");
      return;
    }
    this.matBottomSheet.open(sheetTemplateComponent,
      {
        disableClose: true,
        hasBackdrop: false,
      });
    this.sheetIsShowing = true;
    this.currentSheetType = sheetType;
    this.sheetIsMinimised = false;
  }

  getSheetComponent(sheetType: SheetType){
    switch(sheetType){
      default:
        return undefined;
      case SheetType.Loading:
        return LoadingTemplateComponent;
      case SheetType.Unverified:
        return UnverifiedProvinceTemplateComponent;
      case SheetType.Municipality:
        return MunicTemplateComponent;
      case SheetType.Province:
        return ProvinceTemplateComponent;
    }
  }

  dismissSheet(){
    this.matBottomSheet.dismiss();
    this.sheetIsShowing = false;
    this.sheetIsAvailable = false;
    this.currentSheetType = SheetType.None;
  }

  minimiseSheet(){
    this.matBottomSheet.dismiss();
    this.sheetIsShowing = false;
  }

  openInfoSheet(){
    if(this.sheetIsAvailable){
      this.showSheet(this.currentSheetType);
    }
  }
}
import { Component, OnInit } from "@angular/core";
import { LayoutService } from "../layout.service";
import { Router } from "@angular/router";
import { CartoService } from "../../services/carto.service"


@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {

  constructor(public layoutService: LayoutService, private router: Router, public cartoService: CartoService) { }

  ngOnInit() { }

}

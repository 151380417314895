import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BasemapComponent } from "./basemap/basemap.component";
import { RegionDetailsComponent } from './region-details/region-details.component';
import { SharedComponentsModule } from "../shared-components/shared-components.module";
import { BasemapToggleComponent } from './basemap-toggle/basemap-toggle.component';
import { ParkingBusinessCaseComponent } from './parking-business-case/parking-business-case.component';
import { BusinessCaseItemComponent } from './business-case-item/business-case-item.component';
import { ParkingListComponent } from './parking-list/parking-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { DialogContentComponent } from './dialog-content/dialog-content.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LandingDialogComponent } from './landing-dialog/landing-dialog.component';
import { MatDialogModule } from "@angular/material/dialog";
import { SizeWarningComponent } from './size-warning/size-warning.component';
import { AdvancedOptionsComponent } from './advanced-options/advanced-options.component';
import { AdvancedOptionsItemComponent } from './advanced-options-item/advanced-options-item.component';
import { MobileSearchComponent } from './mobile-search/mobile-search.component';
import { LayoutModule } from "@angular/cdk/layout";
import { SearchbarComponent } from './searchbar/searchbar.component';
import { MobileInfoComponent } from './mobile-info/mobile-info.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { UnverifiedProvinceTemplateComponent } from './mobile-info/templates/unverified-province-template/unverified-province-template.component';
import { MobileinfoCloseComponent } from './mobile-info/mobileinfo-close/mobileinfo-close.component';
import { LoadingTemplateComponent } from './mobile-info/templates/loading-template/loading-template.component';
import { MunicTemplateComponent } from './mobile-info/templates/munic-template/munic-template.component';
import { ProvinceTemplateComponent } from './mobile-info/templates/province-template/province-template.component';
import { MobileFiltersComponent } from './mobile-info/mobile-filters/mobile-filters.component';
import { FiltersComponent } from './filters/filters.component';
import { MobileBusinesscaseComponent } from './mobile-businesscase/mobile-businesscase.component';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [BasemapComponent, RegionDetailsComponent, BasemapToggleComponent, ParkingBusinessCaseComponent, ParkingListComponent, BusinessCaseItemComponent, DialogContentComponent, LandingDialogComponent, SizeWarningComponent, AdvancedOptionsComponent, AdvancedOptionsItemComponent, MobileSearchComponent, SearchbarComponent, MobileInfoComponent, UnverifiedProvinceTemplateComponent, MobileinfoCloseComponent, LoadingTemplateComponent, MunicTemplateComponent, ProvinceTemplateComponent, MobileFiltersComponent, FiltersComponent, MobileBusinesscaseComponent],
  imports: [CommonModule, MatIconModule, MatTooltipModule, BrowserAnimationsModule, MatSortModule, MatTableModule, NgbModule, MatSnackBarModule, MatDialogModule, SharedComponentsModule, LayoutModule, MatBottomSheetModule, MatTabsModule],
  providers: [],
  // exports: [RegionDetailsComponent],
  // bootstrap: [RegionDetailsComponent] 
})
export class ApplicationModule { }

import { Component, OnInit } from '@angular/core';
import { CartoService } from 'src/app/services/carto.service';

@Component({
  selector: 'app-unverified-province-template',
  templateUrl: './unverified-province-template.component.html',
  styleUrls: ['./unverified-province-template.component.css']
})
export class UnverifiedProvinceTemplateComponent implements OnInit {

  public provinceNameCache : string = "";

  constructor(public cartoService: CartoService) { }

  ngOnInit(): void {
    if(this.cartoService.selectedProvinceId)
      this.provinceNameCache = this.cartoService.provinces[this.cartoService.selectedProvinceId].name;
  }

  //During the MatBottomSheet dismiss animation cartoService may set selectedProvinceIs to undefined.
  //This cache is used to avoid errors when this happens.
  getProvinceName(): string{
    if(this.cartoService.selectedProvinceId)
      return this.cartoService.provinces[this.cartoService.selectedProvinceId].name;
    else
      return this.provinceNameCache
  }
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { LayoutService } from "../layout.service";

declare var firebase;

@Component({
  selector: "app-main-view",
  templateUrl: "./main-view.component.html",
  styleUrls: ["./main-view.component.scss"]
})
export class MainViewComponent implements OnInit, OnDestroy {

  analytics:any = firebase.analytics();

  private onRouterEvent: Subscription;
  public showFooter: Boolean = true;

  constructor(public layoutService: LayoutService, private router: Router) {
    this.onRouterEvent = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => this.onNavigationEnd(e));
  }

  public mainClass:string = 'main-content';
  
  captureOutboundLink = (url) =>{
    this.analytics.logEvent('outbound_click', {
      custom_outbound_url: url
    });
  }

  ngOnInit() {
    this.layoutService.checkMapView();
  }

  ngOnDestroy(): void {
      this.onRouterEvent.unsubscribe();
  }

  //Listens for route navigations and flags to hide the footer on the applicaton page,
  //this prevents a page scroll appearing due to a footer behind the map box.
  private onNavigationEnd(navigationEnd: NavigationEnd) {
    this.showFooter = !navigationEnd.url.includes('app');
  }
  
  ngAfterContentChecked(){
    this.layoutService.checkMapView();
  }
}

<mat-card *ngIf="!layoutService.isMobileLayout && cartoService.loadingProvinceData" class="statistics-card">
  <mat-card-subtitle>Data van Provincie
    {{ cartoService.provinces[cartoService.selectedProvinceId].name }} wordt
    geladen... <mat-spinner></mat-spinner>
  </mat-card-subtitle>
</mat-card>

<mat-card *ngIf="!layoutService.isMobileLayout && cartoService.loadingMunicipalityData" class="statistics-card">
  <mat-card-subtitle>Data van gemeente
    {{ cartoService.municipalities[cartoService.selectedMunicipalityId].name }} wordt
    geladen... <mat-spinner></mat-spinner>
  </mat-card-subtitle>
</mat-card>

<mat-card
  *ngIf="!layoutService.isMobileLayout && regionalDetails.provinceVerified() && cartoService.provinceData && !cartoService.municipalityData && !cartoService.hideProvMuniCard"
  [ngClass]="showFilters ? 'statistics-card-pressed-prov': 'statistics-card'">
  <button [ngClass]="cartoService.showParkingListProv ? 'button-light top-left': 'button-primary top-left'"
    (click)="cartoService.showParkingListProv = !cartoService.showParkingListProv"><i class="fa fa-list icon-sm-white"
      aria-hidden="true"></i></button>
  <i *ngIf="cartoService.isMobile()"
    [ngClass]="provCardCollapsed ? 'far fa-window-maximize top-right pointer bcase-window-size-button' : 'fa fa-window-minimize top-right pointer bcase-window-size-button'"
    aria-hidden="true" (click)="toggleProvCollapse()"></i>
  <button *ngIf="!cartoService.loadingProvinceData" [matTooltip]="shareService.tipContent" #tooltip="matTooltip"
    mat-stroked-button [ngClass]="cartoService.isMobile() ? 'share-button-top-left' : 'share-button-top-right'"
    (click)="onShareClick()">
    <mat-icon svgIcon="share"></mat-icon>
  </button>
  <mat-card-content *ngIf="!cartoService.loadingProvinceData">
    <div class="d-flex flex-column align-items-center mb-4">
      <h2 class="text-center heading-underline-primary">
        {{cartoService.provinces[cartoService.selectedProvinceId].name}}
      </h2>

    </div>
    <div *ngIf="!provCardCollapsed" class="statistics-card-content">
      <div class="d-flex align-items-baseline">
        <!-- <span class="fa fa-info-circle icon-sm"></span> -->
        <ng-template #locationContent><i class="fa fa-times top-right pointer"
            aria-hidden="true"></i>{{tooltipText.parking_locations}}</ng-template>
        <mat-icon class="fa fa-info-circle icon-sm mr-3 pointer" placement="left" [ngbPopover]="locationContent"
          popoverTitle="Aantal parkeerterreinen">
        </mat-icon>
        <i class="fa fa-map-marker icon-sm mr-3"></i>
        <p class="mx-1">
          <b>{{ cartoService.provinceDataFiltered.length | number : '1.0-0' : 'nl'}}</b>
          /{{cartoService.provinceData.length | number : '1.0-0' : 'nl'}}
        </p>
        <p>locaties</p>
      </div>
      <div class="d-flex align-items-baseline">
        <!-- <span class="fa fa-info-circle icon-sm"></span> -->
        <ng-template #energyContent><i class="fa fa-times top-right pointer"
            aria-hidden="true"></i>{{tooltipText.energy_use}}</ng-template>
        <mat-icon class="fa fa-info-circle icon-sm mr-3 pointer" placement="bottom" [ngbPopover]="energyContent"
          popoverTitle="Op te stellen vermogen">
        </mat-icon>
        <i class="fa fa-bolt icon-sm mr-3"></i>
        <p class="mx-1">{{ regionalDetails.getTotal('provinceDataFiltered', 'cap_kwp') | number : '1.0-0' : 'nl' }}</p>
        <p>kWp vermogen</p>
      </div>
      <div class="d-flex align-items-baseline">
        <!-- <span class="fa fa-info-circle icon-sm"></span> -->
        <ng-template #m2Content>
          <i class="fa fa-times top-right pointer" aria-hidden="true"></i>
          <div [innerHTML]="tooltipText.m2_content"></div>
        </ng-template>
        <mat-icon class="fa fa-info-circle icon-sm mr-3 pointer" placement="bottom" [ngbPopover]="m2Content"
          popoverTitle="Oppervlakte parkeerterreinen">
        </mat-icon>
        <i class="fas fa-parking mr-3 icon-sm"></i>
        <p class="mx-1">{{ regionalDetails.getTotal('provinceDataFiltered', 'area') | number : '1.0-0' : 'nl' }}</p>
        <p>m2 parkeerterreinen</p>
      </div>
      <button class="button-primary" (click)="cartoService.zoomOutfromProvince()">Terug naar overzicht</button>
      <button [ngClass]="cartoService.showFilters? 'button-light': 'button-primary'"
        (click)="cartoService.toggleFilters()">
        {{cartoService.showFilters? 'Verberg filters' : 'Toon filters'}}
      </button>
      <div *ngIf="cartoService.showFilters" class="mt-3">
        <app-filters></app-filters>
        <!-- <ng-template [ngTemplateOutlet]="filters"></ng-template> -->
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="!layoutService.isMobileLayout && cartoService.municipalityData && !cartoService.hideProvMuniCard"
  [ngClass]="showFilters ? 'statistics-card-pressed-muni': 'statistics-card'">
  <button [ngClass]="cartoService.showParkingList ? 'button-light top-left': 'button-primary top-left'"
    (click)="cartoService.showParkingList = !cartoService.showParkingList"><i class="fa fa-list icon-sm-white"
      aria-hidden="true"></i></button>
  <i *ngIf="cartoService.isMobile()"
    [ngClass]="muniCardCollapsed ? 'far fa-window-maximize top-right pointer bcase-window-size-button' : 'fa fa-window-minimize top-right pointer bcase-window-size-button'"
    aria-hidden="true" (click)="toggleMuniCollapse()"></i>
  <button *ngIf="!cartoService.loadingProvinceData" [matTooltip]="shareService.tipContent" #tooltip="matTooltip"
    mat-stroked-button [ngClass]="cartoService.isMobile() ? 'share-button-top-left' : 'share-button-top-right'"
    (click)="onShareClick()">
    <mat-icon svgIcon="share"></mat-icon>
  </button>
  <!-- <i *ngIf="cartoService.isMobile()"
    [ngClass]="muniCardCollapsed ? 'far fa-window-maximize top-right pointer bcase-window-size-button' : 'fa fa-window-minimize top-right pointer bcase-window-size-button'"
    aria-hidden="true" (click)="toggleProvCollapse()"></i>
  <button *ngIf="!cartoService.loadingProvinceData" [matTooltip]="shareService.tipContent" #tooltip="matTooltip"
    mat-stroked-button [ngClass]="cartoService.isMobile() ? 'share-button-top-left' : 'share-button-top-right'"
    (click)="onShareClick()">
  </button> -->
  <mat-card-content *ngIf="!cartoService.loadingProvinceData">
    <div class="d-flex flex-column align-items-center mb-4">
      <h2 class="text-center heading-underline-primary">
        {{cartoService.municipalities[cartoService.selectedMunicipalityId].name}}
      </h2>
    </div>
    <div *ngIf="!muniCardCollapsed" class="statistics-card-content">
      <div class="d-flex align-items-baseline">
        <!-- <span class="fa fa-info-circle icon-sm"></span> -->
        <ng-template #locationContent><i class="fa fa-times top-right pointer"
            aria-hidden="true"></i>{{tooltipText.parking_locations}}</ng-template>
        <mat-icon class="fa fa-info-circle icon-sm mr-3 pointer" placement="left" [ngbPopover]="locationContent"
          popoverTitle="Aantal parkeerterreinen">
        </mat-icon>
        <i class="fa fa-map-marker icon-sm mr-3"></i>
        <p class="mx-1">
          <b>{{ cartoService.municipalityDataFiltered.length | number : '1.0-0' : 'nl'}}</b>
          /{{cartoService.municipalityData.length | number : '1.0-0' : 'nl'}}
        </p>
        <p>locaties</p>
      </div>
      <div class="d-flex align-items-baseline">
        <!-- <span class="fa fa-info-circle icon-sm"></span> -->
        <ng-template #energyContent><i class="fa fa-times top-right pointer"
            aria-hidden="true"></i>{{tooltipText.energy_use}}</ng-template>
        <mat-icon class="fa fa-info-circle icon-sm mr-3 pointer" placement="bottom" [ngbPopover]="energyContent"
          popoverTitle="Op te stellen vermogen">
        </mat-icon>
        <i class="fas fa-parking mr-3 icon-sm"></i>
        <p class="mx-1">{{ regionalDetails.getTotal('municipalityDataFiltered', 'cap_kwp') | number : '1.0-0' : 'nl' }}</p>
        <p>kWp vermogen</p>
      </div>
      <div class="d-flex align-items-baseline">
        <!-- <span class="fa fa-info-circle icon-sm mr-3"></span> -->
        <ng-template #m2Content>
          <i class="fa fa-times top-right pointer" aria-hidden="true"></i>
          <div [innerHTML]="tooltipText.m2_content"></div>
        </ng-template>
        <mat-icon class="fa fa-info-circle icon-sm mr-3 pointer" placement="bottom" [ngbPopover]="m2Content"
          popoverTitle="Oppervlakte parkeerterreinen">
        </mat-icon>
        <i class="fa fa-bolt icon-sm mr-3"></i>
        <p class="mx-1">{{ regionalDetails.getTotal('municipalityDataFiltered', 'area') | number : '1.0-0' : 'nl' }}</p>
        <p>m2 parkeerterreinen</p>
      </div>
      <button *ngIf="showBackToProvinceButton()" class="button-primary"
        (click)="cartoService.zoomOutfromMunicipality();cartoService.showParkingList = false">Terug naar
        provincie</button>
      <button [ngClass]="cartoService.showFilters? 'button-light': 'button-primary'"
        (click)="cartoService.toggleFilters()">
        {{cartoService.showFilters ? 'Verberg filters' : 'Toon filters'}}
      </button>
      <div *ngIf="cartoService.showFilters" class="mt-3">
        <app-filters></app-filters>
        <!-- <ng-template [ngTemplateOutlet]="filters"></ng-template> -->
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- The filters.component has been created to replace this, to allow access to other components, I don't think this template is being used anymore. -->
<ng-template #filters>
  <h4 class="text-left">Filters</h4>
  <div [ngClass]="cartoService.selectedMunicipalityId == 2 ? 'filters-card-scrollable-content': '' ">
  <div class="d-flex flex-column align-items-start">
    <!-- <span class="fa fa-info-circle icon-sm"></span> -->
    <div class="d-flex">
      <h6 class="filter-title mb-0">
        <!-- <b style="display:inline-block;width:67.5px"> </b>  -->
        Op te stellen vermogen
      </h6>
    </div>
    <div class="d-flex align-items-center w-100 mt-0">
      <ng-template #energyContent><i class="fa fa-times top-right pointer"
          aria-hidden="true"></i>{{tooltipText.energy_use_filter}}</ng-template>
      <mat-icon class="fa fa-info-circle icon-sm mr-3 pointer" placement="left" [ngbPopover]="energyContent"
        popoverTitle="Op te stellen vermogen">
      </mat-icon>
      <i class="fa fa-bolt icon-sm mr-3"></i>
      <mat-slider class="p-2" thumbLabel [displayWith]="formatLabel" step="5" tickInterval="200" min="0" max="1000"
        [value]="cartoService.powerFilter" (change)="powerFilterChange($event)">
      </mat-slider>
    </div>
    <div class="d-flex align-items-baseline">
      <p>Minimaal {{cartoService.powerFilter}} kWp</p>
    </div>
  </div>
  <div class="d-flex flex-column align-items-start">
    <!-- <span class="fa fa-info-circle icon-sm"></span> -->
    <div class="d-flex">
      <h6 class="filter-title mb-0">Totale oppervlakte</h6>
    </div>
    <div class="d-flex align-items-center w-100 mt-0">
      <ng-template #m2Content>
        <i class="fa fa-times top-right pointer" aria-hidden="true"></i>
        <div [innerHTML]="tooltipText.m2_content_filter"></div>
      </ng-template>
      <mat-icon class="fa fa-info-circle icon-sm mr-3 pointer" placement="left" [ngbPopover]="m2Content"
        popoverTitle="Oppervlakte parkeerterreinen">
      </mat-icon>
      <i class="fas fa-parking mr-3 icon-sm"></i>
      <mat-slider class="p-2" thumbLabel [displayWith]="formatLabel" step="5" tickInterval="1000" min="65" max="10000"
        [value]="cartoService.areaFilter" (change)="areaFilterChange($event)">
      </mat-slider>
    </div>
    <div class="d-flex align-items-baseline">
      <p>Minimaal {{cartoService.areaFilter}} m2</p>
    </div>
    <div class="d-flex align-items-center w-100">
      <ng-template #cityScapeContent>
        <i class="fa fa-times top-right pointer" aria-hidden="true"></i>
        <div [innerHTML]="tooltipText.cityscape_content_html"></div>
        <!-- <ng-template #cityScapeContent><i class="fa fa-times top-right pointer"
          aria-hidden="true"></i>{{tooltipText.cityscape_content}}<a
          href="https://www.monumenten.nl/soorten-monumenten/beschermde-stads-en-dorpsgezichten">Kijk op monumenten.nl
          voor meer informatie.</a> -->
      </ng-template>
      <mat-icon class="fa fa-info-circle icon-sm mr-3 pointer" placement="left" [ngbPopover]="cityScapeContent"
        [popoverTitle]="tooltipText.cityscape_title">
      </mat-icon>
      <i class="fas fa-university mr-3 icon-sm"></i>
      <mat-form-field class="w-100">
        <mat-label>{{protectedFilters.hintText}}</mat-label>
        <mat-select [value]="cartoService.protectedFilter" (selectionChange)="protectedFilterChange($event)">
          <mat-option *ngFor="let item of protectedFilters.items" [value]="item.value">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-flex align-items-center w-100">
      <ng-template #distanceContent><i class="fa fa-times top-right pointer" aria-hidden="true"></i>
        <div [innerHTML]="tooltipText.distance_connection_mapview_content"></div>
        <!-- <ng-template #distanceContent><i class="fa fa-times top-right pointer"
          aria-hidden="true"></i>{{tooltipText.distance_connection_content}}<a
          href="https://www.enexis.nl/zakelijk/aansluitingen/tarieven/tarievenoverzicht">Enexis,</a><a
          href="https://www.liander.nl/tarieven-downloads"> Liander </a><a
          href="https://www.stedin.net/tarieven#elektriciteit#tarieven">en Stedin</a> -->
      </ng-template>
      <mat-icon class="fa fa-info-circle icon-sm mr-3 pointer" placement="left" [ngbPopover]="distanceContent"
        [popoverTitle]="tooltipText.distance_connection_title">
      </mat-icon>
      <i class="fas fa-plug mr-3 icon-sm"></i>
      <mat-form-field class="w-100">
        <mat-label>{{gridDistanceFilters.hintText}}</mat-label>
        <mat-select [value]="cartoService.gridDistanceFilter" (selectionChange)="gridDistanceFilterChange($event)">
          <mat-option *ngFor=" let item of gridDistanceFilters.items" [value]="item.value">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="cartoService.privatePublicFeatureActivated() && cartoService.municipalityData" class="d-flex align-items-center w-100">
      <ng-template #distanceContent><i class="fa fa-times top-right pointer" aria-hidden="true"></i>
        <div [innerHTML]="tooltipText.private_public_content"></div>
      </ng-template>
      <mat-icon class="fa fa-info-circle icon-sm mr-3 pointer" placement="left" [ngbPopover]="distanceContent"
        [popoverTitle]="tooltipText.private_public_title">
      </mat-icon>
      <i class="fas fa-user-shield mr-3 icon-sm"></i>
      <mat-form-field class="w-100">
        <mat-label>{{privateFilters.hintText}}</mat-label>
        <mat-select [value]="cartoService.privateFilter" (selectionChange)="privateFilterChange($event)">
          <mat-option *ngFor=" let item of privateFilters.filters" [value]="item.value">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  </div>
  <div class="text-center">
    <button class="button-primary" (click)="resetFilters()">
      Verwijder filters
    </button>
  </div>
</ng-template>

<mat-card
  *ngIf="!layoutService.isMobileLayout && !regionalDetails.provinceVerified() && !cartoService.hideProvMuniCard && !cartoService.municipalityData && !cartoService.loadingProvinceData && !cartoService.loadingMunicipalityData"
  class="statistics-card">
  <button *ngIf="cartoService.isMobile()"
    [ngClass]="provCardCollapsed ? 'button-primary collapse-button' : 'button-light collapse-button'"
    (click)="toggleProvCollapse()"><i class="far icon-sm-white" aria-hidden="true"
      [ngClass]="provCardCollapsed ? 'fa-plus-square' : 'fa-minus-square'"></i></button>
  <!-- <button *ngIf="!cartoService.loadingProvinceData" [matTooltip]="shareService.tipContent" #tooltip="matTooltip"
    mat-stroked-button class="share-button-top-right" (click)="onShareClick()">
    <mat-icon svgIcon="share"></mat-icon>
  </button> -->
  <mat-card-content *ngIf="!cartoService.loadingProvinceData">
    <div class="d-flex flex-column align-items-center mb-4">
      <h6 class="text-center">Potentie - Totaal</h6>
      <h2 class="text-center heading-underline-primary">
        {{cartoService.provinces[cartoService.selectedProvinceId].name}}
      </h2>
    </div>
    <div *ngIf="!provCardCollapsed" class="statistics-card-content">
      <div class="d-flex align-items-baseline">
        <p>Informatie voor de volledige provincie is niet beschikbaar, maar voor de gemeenten met een blauwe cirkel wel.
        </p>
      </div>
      <button class="button-primary" (click)="cartoService.zoomOutfromProvince()">Terug naar overzicht</button>
    </div>
  </mat-card-content>
</mat-card>

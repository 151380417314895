<div class="container-fluid h-100">
  <div class="row h-100">
    <!-- <div
      class="d-none d-lg-block flex-column text-center justify-content-center landing-logo-container"
    >
      <img class="logo-landing" src="/assets/images/pts_logo_new.png" />
    </div> -->
    <div class="landing-left d-flex justify-content-center col-12 col-lg-6">
      <div
        class="landing-content-container-left d-flex flex-column align-items-center col-12 col-xl-10"
      >
        <img
          class="d-block logo-landing"
          src="/assets/images/pts_icon.svg"
        />
        <h3 class="p-2 text-center">
          Wat zijn solar carports en hoe werkt Park the Sun? Benieuwd naar
          geschikte parkeerterreinen? Ga direct aan de slag!
        </h3>
        <div class="d-flex flex-row flex-wrap justify-content-around pl-3 pr-3 w-100">
          <button (click)="moreInfoClick()" class="button-landing p-2 mb-3">
            Meer info
          </button>
          <button (click)="interestedClick()" class="button-landing p-2 mb-3">
            Aan de slag
          </button>
        </div>
      </div>
    </div>
    <div class="landing-right d-sm-flex justify-content-center col-12 col-lg-6 d-none d-sm-block">
      <div
        class="landing-content-container-right d-flex flex-column align-items-center col-12 col-xl-10"
      ></div>
    </div>
  </div>
</div>

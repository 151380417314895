import { Injectable } from "@angular/core";
import { MenuItem } from "./layout.interface";
import { Location } from "@angular/common";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Subscription } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LayoutService {
  menuItems: MenuItem[] = [
    { name: "Over", routerLink: "over" },
    { name: "Applicatie", routerLink: "app"},
    { name: "Vraag & Antwoord", routerLink: "faq" },
    { name: "Contact", routerLink: "contact" }
  ];
  public mapView: boolean;
  private nonMapViewRoutes: string[] = ["over", "faq", "contact"];
  private footerClassses: string[] = ["map-footer", "main-footer"];
  activeFooterClass: string;
  activeMainViewClass: string;
  public showNavbar: boolean = true;
  public inApplication: boolean = false;

  public isMobileLayout: boolean = false;
  public sideMenuIsOpen: boolean = false;
  isMobileBreakpointSubscription: Subscription;

  constructor(private location: Location, private breakpointObserver: BreakpointObserver) 
  {
    //Listens for screen width changes and decides when to declare a mobile layout.
    this.setIsMobileLayout(!this.breakpointObserver.isMatched('(min-width: 768px)'));
    this.isMobileBreakpointSubscription = this.breakpointObserver.observe(['(min-width: 768px)']).subscribe((state: BreakpointState) => {
      this.setIsMobileLayout(!state.matches);
    })
  }

  setIsMobileLayout(isMobile: boolean): void{
    this.sideMenuIsOpen = false;
    this.isMobileLayout = isMobile;
  }

  public checkMapView() {
    let routerLink = this.location.path().substr(1)
    this.mapView = !(this.nonMapViewRoutes.indexOf(String(routerLink)) > -1);
    this.setActiveClasses(this.mapView);
  }

  private setActiveClasses(mapview) {
      if (mapview) {
        this.activeFooterClass = this.footerClassses[0];
      } else {
        this.activeFooterClass = this.footerClassses[1];
      }
  }

  public toggleNavbar() {
    this.showNavbar = !this.showNavbar;
  }

  public toggleSideMenu():void{
    this.sideMenuIsOpen = !this.sideMenuIsOpen;
  }
}

import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { CartoService } from 'src/app/services/carto.service';
import { RegionalDetailsService } from 'src/app/services/regional-details.service';
import { UpdatingTemplate } from '../updating-template';

@Component({
  selector: 'app-province-template',
  templateUrl: './province-template.component.html',
  styleUrls: ['./province-template.component.css']
})
export class ProvinceTemplateComponent extends UpdatingTemplate implements OnInit {

  public provinceNameCache : string = "";
  private filteredDataLengthCache: number = 0;
  private provDataLengthCache: number = 0;

  constructor(public cartoService: CartoService, public regionalDetails: RegionalDetailsService, changeDetection: ChangeDetectorRef) {
    super(changeDetection);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if(this.cartoService.selectedProvinceId)
      this.provinceNameCache = this.cartoService.provinces[this.cartoService.selectedProvinceId].name;
  }

  //During the MatBottomSheet dismiss animation cartoService may set selectedProvinceIs to undefined.
  //This cache is used to avoid errors when this happens.
  getProvinceName(): string{
    if(this.cartoService.selectedProvinceId)
      return this.cartoService.provinces[this.cartoService.selectedProvinceId].name;
    else
      return this.provinceNameCache
  }

  getProvFilteredDataLength() : number{
    if(this.cartoService.provinceDataFiltered){
      this.filteredDataLengthCache = this.cartoService.provinceDataFiltered.length;
      return this.cartoService.provinceDataFiltered.length;
    }
    else
      return this.filteredDataLengthCache;
  }

  getProvDataLength() : number {
    if(this.cartoService.provinceData){
      this.provDataLengthCache = this.cartoService.provinceData.length;
      return this.cartoService.provinceData.length;
    }
    else
      return this.provDataLengthCache;
  }
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { CartoService } from "../../services/carto.service";
import { BusinessCaseService } from "../../services/business-case.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { MatHorizontalStepper } from "@angular/material/stepper";
import { MatDialog } from "@angular/material/dialog";
import { DialogContentComponent } from "../dialog-content/dialog-content.component";
import { AdvancedOptionsComponent } from "../advanced-options/advanced-options.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { saveAs } from "file-saver";
import { ShareService } from 'src/app/services/share.service';
import { BusinessCaseInfoService } from "src/app/services/business-case-info.service";

@Component({
  selector: 'app-parking-business-case',
  templateUrl: './parking-business-case.component.html',
  styleUrls: ['./parking-business-case.component.css'],
  providers: [{
    provide: MAT_STEPPER_GLOBAL_OPTIONS,
    useValue: { displayDefaultIndicatorType: false }
  }]
})
export class ParkingBusinessCaseComponent implements OnInit {
  isLinear = false;
  thumbLabel = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  dialogHeader: string;
  dialogContent: string;
  private carboneTemplateId: string = "39f530877b6a7c9a3ecd2a507613c6954f16b62159bb2e7b5d00e2d8d77ab89d"

  stage1LowGridDistanceText = ["Afstand tot verdeelstation"]
  stage1MidGridDistanceText = ["Afstand tot middenspanningskabel"]

  stage1MainsConnectionFieldentry = ["Beperkt", "transportcapaciteit"];

  stage2PermitFieldentry = ["XX.XXXXXXX"];
  stage2SearchFieldentry = ["XX.XXXXXXX"];
  stage2ConnectionFieldnameEditableText = "Gebaseerd op afstand";
  stage2RentText = ["Op basis van een huurprijs"];

  stage3SalderenFieldnameText = ["Op basis van een verbruik"];
  stage3GridSalesFieldnameText = ["Op basis van een elektriciteitsprijs"];
  stage3OwnUseFieldnameText = ["Op basis van een elektriciteitsprijs"]
  stage3ExtraParkingFieldnameText = ["Op basis van een extra parkeertarief"];

  @ViewChild(MatHorizontalStepper) stepper: MatHorizontalStepper;
  //@ViewChild("sliderValue")sliderValue;
  public tooltipText;

  constructor(
    public cartoService: CartoService,
    public businessService: BusinessCaseService,
    public businessInfo: BusinessCaseInfoService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private httpClient: HttpClient,
    public shareService: ShareService
  ) { }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ["", Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });
    this.cartoService.getJSON().subscribe((data) => {
      this.tooltipText = data;
    });
    this.businessService.showBusinessDetails = true;
  }

  public updateSliderSetting(event) {
    this.businessService.buildableAreaPercentage =
      Math.round(event.value * 100) * 0.01;
  }

  public formatLabel(value: number) {
    if (value <= 1) {
      return Math.round(value * 100).toFixed(0) + "%";
    }

    return value;
  }

  public buildableAreaInputChange(event) {
    this.businessService.buildableAreaPercentage =
      Math.round(event.target.valueAsNumber) * 0.01;
  }

  public batterySizeInputChange(event) {
    this.businessService.batterySize =
      Math.round(event.target.valueAsNumber); 
  }


  public toggleOffBusinessDetails() {
    this.businessService.showBusinessDetails = false;
  }

  public toggleOnBusinessDetails() {
    this.businessService.showBusinessDetails = true;
  }

  public toggleBusinessDetails() {
    this.businessService.showBusinessDetails = !this.businessService.showBusinessDetails;
  }

  public onShareClick() {
    this.shareService.shareBusinessCase();
  }

  // afstandNetaansluiting(value) {

  //   return{
  //   items: [
  //     { value: Math.round(parseFloat(value)), name: "Afstand tot verdeelstation"},
  //     { value: Math.round(parseFloat(value)), name: "Afstand tot middenspanningskabel"}
  //   ]
  // }
  // }

  businessNextPageClicked() { }

  openDialog(
    dialogHeader,
    dialogContent,
    dialogLink,
    dialogLinkContent,
    dialogContent2,
    dialogLink2,
    dialogLinkContent2,
    dialogLink3,
    dialogLinkContent3,
    dialogLink4,
    dialogLinkContent4
  ) {
    this.dialog.open(DialogContentComponent, {
      data: {
        header: dialogHeader,
        content: dialogContent,
        link: dialogLink,
        linkContent: dialogLinkContent,
        content2: dialogContent2,
        link2: dialogLink2,
        linkContent2: dialogLinkContent2,
        link3: dialogLink3,
        linkContent3: dialogLinkContent3,
        link4: dialogLink4,
        linkContent4: dialogLinkContent4,
      },
    });
  }

  openOptionsDialog() {
    this.dialog.open(AdvancedOptionsComponent, {
      height: '90%',
      width: '50%'
    });
  }

  exportPDF() {
    let data = this.getBusinessCaseValues();

    const body = {
      data: data, // JSON data
      convertTo: "pdf", // String|Object, to convert the document (pdf, xlsx, docx, ods, csv, txt, ...)
      lang: "nl", // String, output lang of the report
    };

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-type": "application/json",
        Authorization: `Bearer ${environment.carboneToken}`,
        "carbone-version": "2",
      }),
    };

    if (this.cartoService.selectedProvinceId === undefined || this.cartoService.selectedProvinceId === null) {
      this.cartoService.selectedProvinceId = this.cartoService.getProvinceId(this.cartoService.selectedMunicipalityId + this.cartoService.cartoMunicipalityStartId)
    }
    let prov = this.cartoService.provCodeToName(this.cartoService.selectedProvinceId);
    let mun = this.cartoService.munCodeToName(this.cartoService.selectedMunicipalityId + this.cartoService.cartoMunicipalityStartId);
    this.cartoService.analytics.logEvent('export_pdf', {
      gemeente: mun,
      address: data['address'],
      province: prov,
      data: data,
      custom_event_type: 'pdf_export'
    })

    const templateId = this.carboneTemplateId

    const url = `https://render.carbone.io/render/${templateId}`;

    this.httpClient
      .post(url, body, httpOptions)
      .subscribe(this.handleRender.bind(this), (error) => { return })
  }

  handleRender(response) {
    let renderId = response["data"]["renderId"];

    this.httpClient
      .get("https://render.carbone.io/render/" + renderId, {
        responseType: "blob",
      })
      .subscribe(
        (blob) => {
          saveAs(
            blob,
            "parkeerlocatie_" +
            this.cartoService.parkingData.adrs_name +
            "_" +
            this.getTodayDate() +
            ".pdf"
          );
        },
        (error) => { return; }
      );
  }

  getBusinessCaseValues() {
    let data = {};

    //Metadata
    data["name"] = this.cartoService.parkingData.adrs_name;
    data["address"] = this.cartoService.parkingData.address;
    data["date"] = this.getTodayDate();
    data["condition"] = {
      value: this.businessService.totalSuitabilityPopovers[this.businessService.getTotalSuitability() - 1].title,
      flag: this.getFlagColor(this.businessService.getTotalSuitability()),
    }
    data["payback_time"] = Math.round(this.businessService.getPaybackTime());
    data["color"] = "#ff0000";

    // First page
    data["surface_area"] = {
      name: "Oppervlakte locatie",
      value: this.businessService.roundTens(this.cartoService.parkingData.area, 1),
      flag: this.getFlagColor(this.businessService.getTotalAreaFlagColor()),
    };

    data["sunny_area"] = {
      name: "Zonnige bebouwbare oppervlakte",
      value: this.businessService.roundTens(this.businessService.getSunnyArea(), 1),
      flag: this.getFlagColor(this.businessService.getSunnyFlagColor()),
    };

    data["carport_area"] = {
      name: "Oppervlakte solar carport",
      value: this.businessService.roundTens(this.businessService.getCarportArea(), 1),
      flag: this.getFlagColor(this.businessService.getSolarCarportAreaFlagColor()),
    };

    data["parking_power"] = {
      name: "Op te stellen vermogen",
      value: Math.round(this.businessService.getSolarParkingPower(3)),
      flag: this.getFlagColor(this.businessService.getSolarPowerFlagColor()),
    };

    data["is_protected"] = {
      name: "Beschermd stadsgezicht",
      value: this.businessService.isProtectedCityscape() ? "Ja" : "Nee",
      flag: this.getFlagColor(this.businessService.getCityscapeFlagColor()),
    };

    data["grid_distances"] = {
      name: "Afstand netaansluiting",
      to_distribution: {
        name: this.stage1LowGridDistanceText,
        value: this.businessService.getLowVoltageGridDistance(),
      },
      to_medium_voltage: {
        name: this.stage1MidGridDistanceText,
        value: this.businessService.getMidVoltageGridDistance(),
      },
      flag: this.getFlagColor(this.businessService.getGridDistanceFlagColor()),
    };

    data["connection_size"] = {
      name: "Grootte netaansluiting",
      value: this.businessService.getGridConnectionName(),
    };

    // Second page
    data["permit_costs"] = {
      name: "Leges omgevingsvergunning",
      value: this.businessService.roundTens(this.businessService.getLegesCosts(), 2),
    };

    data["research_costs"] = {
      name: "Vooronderzoek",
      value: this.businessService.roundTens(this.businessService.getResearchCosts(), 2),
    };

    data["grid_costs_fixed"] = {
      name: "Netaansluiting",
      value: this.businessService.roundTens(this.businessService.getFixedGridCosts(), 2),
    };

    data["solarcarport_costs"] = {
      name: "Solarcarport",
      value: this.businessService.roundTens(this.businessService.getSolarCarportCosts(), 2),
      type: this.getNameByValue(
        this.businessService.solarCarportOptions.items,
        this.businessService.solarCarportType
      ),
    };

    data["fundering_costs"] = {
      name: "Fundering",
      value: this.businessService.roundTens(this.businessService.getFunderingCosts(), 2),
    };

    data["charger_costs"] = {
      name: "Laadpunten",
      value: this.businessService.roundTens(this.businessService.getChargerCosts(), 2),
      type: this.getNameByValue(
        this.businessService.chargerOptions.items,
        this.businessService.chargerType
      ),
      num_chargers: this.businessService.getNumChargers(),
    };


    data["battery_costs"] = {
      name: "Batterij systemen",
      value: "In ontwikkeling",
    };

    data["light_costs"] = {
      name: "Verlichting",
      value: this.businessService.roundTens(this.businessService.getLightCosts(), 2),
      type: this.getNameByValue(
        this.businessService.lightOptions.items,
        this.businessService.lightType
      ),
    };

    data["security_costs"] = {
      name: "Beveiliging",
      value: this.businessService.roundTens(this.businessService.getSecurityCosts(), 2),
      type: this.getNameByValue(
        this.businessService.securityOptions.items,
        this.businessService.securityType
      ),
    };

    data["grid_costs_yearly"] = {
      name: "Netaansluiting",
      value: this.businessService.roundTens(this.businessService.getYearlyGridCosts(), 1),
    };

    data["rent_costs"] = {
      name: "Grondhuur",
      value: this.businessService.roundTens(this.businessService.getYearlyRentCosts(), 1),
      tariff: this.businessService.getRentTariff(),
    };

    data["maintenance_costs"] = {
      name: "Onderhoud",
      value: this.businessService.roundTens(this.businessService.getYearlyMaintenanceCosts(), 2),
    };

    data["insurance_costs"] = {
      name: "Verzekering",
      value: this.businessService.roundTens(this.businessService.getYearlyInsuranceCosts(), 2),
    };

    // Third page

    data["subsidy_revenue"] = {
      name: "Subsidie",
      value: this.businessService.roundTens(this.businessService.getYearlySubsidyRevenue(), 2),
      type: this.getNameByValue(
        this.businessService.subsidyDropdown.items,
        this.businessService.subsidyOption
      ),
    };

    data["other_subsidy_revenue_yearly"] = {
      name: "Andere subsidies",
      value: this.businessService.roundTens(this.businessService.getYearlyOtherSubsidy(), 2),
    };


    data["yearly_energy"] = {
      name: "Opgewekte energie",
      value: this.businessService.roundTens(this.businessService.getYearlyEnergy(3), 2),
    };

    data["salderen"] = {
      name: "Salderen",
      value: this.round(this.businessService.getYearlySalderenRevenue(), 1),
      type: this.getNameByValue(
        this.businessService.salderenOptions.items,
        this.businessService.salderenDefaultKwhUsage
      ),
      usage: this.round(this.businessService.getSalderenKwhUsage(), 1),
    };

    data["sales_revenue"] = {
      name: "Terugleveren aan net",
      value: this.businessService.roundTens(this.businessService.getYearlyGridSalesRevenue(), 2),
      tariff: this.round(this.businessService.getGridSalesTariff(), 1),
    };

    data["charging_revenue"] = {
      name: "Laden",
      value: this.businessService.roundTens(this.businessService.getYearlyChargingRevenue(), 1),
      parkingTime: this.getNameByValue(
        this.businessService.parkingTimeOptions.items,
        this.businessService.parkingTime
      ),
      parkingCoverage: this.getNameByValue(
        this.businessService.parkingCoverageOptions.items,
        this.businessService.parkingCoverage
      ),
    };

    data["extra_parking_revenue"] = {
      name: "Overdekt parkeren",
      value: this.businessService.roundTens(this.businessService.getYearlyExtraParkingRevenue(), 1),
      tariff: this.round(this.businessService.getExtraParkingTariff(), 1),
    };

    data["co2_savings"] = {
      name: "CO2 besparing",
      value: this.businessService.roundTens(this.businessService.getCo2Savings(), 2),
    };

    data["other_subsidy_revenue_fixed"] = {
      name: "Andere subsidies",
      value: this.businessService.roundTens(this.businessService.getFixedOtherSubsidy(), 2),
    };


    // Totals
    const fixedCosts = this.businessService.getFixedCosts()
    const yearlyCosts = this.businessService.getYearlyCosts()
    const fixedRevenue = this.businessService.getFixedRevenue()
    const yearlyRevenue = this.businessService.getYearlyRevenue()

    data["fixed_costs"] = this.businessService.roundTens(fixedCosts, 2)
    data["yearly_costs"] = this.businessService.roundTens(yearlyCosts, 2);
    data["fixed_revenue"] = this.businessService.roundTens(fixedRevenue, 2);
    data["yearly_revenue"] = this.businessService.roundTens(yearlyRevenue, 2);

    // Totals
    data["total_investment"] = this.businessService.roundTens(fixedCosts - fixedRevenue, 2);
    data["total_revenue"] = this.businessService.roundTens(yearlyRevenue - yearlyCosts, 2);;

    return data;
  }

  getFlagColor(id: number) {
    if (id == 1) {
      return "#ff0000";
    } else if (id == 2) {
      return "#ffa500";
    } else if (id == 3) {
      return "#008000";
    } else {
      return "#000000";
    }
  }

  getNameByValue(array, value) {
    if (value == undefined) return "";

    // return the name of the selected option
    return array.filter((item) => item.value == value)[0].name;
  }

  getTodayDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    return dd + "/" + mm + "/" + yyyy;
  }

  round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  public openEnergyCooperationDialog() {
    if (this.cartoService.selectedProvinceId === 7) {
      // If province is Noord-Holland, include this custom content
      this.openDialog(this.tooltipText.energyCooperationTitle, this.tooltipText.energyCooperationContent_NH, 'https://veinh.nl/', 'Kijk op de website van VEI NH', this.tooltipText.energyCooperationContent2, undefined, undefined, undefined, undefined, 'https://www.rvo.nl/initiatieven/overzicht/27975', 'Bekijk de website van het RVO')
    } else {
      this.openDialog(this.tooltipText.energyCooperationTitle, this.tooltipText.energyCooperationContent, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    }
  }

  public openFinanceDialog() {
    if (this.cartoService.selectedProvinceId === 6) {
      // If province is Utrecht, include this custom content
      this.openDialog(this.tooltipText.arrange_financing_title, this.tooltipText.arrange_financing_content_utrecht, 'https://www.energiefondsutrecht.nl/', 'Vind meer info op de website van het Energiefonds Utrecht', undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    } else {
      this.openDialog(this.tooltipText.arrange_financing_title, this.tooltipText.arrange_financing_content, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    }
  }

  public getSolarCarportInfoText(): string {
    const carportKwp = this.businessService.getSolarParkingPower(3);
    return `Op basis van het op te stellen vermogen van ${Math.round(carportKwp)} kWp`
  }

  public getChargingInfoText(): string {
    if (this.businessService.chargerType === 0 || this.businessService.getNumChargers() === 0) {
      return 'Je hebt geen laadpalen gekozen. Ga terug naar de investering om laadpalen toe te voegen'
    }

    const chargerText = this.businessService.chargerOptions.items[this.businessService.chargerType].name;

    let numParkedElectricCars = Math.round(this.businessService.numParkedElectricCars())
    let numChargingPoints = this.businessService.numChargingPoints()
    let hint1: string = ""
    let hint2: string = ""
    if (numParkedElectricCars > numChargingPoints) {
      hint1 = `Tip: je kan overwegen hier meer laadpunten te plaatsen. `
    } else if (numParkedElectricCars < numChargingPoints) {
      hint1 = `Tip: je kan overwegen hier minder laadpunten te plaatsen. `
    } else {
      hint2 = " Goed bezig!"
    }

    let numElectricCarText: string = "";
    if (numParkedElectricCars < 1) {
      numElectricCarText = "minder dan 1 elektrische auto tegelijkertijd parkeert"
    } else if (numParkedElectricCars < 2) {
      numElectricCarText = `1 elektrische auto tegelijkertijd parkeert`
    } else {
      numElectricCarText = `${numParkedElectricCars} elektrische auto's tegelijkertijd parkeren`
    }

    return `${hint1}We verwachten dat hier gemiddeld ${numElectricCarText}.
    Je hebt gekozen voor ${numChargingPoints} laadpunten.${hint2} Nog een tip: parkeren auto's hier kort? Overweeg een snellader.
    `
  }

  public getBatteryRevenueInfoText(): string {
    if(this.businessService.batteryType === 0) {
      return `Je hebt geen batterijsysteem gekozen. Wil je een batterijsysteem toevoegen? Ga dan terug naar de investeringen.`
    }
    
    return `Er wordt vanuit gegaan dat ${Math.round(this.businessService.fractionOfBatteryUsedForTrading*100)}% van het batterijsysteem (${this.businessService.batterySize* this.businessService.fractionOfBatteryUsedForTrading} kWh) wordt gebruikt als reservevermogen om te handelen.`
  }

  public backButtonText(): string {
    if (this.cartoService.selectedMunicipalityId) {
      return "Terug naar gemeente"
    } else {
      return "Terug naar provincie"
    }
  }

}

import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class SEOService {
  constructor(private title: Title, private meta: Meta) {}

  setTitle(title: string) {
    this.title.setTitle(title);
  }

  setKeywords(keywords: string) {
    // WARNING: this creates a 'keywords' meta tag only if there isn't one yet
    return this.meta.addTag({ name: "keywords", content: keywords }, true);
  }

  updateKeywords(keywords: string) {
    this.meta.updateTag({ name: "keywords", content: keywords });
  }
  
  updateDescription(desc: string) {
    this.meta.updateTag({ name: "description", content: desc });
  }

  setTag(name: string, content: string) {
    return this.meta.addTag({ name: name, content: content }, true);
  }

  removeTag(selector: string) {
    this.meta.removeTag(selector);
  }




}

<div class="container-fluid main-content-container background-secondary">
  <div class="row justify-content-center background-secondary">
    <div
      class="col-12 col-md-10 col-xl-8 d-flex flex-column align-items-center"
    >
      <h1 class="text-center heading-underline-white">
        Samen de zon parkeren?
      </h1>
    </div>
  </div>

  <div class="row justify-content-center background-secondary py-5 contact-div">
    <div class="col-12 col-md-10 col-xl-8 px-4">
      <div class="row">
        <div class="col-12 col-lg-8 offset-lg-2 mt-4">
          <p
            style="
              font-weight: 500;
              padding-left: 20px;
              padding-right: 20px;
              font-size: 20px;
              text-align: center;
              color: white;
            "
          >
            Wil je als gemeente, provincie, RES-regio of ontwikkelaar aan de
            slag met Park the Sun? We gaan graag in gesprek.
          </p>
        </div>
      </div>
      <div style="color: white" class="row justify-content-center mb-5 my-2">
        <div
          class="col-12 col-md-5 col-lg-4 d-flex flex-column align-items-center mb-5 mb-md-0"
          *ngFor="let member of team"
        >
          <div class="background-profile-image">
            <img
              class="image-profile mb-4"
              src="{{ member.photo }}"
              alt="Foto van {{ member.name }}"
            />
          </div>
          <p class="font-weight-bold m-0">{{ member.name }}</p>
          <p>{{ member.title }}</p>
          <p class="font-weight-bold m-0">{{ member.email }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center background-light py-5">
    <div class="col-12 col-md-8 col-xl-6 px-4">
      <div class="row">
        <div class="col-12 d-flex flex-column align-items-center mb-4">
          <h1 class="text-center heading-underline-black">Samenwerking</h1>
          <h5 style="margin-top: 20px">
            Park the Sun wordt door Sobolt ingezet met lokale- en regionale
            overheden. De volgende gebieden stellen de komende jaren solar
            carport info beschikbaar:
          </h5>
        </div>
        <!-- <div class="col-12 col-md-6 col-lg-4 d-flex flex-column align-items-center justify-content-center mb-4">
          <a href="https://www.sobolt.com/" rel="noopener noreferrer" target="_blank">
            <img class="img-fluid" src="../../../assets/images/sobolt-logo.png" alt="Logo Sobolt" />
          </a>
        </div> -->
        <div
          class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0 d-flex align-items-center justify-content-center my-4 px-5"
          *ngFor="let partner of partners"
        >
          <a
            href="{{ partner.link }}"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              class="img-fluid"
              src="{{ partner.image }}"
              alt="Logo {{ partner.name }}"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

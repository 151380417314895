import { Injectable, ElementRef, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  RegionData,
  ParkingData,
  MapFilters,
  MapConfig,
} from "./carto.interface";
import { LayoutService } from "../layout/layout.service";
import { Router } from "@angular/router";
import { Observable, forkJoin } from "rxjs";

import { fullProvinceClients,verifiedProvinceIds } from "../../assets/json/provinces";
import { verifiedMunicipalityIds, rotterdamCoords } from "../../assets/json/municipalities";
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { environment } from "src/environments/environment";

// Grabs the variables from the pure javascript libraries
declare var mapboxgl: any;
declare var carto: any;
declare var require: any;
declare var firebase: any;

@Injectable({
  providedIn: "root",
})
export class CartoService {
  @Output() aClickedEvent = new EventEmitter<string>();
  map: any;

  // List of regions: I.e. Den Haag, to manually change name from
  cartodb_idArrayOfRegionsToChangeName: number[] = [16946];
  // Index signature of the above
  indexOfRegionsToChangeName: { [id: string]: string } = {
    16946: "Den Haag"
  }

  private api_key: string = 'mwRM3-b16znn6QvLZ8qcJA';
  private baseUrl: string = "https://sobolt.carto.com/api/v2/sql?q=";
  private ParkingRastersWMSLayerName: string = "parking-rasters-211215";
  public dataset: string = environment.dataset;
  public regionDataset: string = "regions_nl_pts";
  
  private google_maps_api_key = "AIzaSyASTqvsgYipnjjJCymM--VW09-_EvdZFTk"
  analytics: any = firebase.analytics();
  selectedProvinceId: number;
  selectedMunicipalityId: number;
  municipalityData: any; // ParkingData[]
  municipalityDataFiltered: any; // ParkingData[]
  municipalityDataSorted: any; //parkingData[]
  municipalityDataSearched: any = this.municipalityDataSorted;
  loadingProvinceData: boolean = false;
  loadingMunicipalityData: boolean = false;
  hideProvMuniCard: boolean = true;
  provinceData: any; // ParkingData[]
  provinceDataFiltered: any; // ParkingData[]
  provinceDataSorted: any; //parkingData[]
  provinces: RegionData[];
  municipalities: RegionData[];
  cartoProvinceStartId: number = 1;
  cartoMunicipalityStartId: number = 16771;
  provinceZoomThreshold: number = 8.75;
  municipalityZoomThreshold: number = 11;
  parkingZoomThreshold: number = 11;
  zoomToProvinceLevel: number = 8.76;
  zoomToMunicipalityLevel: number = 11.01;
  zoomToSingleParkingLevel: number = 16;
  singleParkingZoomThreshold: number = 6;
  // parkingData: ParkingData = {} as ParkingData;
  parkingData: ParkingData;
  public powerFilter: number = 0;
  public areaFilter: number = 65;
  public gridDistanceFilter: number = 999999;
  public privateFilter: number = -1;
  public protectedFilter: string = "YES"

  showParkingList: boolean = false;
  showParkingListProv: boolean = false;
  provinceBoolean: boolean = false;

  totalArea: number;
  hashSize: number = 21;
  showFilters: boolean = false;

  showLegend: boolean = false;

  solarPanelm2Database: number = 1.65;
  solarPanelKwpDatabase: number = 280;
  solarPanelm2New: number = 1.65;
  solarPanelKwpNew: number = 345;
  correctionKwpDatabase: number = (this.solarPanelKwpNew / this.solarPanelm2New) / (this.solarPanelKwpDatabase / this.solarPanelm2Database); // In the database calculations are done with 280Wp 1.65m2 panels, we calculate with 345Wp 1.65m2 panels

  municipalityNotSuitableMessage: string = "Deze parkeerplaats is door de gemeente als niet-geschikt aangegeven. Let op, dit betekent niet per direct dat andere parkeerplaatsen wel geschikt zijn. Neem altijd contact op met jouw gemeente."
  customMunicipalityMessageIds: number[] = [
    2,
    175
  ]
  customMunicipalityMessages: string[] = [
    "Deze parkeerplaats is aangemerkt als economisch slecht haalbaar. Let op, dit betekent niet per se dat andere parkeerplaatsen wel economisch rendabel zijn of een omgevingsvergunning krijgen. Neem altijd eerst contact op met <a href='https://groningenwerktslim.com/contact.html' target='blank'>Groningen Werkt Slim</a>.",
    "Deze parkeerplaats is door de gemeente als niet-geschikt aangegeven. <a href='/assets/client_files/Toelichting parkeerlocaties Den Haag.xlsx'>Download dit bestand</a> voor verdere toelichting. Let op, alleen parkeerplaatsen groter dan 3000m2 zijn door de gemeente beoordeeld. Daarnaast betekent dit niet per direct dat andere parkeerplaatsen wel geschikt zijn. Neem altijd contact op met gemeente Den Haag."
  ]

  municipalityHiddenParkingClickEnabledList: number[] = [
    2,
  ]


  flying: boolean = false;

  privatePublicMunicipalities: number[] = [2]; // List of municipality Ids that have the private public feature enabled

  public munCodeToName(code) {
    return this.municipalities[code - this.cartoMunicipalityStartId]['name'];
  }

  public provCodeToName(code) {
    return this.provinces[code]['name'];
  }

  public getProvinceId(gm_code:number) {
    let selectedMunicipality = this.municipalities.filter(function(municipality) {return municipality.cartodb_id == gm_code})[0]
    return Number(selectedMunicipality.province_id)
  }

  getFiltersQuery(): string {
    return ` ${this.dataset}.area >= ${this.areaFilter} AND ${
      this.dataset
    }.cap_kwp >= ${this.powerFilter / this.correctionKwpDatabase} AND ${
      this.dataset
    }.griddist_m <= ${this.gridDistanceFilter} ${
      this.protectedFilter === "NO"
        ? `AND ${this.dataset}.protected IS NULL`
        : ""
    }${
      this.privateFilter === 0
        ? `AND ${this.dataset}.public_own = 0`
        : this.privateFilter === 1
        ? `AND ${this.dataset}.public_own = 1`
        : ""
    }`;
  }
  public tooltipJsonURL = "../../assets/tooltipstext.json";

  initCenter: number[] = [5.5, 52.2];
  initZoom: number = 7;

  initCenterMobile: number[] = [5.35, 52.28];
  initZoomMobile: number = 6.2;

  containerRef: ElementRef;

  currentMunicipalityParkingLayer: any;

  numberOfParkingLots: any;
  private parkingCenterOffset: number = 0.003 // Offset to get parking on the left side of the screen when zomming to it to not overlap with the business case card

  layers: any;
  sources: any;
  sprite: any;

  basemaps = [
    {
      id: "voyager",
      name: "Kaart",
      style: carto.basemaps.voyager,
      databelow: "watername_ocean",
    },
    {
      id: "terrain",
      name: "Luchtfoto",
      style: {
        version: 8,
        sources: {
          "terrain-tiles-source": {
            type: "raster",
            tiles: [
              "https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0?service=wms&request=GetMap&layers=2021_orthoHR&&bbox={bbox-epsg-3857}&width=512&height=512&format=image/png&crs=EPSG:3857&version=1.3.0&styles=&transparent=true",
            ],
            tileSize: 512,
          },
        },
        layers: [
          {
            id: "terrain-tiles-layer",
            type: "raster",
            source: "terrain-tiles-source",
            minzoom: 6,
            maxzoom: 19,
          },
        ],
        glyphs: "https://tiles.basemaps.cartocdn.com/fonts/{fontstack}/{range}.pbf",
      },
      databelow: "watername_ocean",
    },
    {
      id: "sun",
      name: "Zon",
      style: {
        version: 8,
        sources: {
          "terrain-tiles-source": {
            type: "raster",
            tiles: [
              "https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0?service=wms&request=GetMap&layers=2021_quick_orthoHR&&bbox={bbox-epsg-3857}&width=512&height=512&format=image/png&crs=EPSG:3857&version=1.3.0&styles=&transparent=true",
            ],
            tileSize: 512,
          },
          "sun-tiles-source": {
            type: "raster",
            tiles: [
              `https://maps.parkthesun.com/geoserver/parkthesun/ows?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&BBOX={bbox-epsg-3857}&CRS=EPSG:3857&WIDTH=512&HEIGHT=512&LAYERS=${this.ParkingRastersWMSLayerName}&STYLES=&FORMAT=image/png&TRANSPARENT=TRUE`, //&DPI=96&MAP_RESOLUTION=96&FORMAT_OPTIONS=dpi:96"
            ],
            tileSize: 512,
          },
        },
        layers: [
          {
            id: "terrain-tiles-layer",
            type: "raster",
            source: "terrain-tiles-source",
            minzoom: 6,
            maxzoom: 19,
          },
          {
            id: "sun-tiles-layer",
            type: "raster",
            source: "sun-tiles-source",
            minzoom: 6,
            maxzoom: 19,
          },
        ],
        glyphs: "https://tiles.basemaps.cartocdn.com/fonts/{fontstack}/{range}.pbf",
      },
      databelow: "watername_ocean",
    },
  ];

  selectedBasemap: any = "voyager";

  routeProvince: RegionData;
  routeMunicipality: RegionData;
  routeParking: number;

  zoomedParkingFromRoute = false;
  appliedCustomFilters: boolean = false;
  hiddenUrlProvinceCartodbId: number;
  hiddenUrlMunicipalityCartodbIds: number[];

  constructor(public http: HttpClient, private router: Router, private layoutService: LayoutService) { }

  initFilters(mapFilters: MapFilters) {
    this.areaFilter = mapFilters.areaFilter;
    this.powerFilter = mapFilters.powerFilter;

    // Cancel zoom to province/municipality
    this.appliedCustomFilters = true;
  }

  initConfig(mapConfig: MapConfig) {
    // Load filters
    if (mapConfig.filters) {
      this.toggleFilters()
      this.areaFilter = mapConfig.filters.areaFilter;
      this.powerFilter = mapConfig.filters.powerFilter;
      this.gridDistanceFilter = mapConfig.filters.gridDistanceFilter;
      this.protectedFilter = mapConfig.filters.protectedFilter;
    }
    
    // Load business case
    if (mapConfig.businessCase) {
      if (mapConfig.businessCase.parkingData) {
        this.parkingData = mapConfig.businessCase.parkingData;
      }
    }

    // Cancel zoom to province/municipality
    this.appliedCustomFilters = true;
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  routeIfNoMunicipality() {
    if (this.appliedCustomFilters) {
      return;
    }

    let path = this.getProvinceAndMunicipality(this.router.url);

    if (path.province) {
      if (path.province === "app") {
        return;
      }

      let province = this.provinces.filter(
        (x) => x.name.toLowerCase() == path.province.toLowerCase()
      );

      if (
        province.length != 1 ||
        !this.checkProvincePermission(province[0].cartodb_id)
      ) {
        // alert("Data voor deze provincie is niet beschikbaar.");
        this.router.navigate(["/not-found"]);
      }

      this.routeProvince = province[0];
      this.setProvinceId(this.routeProvince.cartodb_id);
    }

    if (path.municipality) {
      let municipality = this.municipalities.filter(
        (x) => x.name.toLowerCase() == path.municipality.toLowerCase()
      );

      if (
        municipality.length != 1 ||
        !this.checkMunicipalityPermission(municipality[0].cartodb_id)
      ) {
        // alert("Data voor deze gemeente is niet beschikbaar.");
        this.router.navigate(["/not-found"]);
      }

      this.routeMunicipality = municipality[0];

      this.setMunicipalityId(this.routeMunicipality.cartodb_id);
    }
  }

  getProvinceAndMunicipality(url: string) {
    let segments: Array<string> = this.router.url.split("/");
    let province: string = segments[1] ? segments[1].toLowerCase() : undefined;

    if (segments[1] == "app") return;

    let municipality: string = segments[2]
      ? segments[2].toLowerCase()
      : undefined;

    return {
      province: province,
      municipality: municipality,
    };
  }

  AClicked(msg: string) {
    this.aClickedEvent.emit(msg);
  }

  getCartoData(query: string) {
    return this.http.get(`${this.baseUrl}${query}&api_key=${this.api_key}`);
    // .pipe(delay(0));
  }

  private setBasemap(style: any, zoom: number, center: number[]) {
    // Initialize the map
    this.map = new mapboxgl.Map({
      container: this.containerRef.nativeElement,
      style: style,
      center: center,
      zoom: zoom,
      minZoom: 6,
      maxZoom: 19,
      maxTileCacheSize: 1,
      transformRequest: (url, resourceType) => {
        if (
          /* resourceType == 'Tile' && */ url.indexOf(
          "https://maps.parkthesun.com"
        ) > -1
        ) {
          return {
            url: url,
            headers: {
              Authorization: `Basic ${btoa("admin:Sgfgi2020")}`,
            },
          };
        }
      },
    });

    const nav = new mapboxgl.NavigationControl({
      showCompass: false,
    });

    // const geocoder = new MapboxGeocoder({
    //   // accessToken: mapboxgl.accessToken,
    //   mapboxgl: mapboxgl,
    //   accessToken: mapboxgl.accessToken
    // });

    this.map.addControl(nav, "bottom-left");

    // this.map.addControl(geocoder, "bottom-left");

    // Set credentials to authenticate to carto
    carto.setDefaultAuth({
      username: "sobolt",
      apiKey: this.api_key,
    });

    this.setEventsOnMap();
  }

  private setEventsOnMap() {
    this.map.on("flystart", () => {
      this.flying = true;
    });

    this.map.on("flyend", () => {
      this.flying = false;
    });
  }

  public changeBasemap(style, id, callback?) {
    this.resetMap();

    let zoom: number = this.map.getZoom();
    let center: number[] = this.map.getCenter();

    this.setBasemap(style, zoom, center);

    if (id == "sun") {
      this.showLegend = true;
    }
    else {
      this.showLegend = false;
    }


    this.forkJoinMunicipalityProvince(() => {
      //this.getRealisedProjects();
      this.initProvinces();
      this.initMunicipalities();
      if (this.parkingData) {
      // if (this.parkingData && id != "sun" || "terrain") {
        this.initMunicipalityParkings();
        this.showOneParkingLot();
      } else if (this.selectedMunicipalityId) {
        this.initMunicipalityParkings();
      }
      callback();
    });
  }

  resetMap() {
    this.map.remove();
  }

  getInitZoom(): number {
    if (this.layoutService.isMobileLayout) {
      return this.initZoomMobile;
    }
    return this.initZoom;
  }

  getInitCenter(): number[] {
    if (this.isMobile()) {
      return this.initCenterMobile;
    }
    return this.initCenter;
  }

  isMobile(): boolean {
    return window.innerWidth <= 1024;// && window.innerHeight <= 768;
  }

  public initBasemap(
    mapContainerReference: ElementRef,
    zoom: number = this.getInitZoom(),
    center: number[] = this.getInitCenter()
  ) {
    this.containerRef = mapContainerReference;
    this.setBasemap(carto.basemaps.voyager, zoom, center);
    this.layoutService.inApplication = true;
    this.layoutService.showNavbar = false

    // this.getMunicipalityAndProvinceNames();
    this.forkJoinMunicipalityProvince(() => {
      //this.getRealisedProjects();
      this.initProvinces();
      this.initMunicipalities();
    });
  }

  public getJSON(): Observable<any> {
    return this.http.get(this.tooltipJsonURL);
  }

  forkJoinMunicipalityProvince(callback) {
    // Get province & municipality names + ids and sort on ID
    forkJoin(this.getMunicipalityNames(), this.getProvinceNames()).subscribe(
      ([municipalities, provinces]) => {
        this.processMunicipalities(municipalities);
        this.processProvinces(provinces);
        callback();
      }
    );
  }

  getMunicipalityNames() {
    const municipalityQuery = `SELECT cartodb_id, statnaam, province_id  FROM ${this.regionDataset} WHERE rubriek='gemeente'`;
    return this.getCartoData(municipalityQuery);
  }

  getProvinceNames() {
    const provinceQuery = `SELECT cartodb_id, statnaam FROM ${this.regionDataset} WHERE rubriek='provincie'`;
    return this.getCartoData(provinceQuery);
  }

  processProvinces(cartoData) {
    this.provinces = cartoData.rows.map((province) => {
      return { name: province.statnaam, cartodb_id: province.cartodb_id };
    });
    this.provinces.sort((a, b) => {
      return a.cartodb_id - b.cartodb_id;
    });
    this.getProvinceNumParkings();
  }

  processMunicipalities(cartoData) {
    this.municipalities = cartoData.rows.map((municipality) => {
      if (this.cartodb_idArrayOfRegionsToChangeName.includes(municipality.cartodb_id)) {
        return {
          name: this.indexOfRegionsToChangeName[municipality.cartodb_id],
          cartodb_id: municipality.cartodb_id,
        };
      } else {
        return {
          name: municipality.statnaam,
          cartodb_id: municipality.cartodb_id,
          province_id: municipality.province_id
        };
      }
    });
    this.municipalities.sort((a, b) => {
      return a.cartodb_id - b.cartodb_id;
    });
    this.getMunicipalityNumParkings();
  }

  private getProvinceNumParkings() {
    this.provinces.forEach((province, idx) => {
      const numParkingsQuery = `SELECT COUNT(${this.dataset}.*) FROM ${this.dataset}, ${this.regionDataset} WHERE ST_Intersects(${this.dataset}.the_geom, ${this.regionDataset}.the_geom) AND ${this.regionDataset}.rubriek='provincie' AND ${this.dataset}.mun_hidden='0' AND ${this.regionDataset}.cartodb_id=${province.cartodb_id}`;
      this.getCartoData(numParkingsQuery).subscribe((data: any) => {
        this.provinces[idx].numParkings = data.rows[0].count;
      });
    });
  }

  private getMunicipalityNumParkings() {
    this.municipalities.forEach((mun, idx) => {
      if (
        this.checkMunicipalityPermission(idx + this.cartoMunicipalityStartId)
      ) {
        const numParkingsQuery = `SELECT COUNT(${this.dataset}.*) FROM ${this.dataset}, ${this.regionDataset} WHERE ST_Intersects(${this.dataset}.the_geom, ${this.regionDataset}.the_geom) AND ${this.regionDataset}.rubriek='gemeente' AND ${this.dataset}.mun_hidden='0' AND ${this.regionDataset}.cartodb_id=${mun.cartodb_id}`;
        this.getCartoData(numParkingsQuery).subscribe((data: any) => {
          this.municipalities[idx].numParkings = data.rows[0].count;
        });
      }
    });
  }

  showOneParkingLot() {
    var mapLayer = this.map.getLayer("one_parking_lot_layer");
    if (typeof mapLayer !== "undefined") {
      // Remove map layer & source.
      this.map.removeLayer("one_parking_lot_layer");
    }
    const query = `SELECT * FROM ${this.dataset} WHERE ${this.dataset}.cartodb_id=${this.parkingData.id}`;
    const municipalityParkingViz = new carto.Viz(`
      filter: zoom()>${this.singleParkingZoomThreshold}
      color: rgba(242,242,71,0.0)
      strokeColor: rgb(31, 183, 255)
      strokeWidth: 2
      @area: $area
      @address: $address
      @sunny_area: $sunny_area,
      @pc_sn_area: $pc_sn_area,
      @suit_area: $suit_area,
      @cap_kwp: $cap_kwp*${this.correctionKwpDatabase},
      @energy_mwh: $energy_mwh,
      @protected: $protected
    `);
    const municipalityParkingSource = new carto.source.SQL(query);
    const oneParkingLayer = new carto.Layer(
      "one_parking_lot_layer",
      municipalityParkingSource,
      municipalityParkingViz
    );
    oneParkingLayer.addTo(this.map);

    const interactiveParkingLayer = new carto.Interactivity(oneParkingLayer);
  }

  removeLayer(layerName) {
    let mapLayer = this.map.getLayer(layerName);
    if (typeof mapLayer !== "undefined") {
      // Remove map layer & source.
      this.map.removeLayer(layerName);
    }
  }

  zoomOutfromParkingLot() {
    if (this.map.getLayer("sun-tiles-layer")) {
      this.map.removeLayer("sun-tiles-layer");
      this.selectedBasemap = this.basemaps[1].id;
    }

    if (this.map.getSource("sun-tiles-source")) {
      this.map.removeSource("sun-tiles-source");
      this.selectedBasemap = this.basemaps[1].id;
    }

    this.showLegend = false;
    this.hideProvMuniCard = false;
    this.parkingData = undefined;
    var mapLayer = this.map.getLayer("one_parking_lot_layer");
    if (typeof mapLayer !== "undefined") {
      // Remove map layer & source.
      this.map.removeLayer("one_parking_lot_layer");
    }

    if (this.selectedMunicipalityId) {
      this.map.flyTo({
        speed: 0.6,
        zoom: this.zoomToMunicipalityLevel,
      });
    } else if (this.selectedProvinceId) {
      this.map.flyTo({
        speed: 0.6,
        zoom: this.zoomToProvinceLevel,
      })
    }


  }

  zoomOutfromMunicipality() {
    this.selectedMunicipalityId = undefined
    this.hideProvMuniCard = false;
    this.parkingData = undefined;
    var mapLayer = this.map.getLayer("municipality_parking_layer");
    if (typeof mapLayer !== "undefined") {
      // Remove map layer & source.
      this.map.removeLayer("municipality_parking_layer");
    }


    var mapLayer = this.map.getLayer("one_parking_lot_layer");
    if (typeof mapLayer !== "undefined") {
      // Remove map layer & source.
      this.map.removeLayer("one_parking_lot_layer");
    }
    this.getProvinceData();
    this.map.flyTo({
      speed: 1,
      // center: coords,
      zoom: this.zoomToProvinceLevel,
    });
    // if (this.getCartoProvinceId() == 9) {
    //   // Zuid-Holland
    //   alert(
    //     "Informatie voor de volledige provincie is niet beschikbaar, maar voor de gemeenten met een blauwe cirkel wel."
    //   );
    // }
  }

  zoomOutfromProvince() {
    this.selectedProvinceId = undefined;
    this.selectedMunicipalityId = undefined;
    this.hideProvMuniCard = true;
    this.parkingData = undefined;
    var mapLayer = this.map.getLayer("one_parking_lot_layer");
    if (typeof mapLayer !== "undefined") {
      // Remove map layer & source.
      this.map.removeLayer("one_parking_lot_layer");
    }
    this.map.flyTo({
      speed: 1,
      // center: coords,
      zoom: 8,
    });
  }

  initMunicipalityParkings() {
    var mapLayer = this.map.getLayer("municipality_parking_layer");
    if (typeof mapLayer !== "undefined") {
      // Remove map layer & source.
      this.map.removeLayer("municipality_parking_layer");
    }

    const query = `SELECT ${this.dataset}.* FROM ${
      this.dataset
      }, ${this.regionDataset} WHERE (ST_Intersects(ST_BUFFER(${
      this.dataset
      }.the_geom, 0), ${this.regionDataset}.the_geom) AND ${this.regionDataset}.rubriek='gemeente' AND ${this.regionDataset}.cartodb_id='${this.getCartoMunicipalityId()}' AND ${this.getFiltersQuery()})`;

    const municipalityParkingViz = new carto.Viz(`
      filter: zoom()>${this.parkingZoomThreshold}
      color: ramp(buckets($mun_hidden, [1]), [rgba(242,242,71, 0.2), rgba(207, 0, 15, 0.3)])
      strokeColor: rgb(0,0,0)
      strokeWidth: 2
      @area: $area
      @address: $address
      @adrs_name: $adrs_name
      @sunny_area: $sunny_area,
      @pc_sn_area: $pc_sn_area,
      @suit_area: $suit_area,
      @cap_kwp: $cap_kwp* ${this.correctionKwpDatabase},
      @energy_mwh: $energy_mwh,
      @protected: $protected,
      @griddist_m: $griddist_m,
      @mun_hidden: $mun_hidden,
      @public_own: $public_own
    `);
    const municipalityParkingSource = new carto.source.SQL(query);
    const parkingLayer = new carto.Layer(
      "municipality_parking_layer",
      municipalityParkingSource,
      municipalityParkingViz
    );
    this.currentMunicipalityParkingLayer = parkingLayer;
    parkingLayer.addTo(this.map);

    const popup = new mapboxgl.Popup();
    const intParkingLayer = new carto.Interactivity(parkingLayer);
    intParkingLayer.on("featureEnter", (featureEvent) => {
      const feature = featureEvent.features[0];
      if (!feature) {
        return;
      }

      const adrs_name = feature.variables.adrs_name.value;
      const power = this.roundTens(feature.variables.cap_kwp.value, 1);
      const area = this.roundTens(feature.variables.area.value, 1);
      // Change value to Private/Public and Province to Groningen instead of Zuid-Holland
      const publiek = feature.variables.public_own.value == 0 ? "Nee" : "Ja"
      const coords = feature.getRenderedCentroid();
      popup
        .setLngLat(coords)
        .setHTML(
          this.parkingIsDisabled(feature.variables.mun_hidden.value) ?
            `<mat-card>
            <mat-card-content>
              <div class="parking-lot-card-content">
                <h4 class="heading-underline-secondary mb-3">${adrs_name}</h4>
                <p class="mb-1">${this.setMunicipalityNotSuitableMessage()}</p>
              </div>
            </mat-card-content>
          </mat-card>`
            : this.privatePublicFeatureActivated() ?
              `<mat-card>
            <mat-card-content>
              <div class="parking-lot-card-content">
                <h4 class="heading-underline-secondary mb-3">${adrs_name}</h4>
                <p class="mb-1"><i class="fa fa-bolt icon-sm mr-3 text-center"></i>Opwek: ${power} kWp</p>
                <p class="mb-1"><i class="fas fa-star icon-sm mr-3 text-center"></i>Oppervlak: ${area} m2</p>
                <p class="mb-1"><i class="fas fa-star icon-sm mr-3 text-center"></i>Publiek: ${publiek}</p>
              </div>
            </mat-card-content>
          </mat-card>`
              :
              `<mat-card>
            <mat-card-content>
              <div class="parking-lot-card-content">
                <h4 class="heading-underline-secondary mb-3">${adrs_name}</h4>
                <p class="mb-1"><i class="fa fa-bolt icon-sm mr-3 text-center"></i>Opwek: ${power} kWp</p>
                <p class="mb-1"><i class="fas fa-star icon-sm mr-3 text-center"></i>Oppervlak: ${area} m2</p>
              </div>
            </mat-card-content>
          </mat-card>`
        )
        .addTo(this.map);
    });

    // <p class="mb-1"><i class="fas fa-star icon-sm mr-3 text-center" *ngIf=""></i>Oppervlak: ${area} m2</p>


    intParkingLayer.on("featureLeave", () => {
      popup.remove();
    });

    intParkingLayer.on("featureClick", (featureEvent) => {
      if (
        this.map.getZoom() < this.municipalityZoomThreshold 
      ) {
        return;
      }
      if (!this.parkingIsDisabled(featureEvent.features[0].variables.mun_hidden.value)) {
        // Parking is not disabled
        this.onParkingClick(featureEvent);
      } else if (this.municipalityHiddenParkingClickEnabled()) {
        // Municipality has enabled hidden parking clicks, continue
        this.onParkingClick(featureEvent);
        return
      } else {
        // Parking is disabled, return
        return
      }
    });
  }

  private municipalityHiddenParkingClickEnabled(): boolean {
    return this.municipalityHiddenParkingClickEnabledList.indexOf(this.selectedMunicipalityId) > -1
  }

  public privatePublicFeatureActivated(): boolean {
    return this.privatePublicMunicipalities.indexOf(this.selectedMunicipalityId) > -1
  }

  private setMunicipalityNotSuitableMessage(): string {

    const customMunicipalityId = this.customMunicipalityMessageIds.indexOf(this.selectedMunicipalityId)
    if (customMunicipalityId > -1) {
      return this.customMunicipalityMessages[customMunicipalityId]
    } else {
      return this.municipalityNotSuitableMessage
    }
  }

  private parkingIsDisabled(mun_hidden: number) {
    return mun_hidden == 1
  }

  private parkingById(cartoId) {
    if (this.showParkingList) {
      var parking = this.municipalityData.filter((parking) => {
        return parking.cartodb_id === cartoId;
      });
    }
    if (this.showParkingListProv) {
      var parking = this.provinceData.filter((parking) => {
        return parking.cartodb_id === cartoId;
      });
    }
    this.parkingData = {
      id: parking[0].cartodb_id,
      address: parking[0].address,
      adrs_name: parking[0].adrs_name,
      area: parking[0].area,
      sunny_area: parking[0].sunny_area,
      pc_sn_area: parking[0].pc_sn_area,
      suit_area: parking[0].suit_area,
      cap_kwp: parking[0].cap_kwp,
      energy_mwh: parking[0].energy_mwh,
      protected: parking[0].protected,
      griddist_m: parking[0].griddist_m,
    };

    const viz = new carto.Viz(`
      @features: viewportFeatures()
    `);
    const myFeature = viz.variables.features.value.find(
      (x) => x.id === this.parkingData.id
    );

    const coords = [parking[0].centre_lon, parking[0].centre_lat];
    this.hideProvMuniCard = true;
    this.map.flyTo({
      speed: 0.6,
      center: coords,
      zoom: this.zoomToSingleParkingLevel,
    });
    this.showOneParkingLot();
    var mapLayer = this.map.getLayer("municipality_parking_layer");
    if (typeof mapLayer !== "undefined") {
      // Remove map layer & source.
      // this.map.removeLayer("municipality_parking_layer");
    }

    this.showParkingList = false;
    this.showParkingListProv = false;
  }

  private onParkingClick(featureEvent) {
    var mapLayer = this.map.getLayer("searchLocationLayer");
    if (typeof mapLayer !== "undefined") {
      // Remove map layer & source.
      this.map.removeLayer("searchLocationLayer");
    }
    const feature = featureEvent.features[0];
    if (!feature) {
      return;
    }
    this.parkingData = {
      id: feature.id,
      address: feature.variables.address.value,
      adrs_name: feature.variables.adrs_name.value,
      area: feature.variables.area.value,
      sunny_area: feature.variables.sunny_area.value,
      pc_sn_area: feature.variables.pc_sn_area.value,
      suit_area: feature.variables.suit_area.value,
      cap_kwp: feature.variables.cap_kwp.value,
      energy_mwh: feature.variables.energy_mwh.value,
      protected: feature.variables.protected.value,
      griddist_m: feature.variables.griddist_m.value,
    };

    this.analytics.logEvent('parking_click', {
      parking_id: this.parkingData.id,
      address: this.parkingData.address,
      custom_event_type: 'parking_click'
    })

    this.zoomToParking(feature.getRenderedCentroid());
  }

  zoomToParking(parkingCenterCoordinates: number[]) {
    this.hideProvMuniCard = true;
    this.map.flyTo({
      speed: 0.6,
      center: [parkingCenterCoordinates[0] + this.parkingCenterOffset, parkingCenterCoordinates[1]],
      zoom: this.zoomToSingleParkingLevel,
    });
    this.showOneParkingLot();
  }

  zoomedProvinceFromRoute = false;

  initProvinces() {
    const provinceViz = new carto.Viz(`
        @v_features: viewportFeatures($cartodb_id, $statnaam)
        color: rgba(255,255,255, 0)
        strokeColor: rgb(10, 188, 138)
        strokeWidth: 2
        filter: zoom()<${this.provinceZoomThreshold}
        @statnaam: $statnaam
    `);

    const provinceSource = new carto.source.SQL(
      `SELECT * FROM ${this.regionDataset} WHERE rubriek='provincie'`
    );
    const provinceLayer = new carto.Layer(
      "provinces",
      provinceSource,
      provinceViz
    );

    if (this.map.getLayer("provinces")) this.map.removeLayer("provinces");
    provinceLayer.addTo(this.map);

    provinceLayer.on("loaded", () => {
      const features = provinceViz.variables.v_features.value;
      // The first time the province layer is loaded get the province center coordinates
      if (!this.provinces[0].coordinates) {
        features.forEach((feature) => {
          this.provinces[
            feature.properties["cartodb_id"] - this.cartoProvinceStartId
          ].coordinates = feature.getRenderedCentroid();
        });
      }
      this.buildProvinceCircles();
    });

    const interactiveProvinceLayer = new carto.Interactivity(provinceLayer);
    interactiveProvinceLayer.on("featureEnter", (featureEvent) => {
      featureEvent.features.forEach((feature) => {
        feature.color.blendTo("rgba(10, 188, 138,0.5)", 100);
      });
    });

    interactiveProvinceLayer.on("featureLeave", (featureEvent) => {
      featureEvent.features.forEach((feature) => {
        feature.color.blendTo("rgba(255,255,255, 0)", 100);
      });
    });

    interactiveProvinceLayer.on("featureClick", (featureEvent) => {
      this.onProvinceClick(featureEvent);
    });
  }

  private buildMunicipalityCircles() {
    let munCircleGeoJson = {
      type: "FeatureCollection",
      features: [],
    };
    this.municipalities.forEach((mun) => {
      if (!mun.coordinates) return;

      let numParkings;
      if (this.checkMunicipalityPermission(mun.cartodb_id)) {
        numParkings = String(mun.numParkings);
      } else {
        numParkings = "?";
      }

      munCircleGeoJson.features.push({
        type: "Feature",
        geometry: { type: "Point", coordinates: mun.name == "Rotterdam" ? rotterdamCoords : mun.coordinates }, // fix to reposition Rotterdam bubble
        properties: {
          numParkings: numParkings,
        },
      });
    });

    const munCircleSource = new carto.source.GeoJSON(munCircleGeoJson);
    // zoom()>${this.provinceZoomThreshold} and
    const munCircleViz = new carto.Viz(`
      filter: zoom()>${this.provinceZoomThreshold} and zoom()<${this.municipalityZoomThreshold}
      @v_features: viewportFeatures($numParkings)
      color: rgb(10, 188, 138)
      width: 40
      @numParkings: $numParkings
    `);

    const munCircleLayer = new carto.Layer(
      "munCircleGeoJson",
      munCircleSource,
      munCircleViz
    );

    munCircleLayer.on("loaded", () => {
      if (this.map.getSource("mun_labels")) this.map.removeSource("mun_labels");
      this.map.addSource("mun_labels", { type: "geojson", data: null });
      const labelSource = this.map.getSource("mun_labels");

      const layerUpdated = function () {
        const features = munCircleViz.variables.v_features.value;
        const geojsonFeatures = features.map((feature) => {
          return {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: feature.getRenderedCentroid(),
            },
            properties: {
              label_field: `${feature.properties["numParkings"]}`,
            },
          };
        });

        labelSource.setData({
          type: "FeatureCollection",
          features: geojsonFeatures,
        });
      };

      munCircleLayer.on("updated", layerUpdated);

      this.map.addLayer({
        id: "mun-labels",
        type: "symbol",
        source: "mun_labels",
        layout: {
          "text-field": "{label_field}",
          "text-font": ["Open Sans Bold"],
          "text-size": 15,
          "text-offset": [0, -0.5],
          "text-anchor": "top",
          "text-justify": "center",
        },
        paint: {
          "text-color": "white",
        },
      });
    });

    if (this.map.getLayer("munCircleGeoJson"))
      this.map.removeLayer("munCircleGeoJson");
    munCircleLayer.addTo(this.map);
  }

  getProvCircleWidth() {
    if (this.isMobile()) {
      return 65;
    }
    return 80;
  }

  private buildProvinceCircles() {
    let provinceCircleGeoJson = {
      type: "FeatureCollection",
      features: [],
    };
    this.provinces.forEach((province) => {
      let numParkings;
      if (fullProvinceClients.indexOf(province.cartodb_id) > -1) {
        numParkings = String(province.numParkings);
      } else {
        numParkings = "?";
      }
      provinceCircleGeoJson.features.push({
        type: "Feature",
        geometry: { type: "Point", coordinates: province.coordinates },
        properties: {
          numParkings: numParkings,
        },
      });
    });
    const provinceCircleSource = new carto.source.GeoJSON(
      provinceCircleGeoJson
    );
    const provinceCircleViz = new carto.Viz(`
        filter: zoom()<${this.provinceZoomThreshold}
        @v_features: viewportFeatures($numParkings)
        color: rgb(10, 188, 138)
        width: ${this.getProvCircleWidth()}
        @numParkings: $numParkings
    `);

    const provinceCircleLayer = new carto.Layer(
      "provinceCircleGeoJson",
      provinceCircleSource,
      provinceCircleViz
    );

    // Create labeling layer centroids
    provinceCircleLayer.on("loaded", () => {
      this.map.addSource("labels", { type: "geojson", data: null });
      const labelSource = this.map.getSource("labels");

      const layerUpdated = function () {
        const features = provinceCircleViz.variables.v_features.value;
        const geojsonFeatures = features.map((feature) => {
          return {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: feature.getRenderedCentroid(),
            },
            properties: {
              label_field: `${feature.properties["numParkings"]}`,
            },
          };
        });

        labelSource.setData({
          type: "FeatureCollection",
          features: geojsonFeatures,
        });
      };

      provinceCircleLayer.on("updated", layerUpdated);

      this.map.addLayer({
        id: "province-labels",
        type: "symbol",
        source: "labels",
        layout: {
          "text-field": "{label_field}",
          "text-font": ["Open Sans Bold"],
          "text-size": this.getProvCircleTextSize(),
          "text-offset": [0, -0.8],
          "text-anchor": "top",
          "text-justify": "center",
        },
        paint: {
          "text-color": "white",
        },
      });
    });

    provinceCircleLayer.addTo(this.map);
    this.getRealisedProjects();
  }

  getProvCircleTextSize() {
    if (this.isMobile())
      return 21;
    return 24;
  }

  private onProvinceClick(featureEvent) {
    // Check if the province Layer is being shown
    if (this.map.getZoom() > this.provinceZoomThreshold) {
      return;
    }
    // Check if the province is allowed to access data
    if (!this.checkProvincePermission(featureEvent.features[0].id)) {
      alert("Data voor deze provincie is niet beschikbaar.");
      return;
    }

    const feature = featureEvent.features[0];
    let prov = this.provCodeToName(feature.id);

    this.analytics.logEvent('province_click', {
      province: prov,
      custom_event_type: 'province_click'
    })

    this.setProvinceId(feature.id);
    this.zoomToProvince(feature);
  }

  zoomToProvince(feature) {
    this.map.fire('flystart');

    this.getProvinceData();
    this.parkingData = undefined;
    if (!feature) {
      return;
    }
    const coords = feature.getRenderedCentroid();
    this.map.flyTo({
      speed: 1,
      center: coords,
      zoom: this.zoomToProvinceLevel,
    });

    this.map.on("moveend", () => {
      if (this.flying) {
        this.map.fire("flyend");
      }
    });
  }
  zoomedFromRoute: boolean = false;

  initMunicipalities() {
    const municipalityViz = new carto.Viz(`
        @v_features: viewportFeatures($cartodb_id, $statnaam)
        color: rgba(255,255,255,0)
        strokeColor: rgb(10, 188, 138)
        strokeWidth: 2
        filter: zoom()>${this.provinceZoomThreshold}
        @statnaam: $statnaam
        @the_geom: $the_geom
        @the_geom_web: $the_geom_webmercator
    `);

    const municipalitySource = new carto.source.SQL(
      `SELECT * FROM ${this.regionDataset} WHERE rubriek='gemeente'`
    );
    const municipalityLayer = new carto.Layer(
      "municipalities",
      municipalitySource,
      municipalityViz
    );
    
    if (this.map.getLayer("municipalities")) this.map.removeLayer("municipalities");
    municipalityLayer.addTo(this.map);
    
    // municipalityLayer.on("loaded", () => {
    //   const features = municipalityViz.variables.v_features.value;
    //   // The first time the municipality layer is loaded get the municipality center coordinates
    //   if (!this.municipalities[0].coordinates) {
    //     features.forEach((feature) => {
    //       this.municipalities[
    //         feature.properties["cartodb_id"] - this.cartoMunicipalityStartId
    //       ].coordinates = feature.getRenderedCentroid();
    //     });
    //   }
    // });

    const interactiveMunicipalityLayer = new carto.Interactivity(
      municipalityLayer
    );

    let once = true;
    municipalityLayer.on("updated", () => {
      const features = municipalityViz.variables.v_features.value;
      // The first time the province layer is loaded get the province center coordinates

      if (features.length > 0 && once && !this.municipalities[0].coordinates) {
        once = false;
        features.forEach((feature) => {
          if (
            !this.checkMunicipalityPermission(feature.properties["cartodb_id"])
          )
            return;

          this.municipalities[
            feature.properties["cartodb_id"] - this.cartoMunicipalityStartId
          ].coordinates = feature.getRenderedCentroid();
          // munJson.filter(
          //   (x) => x.cartodb_id == feature.properties["cartodb_id"]
          // )[0].coordinates;
        });

        this.buildMunicipalityCircles();
      }
    });

    interactiveMunicipalityLayer.on("featureEnter", (featureEvent) => {
      if (this.map.getZoom() > this.parkingZoomThreshold) {
        this.blendFeatures(featureEvent, "rgba(255,255,255, 0)");
        return;
      }
      this.blendFeatures(featureEvent, "rgba(10, 188, 138,0.5)");
    });

    interactiveMunicipalityLayer.on("featureLeave", (featureEvent) => {
      if (this.map.getZoom() > this.parkingZoomThreshold) return;
      this.blendFeatures(featureEvent, "rgba(255,255,255, 0)");
    });

    interactiveMunicipalityLayer.on("featureClick", (featureEvent) => {
      if (this.map.getZoom() > this.parkingZoomThreshold) {
        this.blendFeatures(featureEvent, "rgba(255,255,255, 0)");
        return;
      }
      this.onMunicipalityClick(featureEvent);
    });
  }

  blendFeatures(featureEvent, color) {
    featureEvent.features.forEach((feature) => {
      feature.color.blendTo(color, 100);
    });
  }

  private onMunicipalityClick(featureEvent) {
    // Check if the municipality layer is being shown
    if (
      this.map.getZoom() > this.municipalityZoomThreshold ||
      this.map.getZoom() < this.provinceZoomThreshold
    ) {
      return;
    }

    const feature = featureEvent.features[0];
    if (!feature) {
      return;
    }

    // Check if the municipality is allowed to access data
    if (!this.checkMunicipalityPermission(featureEvent.features[0].id)) {
      alert("Data voor deze gemeente is niet beschikbaar.");
      return;
    }

    let code = featureEvent.features[0].id;
    let name = this.munCodeToName(code);
    this.analytics.logEvent('municipality_click', {
      mun_name: name,
      custom_event_type: 'municipality_click'
    })


    feature.color.blendTo("rgba(255,255,255, 0)", 100);

    this.zoomToMunicipality(feature);
  }

  checkMunicipalityPermission(id: number) {
    if (verifiedMunicipalityIds.indexOf(id) > -1) {
      // Municipality in production
      return true
    } else if(this.hiddenUrlMunicipalityCartodbIds){
      if(this.hiddenUrlMunicipalityCartodbIds.indexOf(id) > -1 ){
        // Municipality in idden URL
        return true
      }
    }
    else{
      return false
    }
  }

  checkProvincePermission(id: number) {
    if(verifiedProvinceIds.indexOf(id) > -1){
      // Province in production
      return true
    } else if(this.hiddenUrlProvinceCartodbId){
      if(this.hiddenUrlProvinceCartodbId === id){
        // Province in hidden URL
        return true
      }
    }
    else {
      return false
    }
  }

  zoomToMunicipality(feature) {
    this.loadingMunicipalityData = true;
    this.loadingProvinceData = false;

    this.setMunicipalityId(feature.id);
    this.getMunicipalityData();
    this.initMunicipalityParkings();

    var coords;

    if (feature.variables.statnaam.value == "Rotterdam") {
      coords = rotterdamCoords;
    } else {
      coords = feature.getRenderedCentroid();
    }

    this.map.flyTo({
      speed: 0.6,
      center: coords,
      zoom: this.zoomToMunicipalityLevel,
    });
  }

  getNParkingLotsMunicipality() {
    const municipalityViz = new carto.Viz(`
        @v_features: viewportFeatures($statnaam)


        color: lightgrey
        width: 6
        strokeColor: white
        strokeWidth: 2
        filter: zoom()>${this.provinceZoomThreshold}
    `);
    const municipalitySource = new carto.source.SQL(
      `SELECT * FROM ${this.regionDataset} WHERE rubriek='gemeente'`
    );
    const municipalityLayer = new carto.Layer(
      "municipalitiesParkingLots",
      municipalitySource,
      municipalityViz
    );

    municipalityLayer.on("loaded", () => {
      this.map.addSource("labels", { type: "geojson", data: null });
      const labelSource = this.map.getSource("labels");

      const layerUpdated = function () {
        const features = municipalityViz.variables.v_features.value;
        const geojsonFeatures = features.map((feature) => {
          return {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: feature.getRenderedCentroid(),
            },
            properties: {
              label_field: `${feature.properties["statnaam"]}`,
              color: "black",
            },
          };
        });

        labelSource.setData({
          type: "FeatureCollection",
          features: geojsonFeatures,
        });
      };

      municipalityLayer.on("updated", layerUpdated);
    });
    municipalityLayer.addTo(this.map);
    const query2 = `SELECT ST_X(ST_Centroid(the_geom)) as longitude, ST_Y(ST_Centroid(the_geom)) as latitude, statnaam FROM ${this.regionDataset} WHERE ${this.regionDataset}.rubriek='gemeente'`;
    this.getCartoData(query2).subscribe((data) => { return; });
  }

  getMunicipalityData() {
    this.hideProvMuniCard = false;
    this.provinceData = undefined;
    this.municipalityData = undefined;
    this.loadingMunicipalityData = true;
    const query = `SELECT ${this.dataset}.* FROM ${
      this.dataset
      }, ${this.regionDataset} WHERE ST_Intersects(ST_BUFFER(${
      this.dataset
      }.the_geom, 0), ${this.regionDataset}.the_geom) AND ${this.regionDataset}.rubriek='gemeente' AND ${this.regionDataset}.cartodb_id='${this.getCartoMunicipalityId()}' AND ${this.dataset}.mun_hidden='0'`;
    this.getCartoData(query).subscribe((data: any) => {
      this.municipalityData = data.rows;
      this.filterMunicipalityData();
      this.loadingMunicipalityData = false;
    });
  }

  getProvinceData() {
    this.hideProvMuniCard = false;
    this.provinceData = undefined;
    this.municipalityData = undefined;
    this.loadingProvinceData = true;
    const query = `SELECT ${this.dataset}.* FROM ${
      this.dataset
      }, ${this.regionDataset} WHERE ST_Intersects(${
      this.dataset
      }.the_geom, ${this.regionDataset}.the_geom) AND ${this.regionDataset}.rubriek='provincie' AND ${this.regionDataset}.cartodb_id='${this.getCartoProvinceId()}' AND ${this.dataset}.mun_hidden='0'`;
    this.getCartoData(query).subscribe((data: any) => {
      this.provinceData = data.rows;
      this.filterProvinceData();
      this.loadingProvinceData = false;
    });
  }

  private setProvinceId(cartoProvinceId: number) {
    this.selectedProvinceId = cartoProvinceId - this.cartoProvinceStartId;
  }

  public getCartoProvinceId(): number {
    return this.selectedProvinceId + this.cartoProvinceStartId;
  }

  private setMunicipalityId(cartoMunicipalityId: number) {
    this.selectedMunicipalityId =
      cartoMunicipalityId - this.cartoMunicipalityStartId;
  }

  public getCartoMunicipalityId(): number {
    return this.selectedMunicipalityId + this.cartoMunicipalityStartId;
  }

  getRealisedProjects() {
    const realisedProjects = require("../../assets/realised-projects.json");
    const rpSource = new carto.source.GeoJSON(realisedProjects);
    const rpViz = new carto.Viz(`
        color: rgb(241,183,63)
        width: 18
        @imagePath: $imagePath
        @title: $title
        @address: $address
        @building_year: $building_year
        @power: $power
        @yearly_revenue: $yearly_revenue
        @parking_spaces: $parking_spaces
        @developer: $developer
    `);
    // filter: zoom()>${this.provinceZoomThreshold}
    const rpLayer = new carto.Layer("realisedProjects", rpSource, rpViz);
    rpLayer.addTo(this.map);

    const interactiveRpLayer = new carto.Interactivity(rpLayer);

    const popup = new mapboxgl.Popup({ className: "realised-carports-card" });

    interactiveRpLayer.on("featureEnter", (featureEvent) => {
      const feature = featureEvent.features[0];
      const imagePath = feature.variables.imagePath.value;
      const title = feature.variables.title.value;
      const address = feature.variables.address.value;
      const building_year = feature.variables.building_year.value;
      const power = feature.variables.power.value;
      const yearly_revenue = feature.variables.yearly_revenue.value;
      const parking_spaces = feature.variables.parking_spaces.value;
      const developer = feature.variables.developer.value;
      const coords = feature.getRenderedCentroid();

      popup
        .setLngLat(coords)
        .setHTML(
          `<mat-card>
            <mat-card-content>
              <div class="realised-carports-card-content">
                <h4 class="heading-underline-secondary mb-3 text-center">${title}</h4>
                <p class="mb-1 ml-4"><i class="fas fa-map-marker icon-sm mr-3 text-center"></i>${address}</p>
                <p class="mb-1 ml-4"><i class="fas fa-star icon-sm mr-3 text-center"></i>Bouwjaar: ${building_year}</p>
                <p class="mb-1 ml-4"><i class="fa fa-bolt icon-sm mr-3 text-center"></i>Opgesteld: ${power}</p>
                <p class="mb-1 ml-4"><i class="fas fa-parking icon-sm mr-3 text-center"></i>Parkeervakken: ${parking_spaces}</p>
                <p class="mb-1 ml-4"><i class="fas fa-wrench icon-sm mr-3 text-center"></i>Ontwikkelaar: ${developer}</p>
                <div class="text-center">
                  <img src=${imagePath} class="img-fluid" style="width: 80%">
                </div>
              </div>
            </mat-card-content>
          </mat-card>`
        )
        .addTo(this.map);
    });

    interactiveRpLayer.on("featureLeave", () => {
      popup.remove();
    });
  }

  public filterData() {
    if (this.provinceData) {
      this.filterProvinceData();
    }
    if (this.municipalityData) {
      this.filterMunicipalityData();
      this.initMunicipalityParkings();
    }
  }

  private filterProvinceData() {
    this.provinceDataFiltered = this.provinceData.filter(
      (parkingData) =>
        parkingData.area >= this.areaFilter &&
        parkingData.cap_kwp * this.correctionKwpDatabase >= this.powerFilter &&
        parkingData.griddist_m <= this.gridDistanceFilter &&
        (parkingData.protected === null ||
          parkingData.protected === this.protectedFilter)
    );

    this.provinceDataSorted = this.provinceDataFiltered;
  }

  private filterMunicipalityData() {
    if (this.privateFilter < 0) {
      this.municipalityDataFiltered = this.municipalityData.filter(
        (parkingData) =>
          parkingData.area >= this.areaFilter &&
          parkingData.cap_kwp * this.correctionKwpDatabase >= this.powerFilter &&
          parkingData.griddist_m <= this.gridDistanceFilter &&
          (parkingData.protected === null ||
            parkingData.protected === this.protectedFilter)
      );
    } else {
      this.municipalityDataFiltered = this.municipalityData.filter(
        (parkingData) =>
          parkingData.area >= this.areaFilter &&
          parkingData.cap_kwp * this.correctionKwpDatabase >= this.powerFilter &&
          parkingData.griddist_m <= this.gridDistanceFilter &&
          (parkingData.protected === null ||
            parkingData.protected === this.protectedFilter) &&
          (parkingData.public_own === this.privateFilter)
      );
    }

    this.municipalityDataSorted = this.municipalityDataFiltered;
  }

  public filterMunicipalityBySearch(filterValue) {
    let filterValueLower = filterValue.toLowerCase();
    if (filterValue === '') {
      this.municipalityDataSorted = this.municipalityDataFiltered;
    }
    else {
      this.municipalityDataSorted = this.municipalityDataFiltered;
      this.municipalityDataSorted = this.municipalityDataSorted.filter((data) => {
        return data.adrs_full.toLowerCase().includes(filterValueLower)
      }
      )
    }
  }

  public filterProvinceBySearch(filterValue) {
    let filterValueLower = filterValue.toLowerCase();
    if (filterValue === '') {
      this.provinceDataSorted = this.provinceDataFiltered;
    }
    else {
      this.provinceDataSorted = this.provinceDataFiltered;
      this.provinceDataSorted = this.provinceDataSorted.filter((data) => {
        return data.adrs_full.toLowerCase().includes(filterValueLower)
      }
      )
    }
  }

  public getSearchLocation(address: string) {
    // Note specifying language to return the geocoded address in is important! Otherwise we might get language issues when parsing parts of the results
    return this.http.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${this.google_maps_api_key}&language=en`
    );
  }

  public goToSearchLocation(lat, lng) {
    if (this.map.getLayer("searchLocationLayer")) {
      this.map.removeLayer("searchLocationLayer");
    }

    let coords = [lng, lat]

    this.map.flyTo({
      speed: 1,
      center: coords,
      zoom: this.zoomToSingleParkingLevel
    });

    this.showParkingLotsFromSearch(lat, lng)

    const search_location = {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": coords
          }
        },
      ]
    };
    const searchSource = new carto.source.GeoJSON(search_location);
    const searchViz = new carto.Viz(`
        color: rgb(179,0,30)
        width: 12
    `);

    const searchLayer = new carto.Layer("searchLocationLayer", searchSource, searchViz);
    searchLayer.addTo(this.map);
  }

  public showParkingLotsFromSearch(lat, lng) {
    var mapLayer = this.map.getLayer("one_parking_lot_layer");
    if (typeof mapLayer !== "undefined") {
      // Remove map layer & source.
      this.map.removeLayer("one_parking_lot_layer");
    }
    const parkingsQuery = `SELECT ${this.regionDataset}.* FROM ${this.regionDataset} WHERE ST_Intersects(ST_SetSRID(ST_MakePoint(${lng}, ${lat}), 4326), ${this.regionDataset}.the_geom) AND ${this.regionDataset}.rubriek='gemeente'`;
    this.getCartoData(parkingsQuery).subscribe((data: any) => {
      if (!this.checkMunicipalityPermission(data.rows[0].cartodb_id)) {
        alert("Data voor deze gemeente is niet beschikbaar.");
        return;
      }
      this.loadingMunicipalityData = true;
      this.loadingProvinceData = false;

      this.setMunicipalityId(data.rows[0].cartodb_id);
      this.getMunicipalityData();
      this.initMunicipalityParkings();
    });
  }

  public dataSortedIsDataFilteredMuni() {
    this.municipalityDataSorted = this.municipalityDataFiltered;
  }

  public dataSortedIsDataFilteredProv() {
    this.provinceDataSorted = this.provinceDataFiltered;
  }

  getTotals() {
    const query = `SELECT SUM(${this.dataset}.area) FROM ${this.dataset}`;
    this.getCartoData(query).subscribe((data: any) => {
      this.totalArea = data.rows[0].sum;
    });
  }

  sort(headers, column: string, direction: string, originalData, sortedData) {
    // resetting other headers
    headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    // sorting countries
    if (direction === "") {
      sortedData = originalData;
    } else {
      sortedData = [...originalData].sort((a, b) => {
        const res = this.compare(a[column], b[column]);
        return direction === "asc" ? res : -res;
      });
    }
    return sortedData;
  }

  compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  private roundTens(inputNumber: number, digits: number): number {
    return Math.round(inputNumber / 10 ** digits) * 10 ** digits;
  }

  public getMunicipalityIdsForProvince(provinceId: number): number[] {
    if(provinceId === 10) {
      // This is Zeeland
      return [16978, 16979, 16981, 16982, 16983, 17076, 16984, 17073, 17083, 16985, 16986, 16987, 16988  ]
    } else {
      // This is another province
      alert("Add list of municipality IDs for new province!")
      console.log("Add list of municipality IDs for new province here")
      return undefined
    }
  }
}

export const team = [
  {
    name: "Otto Fabius",
    title: "Clients & Projects",
    photo: "../../../assets/images/Otto.png",
    email: "o.fabius@sobolt.com",
  },
  {
    name: "Jens Reichwein",
    title: "Full-Stack Developer",
    photo: "../../../assets/images/Jens.png",
    email: "j.reichwein@sobolt.com",
  },
  {
    name: "Annemarie Hagenaars",
    title: "New Partners",
    photo: "../../../assets/images/Annemarie.png",
    email: "a.hagenaars@sobolt.com",
  },
  {
    name: "Simone van Gent",
    title: "Developer",
    photo: "../../../assets/images/Simone.png",
    email: "s.vangent@sobolt.com",
  },
  {
    name: "Thomas Wierikx",
    title: "Impact",
    photo: "../../../assets/images/Thomas.png",
    email: "t.wierikx@sobolt.com",
  },
];

import { Component, OnInit } from '@angular/core';
import { LoadChildren } from '@angular/router';
import { LayoutService } from 'src/app/layout/layout.service';
import { CartoService } from 'src/app/services/carto.service';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent {

  public location: string;

  constructor(private cartoService: CartoService, public layoutService: LayoutService) { }

  getLocation() {
    this.cartoService
      .getSearchLocation(this.location)
      .subscribe((data: any) => {
        let succesfullSearch = false;
        if (data.results[0]) {
          // Run this if search results can be found. This if statement makes sure there is no error when there are no search results
          for (var i = 0; i < data.results[0].address_components.length; i++) {
            if (
              // Note that we set return language to be English (&language=en) in the query, hence the line below works.
              // In the past there was an error, because without specifying the return language the browser default is used (which was often Dutch for our clients and returned "Nederland") 
              data.results[0].address_components[i].long_name.toLowerCase() == "netherlands"
            ) {
              succesfullSearch = true;
              this.cartoService.goToSearchLocation(
                data.results[0].geometry.location.lat,
                data.results[0].geometry.location.lng
              );
            }
          }
        }
        if (!succesfullSearch) {
          // Show an error message if no address was found
          alert(
            "Voer een geldig adres in. Bijvoorbeeld: Westfriese Parkweg 5, Hoorn"
          );
        }
      });
  }

}

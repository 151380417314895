import { ChangeDetectorRef, OnDestroy, OnInit } from "@angular/core";
import { MobileInfoComponent } from "../mobile-info.component";

export class UpdatingTemplate implements OnInit, OnDestroy {
    //The regional data pop-ups were made using angular BottomSheet component, after the fact I found out that
    //the bottom sheet component has its ChangeDetection set to Push meaning the pop-ups wouldn't update the display
    //data when the user changed filter settings. This prompts the pop-up templates to check for an update on an interval.
    private intervalId;
    private intervalPeriod: number = 100; //ms

    constructor(private changeDetection: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.intervalId = setInterval(() => { this.changeDetection.markForCheck(); }, this.intervalPeriod);
    }

    ngOnDestroy(): void {
        clearInterval(this.intervalId);
    }
}
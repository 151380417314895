<h4 class="text-left">Filters</h4>
<div
     [ngClass]="cartoService.selectedMunicipalityId == 2 ? 'filters-card-scrollable-content': '' ">
  <div class="d-flex flex-column align-items-start">
    <div class="d-flex">
      <h6 class="filter-title mb-0">
        Op te stellen vermogen
      </h6>
    </div>
    <div class="d-flex align-items-center w-100 mt-0">
      <ng-template #energyContent><i class="fa fa-times top-right pointer"
           aria-hidden="true"></i>{{regionalDetails.tooltipText.energy_use_filter}}
      </ng-template>
      <mat-icon class="fa fa-info-circle icon-sm mr-3 pointer"
                placement="left"
                [ngbPopover]="energyContent"
                popoverTitle="Op te stellen vermogen">
      </mat-icon>
      <i class="fa fa-bolt icon-sm mr-3"></i>
      <mat-slider class="p-2"
                  thumbLabel
                  [displayWith]="formatLabel"
                  step="5"
                  tickInterval="200"
                  min="0"
                  max="1000"
                  [value]="cartoService.powerFilter"
                  (change)="powerFilterChange($event)">
      </mat-slider>
    </div>
    <div class="d-flex align-items-baseline">
      <p>Minimaal {{cartoService.powerFilter}} kWp</p>
    </div>
  </div>
  <div class="d-flex flex-column align-items-start">
    <div class="d-flex">
      <h6 class="filter-title mb-0">Totale oppervlakte</h6>
    </div>
    <div class="d-flex align-items-center w-100 mt-0">
      <ng-template #m2Content>
        <i class="fa fa-times top-right pointer"
           aria-hidden="true"></i>
        <div [innerHTML]="regionalDetails.tooltipText.m2_content_filter"></div>
      </ng-template>
      <mat-icon class="fa fa-info-circle icon-sm mr-3 pointer"
                placement="left"
                [ngbPopover]="m2Content"
                popoverTitle="Oppervlakte parkeerterreinen">
      </mat-icon>
      <i class="fas fa-parking mr-3 icon-sm"></i>
      <mat-slider class="p-2"
                  thumbLabel
                  [displayWith]="formatLabel"
                  step="5"
                  tickInterval="1000"
                  min="65"
                  max="10000"
                  [value]="cartoService.areaFilter"
                  (change)="areaFilterChange($event)">
      </mat-slider>
    </div>
    <div class="d-flex align-items-baseline">
      <p>Minimaal {{cartoService.areaFilter}} m2</p>
    </div>
    <div class="d-flex align-items-center w-100">
      <ng-template #cityScapeContent>
        <i class="fa fa-times top-right pointer"
           aria-hidden="true"></i>
        <div [innerHTML]="regionalDetails.tooltipText.cityscape_content_html"></div>
      </ng-template>
      <mat-icon class="fa fa-info-circle icon-sm mr-3 pointer"
                placement="left"
                [ngbPopover]="cityScapeContent"
                [popoverTitle]="regionalDetails.tooltipText.cityscape_title">
      </mat-icon>
      <i class="fas fa-university mr-3 icon-sm"></i>
      <mat-form-field class="w-100">
        <mat-label>{{protectedFilters.hintText}}</mat-label>
        <mat-select [value]="cartoService.protectedFilter"
                    (selectionChange)="protectedFilterChange($event)">
          <mat-option *ngFor="let item of protectedFilters.filters"
                      [value]="item.value">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-flex align-items-center w-100">
      <ng-template #distanceContent><i class="fa fa-times top-right pointer"
           aria-hidden="true"></i>
        <div [innerHTML]="regionalDetails.tooltipText.distance_connection_mapview_content">
        </div>
      </ng-template>
      <mat-icon class="fa fa-info-circle icon-sm mr-3 pointer"
                placement="left"
                [ngbPopover]="distanceContent"
                [popoverTitle]="regionalDetails.tooltipText.distance_connection_title">
      </mat-icon>
      <i class="fas fa-plug mr-3 icon-sm"></i>
      <mat-form-field class="w-100">
        <mat-label>{{gridDistanceFilters.hintText}}</mat-label>
        <mat-select [value]="cartoService.gridDistanceFilter"
                    (selectionChange)="gridDistanceFilterChange($event)">
          <mat-option *ngFor=" let item of gridDistanceFilters.filters"
                      [value]="item.value">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="cartoService.privatePublicFeatureActivated() && cartoService.municipalityData"
         class="d-flex align-items-center w-100">
      <ng-template #distanceContent><i class="fa fa-times top-right pointer"
           aria-hidden="true"></i>
        <div [innerHTML]="regionalDetails.tooltipText.private_public_content"></div>
      </ng-template>
      <mat-icon class="fa fa-info-circle icon-sm mr-3 pointer"
                placement="left"
                [ngbPopover]="distanceContent"
                [popoverTitle]="regionalDetails.tooltipText.private_public_title">
      </mat-icon>
      <i class="fas fa-user-shield mr-3 icon-sm"></i>
      <mat-form-field class="w-100">
        <mat-label>{{privateFilters.hintText}}</mat-label>
        <mat-select [value]="cartoService.privateFilter"
                    (selectionChange)="privateFilterChange($event)">
          <mat-option *ngFor=" let item of privateFilters.filters"
                      [value]="item.value">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="text-center">
  <button class="button-primary"
          (click)="resetFilters()">
    Verwijder filters
  </button>
</div>
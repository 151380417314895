import { Component, OnInit } from '@angular/core';
import { LayoutService } from "../../layout/layout.service";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  public showAlgemeen: boolean = false;
  public showWhatIs: boolean = true;
  public showByWho: boolean = false;
  public showWhyIs: boolean = false;
  public showForWho: boolean = false;
  public showIsInRegion: boolean = false;
  public showWhyInRegion: boolean = false;

  public showSolarCarports: boolean = false;
  public showWhatAre: boolean = true;
  public showWhoBuilds: boolean = false;
  public showWhySolarCarports: boolean = false;
  public showIWant: boolean = false;
  public showHowLong: boolean = false;

  public showApplicatie: boolean = false;
  public showSomeAreas: boolean = true;
  public showNumbersBlueCircles: boolean = false;
  public showHowDetect: boolean = false;
  public showWhatAnalyse: boolean = false;
  public showWhatNotAnalyse: boolean = false;
  public showHowFind: boolean = false;
  public showSunArea: boolean = false;
  public showAreaNotVisible: boolean = false;

  public showDetails: boolean = false;
  public showWhereFrom: boolean = true;
  public showIWantNumbers: boolean = false;
  public showIHave: boolean = false;
  public showHelpSolarCarport: boolean = false;

  public showOther: boolean = false;
  public showDeveloper: boolean = true;
  public showOwner: boolean = false;
  public showIsItPossible: boolean = false;





  constructor(private layoutService: LayoutService) { }

  ngOnInit() {
    this.layoutService.inApplication = false;
    this.layoutService.showNavbar = true;
  }

  toggleAlgemeen() {
    this.showAlgemeen = !this.showAlgemeen
  }

  toggleWhatIs() {
    this.showWhatIs = !this.showWhatIs
  }

  toggleByWho() {
    this.showByWho = !this.showByWho
  }

  toggleWhyIs() {
    this.showWhyIs = !this.showWhyIs
  }

  toggleForWho() {
    this.showForWho = !this.showForWho
  }

  toggleIsInRegion() {
    this.showIsInRegion = !this.showIsInRegion
  }

  toggleWhyInRegion() {
    this.showWhyInRegion = !this.showWhyInRegion
  }

  toggleSolarCarports() {
    this.showSolarCarports = !this.showSolarCarports
  }

  toggleWhatAre() {
    this.showWhatAre = !this.showWhatAre
  }

  toggleWhoBuilds() {
    this.showWhoBuilds = !this.showWhoBuilds
  }

  toggleWhySolarCarports() {
    this.showWhySolarCarports = !this.showWhySolarCarports
  }

  toggleIWant() {
    this.showIWant = !this.showIWant
  }

  toggleHowLong() {
    this.showHowLong = !this.showHowLong
  }

  toggleApplicatie() {
    this.showApplicatie = !this.showApplicatie
  }

  toggleSomeAreas() {
    this.showSomeAreas = !this.showSomeAreas
  }

  toggleNumbersBlueCircles() {
    this.showNumbersBlueCircles = !this.showNumbersBlueCircles
  }

  toggleHowDetect() {
    this.showHowDetect = !this.showHowDetect
  }

  toggleWhatAnalyse() {
    this.showWhatAnalyse = !this.showWhatAnalyse
  }

  toggleWhatNotAnalyse() {
    this.showWhatNotAnalyse = !this.showWhatNotAnalyse
  }

  toggleHowFind() {
    this.showHowFind = !this.showHowFind
  }

  toggleSunArea() {
    this.showSunArea = !this.showSunArea
  }

  toggleAreaNotVisible() {
    this.showAreaNotVisible = !this.showAreaNotVisible
  }

  toggleDetails() {
    this.showDetails = !this.showDetails
  }

  toggleWhereFrom() {
    this.showWhereFrom = !this.showWhereFrom
  }

  toggleIWantNumbers() {
    this.showIWantNumbers = !this.showIWantNumbers
  }

  toggleIHave() {
    this.showIHave = !this.showIHave
  }

  toggleHelpSolarCarport() {
    this.showHelpSolarCarport = !this.showHelpSolarCarport
  }

  toggleOther() {
    this.showOther = !this.showOther
  }

  toggleDeveloper() {
    this.showDeveloper = !this.showDeveloper
  }

  toggleOwner() {
    this.showOwner = !this.showOwner
  }

  toggleIsItPossible() {
    this.showIsItPossible = !this.showIsItPossible
  }

}

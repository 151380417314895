import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LandingComponent } from "./pages/landing/landing.component";
import { BasemapComponent } from "./application/basemap/basemap.component";
import { AboutComponent } from "./pages/about/about.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { description, keywords } from "../assets/json/seo";

const desc =
  "Grootschalig zonne energie opwekken? Wij geven je inzicht in de potentie van zonneparken op parkeerterreinen. Ontdek de mogelijkheden van solar carports met Park the Sun!";

const routes: Routes = [
  {
    path: "",
    component: LandingComponent,
    data: {
      title: "Park the Sun - Parkeer de zon op jouw parkeerterrein!",
      description: description,
      keywords: keywords,
    },
  },
  {
    path: "app",
    component: BasemapComponent,
    data: {
      title: "Applicatie - Aan de slag met solar carports!", // maybe this looks better: "Applicatie - Park The Sun - Aan de slag met solar carports!"
      description: description,
      keywords: keywords,
    },
  },
  {
    path: "over",
    component: AboutComponent,
    data: {
      title: "Over - Wat is Park the Sun?",
      description: description,
      keywords: keywords,
    },
  },
  {
    path: "faq",
    component: FaqComponent,
    data: {
      title: "FAQ - frequently asked questions",
      description: description,
      keywords: keywords,
    },
  },
  {
    path: "contact",
    component: ContactComponent,
    data: {
      title: "Contact - Ontmoet ons",
      description: description,
      keywords: keywords,
    },
  },
  {
    path: "not-found",
    component: NotFoundComponent,
    data: { title: "Park The Sun - 404 pagina niet gevonden", keywords: keywords },
  },
  // List of hardcoded URL entries to redirect to
  {
    path: "rotterdam",
    redirectTo: 'zuid-holland/rotterdam'
  },
  {
    path: "amsterdam",
    redirectTo: 'noord-holland/amsterdam'
  },
  {
    path: "den haag",
    redirectTo: 'zuid-holland/den-haag'
  },
  {
    path: "den-haag",
    redirectTo: 'zuid-holland/den-haag'
  },
  {
    path: "groningen",
    pathMatch: "full",
    redirectTo: "groningen/groningen"
  },
  {
    path: "apeldoorn",
    redirectTo: 'gelderland/apeldoorn'
  },
  {
    path: "**",
    component: BasemapComponent,
    data: {
      title: "Applicatie - Aan de slag met solar carports!",
      description: description,
      keywords: keywords,
    },
  },
  {
    // Den Haag testing URL
    path: "apazhb",
    component: BasemapComponent,
    data: {
      title: "Applicatie - Aan de slag met solar carports!",
      description: description,
      keywords: keywords,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule { }

import { Component, OnInit, HostBinding } from "@angular/core";
import { LayoutService } from "../../layout/layout.service";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"]
})
export class NotFoundComponent implements OnInit {
  constructor(private layoutService: LayoutService) {}
  @HostBinding('class') class ="h-100";

  ngOnInit() {
    this.layoutService.checkMapView();
    this.layoutService.inApplication = false;
  }
}

import { Component } from '@angular/core';
import { CartoService } from 'src/app/services/carto.service';

@Component({
  selector: 'app-loading-template',
  templateUrl: './loading-template.component.html',
  styleUrls: ['./loading-template.component.css']
})
export class LoadingTemplateComponent {

  constructor(public cartoService: CartoService) {
  }
}

import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/layout/layout.service';
import { CartoService } from 'src/app/services/carto.service';

@Component({
  selector: 'app-mobile-filters',
  templateUrl: './mobile-filters.component.html',
  styleUrls: ['./mobile-filters.component.css']
})
export class MobileFiltersComponent{

  constructor(public cartoService: CartoService, public layoutService: LayoutService) { }

  public closeFilters(){
    this.cartoService.showFilters = false;
  }
}
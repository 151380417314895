<div class="row business-case-row">

  <div class="d-none d-md-block col-1 no-x-pad-mobile">
    <i [ngClass]="iconClass"></i>
  </div>

  <div class="col-8 col-md-6 d-flex flex-column mb-3 no-x-pad-mobile">
    <h3 class="business-case-fields" [class.business-case-text-disabled]="disabled">{{fieldName}}
      <mat-icon *ngIf="flag == 1" class="ml-2 fa fa-flag flag-red pointer" aria-hidden="true" placement="bottom"
        [ngbPopover]="flagPopoverMessage" popoverTitle="Slecht" container="body"></mat-icon>
      <mat-icon *ngIf="flag == 2" class="ml-2 fa fa-flag flag-orange  pointer" aria-hidden="true" placement="bottom"
        [ngbPopover]="flagPopoverMessage" popoverTitle="Middelmatig" container="body"></mat-icon>
      <mat-icon *ngIf="flag == 3" class="ml-2 fa fa-flag flag-green  pointer" aria-hidden="true" placement="bottom"
        [ngbPopover]="flagPopoverMessage" popoverTitle="Goed" container="body"></mat-icon>
    </h3>
    <mat-form-field *ngIf="dropdownContent">
      <mat-label>{{dropdownContent.hintText}}</mat-label>
      <mat-select [(value)]="selectedDropdownValue" (selectionChange)="editDropdownValue($event)">
        <mat-option *ngFor="let item of dropdownContent.items" [value]="item.value">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="dropdownContent2">
      <mat-label>{{dropdownContent2.hintText}}</mat-label>
      <mat-select [(value)]="selectedDropdownValue2" (selectionChange)="editDropdownValue2($event)">
        <mat-option *ngFor="let item of dropdownContent2.items" [value]="item.value">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <p *ngFor="let item of fieldnameTextList" class="mb-0 mt-0">
      {{item}}
      <button type="button" *ngIf="fieldnameButtonIconClass" mat-icon-button
        class="business-case-edit-icon-button ml-2"><i [ngClass]="fieldnameButtonIconClass"
          class="business-case-edit-icon"></i></button>
    </p>


    <p *ngIf="fieldnameEditableNumberUnit && !editingFieldnameNumber" class="mb-0 mt-0">
      van {{fieldnameEditableNumber}} {{fieldnameEditableNumberUnit}}
      <button type="button" mat-icon-button class="business-case-edit-icon-button ml-2"
        (click)="toggleEditFieldnameNumber()"><i class="fas fa-pencil-alt business-case-edit-icon"></i></button>
    </p>
    <p *ngIf="fieldnameEditableNumberUnit && editingFieldnameNumber" class="mt-0 mb-0">
      <mat-form-field class="mt-0">
        <input matInput [value]="editableFieldnameNumber | number : '1.0-1' : 'nl'"
          (change)="editFieldnameNumber($event)">
        <span matSuffix>
          {{fieldnameEditableNumberUnit}}
          <button type="button" (click)="resetEditFieldnameNumber()" mat-icon-button
            class="business-case-edit-icon-button ml-3"><i class="fas fa-times business-case-edit-icon"></i></button>
        </span>
      </mat-form-field>
    </p>
    <p *ngIf="!noLeesMeer" class="business-case-links mb-0 mt-0" (click)="openDialog()">Lees meer</p>
  </div>

  <div class="col-3 col-md-5 no-x-pad-mobile">
    <div *ngIf="fieldentryEditableNumber || fieldentryEditableNumber === 0">
      <p class="mt-0 mb-0" *ngIf="!editingNumber">
        {{prefix}} {{ fieldentryEditableNumber | number : '1.0-0' : 'nl'}} {{unit}}
        <button type="button" mat-icon-button class="business-case-edit-icon-button ml-3"
          (click)="toggleEditNumber()"><i class="fas fa-pencil-alt business-case-edit-icon"></i></button>
      </p>
      <p class="mt-0 mb-0" *ngIf="editingNumber">
        <mat-form-field class="mt-0">
          <span matPrefix>{{prefix}}</span>
          <input matInput [value]="editableNumber | number : '1.0-0' : 'nl'" (change)="editNumber($event)">
          <span matSuffix>
            <button type="button" (click)="resetEditNumber()" mat-icon-button
              class="business-case-edit-icon-button ml-3"><i class="fas fa-times business-case-edit-icon"></i></button>
          </span>
        </mat-form-field>
      </p>
      <p *ngIf="fieldentryInfoText" class="d-none d-md-block color-lightGrey">{{fieldentryInfoText}}</p>
    </div>


    <div *ngIf="fieldentryTextList">
      <p *ngIf="!editingNumber" class="mt-0 mb-0">
        {{fieldentryTextList[0]}} {{unit}}
        <button type="button" *ngIf="fieldentryButtonIconClass" mat-icon-button
          class="business-case-edit-icon-button ml-3" (click)="toggleEditNumber()">
          <i [ngClass]="fieldentryButtonIconClass" class="business-case-edit-icon"></i></button>
      </p>
      <p *ngIf="editingNumber" class="mt-0 mb-0">
        <mat-form-field class="mt-0">
          <input matInput [value]="editableNumber | number : '1.0-0' : 'nl'" (change)="editNumber($event)">
          <span matSuffix>
            <button type="button" (click)="resetEditNumber()" mat-icon-button
              class="business-case-edit-icon-button ml-3"><i class="fas fa-times business-case-edit-icon"></i></button>
          </span>
        </mat-form-field>
      </p>
      <p *ngIf="fieldentryInfoText" class="color-lightGrey">{{fieldentryInfoText}}</p>
    </div>

    <p *ngIf="disabled" class="color-lightGrey">{{disabledText}}</p>

    <div *ngIf="statistic">
      <p *ngIf="isNumber(statistic)">
        {{prefix}}
        {{statistic | number : '1.0-0' : 'nl'}}
        {{unit}}
      </p>
      <p *ngIf="isString(statistic)">
        {{prefix}} {{statistic}} {{unit}}
      </p>
      <p *ngIf="fieldentryInfoText" class="color-lightGrey">{{fieldentryInfoText}}</p>
    </div>


    <div *ngIf="fieldentryDropdownContent">
      <mat-form-field>
        <mat-label>{{fieldentryDropdownContent.hintText}}</mat-label>
        <mat-select [(value)]="selectedDropdownValue" (selectionChange)="editDropdownValue($event)">
          <mat-option *ngFor="let item of fieldentryDropdownContent.items" [value]="item.value">{{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

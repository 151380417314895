import { Directive, EventEmitter, Input, Output } from '@angular/core';

export type SortDirection = 'desc' | 'asc' | '';
const rotate: { [key: string]: SortDirection } = { 'desc': 'asc', 'asc': '', '': 'desc' };

export interface SortEvent {
    column: string;
    direction: SortDirection;
}

@Directive({
    selector: 'th[sortable]',
    host: {
        '[class.desc]': 'direction === "desc"',
        '[class.asc]': 'direction === "asc"',
        '(click)': 'rotate()'
    }
})
export class SortableDirective {

    @Input() sortable: string;
    @Input() direction: SortDirection = '';
    @Output() sort = new EventEmitter<SortEvent>();

    rotate() {
        this.direction = rotate[this.direction];
        this.sort.emit({ column: this.sortable, direction: this.direction });
    }
}
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { CartoService } from 'src/app/services/carto.service';
import { RegionalDetailsService } from 'src/app/services/regional-details.service';
import { MobileInfoComponent } from '../../mobile-info.component';
import { UpdatingTemplate } from '../updating-template';

@Component({
  selector: 'app-munic-template',
  templateUrl: './munic-template.component.html',
  styleUrls: ['./munic-template.component.css']
})
export class MunicTemplateComponent extends UpdatingTemplate implements OnInit {

  private selectedMunicipalityName: string = "";
  private filteredDataLengthCache: number = 0;
  private municDataLengthCache: number = 0;


  constructor(public cartoService: CartoService, public regionalDetails: RegionalDetailsService, changeDetection: ChangeDetectorRef) {
    super(changeDetection);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if(this.cartoService.selectedMunicipalityId)
      this.selectedMunicipalityName = this.cartoService.municipalities[this.cartoService.selectedMunicipalityId].name;
  }

  //During the MatBottomSheet dismiss animation cartoService may set selectedMunicipalityId to undefined.
  //These caches are used to avoid errors when this happens.
  getMunicName(): string{
    if(this.cartoService.selectedMunicipalityId)
      return this.cartoService.municipalities[this.cartoService.selectedMunicipalityId].name;
    else
      return this.selectedMunicipalityName
  }

  getMunicFilteredDataLength() : number{
    if(this.cartoService.municipalityDataFiltered){
      this.filteredDataLengthCache = this.cartoService.municipalityDataFiltered.length;
      return this.cartoService.municipalityDataFiltered.length;
    }
    else
      return this.filteredDataLengthCache;
  }

  getMunicDataLength() : number {
    if(this.cartoService.municipalityData){
      this.municDataLengthCache = this.cartoService.municipalityData.length;
      return this.cartoService.municipalityData.length;
    }
    else
      return this.municDataLengthCache;
  }
}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BusinessCaseService } from 'src/app/services/business-case.service';

@Component({
  selector: 'app-advanced-options-item',
  templateUrl: './advanced-options-item.component.html',
  styleUrls: ['./advanced-options-item.component.css']
})
export class AdvancedOptionsItemComponent implements OnInit {
  public editItem: boolean = false;
  @Input() name: string;
  @Input() value;
  @Input() valueName;

  @Input() customValue = 0;
  @Input() defaultValue;
  @Output() editableNumberChange = new EventEmitter<number>();
  @Input() listNumber;

  // public newValue;

  constructor(
    public businessService: BusinessCaseService
  ) { }

  ngOnInit() { }

  public changeItem(event) {
    if (this.listNumber == undefined) {
      this.businessService[this.name] = event.target.value;
    }
    else {
      this.businessService[this.name][this.listNumber] = event.target.value;
    }
    // this.editableNumberChange.emit(parseInt(event.target.value))
  }

  public toggleEditItem() {
    if (!this.editItem) {
      this.editItem = !this.editItem;
    } else {
      this.editItem = !this.editItem;
    }
  }

  public resetValue() {
    if (!this.listNumber) {
      this.businessService[this.name] = this.defaultValue;
    }
    else {
      this.businessService[this.name][this.listNumber] = this.defaultValue;
    }
  }
}

<app-sidemenu>
  <div class="h-100">
    <app-top-nav></app-top-nav>
    <div class="main-content">
         <!-- [ngClass]="layoutService.showNavbar ? 'main-content' : 'main-content-100'" -->
      <div class="d-flex h-100 flex-column">
        <div class="d-flex h-100 flex-column flex-grow-1">
          <router-outlet></router-outlet>

          <div *ngIf="showFooter" class="footer"
               [ngClass]="layoutService.activeFooterClass">
            <div class=""
                 style="display: flex; flex-direction: row; flex-wrap: wrap;">

              <div class="footer-content-left">
                <div>© Sobolt B.V. 2021</div>
                <div>Alle rechten voorbehouden</div>
                <div><a
                     href="https://www.sobolt.com/solarsearch-nl">sobolt.com/solarsearch-nl</a>
                </div>
              </div>
              <div class="footer-content-right">
                <a style="color: inherit; text-decoration: underline"
                   href="https://www.sobolt.com"
                   (click)="captureOutboundLink('https://www.sobolt.com')"
                   target="_blank">
                  <img src="/assets/images/powered_by_sobolt.png"
                       class="m-3 img-fluid"
                       style="width: 50px"
                       alt="Powered by Sobolt" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-sidemenu>
<h2 class="text-center heading-underline-primary">{{data.header}}</h2>
<div mat-dialog-content>
  <p [innerHtml]="data.customHtml"></p>
  <p *ngIf="data.customHtml">
    <ng-container [ngTemplateOutlet]="data.customHtml">
    </ng-container>
    <!-- {{data.customHtml}} -->
  </p>
  <p>{{data.content}}
    <a *ngIf="data.link" mat-dialog-content href="{{data.link}}" target="_blank">
      <p class="text-blue">
        {{data.linkContent}}</p>
    </a>
    <a *ngIf="data.link2" mat-dialog-content href="{{data.link2}}" target="_blank">
      <p class="text-blue">{{data.linkContent2}}</p>
    </a>
    <a *ngIf="data.link3" mat-dialog-content href="{{data.link3}}" target="_blank">
      <p class="text-blue">{{data.linkContent3}}</p>
    </a>
    <img *ngIf="data.image" src="{{data.image}}">
    {{data.content2}}
    <a *ngIf="data.link4" mat-dialog-content href="{{data.link4}}" target="_blank">
      <p class="text-blue">{{data.linkContent4}}</p>
    </a>
  </p>
</div>
<!-- <div *ngIf="data.link2" mat-dialog-content>{{data.content2}}</div> -->
<div mat-dialog-actions class="mt-3 d-flex justify-content-center">
  <button (click)="dialog.closeAll()" class="button-primary btn-narrow">Sluiten</button>
</div>
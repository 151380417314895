<mat-card *ngIf="cartoService.parkingData"
          class="mobileBusinessCaseContainer container">
  <div class="row justify-content-center mb-2">
    <span class="heading-underline-primary title">{{cartoService.parkingData.adrs_name || "inexistent"}}</span> 
  </div>
  <div class="row mb-2 justify-content-center">
  <ul style="list-style: none;">
    <li><span><b>Adress</b> {{cartoService.parkingData.address ==="None" ? 'Onbekend' : cartoService.parkingData.address }}</span><br></li>
    <li><span><b>Randvoorwaarden</b> {{businessService.totalSuitabilityPopovers[businessService.getTotalSuitability()-1].title}}</span><br></li>
    <li><span><b>Eenmalige investering</b> € {{businessService.roundTens(businessService.getFixedCosts() - businessService.getFixedRevenue(), 2) | number : '1.0-0' : 'nl' }}</span><br></li>
    <li><span><b>Jaarlijks resultaat</b> € {{businessService.roundTens(businessService.getYearlyRevenue() - businessService.getYearlyCosts(), 2) | number : '1.0-0' : 'nl' }} / jaar</span><br></li>
    <li><span><b>Terugverdientijd</b> {{businessService.getPaybackTime() | number : '1.0-0' : 'nl' }} jaar</span><br></li>
  </ul>
  </div>
  <div class="row justify-content-center mb-2">
    <mat-tab-group>
      <mat-tab label="Randvoorwaarden">
        <app-business-case-item iconClass="fas fa-compress-alt"
                                fieldName="Totale oppervlakte"
                                [statistic]="businessService.roundTens(cartoService.parkingData.area, 1) | number : '1.0-0' : 'nl'"
                                [dialogHeader]="businessInfo.tooltipText.surface_title"
                                [dialogContent]="businessInfo.tooltipText.surface_content"
                                unit="m2"
                                [flag]="businessService.getTotalAreaFlagColor()"
                                [flagPopoverMessage]="businessInfo.tooltipText.flagPopover_totalArea">
        </app-business-case-item>
        <app-business-case-item iconClass="fas fa-sun"
                                fieldName="Zonnige oppervlakte"
                                [fieldentryEditableNumber]="businessService.getSunnyArea()"
                                [(editableNumber)]="businessService.customSunnyArea"
                                unit="m2"
                                [flag]="businessService.getSunnyFlagColor()"
                                [flagPopoverMessage]="businessInfo.tooltipText.flagPopover_sunnyArea"
                                [dialogHeader]="businessInfo.tooltipText.sunny_surface_title"
                                [dialogContent]="businessInfo.tooltipText.sunny_surface_content">
        </app-business-case-item>
        <ng-template [ngTemplateOutlet]="areaSolarCarportField"></ng-template>
        <app-business-case-item iconClass="fas fa-bolt"
                                fieldName="Op te stellen vermogen"
                                unit="kWp"
                                [statistic]="businessService.getSolarParkingPower(3) | number : '1.0-0' : 'nl'"
                                [dialogHeader]="businessInfo.tooltipText.worth_title"
                                [dialogContent]="businessInfo.tooltipText.worth_content"
                                [flag]="businessService.getSolarPowerFlagColor()"
                                [flagPopoverMessage]="businessInfo.tooltipText.flagPopover_carportPower">
        </app-business-case-item>
        <app-business-case-item iconClass="fas fa-eye"
                                fieldName="Beschermd stadsgezicht"
                                [statistic]="businessService.isProtectedCityscape() ? 'Ja' : 'Nee'"
                                [flag]="businessService.getCityscapeFlagColor()"
                                [flagPopoverMessage]="businessInfo.tooltipText.flagPopover_protectedCityscape"
                                [dialogHeader]="businessInfo.tooltipText.cityscape_title"
                                [dialogContent]="businessInfo.tooltipText.cityscape_content"
                                [dialogLink]="'https://www.monumenten.nl/soorten-monumenten/beschermde-stads-en-dorpsgezichten'"
                                dialogLinkContent="Ga naar monumenten.nl">
        </app-business-case-item>
        <app-business-case-item *ngIf="businessService.getSolarParkingPower(3) < 160"
                                iconClass="fas fa-plug"
                                fieldName="Afstand tot netaansluiting"
                                fieldentryButtonIconClass="fas fa-pencil-alt"
                                [fieldnameTextList]="stage1LowGridDistanceText"
                                [(editableNumber)]="businessService.customLowVoltageGridDistance"
                                unit="m"
                                [fieldentryEditableNumber]="businessService.getLowVoltageGridDistance()"
                                [fieldentryInfoText]="businessInfo.tooltipText.distance_connection_low_infotext"
                                [dialogHeader]="businessInfo.tooltipText.distance_connection_title"
                                [dialogContent]="businessInfo.tooltipText.distance_connection_content"
                                [flag]="businessService.getGridDistanceFlagColor()"
                                [flagPopoverMessage]="businessInfo.tooltipText.flagPopover_gridDistance"
                                [dialogLink]="'https://www.enexis.nl/zakelijk/aansluitingen/tarieven/tarievenoverzicht'"
                                dialogLinkContent="Enexis"
                                [dialogLink2]="'https://www.liander.nl/tarieven-downloads'"
                                dialogLinkContent2="Liander"
                                [dialogLink3]="'https://www.stedin.net/tarieven#elektriciteit#tarieven'"
                                dialogLinkContent3="Stedin">
        </app-business-case-item>
        <app-business-case-item *ngIf="businessService.getSolarParkingPower(3) > 160"
                                iconClass="fas fa-plug"
                                fieldName="Afstand tot netaansluiting"
                                fieldentryButtonIconClass="fas fa-pencil-alt"
                                [fieldnameTextList]="stage1MidGridDistanceText"
                                [(editableNumber)]="businessService.customMidVoltageGridDistance"
                                unit="m"
                                [fieldentryEditableNumber]="businessService.getMidVoltageGridDistance()"
                                [fieldentryInfoText]="businessInfo.tooltipText.distance_connection_mid_infotext"
                                [dialogHeader]="businessInfo.tooltipText.distance_connection_title"
                                [dialogContent]="businessInfo.tooltipText.distance_connection_content"
                                [flag]="businessService.getGridDistanceFlagColor()"
                                [flagPopoverMessage]="businessInfo.tooltipText.flagPopover_gridDistance"
                                [dialogLink]="'https://www.enexis.nl/zakelijk/aansluitingen/tarieven/tarievenoverzicht'"
                                dialogLinkContent="Enexis"
                                [dialogLink2]="'https://www.liander.nl/tarieven-downloads'"
                                dialogLinkContent2="Liander"
                                [dialogLink3]="'https://www.stedin.net/tarieven#elektriciteit#tarieven'"
                                dialogLinkContent3="Stedin">
        </app-business-case-item>
        <app-business-case-item iconClass="fas fa-tachometer-alt"
                                fieldName="Grootte netaansluiting"
                                unit=""
                                [statistic]="businessService.getGridConnectionName()"
                                [fieldentryDropdownContent]="businessService.yesNoGridDistanceDropdown"
                                [(selectedDropdownValue)]="businessService.yesNoGridConnection"
                                [dialogHeader]="businessInfo.tooltipText.size_connection_title"
                                [dialogContent]="businessInfo.tooltipText.size_connection_content"
                                [dialogLink]="'https://www.enexis.nl/zakelijk/aansluitingen/tarieven/tarievenoverzicht'"
                                dialogLinkContent="Enexis"
                                [dialogLink2]="'https://www.liander.nl/tarieven-downloads'"
                                dialogLinkContent2="Liander"
                                [dialogLink3]="'https://www.stedin.net/tarieven#elektriciteit#tarieven'"
                                dialogLinkContent3="Stedin">
        </app-business-case-item>
      </mat-tab>
      <mat-tab label="Investering">
        <app-business-case-item iconClass="fas fa-solar-panel"
                                fieldName="Solar carport"
                                [statistic]="businessService.roundTens(businessService.getSolarCarportCosts(),2) | number : '1.0-0' : 'nl'"
                                prefix="€"
                                [dropdownContent]="businessService.solarCarportOptions"
                                [(selectedDropdownValue)]="businessService.solarCarportType"
                                [templateRef]="solarCarportDialog"
                                [fieldentryInfoText]="businessInfo.getSolarCarportInfoText()"
                                >
        </app-business-case-item>
        <!-- Fundering -->
        <app-business-case-item iconClass="fas fa-tools"
                                [fieldName]="businessInfo.tooltipText.fundering_title"
                                [statistic]="businessService.roundTens(businessService.getFunderingCosts(),2) | number : '1.0-0' : 'nl'"
                                prefix="€"
                                [dropdownContent]="businessService.funderingOptions"
                                [(selectedDropdownValue)]="businessService.funderingOption"
                                [dialogHeader]="businessInfo.tooltipText.fundering_title"
                                [dialogContent]="businessInfo.tooltipText.fundering_content">
        </app-business-case-item>
        <!-- <app-business-case-item iconClass="fas fa-plug"
                                fieldName="Netaansluiting"
                                [fieldnameEditableNumber]="businessInfo.stage2ConnectionfieldnameEditableNumber"
                                prefix="€"
                                [statistic]="businessService.roundTens(businessService.getFixedGridCosts(),2) | number : '1.0-0' : 'nl'"
                                [dialogHeader]="businessInfo.tooltipText.cost_connection_title"
                                [dialogContent]="businessInfo.tooltipText.cost_connection_content"
                                [dialogContent2]="businessInfo.tooltipText.cost_connection_content2"
                                [dialogLink]="'https://www.enexis.nl/zakelijk/aansluitingen/tarieven/tarievenoverzicht'"
                                dialogLinkContent="Enexis"
                                [dialogLink2]="'https://www.liander.nl/tarieven-downloads'"
                                dialogLinkContent2="Liander"
                                [dialogLink3]="'https://www.stedin.net/tarieven#elektriciteit#tarieven'"
                                dialogLinkContent3="Stedin"
                                [fieldnameEditableText]="stage2ConnectionFieldnameEditableText"
                                >
        </app-business-case-item> -->
        <app-business-case-item iconClass=" fas fa-file-invoice"
                                [fieldName]="businessInfo.tooltipText.leges_title"
                                [statistic]="businessService.roundTens(businessService.getLegesCosts(),2) | number : '1.0-0' : 'nl'"
                                prefix="€"
                                [dialogHeader]="businessInfo.tooltipText.leges_title"
                                [dialogContent]="businessInfo.getLegesPopupText()">
        </app-business-case-item>
        <app-business-case-item iconClass=" fas fa-search"
                                fieldName="Vaste projectkosten & vooronderzoek"
                                [fieldentryEditableNumber]="businessService.roundTens(businessService.getResearchCosts(),2)"
                                prefix="€"
                                [(editableNumber)]="businessService.customResearchCosts"
                                [dialogHeader]="businessInfo.tooltipText.research_title"
                                [dialogCustomHtml]="businessInfo.tooltipText.research_content">
        </app-business-case-item>
        
        <app-business-case-item iconClass="fas fa-charging-station"
                                fieldName="Laadpunten"
                                [statistic]="businessService.roundTens(businessService.getChargerCosts(),2) | number : '1.0-0' : 'nl'"
                                prefix="€"
                                [(selectedDropdownValue)]="businessService.chargerType"
                                [dropdownContent]="businessService.chargerOptions"
                                [fieldnameEditableNumber]="businessService.defaultNumChargers"
                                fieldnameEditableNumberUnit="stuks"
                                [(editableFieldnameNumber)]="businessService.customNumChargers"
                                [templateRef]="laadpuntenDialog">
        </app-business-case-item>
        <!-- Verlichting -->
        <app-business-case-item iconClass=" far fa-lightbulb"
                                [fieldName]="businessInfo.tooltipText.light_title"
                                [dropdownContent]="businessService.lightOptions"
                                prefix="€"
                                [(selectedDropdownValue)]="businessService.lightType"
                                [statistic]="businessService.roundTens(businessService.getLightCosts(),2) | number : '1.0-0' : 'nl'"
                                [dialogHeader]="businessInfo.tooltipText.light_title"
                                [dialogCustomHtml]="businessInfo.tooltipText.light_content">
        </app-business-case-item>
        <app-business-case-item iconClass="fas fa-shield-alt"
                                fieldName="Beveiliging"
                                [statistic]="businessService.roundTens(businessService.getSecurityCosts(),2) | number : '1.0-0' : 'nl'"
                                prefix="€"
                                [dropdownContent]="businessService.securityOptions"
                                [(selectedDropdownValue)]="businessService.securityType"
                                [dialogHeader]="businessInfo.tooltipText.cost_security_title"
                                [dialogContent]="businessInfo.tooltipText.cost_security_content">
        </app-business-case-item>
        <ng-template [ngTemplateOutlet]="batteryCostField"></ng-template>
        <br>
        <div class="row business-case-row">
          <div class="col-7">
            <p><b>Jaarlijkse investering</b></p>
          </div>
          <div class="col-5">
            <p><b>€ {{businessService.roundTens(businessService.getYearlyCosts() ,2) |
                number : '1.0-0' : 'nl'}}</b>
            </p>
          </div>
        </div>
        <app-business-case-item iconClass="fas fa-plug"
                                fieldName="Netaansluiting"
                                [fieldnameEditableNumber]="stage2ConnectionfieldnameEditableNumber"
                                prefix="€"
                                unit="per jaar"
                                [statistic]="businessService.roundTens(businessService.getYearlyGridCosts(),1) | number : '1.0-0' : 'nl'"
                                [dialogHeader]="businessInfo.tooltipText.cost_connection_title"
                                [dialogContent]="businessInfo.tooltipText.cost_connection_content"
                                [dialogContent2]="businessInfo.tooltipText.cost_connection_content2"
                                [dialogLink]="'https://www.enexis.nl/zakelijk/aansluitingen/tarieven/tarievenoverzicht'"
                                dialogLinkContent="Enexis"
                                [dialogLink2]="'https://www.liander.nl/tarieven-downloads'"
                                dialogLinkContent2="Liander"
                                [dialogLink3]="'https://www.stedin.net/tarieven#elektriciteit#tarieven'"
                                dialogLinkContent3="Stedin"
                                [fieldnameEditableText]="stage2ConnectionFieldnameEditableText">
        </app-business-case-item>
        <app-business-case-item iconClass="fas fa-sign"
                                fieldName="Huur/pacht kosten"
                                [statistic]="businessService.roundTens(businessService.getYearlyRentCosts(),1) | number : '1.0-0' : 'nl'"
                                prefix="€"
                                [fieldnameTextList]="stage2RentText"
                                unit="per jaar"
                                [fieldnameEditableNumber]="businessService.defaultRentTariff"
                                fieldnameEditableNumberUnit="€/m2 per jaar"
                                [(editableFieldnameNumber)]="businessService.customRentTariff"
                                [dialogHeader]="businessInfo.tooltipText.cost_rent_title"
                                [dialogContent]="businessInfo.tooltipText.cost_rent_content">
        </app-business-case-item>
        <app-business-case-item iconClass="fas fa-toolbox"
                                fieldName="Onderhoud"
                                [fieldentryEditableNumber]="businessService.roundTens(businessService.getYearlyMaintenanceCosts(), 2)"
                                prefix="€"
                                [(editableNumber)]="businessService.customMaintenanceCosts"
                                unit="per jaar"
                                [dialogHeader]="businessInfo.tooltipText.cost_maintenance_title"
                                [dialogContent]="businessInfo.tooltipText.cost_maintenance_content">
        </app-business-case-item>
        <app-business-case-item iconClass="fas fa-briefcase-medical"
                                fieldName="Verzekering"
                                [fieldentryEditableNumber]="businessService.roundTens(businessService.getYearlyInsuranceCosts(),2)"
                                prefix="€"
                                unit="per jaar"
                                [(editableNumber)]="businessService.customYearlyInsuranceCosts"
                                [dialogHeader]="businessInfo.tooltipText.cost_insurance_title"
                                [dialogContent]="businessInfo.tooltipText.cost_insurance_content">
        </app-business-case-item>
      </mat-tab>
      <mat-tab label="Obrengst">
        <app-business-case-item iconClass="fas fa-money-bill-alt"
                                fieldName="Landelijke subsidie"
                                [statistic]="businessService.roundTens(businessService.getYearlySubsidyRevenue(),2) | number : '1.0-0' : 'nl'"
                                [dropdownContent]="businessService.subsidyDropdown"
                                [dialogHeader]="businessInfo.tooltipText.subsidy_title"
                                [(selectedDropdownValue)]="businessService.subsidyOption"
                                [dialogContent]="businessInfo.tooltipText.subsidy_content"
                                prefix="€"
                                unit="per jaar"
                                [dialogLink]="'https://www.rvo.nl/subsidie-en-financieringswijzer/stimulering-duurzame-energieproductie-en-klimaattransitie-sde/aanvragen-sde'"
                                dialogLinkContent="Stimulering Duurzame Energie, SDE++"
                                [dialogLink2]="'https://www.postcoderoosregeling.nl/'"
                                dialogLinkContent2="Postcoderoosregeling (NB: verandert eind 2020 in een subsidieregeling!)">
        </app-business-case-item>
        <app-business-case-item iconClass="fas fa-piggy-bank"
                                [fieldName]="businessInfo.tooltipText.other_subsidies_title"
                                [fieldentryEditableNumber]="businessService.defaultYearlyOtherSubsidy | number : '1.0-0' : 'nl'"
                                [(editableNumber)]="businessService.customYearlyOtherSubsidy"
                                prefix="€"
                                unit=" per jaar"
                                [dialogHeader]="businessInfo.tooltipText.other_subsidies_title"
                                [dialogCustomHtml]="businessInfo.tooltipText.other_subsidies_content">
        </app-business-case-item>
        <app-business-case-item iconClass="fas fa-battery-three-quarters"
                                fieldName="Opgewekte energie"
                                [statistic]="businessService.roundTens(businessService.getYearlyEnergy(3),2) | number : '1.0-0' : 'nl'"
                                [dialogHeader]="businessInfo.tooltipText.energy_title"
                                [dialogContent]="businessInfo.tooltipText.energy_content"
                                unit="kWh per jaar">
        </app-business-case-item>
        <app-business-case-item iconClass="fas fa-bolt"
                                [fieldName]="businessInfo.tooltipText.return_title"
                                [statistic]="businessService.roundTens(businessService.getYearlyGridSalesRevenue(),2) | number : '1.0-0' : 'nl'"
                                [fieldnameTextList]="stage3GridSalesFieldnameText"
                                prefix="€"
                                unit="per jaar"
                                [fieldnameEditableNumber]="businessService.gridSalesDefaultTariff"
                                fieldnameEditableNumberUnit="ct/kWh"
                                [(editableFieldnameNumber)]="businessService.gridSalesCustomTariff"
                                [dialogHeader]="businessInfo.tooltipText.return_title"
                                [dialogContent]="businessInfo.tooltipText.return_content">
        </app-business-case-item>
        <app-business-case-item iconClass="fas fa-plug"
                                [fieldName]="businessInfo.tooltipText.ownuse_title"
                                [statistic]="businessService.roundTens(businessService.getOwnUseRevenue(),2) | number : '1.0-0' : 'nl'"
                                prefix="€"
                                [dropdownContent]="businessService.ownUsePercentageOptions"
                                unit="per jaar"
                                [(selectedDropdownValue)]="businessService.ownUseFraction"
                                [dialogHeader]="businessInfo.tooltipText.ownuse_title"
                                [fieldnameEditableNumber]="businessService.ownUseKwhDefaultTariff"
                                fieldnameEditableNumberUnit="ct/kWh"
                                [fieldnameTextList]="stage3OwnUseFieldnameText"
                                [dialogContent]="businessInfo.tooltipText.ownuse_content"
                                [(editableFieldnameNumber)]="businessService.ownUseKwhCustomTariff">
        </app-business-case-item>
        <!-- [fieldentryInfoText]="getChargingInfoText()" was removed from below for mobile formatting. -->
        <app-business-case-item iconClass="fas fa-charging-station"
                                fieldName="Laden"
                                [statistic]="businessService.roundTens(businessService.getYearlyChargingRevenue(),1) | number : '1.0-0' : 'nl'"
                                prefix="€"
                                [dropdownContent]="businessService.parkingTimeOptions"
                                unit="per jaar"
                                [(selectedDropdownValue)]="businessService.parkingTime"
                                
                                [dropdownContent2]="businessService.parkingCoverageOptions"
                                [(selectedDropdownValue2)]="businessService.parkingCoverage"
                                [dialogHeader]="businessInfo.tooltipText.charge_title"
                                [dialogContent]="businessInfo.tooltipText.charge_content">
        </app-business-case-item>
        <!-- [fieldentryInfoText]="businessInfo.tooltipText.netting_info" [dialogHeader]="businessInfo.tooltipText.netting_title" was removed from below for mobile formatting. -->
        <app-business-case-item *ngIf="businessService.getSolarParkingPower(3) < 15"
                                iconClass="fas fa-calculator"
                                fieldName="Salderen"
                                [statistic]="businessService.getYearlySalderenRevenue() | number : '1.0-0' : 'nl'"
                                prefix="€"
                                [(selectedDropdownValue)]="businessService.salderenDefaultKwhUsage"
                                unit=" per jaar"
                                [dropdownContent]="businessService.salderenOptions"
                                [fieldnameTextList]="stage3SalderenFieldnameText"
                                [fieldnameEditableNumber]="businessService.salderenDefaultKwhUsage"
                                fieldnameEditableNumberUnit="kWh per jaar"
                                [(editableFieldnameNumber)]="businessService.salderenCustomKwhUsage"
                                [dialogHeader]="businessInfo.tooltipText.netting_title"
                                [dialogContent]="businessInfo.tooltipText.netting_content"
                                [dialogLink]="'https://www.rijksoverheid.nl/actueel/nieuws/2019/04/26/salderingsregeling-verlengd-tot-2023'"
                                dialogLinkContent="De salderingsregeling blijft tot 1 januari 2023 bestaan en wordt daarna afgebouwd. ">
        </app-business-case-item>
        <!-- [fieldentryInfoText]="businessInfo.tooltipText.no_netting_info" was removed from below for mobile formatting. -->
        <app-business-case-item *ngIf="businessService.getSolarParkingPower(3) > 15"
                                iconClass="fas fa-calculator"
                                prefix="€"
                                statistic="0"
                                fieldName="Salderen"
                                [dialogHeader]="businessInfo.tooltipText.netting_title"
                                [dialogContent]="businessInfo.tooltipText.netting_content"
                                [dialogLink]="'https://www.rijksoverheid.nl/actueel/nieuws/2019/04/26/salderingsregeling-verlengd-tot-2023'"
                                dialogLinkContent="De salderingsregeling blijft tot 1 januari 2023 bestaan en wordt daarna afgebouwd.">
        </app-business-case-item>
        <app-business-case-item iconClass="fas fa-parking"
                                fieldName="Overdekt parkeren"
                                [statistic]="businessService.roundTens(businessService.getYearlyExtraParkingRevenue(),1) | number : '1.0-0' : 'nl'"
                                prefix="€"
                                [fieldnameTextList]="stage3ExtraParkingFieldnameText"
                                unit="per jaar"
                                [fieldnameEditableNumber]="businessService.extraParkingDefaultTariff"
                                fieldnameEditableNumberUnit="ct/uur"
                                [(editableFieldnameNumber)]="businessService.extraParkingCustomTariff"
                                [dialogHeader]="businessInfo.tooltipText.indoor_title"
                                [dialogContent]="businessInfo.tooltipText.indoor_content">
        </app-business-case-item>
        <app-business-case-item iconClass="fas fa-leaf"
                                fieldName="Vermeden CO2 uitstoot"
                                unit="kg CO2 per jaar"
                                [statistic]="businessService.roundTens(businessService.getCo2Savings(), 2) | number : '1.0-0' : 'nl'"
                                [dialogHeader]="businessInfo.tooltipText.co2_title"
                                [dialogContent]="businessInfo.tooltipText.co2_content"
                                [dialogLink]="'https://www.pbl.nl/sites/default/files/downloads/pbl-2020-eindadvies-basisbedragen-sde-plus-plus-2020_3526_27-02-2020.pdf'"
                                dialogLinkContent="Meer informatie over dit kengetal">
        </app-business-case-item>
        <!-- [fieldentryInfoText]="businessInfo.getBatteryRevenueInfoText()" has been removed for mobile formatting -->
        <app-business-case-item iconClass="fas fa-car-battery"
                                [fieldName]="businessInfo.tooltipText.revenue_battery_title"
                                [statistic]="businessService.roundTens(businessService.getYearlyBatteryRevenue(),1) | number : '1.0-0' : 'nl'"
                                prefix="€"
                                unit=" per jaar"
                                [dialogHeader]="businessInfo.tooltipText.revenue_battery_title"
                                [dialogCustomHtml]="businessInfo.tooltipText.revenue_battery_content">
        </app-business-case-item>
        <div class="row business-case-row">
          <div class="col-8">
            <p><b>Eenmalige opbrengsten</b></p>
          </div>
          <div class="col-4">
            <p><b>€
                {{businessService.roundTens(businessService.getFixedRevenue() ,2) |
                number : '1.0-0' : 'nl'}}</b>
            </p>
          </div>
        </div>
        <app-business-case-item iconClass="fas fa-piggy-bank"
                                [fieldName]="businessInfo.tooltipText.other_subsidies_title"
                                [fieldentryEditableNumber]="businessService.defaultFixedOtherSubsidy | number : '1.0-0' : 'nl'"
                                [(editableNumber)]="businessService.customFixedOtherSubsidy"
                                prefix="€"
                                [dialogHeader]="businessInfo.tooltipText.other_subsidies_title"
                                [dialogCustomHtml]="businessInfo.tooltipText.other_subsidies_content">
        </app-business-case-item>
      </mat-tab>
      <mat-tab label="Aan de slag">
        <p style="padding-left: 1rem; padding-right: 1rem;">
          Ben je tevreden met de gegevens & keuzes in stap 1 t/m 3? Dan ben je klaar om echt aan de slag te gaan!
          Zet de volgende belangrijke acties in gang:
        </p>
        <div class="row mt-4">
          <div class="col-2">
            <i class="fas fa-money-bill-alt icon-sm-dark"></i>
          </div>
          <div class="col-10">
            <p style="font-weight: bold;">Subsidies aanvragen</p>
            <p class="business-case-links"
               (click)="openDialog(businessInfo.tooltipText.apply_SDE_title, businessInfo.tooltipText.apply_SDE_content, 'https://www.rvo.nl/subsidie-en-financieringswijzer/stimulering-duurzame-energieproductie-en-klimaattransitie-sde/aanvragen-sde', 'SDE++' , undefined, undefined, undefined, 'https://www.rvo.nl/subsidie-en-financieringswijzer/energie-investeringsaftrek-eia' , 'Energie-investeringsaftrek (EIA)' , 'https://www.postcoderoosregeling.nl/ ', 'Postcoderegeling' )">
              Lees meer</p>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-2">
            <i class="fas fa-bolt icon-sm-dark"></i>
          </div>
          <div class="col-10">
            <p style="font-weight: bold;">Netbeheerder checken</p>
            <p class="business-case-links"
               (click)="openDialog(businessInfo.tooltipText.checkNetworkOperatorTitle, businessInfo.tooltipText.checkNetworkOperatorContent, 'https://www.netbeheernederland.nl/_contentediting/files/files/EN_Elektriciteit-2019-Legenda.pdf', 'op de kaart van Netbeheer Nederland', undefined, undefined, undefined, undefined, undefined, undefined, undefined)">
              Lees meer</p>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-2">
            <i class="fas fa-stamp icon-sm-dark"></i>
          </div>
          <div class="col-10">
            <p style="font-weight: bold;">Omgevingsvergunning aanvragen</p>
            <p class="business-case-links"
               (click)="openDialog(businessInfo.tooltipText.apply_permit_title, businessInfo.tooltipText.apply_permit_content, 'https://www.omgevingsloket.nl/', 'Kijk op de website van het Omgevingsloket voor meer informatie', undefined, undefined, undefined, undefined, undefined, undefined, undefined)">
              Lees meer</p>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-2">
            <i class="fas fa-house-user icon-sm-dark"></i>
          </div>
          <div class="col-10">
            <p style="font-weight: bold;">Participatieproject opstarten</p>
            <p class="business-case-links"
               (click)="openDialog(businessInfo.tooltipText.startParticipationProjectTitle, businessInfo.tooltipText.startParticipationProjectContent, 'https://departicipatiecoalitie.nl/wat-is-de-pc/', 'www.departicipatiecoalitie.nl', undefined, undefined, undefined, undefined, undefined, undefined, undefined)">
              Lees meer</p>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-2">
            <i class="fas fa-handshake icon-sm-dark"></i>
          </div>
          <div class="col-10">
            <p style="font-weight: bold;"> Energiecoöperatie aanhaken</p>
            <p class="business-case-links"
                (click)="openEnergyCooperationDialog()">Lees meer</p>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-2">
            <i class="fas fa-wrench icon-sm-dark"></i>
          </div>
          <div class="col-10">
            <p style="font-weight: bold;">Ontwikkelaar aanhaken</p>
            <p class="business-case-links"
              (click)="openDialog(businessInfo.tooltipText.installer_title, businessInfo.tooltipText.installer_content, 'https://hollandsolar.nl/leden?marktsegment=0--1c-zon-op-land-zonneparken-op-land&productdienst=0--4-projectontwikkelaar-project-financiering', 'Overzicht van bij branchevereniging aangesloten ontwikkelaars', undefined, undefined, undefined, undefined, undefined, undefined, undefined)">
              Lees meer</p>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-2">
            <i class="fas fa-users icon-sm-dark"></i>
          </div>
          <div class="col-10">
            <p style="font-weight: bold;">Omwonenden betrekken</p>
            <p class="business-case-links"
               (click)="openDialog(businessInfo.tooltipText.localResidentsTitle, businessInfo.tooltipText.localResidentsContent, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)">
              Lees meer</p>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-2">
            <i class="fas fa-piggy-bank icon-sm-dark"></i>
          </div>
          <div class="col-10">
            <p style="font-weight: bold;">Financiering regelen</p>
            <p class="business-case-links"
               (click)="openFinanceDialog()">Lees meer</p>
          </div>
        </div>
        <div class="row my-2 justify-content-center">
          <button (click)="exportPDF()" class="button-primary">Exporteer PDF</button>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="row justify-content-center">
    <button class="button-primary"
          (click)="cartoService.zoomOutfromParkingLot()">{{backButtonText()}}</button>
  </div>
</mat-card>

<ng-template #areaSolarCarportField>
  <div class="row business-case-row px-0 mx-0">
    <div class="d-none col-1">
      <!-- <i class="fas fa-solar-panel"></i> -->
    </div>
    <div class="col-8 px-0">
      <h3 class="business-case-fields">Oppervlakte solar carport
        <mat-icon *ngIf="businessService.getSolarCarportAreaFlagColor() === 1" class="ml-2 fa fa-flag flag-red pointer"
          aria-hidden="true" placement="bottom" [ngbPopover]="businessInfo.tooltipText.flagPopover_carportArea" popoverTitle="Slecht"
          container="body">
        </mat-icon>
        <mat-icon *ngIf="businessService.getSolarCarportAreaFlagColor() === 2"
          class="ml-2 fa fa-flag flag-orange  pointer" aria-hidden="true" placement="bottom"
          [ngbPopover]="businessInfo.tooltipText.flagPopover_carportArea" popoverTitle="Middelmatig" container="body">
        </mat-icon>
        <mat-icon *ngIf="businessService.getSolarCarportAreaFlagColor() === 3"
          class="ml-2 fa fa-flag flag-green  pointer" aria-hidden="true" placement="bottom"
          [ngbPopover]="businessInfo.tooltipText.flagPopover_carportArea" popoverTitle="Goed" container="body">
        </mat-icon>
      </h3>
      <p class="business-case-links"
        (click)="openDialog(businessInfo.tooltipText.build_surface_title, businessInfo.tooltipText.build_surface_content, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)">
        Lees meer</p>
      <mat-form-field class="input-wide">
        <mat-label>{{businessService.carportAreaOptions.hintText}}</mat-label>
        <mat-select [(value)]="businessService.carportAreaType"
          (selectionChange)="businessService.editCarportArea($event)">
          <mat-option *ngFor="let item of businessService.carportAreaOptions.items" [value]="item.value">
            {{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mt-0 input-wide" *ngIf="businessService.carportAreaType == 2">
        <input type="number" matInput placeholder="Hoeveel zonnig oppervlakte overkappen? (%)"
          [value]="(businessService.buildableAreaPercentage * 100) |  number : '1.0-0' : 'nl'"
          (change)="buildableAreaInputChange($event)" onwheel="this.blur()">
        <span matSuffix>%</span>
      </mat-form-field>
    </div>
    <div class="col-3 px-0">
      {{businessService.roundTens(businessService.getCarportArea(),1) | number : '1.0-0' : 'nl'}}
      m2</div>
  </div>
</ng-template>

<ng-template #solarCarportDialog>
  <h2 class="text-center text-cutoff-nowrap heading-underline-primary">{{businessInfo.tooltipText.solar_carport_title}}</h2>
  <div mat-dialog-content>
    <p>{{businessInfo.tooltipText.solar_carport_content}}
    </p>
    <div class="row justify-content-center background-light pb-4">
      <div class="col-6 align-items-center">
        <p class=""><b>Standaard</b></p>
        <p class="">Stalen constructie</p>
        <p class="">Zwart / grijze kleur</p>
        <p class="">V-vorm</p>
        <p class="">Standaard hoogte personenauto</p>
        <p class="">Geen logo</p>
      </div>
      <div class="col-6 align-items-center">
        <p class=""><b>Gepersonaliseerd</b></p>
        <p class="">Stalen of houten constructie</p>
        <p class="">Kleur naar wens</p>
        <p class="">Vorm naar wens</p>
        <p class="">Maatwerk hoogte vrachtwagens</p>
        <p class="">Logo</p>
      </div>
      <div class="col-12 background-light d-flex align-items-center justify-content-center">
        <img class="systems-image" src="/assets/images/landing-kaart-06.png" alt="Solar carport dubbele rij" />
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="mt-3 d-flex justify-content-center">
    <button (click)="dialog.closeAll()" class="button-primary btn-narrow">Sluiten</button>
  </div>
</ng-template>

<ng-template #batteryCostField>
  <div class="row business-case-row">
    <div class="d-none col-1"><i class="fas fa-solar-panel"></i></div>
    <div class="col-7">
      <h3 class="business-case-fields">Batterij systemen</h3>
      <p class="business-case-links"
        (click)="openDialog(businessInfo.tooltipText.cost_battery_title, businessInfo.tooltipText.cost_battery_content, 'https://www.tennet.org/bedrijfsvoering/Systeemgegevens_voorbereiding/Aangeboden_regel_en_reservevermogen/index.aspx', 'Meer informatie over het gebruiken van de batterij als reservevermogen', undefined, undefined, undefined, undefined, undefined, undefined)">
        Lees meer</p>
      <mat-form-field class="input-wide">
        <mat-label>{{businessService.batteryCostOptions.hintText}}</mat-label>
        <mat-select [(value)]="businessService.batteryType"
          (selectionChange)="businessService.editBatteryCost($event)">
          <mat-option *ngFor="let item of businessService.batteryCostOptions.items" [value]="item.value">
            {{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mt-0 input-wide" *ngIf="businessService.batteryType == (businessService.batteryCostOptions.items.length-1)">
        <input type="number" matInput placeholder="Hoeveel opslag is gewenst? (kWh)"
          [value]="businessService.batterySize |  number : '1.0-0' : 'nl'"
          (change)="batterySizeInputChange($event)" onwheel="this.blur()">
        <span matSuffix>kWh</span>
      </mat-form-field>
    </div>
    <div class="col-4">
      € {{businessService.roundTens(businessService.getBatteryCosts(),1) | number : '1.0-0' : 'nl'}}
      </div>
  </div>
</ng-template>
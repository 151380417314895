<mat-card *ngIf="cartoService.showParkingList" class="parking-list-card">
  <button class="close-button button-primary-v-small" (click)="closeParkingListMuni()">
    <i class="fas fa-window-close"></i>
  </button>
  <div class="container mb-3">
    <div class="row">
      <div class="col-11">
        <h2 class="text-center heading-underline-primary">Parkeerterreinen in de gemeente
          {{ cartoService.municipalities[cartoService.selectedMunicipalityId].name }}</h2>
      </div>
      <!-- <div class="col-1 text-right mr-0">
        <button class="close-button button-primary-v-small" (click)="closeParkingListMuni()">
          <i class="fas fa-window-close"></i>
        </button>
      </div> -->
    </div>
  </div>
  <mat-card-content class="parking-list-card-content">
    <input type="text" id="input_search_muni" (keyup)="cartoService.filterMunicipalityBySearch($event.target.value)"
      placeholder="Zoeken">
    <table class="table table-responsive-lg table-hover" id="address_table">
      <thead>
        <tr>
          <th scope="col" sortable="adrs_name">Locatie</th>
          <th scope="col" sortable="address" (sort)="onSortMunicipality($event)">
            Adres
          </th>
          <!-- <th scope="col" sortable="address" (sort)="onSort($event)">
            adrs_full
          </th> -->
          <th scope="col" sortable="area" start="desc" (sort)="onSortMunicipality($event)">
            Oppervlakte (m2)
          </th>
          <th scope="col" sortable="cap_kwp" (sort)="onSortMunicipality($event)">
            Vermogen (kWp) 
          </th>
          <!-- <th scope="col">Investering (€)</th>
          <th scope="col">Terugverdientijd(€)</th> -->
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let parking of cartoService.municipalityDataSorted | slice: (pageMunicipality-1) * pageSize : (pageMunicipality-1) * pageSize + pageSize;  let idx = index"
          class="table-row-clickable">
          <td (click)="cartoService.parkingById(parking.cartodb_id)">
            {{ parking.adrs_name }}
          </td>
          <td (click)="cartoService.parkingById(parking.cartodb_id)">
            {{ parking.address }}
          </td>
          <!-- <td (click)="cartoService.parkingById(parking.cartodb_id)">
            {{ parking.adrs_full }}
          </td> -->
          <td (click)="cartoService.parkingById(parking.cartodb_id)">
            {{ parking.area | number : '1.1-1' : 'nl'}}
          </td>
          <td (click)="cartoService.parkingById(parking.cartodb_id)">
            {{ (parking.cap_kwp * cartoService.correctionKwpDatabase) | number : '1.0-0' : 'nl'}}
          </td>
          <!-- <td (click)="cartoService.parkingById(parking.cartodb_id)">
            Investering
          </td>
          <td (click)="cartoService.parkingById(parking.cartodb_id)">
            Opbrengst
          </td> -->
        </tr>
      </tbody>
    </table>
  </mat-card-content>
  <ngb-pagination class="d-flex justify-content-end" [collectionSize]="cartoService.municipalityDataSorted.length"
    [(page)]="pageMunicipality" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
    [pageSize]="pageSize">
  </ngb-pagination>
  <div class="d-flex flex-row justify-content-center">
    <button class="p-2 mr-2 button-light-small" (click)="exportExcel()">Export Excel</button>
    <button class="p-2 ml-2 button-light-small" (click)="exportGeoJson()">Export GIS</button>
  </div>
</mat-card>

<mat-card *ngIf="cartoService.showParkingListProv" class="parking-list-card mb-3">
  <button class="button-primary-v-small close-button" (click)="closeParkingListProv()">
    <i class="fas fa-window-close"></i>
  </button>
  <div class="container mb-3">
    <div class="row">
      <div class="col-11">
        <h2 class="text-center heading-underline-primary">Parkeerterreinen in de provincie
          {{ cartoService.provinces[cartoService.selectedProvinceId].name }}</h2>
      </div>
      <!-- <div class="col-1 text-right mr-0">
        <button class="button-primary-v-small" (click)="closeParkingListProv()">
          <i class="fas fa-window-close"></i>
        </button>
      </div> -->
    </div>
  </div>
  <mat-card-content class="parking-list-card-content">
    <input type="text" id="input_search_prov" (keyup)="cartoService.filterProvinceBySearch($event.target.value)"
      placeholder="Zoeken">
    <table class="table table-responsive-lg table-hover">
      <thead>
        <tr>
          <th scope="col">Locatie</th>
          <th scope="col" sortable="address" (sort)="onSortProvince($event)">
            Adres
          </th>
          <th scope="col" sortable="area" (sort)="onSortProvince($event)">
            Oppervlakte (m2)
          </th>
          <th scope="col" sortable="cap_kwp" (sort)="onSortProvince($event)">
            Vermogen (kWp)
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let parking of this.cartoService.provinceDataSorted | slice: (pageProvince-1) * pageSize : (pageProvince-1) * pageSize + pageSize;  let idx = index"
          class="table-row-clickable">
          <td (click)="cartoService.parkingById(parking.cartodb_id)">
            {{ parking.adrs_name }}
          </td>
          <td (click)="cartoService.parkingById(parking.cartodb_id)">
            {{ parking.address }}
          </td>
          <td (click)="cartoService.parkingById(parking.cartodb_id)">
            {{ parking.area | number : '1.1-1' : 'nl'}}
          </td>
          <td (click)="cartoService.parkingById(parking.cartodb_id)">
            {{ (parking.cap_kwp * cartoService.correctionKwpDatabase) | number : '1.0-0' : 'nl'}}
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card-content>
  <ngb-pagination class="d-flex justify-content-end" [collectionSize]="cartoService.provinceDataSorted.length"
    [(page)]="pageProvince" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
    [pageSize]="pageSize"></ngb-pagination>
  <div class="d-flex flex-row justify-content-center">
    <button class="p-2 mr-2 button-light-small" (click)="exportExcel()">Export Excel</button>
    <button class="p-2 ml-2 button-light-small" (click)="exportGeoJson()">Export GIS</button>
  </div>
</mat-card>

<app-mobileinfo-close></app-mobileinfo-close>
<div class="container">
  <button [ngClass]="cartoService.showParkingList ? 'button-light top-left': 'button-primary top-left'"
          (click)="cartoService.showParkingList = !cartoService.showParkingList"><i
       class="fa fa-list icon-sm-white"
       aria-hidden="true"></i></button>
  <div class="row justify-content-center">
    <h2 class="text-center heading-underline-primary">
      <!-- {{cartoService.municipalities[cartoService.selectedMunicipalityId].name}} -->
      {{getMunicName()}}
    </h2>
  </div>
  <div class="row icon-row">
    <i class="fa fa-map-marker icon-sm mr-3"></i>
    <p class="mx-1">
      <!-- <b>{{ cartoService.municipalityDataFiltered.length | number : '1.0-0' :
        'nl'}}</b>
      /{{cartoService.municipalityData.length | number : '1.0-0' : 'nl'}} -->
      <b>{{ getMunicFilteredDataLength() | number : '1.0-0' :
        'nl'}}</b>
      /{{getMunicDataLength() | number : '1.0-0' : 'nl'}}
    </p>
    <p>locaties</p>
  </div>
  <div class="row icon-row">
    <i class="fas fa-parking mr-3 icon-sm"></i>
    <p class="mx-1">{{ regionalDetails.getTotal('municipalityDataFiltered',
      'cap_kwp') | number : '1.0-0' : 'nl' }}</p>
    <p>kWp vermogen</p>
  </div>
  <div class="row icon-row">
    <i class="fas fa-parking mr-3 icon-sm"></i>
    <p class="mx-1">{{ regionalDetails.getTotal('municipalityDataFiltered',
      'area')
      | number : '1.0-0' : 'nl' }}</p>
    <p>m2 parkeerterreinen</p>
  </div>
  <div class="row justify-content-center">
    <button class="button-primary mb-1"
            (click)="cartoService.zoomOutfromMunicipality();cartoService.showParkingList = false">
      Terug naar provincie
    </button>
  </div>
  <div class="row justify-content-center mb-1">
    <button [ngClass]="cartoService.showFilters? 'button-light': 'button-primary'"
            (click)="cartoService.toggleFilters()">
      {{cartoService.showFilters ? 'Verberg filters' : 'Toon filters'}}
    </button>
  </div>
</div>
<div class="banner">
  Dit is een oude versie van Park the Sun. Na 14 december is deze niet meer beschikbaar. Kijk op <a style="color: #F1B73F; font-weight: 600;" href="https://zonnedakje.nl/kaart">zonnedakje.nl</a> voor de nieuwste versie. Meer informatie? Neem <a style="color: #F1B73F; font-weight: 600;" href="https://zonnedakje.nl/contact">contact</a> met ons op.
</div>

<div class="topmenu">
  <div class="container">
    <button *ngIf="layoutService.isMobileLayout"
            (click)="toggleSideMenu()"
            class="sideMenuButton"
            mat-icon-button
            aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
    <div class="row justify-content-center">
      <a 
         routerLink="over"><img class="navBarIcon"
             src="/assets/images/pts_logo.png" /></a>
      <div class="menu">
        <a class="menuLink"
           *ngFor="let item of layoutService.menuItems"
           [routerLink]="item.routerLink"
           [queryParams]="item.routerLink === 'app' ? {skipdialog: 'true'} : {}"
           routerLinkActive="active">
          {{item.name}}
        </a>
      </div>
    </div>
  </div>
</div>
<mat-sidenav-container>
  <mat-sidenav [opened]="layoutService.sideMenuIsOpen"
               (openedChange)="onOpenedChange($event)">
    <div class="container">
      <div class="d-flex flex-column align-items-center">
        <img class="sideMenuIcon"
             src="/assets/images/pts_logo_new.png">
        <mat-divider></mat-divider>
        <a class="sideMenuLink"
           *ngFor="let item of layoutService.menuItems"
           [routerLink]="item.routerLink"
           [queryParams]="item.routerLink === 'app' ? {skipdialog: 'true'} : {}"
           routerLinkActive="active">
          {{item.name}}
        </a>
        <mat-divider></mat-divider>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
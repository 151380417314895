import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import {MatButtonModule} from '@angular/material/button';
import { PopoverModule, WavesModule } from "angular-bootstrap-md";

import { AppRoutingModule } from "./app-routing.module";
import { PagesModule } from "./pages/pages.module";
import { ApplicationModule } from "./application/application.module";
import { LayoutModule } from "./layout/layout.module";
import { HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";

import { registerLocaleData } from "@angular/common";
import localeNl from "@angular/common/locales/nl";
import { CartoService } from "./services/carto.service";

const shouldUseEmulator = () => false; // true to use local emulator instead of cloud
registerLocaleData(localeNl, "nl");

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    PopoverModule,
    WavesModule,
    AppRoutingModule,
    PagesModule,
    ApplicationModule,
    CommonModule,
    LayoutModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    NgbModule
    ],
  providers: [
    CartoService
  ],
  bootstrap: [AppComponent],
  exports: [CommonModule, BrowserAnimationsModule, MatTooltipModule],
})
export class AppModule { }

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { CartoService } from "../../services/carto.service";
import { NavigationEnd, Router } from "@angular/router";
import {
  MapFilters,
  MapConfig,
  RegionData
} from "src/app/services/carto.interface";
import { municipalities as munJson } from "../../../assets/json/municipalities";
import { provinces as provJson } from "../../../assets/json/provinces";
import { LandingDialogComponent } from "../landing-dialog/landing-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { BusinessCaseService } from "src/app/services/business-case.service";
import { UrlService } from "src/shared/url.service";
import { ActivatedRoute } from '@angular/router';
import { LayoutService } from "src/app/layout/layout.service";

declare var firebase: any;

@Component({
  selector: "app-basemap",
  templateUrl: "./basemap.component.html",
  styleUrls: ["./basemap.component.scss"]
})
export class BasemapComponent implements OnInit {
  // Gives mapping features to basemap component (renders map)
  @ViewChild("themap", { static: true }) themap: ElementRef;

  // Name of the collection which stores the objects in Firestore
  private collectionName: string = "config-links";
  public location: string;
  private developmentUrls: string[] = ["apazhb"];
  private developmentCartodbIds: number[] = [
    16946, // Den Haag
  ];
  
  private latFromUrl: number;
  private lonFromUrl: number;

  constructor(
    // Include Carto service here
    private cartoService: CartoService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private businessService: BusinessCaseService,
    private urlService: UrlService,
    public layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if(params["skipdialog"] !== "true"){
          if (window.innerWidth >= 768) {
            this.openDialog();
          } 
        }
      }
    );
    // this.urlService.previousUrl$
    // .subscribe((previousUrl: string) => {
    //     console.log(previousUrl)
    // });

    this.checkRoute();
  }

  getLocation() {
    this.cartoService
      .getSearchLocation(this.location)
      .subscribe((data: any) => {
        if (data.status == 'OK' && data.results[0]) {

          if(this.checkIfLocationInNetherlands(data.results[0].address_components)){
            this.cartoService.goToSearchLocation(
              data.results[0].geometry.location.lat,
              data.results[0].geometry.location.lng);
          }
          else{
             // Show an error message if no address was found
            alert(
              "Voer een geldig adres in. Bijvoorbeeld: Westfriese Parkweg 5, Hoorn"
            );
          }
        }
      });
  }

  checkIfLocationInNetherlands(address_components:any[]) : boolean{
    address_components.forEach(component => {
      // Note that we set return language to be English (&language=en) in the query, hence the line below works.
      // In the past there was an error, because without specifying the return language the browser default is used (which was often Dutch for our clients and returned "Nederland") 
      if(component.long_name.toLowerCase() == "netherlands")
        return true;
    })
    return false;
  }

  checkRoute() {
    let hash = this.getHashFromUrl();
    
    if (!hash) return;

    if (hash.startsWith("app")) {
      this.initBasemap();
      return;
    }

    this.filtersPromise(hash)
      .then((mapConfig: MapConfig) => {
        mapConfig = mapConfig['data'];
        this.initBasemap(mapConfig);
        return;
      })
      .catch(err => {
        console.error("document not found", err);
        // this.router.navigate(["not-found"]);

        this.initBasemap();
        return;
      });
  }

  filtersPromise(hash) {
    let readDb = firebase.app().functions('europe-west3').httpsCallable("read");
    
    return readDb({
      collection: this.collectionName,
      doc: hash
    });
  }

  getHashFromUrl() {
    let segments: string[] = this.router.url.split("/");
    if (
      segments.length >= 2 &&
      segments[1].length != this.cartoService.hashSize &&
      segments[1] !== "app" &&
      segments[1].substring(0, 4) !== "app?"
    ) {
      // province/municipality case

      this.provinceMunicipalityInUrl(segments);

      return;
    } else if (segments[1].substring(0, 7) === "app?lat") {
      // Latitude longitude case
      this.parkingCoordinatesInUrl(segments[1].substring(4))
      return
    }

    return segments[1];
  }

  provinceMunicipalityInUrl(segments: string[]) {
    let path = this.getProvinceAndMunicipalityFromUrl(segments);
    if (path.province === "app") {
      return;
    }

    this.cartoService.forkJoinMunicipalityProvince(() => {
      let mapConfig: MapConfig = this.checkProvinceMunicipality(path);
      // this.cartoService.map.remove();
      this.initBasemap(mapConfig);
    });
  }

  checkProvinceMunicipality(path: any) {
    let mapConfig: MapConfig = new MapConfig();

    if (path.province) {
      if (path.province === "app") {
        return;
      }

      // Check if there is a develoment URL being used for a new region
      if (this.checkForDevelopmentUrl(path.province)) {
        // If this is the case, load the relevant region
        mapConfig.location = this.getDevelopmentUrlLocation(path.province);
        return mapConfig;
      }

      let province = this.cartoService.provinces.filter(
        x => x.name.toLowerCase() == path.province.toLowerCase()
      );

      if (
        province.length != 1 ||
        !this.cartoService.checkProvincePermission(province[0].cartodb_id)
      ) {
        // alert("Data voor deze provincie is niet beschikbaar.");
        this.router.navigate(["/not-found"]);
        return;
      }

      mapConfig.location = {
        center: this.getLocationJson(provJson, province[0].cartodb_id),
        zoom: this.cartoService.provinceZoomThreshold + 0.01,
        municipalityId: undefined,
        provinceId:
          province[0].cartodb_id - this.cartoService.cartoProvinceStartId // or setCartoProvinceId??
      };
    }

    if (path.municipality) {
      let municipality;
      municipality = this.cartoService.municipalities.filter(
        x => x.name.toLowerCase() == path.municipality.toLowerCase()
      );
      // Catch instances when the entere-ed URL path contains a '-', i.e. den-haag
      if (municipality.length != 1) {
        municipality = this.cartoService.municipalities.filter(
          x =>
            x.name.toLowerCase() ==
            path.municipality.replace(/\-/g, " ").toLowerCase()
        );
      }
      // Catch instances when the entere-ed URL path contains a ' ', i.e. den haag
      if (municipality.length != 1) {
        municipality = this.cartoService.municipalities.filter(
          x =>
            x.name.toLowerCase() ==
            path.municipality.replace(/%20/g, " ").toLowerCase()
        );
      }
      // Check municipality permissions
      if (
        municipality.length != 1 ||
        !this.cartoService.checkMunicipalityPermission(
          municipality[0].cartodb_id
        )
      ) {
        // alert("Data voor deze gemeente is niet beschikbaar.");
        this.router.navigate(["/not-found"]);
        return;
      }

      mapConfig.location = {
        center: this.getLocationJson(munJson, municipality[0].cartodb_id),
        zoom: this.cartoService.municipalityZoomThreshold + 0.01,
        municipalityId:
          municipality[0].cartodb_id -
          this.cartoService.cartoMunicipalityStartId,
        provinceId: mapConfig.location.provinceId //Use provinceId defined in checking path.province
      };
    }

    return mapConfig;
  }

  private parkingCoordinatesInUrl(latLonUrl: string) {
    let latLonArray: string[] = latLonUrl.split("&");
    if (latLonArray.length == 2 && (latLonArray[0].substring(0, 3) === "lat") && (latLonArray[1].substring(0, 3) === "lon")) {
      // Correct URL according to this format: http://parkthesun.com/app?lat=52.65696659579463&lon=5.041089880878916
      // For easy copy paste testing localhost:4200/app?lat=52.65696659579463&lon=5.041089880878916
      this.latFromUrl = parseFloat(latLonArray[0].substring(4))
      this.lonFromUrl = parseFloat(latLonArray[1].substring(4))
    } else {
      // incorrect URL
      this.router.navigate(["/not-found"]);
      return;
    }

    const pointGeometry: string = `ST_GeomFromText('POINT(${this.lonFromUrl} ${this.latFromUrl})', 4326)`
    const regionQuery: string = `SELECT * FROM sobolt.${this.cartoService.regionDataset} WHERE ST_Contains(sobolt.${this.cartoService.regionDataset}.the_geom, ${pointGeometry})`
    const parkingQuery: string = `SELECT * FROM sobolt.${this.cartoService.dataset} WHERE ST_Contains(sobolt.${this.cartoService.dataset}.the_geom, ${pointGeometry})`

    let municipalityId: number
    let provinceId: number
    let mapConfig: MapConfig = new MapConfig();

    this.cartoService.getCartoData(regionQuery).subscribe((data: any) => {
      data.rows.forEach(region => {
        if (region.rubriek === "provincie") {
          provinceId = region.cartodb_id
        } else if (region.rubriek === "gemeente") {
          municipalityId = region.cartodb_id
        } else {
          return
        }
      })
      mapConfig.location = {
        center: this.getLocationJson(munJson, municipalityId),
        zoom: this.cartoService.municipalityZoomThreshold + 0.01,
        municipalityId: municipalityId - this.cartoService.cartoMunicipalityStartId,
        provinceId: provinceId - this.cartoService.cartoProvinceStartId
      }

      this.cartoService.getCartoData(parkingQuery).subscribe((data: any) => {
        mapConfig.businessCase = { parkingData: data.rows[0] }
        this.initBasemap(mapConfig)
      })
    })

  }

  openDialog() {
    const dialogRef = this.dialog.open(LandingDialogComponent, {
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      return;
    });
  }

  getProvinceAndMunicipalityFromUrl(segments: string[]) {
    let province: string = segments[1] ? segments[1].toLowerCase() : undefined;

    if (segments[1] == "app") {
    }

    let municipality: string = segments[2]
      ? segments[2].toLowerCase()
      : undefined;

    return {
      province: province,
      municipality: municipality
    };
  }

  initBasemap(mapConfig?: MapConfig) {
    if (mapConfig) {
      this.cartoService.initConfig(mapConfig);
      this.businessService.initCustomValues(mapConfig);
      // Load location
      if (mapConfig.location) {
        this.cartoService.initBasemap(
          this.themap,
          mapConfig.location.zoom,
          mapConfig.location.center
        );
        
        // Load corresponding data (i.e. if zoom was on parkinglot, municipality, or province)
        if (mapConfig.businessCase) {
          this.cartoService.initConfig(mapConfig);

          if (mapConfig.businessCase.parkingData) {
            this.cartoService.selectedProvinceId = mapConfig.location.provinceId;
            this.cartoService.selectedMunicipalityId = mapConfig.location.municipalityId;
            this.cartoService.getMunicipalityData();
            this.cartoService.initMunicipalityParkings();
            this.cartoService.zoomToParking([mapConfig.location.center.lng, mapConfig.location.center.lat])

          }
        } else if (mapConfig.location.municipalityId) {
          this.cartoService.selectedMunicipalityId =
            mapConfig.location.municipalityId;
          this.cartoService.selectedProvinceId = mapConfig.location.provinceId;

          this.cartoService.loadingMunicipalityData = true;
          this.cartoService.loadingProvinceData = false;

          this.cartoService.getMunicipalityData();
          this.cartoService.initMunicipalityParkings();
        } else if (mapConfig.location.provinceId) {
          this.cartoService.selectedProvinceId = mapConfig.location.provinceId;

          this.cartoService.getProvinceData();
          this.cartoService.parkingData = undefined;
        }
      }
    } else this.cartoService.initBasemap(this.themap);
  }

  getLocationJson(jsonArray, cartodb_id) {
    return jsonArray.filter(x => x.cartodb_id == cartodb_id)[0].coordinates;
  }

  private checkForDevelopmentUrl(url: string): boolean {
    return this.developmentUrls.indexOf(url) > -1;
  }

  private getDevelopmentUrlLocation(url: string) {
    const regionIndex: number = this.developmentUrls.indexOf(url);
    const cartoDbId: number = this.developmentCartodbIds[regionIndex];

    if(cartoDbId > 12) {
      //  This is a municipality
      this.cartoService.hiddenUrlMunicipalityCartodbIds= [cartoDbId]
      this.cartoService.selectedMunicipalityId = cartoDbId - this.cartoService.cartoMunicipalityStartId

      return {
        center: this.getLocationJson(munJson, cartoDbId),
        zoom: this.cartoService.municipalityZoomThreshold + 0.01,
        municipalityId: cartoDbId - this.cartoService.cartoMunicipalityStartId,
        provinceId: undefined
      };
    } 
    
    else {
      // This is a province
      this.cartoService.hiddenUrlProvinceCartodbId= cartoDbId
      this.cartoService.hiddenUrlMunicipalityCartodbIds = this.cartoService.getMunicipalityIdsForProvince(cartoDbId)
      this.cartoService.selectedProvinceId = cartoDbId - this.cartoService.cartoProvinceStartId

      return {
        center: this.getLocationJson(provJson, cartoDbId),
        zoom: this.cartoService.provinceZoomThreshold + 0.01,
        municipalityId: undefined,
        provinceId: cartoDbId - this.cartoService.cartoProvinceStartId
      };
    }


 
  }
}

import { Component, OnInit, HostBinding } from "@angular/core";
import { Router } from "@angular/router";
import { LayoutService } from "../../layout/layout.service";

declare var firebase:any;

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"]
})
export class LandingComponent implements OnInit {
  constructor(public router: Router, private layoutService: LayoutService) { }
  @HostBinding("class") classAtrribute: string = `h-100`;

  ngOnInit() {
    this.layoutService.checkMapView();
    this.layoutService.inApplication = false;
    this.layoutService.showNavbar = true;
  }

  public moreInfoClick() {
    firebase.analytics().logEvent("landingLeftButtonClick");
    this.router.navigate(["/over"]);
  }

  public interestedClick() {
    firebase.analytics().logEvent("landingRightButtonClick");
    this.router.navigate(["/app"], {queryParams: {skipdialog: "true"}});
  }
}

import { Component, OnInit } from '@angular/core';
import { CartoService } from 'src/app/services/carto.service';
import { RegionalDetailsService } from 'src/app/services/regional-details.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent {

  constructor(public regionalDetails: RegionalDetailsService, public cartoService: CartoService){}

  protectedFilters: { hintText: string; filters: any[] } = {
    hintText: "Beschermd stadsgezicht",
    filters: [
      { value: "YES", name: "Inclusief beschermd stadsgezicht" },
      { value: "NO", name: "Alleen buiten beschermd stadsgezicht" }
    ]
  };
  gridDistanceFilters: { hintText: string; filters: any[] } = {
    hintText: "Afstand tot net",
    filters: [
      { value: 999999, name: "Alle afstanden" },
      { value: 500, name: "Kleiner dan  500m" },
      { value: 200, name: "Kleiner dan  200m" },
      { value: 100, name: "Kleiner dan  100m" },
      { value: 50, name: "kleiner dan 50m" },
      { value: 25, name: "kleiner dan 25m" }
    ]
  };
  privateFilters: { hintText: string; filters: any[] } = {
    hintText: "Publiek of privebezit",
    filters: [
      { value: -1, name: "Publieke en private parkeerplaatsen" },
      { value: 0, name: "Alleen private parkeerplaatsen" },
      { value: 1, name: "Alleen publieke parkeerplaatsen" },
    ]
  };

  public protectedFilterChange(event) {
    this.cartoService.protectedFilter = event.value;
    this.cartoService.filterData();
  }

  public powerFilterChange(event) {
    this.cartoService.powerFilter = event.value;
    this.cartoService.filterData();
  }

  public areaFilterChange(event) {
    this.cartoService.areaFilter = event.value;
    this.cartoService.filterData();
  }

  public gridDistanceFilterChange(event) {
    this.cartoService.gridDistanceFilter = event.value;
    this.cartoService.filterData();
  }

  public privateFilterChange(event) {
    this.cartoService.privateFilter = event.value;
    this.cartoService.filterData();
  }

  public resetFilters() {
    this.cartoService.powerFilter = 0;
    this.cartoService.areaFilter = 65;
    this.cartoService.gridDistanceFilter = 999999;
    this.cartoService.protectedFilter = "YES";
    this.cartoService.privateFilter = -1;
    this.cartoService.filterData();
  }

  public formatLabel(value: number): number {
    return Math.round(value);
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessCaseService } from './business-case.service';
import { CartoService } from './carto.service';

@Injectable({
  providedIn: 'root'
})
export class RegionalDetailsService implements OnDestroy{

  public tooltipText;
  private cartoDataSub: Subscription; 

  //During the creation of a mobile friendly data display (mobile-info.component) some functionality has been
  //refractored out of region-details.ts to make it accessible to both components.
  constructor(private cartoService: CartoService, public businessService: BusinessCaseService) 
  {
    this.cartoDataSub = this.cartoService.getJSON().subscribe(data => {
      this.tooltipText = data;
    });
  }

  ngOnDestroy(): void {
      this.cartoDataSub.unsubscribe();
  }

  public getTotal(datasetName: string, statistic: string): number {
    if (this.cartoService[datasetName].length === 0) {
      return 0;
    }
    const total = this.cartoService[datasetName]
      .map(parking => {
        return parking[statistic];
      })
      .reduce(this.sum);
    return this.businessService.roundTens(total, 3);
  }

  private sum(prev: number, next: number): number {
    return prev + next;
  }

  public provinceVerified() {
    const provinceId = this.cartoService.getCartoProvinceId()
    if(provinceId === 7 || provinceId === 8 || provinceId === 10){
      return true
    } else {
      return false
    }
  }  
}

<app-mobileinfo-close></app-mobileinfo-close>
<div class="container">
  <button [ngClass]="cartoService.showParkingListProv ? 'button-light top-left': 'button-primary top-left'"
          (click)="cartoService.showParkingListProv = !cartoService.showParkingListProv"><i
       class="fa fa-list icon-sm-white"
       aria-hidden="true"></i></button>
  <div class="row justify-content-center">
    <h2 class="text-center heading-underline-primary">
      <!-- {{cartoService.provinces[cartoService.selectedProvinceId].name}} -->
      {{getProvinceName()}}
    </h2>
  </div>
  <div class="row icon-row">
    <i class="fa fa-map-marker icon-sm mr-3"></i>
    <p class="mx-1">
      <!-- <b>{{ cartoService.provinceDataFiltered.length | number : '1.0-0' :
        'nl'}}</b>
      /{{cartoService.provinceData.length | number : '1.0-0' : 'nl'}} -->
      <b>{{ getProvFilteredDataLength() | number : '1.0-0' :
        'nl'}}</b>
      /{{getProvDataLength() | number : '1.0-0' : 'nl'}}
    </p>
    <p>locaties</p>
  </div>
  <div class="row icon-row">
    <i class="fas fa-parking mr-3 icon-sm"></i>
    <p class="mx-1">{{ regionalDetails.getTotal('provinceDataFiltered',
      'cap_kwp') | number : '1.0-0' : 'nl' }}</p>
    <p>kWp vermogen</p>
  </div>
  <div class="row icon-row">
    <i class="fas fa-parking mr-3 icon-sm"></i>
    <p class="mx-1">{{ regionalDetails.getTotal('provinceDataFiltered', 'area')
      | number : '1.0-0' : 'nl' }}</p>
    <p>m2 parkeerterreinen</p>
  </div>
  <div class="row justify-content-center">
    <button class="button-primary mb-1"
            (click)="cartoService.zoomOutfromProvince()">
      Terug naar overzicht
    </button>
  </div>
  <div class="row justify-content-center mb-1">
    <button [ngClass]="cartoService.showFilters? 'button-light': 'button-primary'"
            (click)="cartoService.toggleFilters()">
      {{cartoService.showFilters ? 'Verberg filters' : 'Toon filters'}}
    </button>
  </div>
</div>
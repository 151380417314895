import { Component, OnInit, Input } from "@angular/core";
import { CartoService } from "../../services/carto.service";
import { BusinessCaseService } from "src/app/services/business-case.service";
import { ShareService } from "src/app/services/share.service";
import { LayoutService } from "src/app/layout/layout.service";
import { RegionalDetailsService } from "src/app/services/regional-details.service";

@Component({
  selector: "app-region-details",
  templateUrl: "./region-details.component.html",
  styleUrls: ["./region-details.component.css"]
})
export class RegionDetailsComponent implements OnInit {
  showFilters: boolean = false;
  public tooltipText;
  //showBusinessDetails: boolean = this.parkingCloseupComponent.showBusinessDetails;

  provCardCollapsed: boolean = false;
  muniCardCollapsed: boolean = false;

  // TODO: Might need to remove this. Redundant (used for receiving information from parking-closeup-component)
  @Input() businessFlag: boolean;
  protectedFilters: { hintText: string; items: any[] } = {
    hintText: "Beschermd stadsgezicht",
    items: [
      { value: "YES", name: "Inclusief beschermd stadsgezicht" },
      { value: "NO", name: "Alleen buiten beschermd stadsgezicht" }
    ]
  };
  gridDistanceFilters: { hintText: string; items: any[] } = {
    hintText: "Afstand tot net",
    items: [
      { value: 999999, name: "Alle afstanden" },
      { value: 500, name: "Kleiner dan  500m" },
      { value: 200, name: "Kleiner dan  200m" },
      { value: 100, name: "Kleiner dan  100m" },
      { value: 50, name: "kleiner dan 50m" },
      { value: 25, name: "kleiner dan 25m" }
    ]
  };
  privateFilters: { hintText: string; items: any[] } = {
    hintText: "Publiek of privebezit",
    items: [
      { value: -1, name: "Publieke en private parkeerplaatsen" },
      { value: 0, name: "Alleen private parkeerplaatsen" },
      { value: 1, name: "Alleen publieke parkeerplaatsen" },
    ]
  };

  constructor(
    public cartoService: CartoService,
    public businessService: BusinessCaseService,
    private shareService: ShareService,
    public layoutService: LayoutService,
    public regionalDetails: RegionalDetailsService
  ) {
    this.shareService.initShareIcon();
  }

  ngOnInit() {
    this.cartoService.getJSON().subscribe(data => {
      this.tooltipText = data;
    });
  }

  public toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  public toggleProvCollapse() {
    this.showFilters = false;
    this.provCardCollapsed = !this.provCardCollapsed;
  }

  public toggleMuniCollapse() {
    this.showFilters = false;
    this.muniCardCollapsed = !this.muniCardCollapsed;
  }

  onShareClick() {
    this.shareService.shareCurrentFilters();
  }

  toggleListProv() {
    // Log event
    let prov = this.cartoService.provCodeToName(this.cartoService.selectedProvinceId);
    let mun = this.cartoService.munCodeToName(this.cartoService.selectedMunicipalityId + this.cartoService.cartoMunicipalityStartId);
    this.cartoService.analytics.logEvent('toggle_list_provinces', {
      gemeente: mun,
      province: prov,
      custom_event_type: 'toggle_list_provinces'
    })
    this.cartoService.showParkingListProv = !this.cartoService
      .showParkingListProv;
    this.cartoService.dataSortedIsDataFilteredProv();
  }

  toggleListMuni() {
    // Log event
    let prov = this.cartoService.provCodeToName(this.cartoService.selectedProvinceId);
    let mun = this.cartoService.munCodeToName(this.cartoService.selectedMunicipalityId + this.cartoService.cartoMunicipalityStartId);
    this.cartoService.analytics.logEvent('toggle_list_municipalities', {
      gemeente: mun,
      province: prov,
      custom_event_type: 'toggle_list_municipalities'
    })
    this.cartoService.showParkingList = !this.cartoService.showParkingList;
    this.cartoService.dataSortedIsDataFilteredMuni();
  }

  public showBackToProvinceButton(): boolean {
    //  If the selected province ID is undefined return false (don't show button), otherwise return true (show button)
    return this.cartoService.selectedProvinceId > -1 
  }
}

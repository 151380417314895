import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BusinessCaseInfoService } from 'src/app/services/business-case-info.service';
import { BusinessCaseService } from 'src/app/services/business-case.service';
import { CartoService } from 'src/app/services/carto.service';
import { DialogContentComponent } from '../dialog-content/dialog-content.component';

@Component({
  selector: 'app-mobile-businesscase',
  templateUrl: './mobile-businesscase.component.html',
  styleUrls: ['./mobile-businesscase.component.scss']
})
export class MobileBusinesscaseComponent {

  public tooltipText;

  constructor(public cartoService: CartoService, public businessService: BusinessCaseService, public businessInfo: BusinessCaseInfoService, public dialog: MatDialog,) { }

  public backButtonText(): string {
    if (this.cartoService.selectedMunicipalityId) {
      return "Terug naar gemeente"
    } else {
      return "Terug naar provincie"
    }
  }

  public getLegesPopupText(): string {
    // Specific popup for Rotterdam
    if (this.cartoService.selectedMunicipalityId === 193) {
      return this.businessInfo.tooltipText.leges_lees_meer_rotterdam
    } else {
      return this.businessInfo.tooltipText.leges_lees_meer
    }
  }

  openDialog(
    dialogHeader,
    dialogContent,
    dialogLink,
    dialogLinkContent,
    dialogContent2,
    dialogLink2,
    dialogLinkContent2,
    dialogLink3,
    dialogLinkContent3,
    dialogLink4,
    dialogLinkContent4
  ) {
    this.dialog.open(DialogContentComponent, {
      data: {
        header: dialogHeader,
        content: dialogContent,
        link: dialogLink,
        linkContent: dialogLinkContent,
        content2: dialogContent2,
        link2: dialogLink2,
        linkContent2: dialogLinkContent2,
        link3: dialogLink3,
        linkContent3: dialogLinkContent3,
        link4: dialogLink4,
        linkContent4: dialogLinkContent4,
      },
    });
  }

  public openEnergyCooperationDialog() {
    if (this.cartoService.selectedProvinceId === 7) {
      // If province is Noord-Holland, include this custom content
      this.openDialog(this.tooltipText.energyCooperationTitle, this.tooltipText.energyCooperationContent_NH, 'https://veinh.nl/', 'Kijk op de website van VEI NH', this.tooltipText.energyCooperationContent2, undefined, undefined, undefined, undefined, 'https://www.rvo.nl/initiatieven/overzicht/27975', 'Bekijk de website van het RVO')
    } else {
      this.openDialog(this.tooltipText.energyCooperationTitle, this.tooltipText.energyCooperationContent, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    }
  }

  public openFinanceDialog() {
    if (this.cartoService.selectedProvinceId === 6) {
      // If province is Utrecht, include this custom content
      this.openDialog(this.tooltipText.arrange_financing_title, this.tooltipText.arrange_financing_content_utrecht, 'https://www.energiefondsutrecht.nl/', 'Vind meer info op de website van het Energiefonds Utrecht', undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    } else {
      this.openDialog(this.tooltipText.arrange_financing_title, this.tooltipText.arrange_financing_content, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    }
  }
}

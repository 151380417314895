import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-mobileinfo-close',
  templateUrl: './mobileinfo-close.component.html',
  styleUrls: ['./mobileinfo-close.component.scss']
})
export class MobileinfoCloseComponent {
  //The downward arrow button at the top of each mobile info bottom-sheet.

  constructor(private bottomSheetRef: MatBottomSheetRef) { }

  minimiseSheet() {
    this.bottomSheetRef.dismiss();
  }
}

<h1 mat-dialog-title class="heading-underline-primary mb-3 text-center text-cutoff-nowrap">GEAVANCEERDE INSTELLINGEN</h1>
<!-- <div class="d-flex justify-content-center">
  <h1 mat-dialog-title class="soft-landing-title">Geavanceerde instellingen</h1>
</div> -->
<mat-dialog-content class="mat-typography">
  <div class="-flex flex-column">
    <app-advanced-options-item name="solarPanelPower" [value]="businessService.solarPanelPower"
      valueName="Solar Panel Power" defaultValue=200 customValue=200>
    </app-advanced-options-item>
    <app-advanced-options-item name="lowVoltageGridCostsFixed" [value]="businessService.lowVoltageGridCostsFixed"
      valueName="Low Voltage Grid Costs (Fixed)" defaultValue=4802>
    </app-advanced-options-item>
    <app-advanced-options-item name="lowVoltageGridExtraLengthTariff"
      [value]="businessService.lowVoltageGridExtraLengthTariff" valueName="Tariff for Extra Low"
      defaultValue=52.4>
    </app-advanced-options-item>
    <app-advanced-options-item name="monthlyLowVoltageGridCostsVariable" valueName="Monthly Low Voltage Grid Costs"
      [value]="businessService.monthlyLowVoltageGridCostsVariable" defaultValue=10.78>
    </app-advanced-options-item>
    <app-advanced-options-item name="legesFraction" valueName="Leges (fractie bouwkosten)"
      [value]="businessService.legesFraction" defaultValue=0.015>
    </app-advanced-options-item>
    <app-advanced-options-item name="carportOperationalYears" valueName="Operational Years of Built Carport"
      [value]="businessService.carportOperationalYears" defaultValue=20>
    </app-advanced-options-item>
    <app-advanced-options-item name="insuranceFractionYearly" valueName="Yearly Insurance Fraction"
      [value]="businessService.insuranceFractionYearly" defaultValue=0.02>
    </app-advanced-options-item>
    <!-- <app-advanced-options-item name="solarCarportType0Costs" valueName="solarCarportType0Costs"
      [value]="businessService.solarCarportType0Costs" defaultValue=0.02>
    </app-advanced-options-item> -->
    <!-- <app-advanced-options-item name="solarCarportType1Costs" valueName="solarCarportType1Costs"
      [value]="businessService.solarCarportType1Costs" defaultValue=0.02>
    </app-advanced-options-item> -->
    <app-advanced-options-item name="chargerCosts" valueName="Charger Costs"
      [value]="businessService.chargerCosts[businessService.chargerType]"
      [defaultValue]=businessService.defaultChargerCosts[businessService.chargerType]
      [listNumber]=businessService.chargerType>
    </app-advanced-options-item>
    <!-- <app-advanced-options-item name="securityCosts" valueName="securityCosts" [value]="businessService.securityCosts"
      defaultValue=0.02>
    </app-advanced-options-item> -->
    <!-- <app-advanced-options-item name="maintenanceCosts" valueName="maintenanceCosts"
      [value]="businessService.maintenanceCosts" defaultValue=0.02>
    </app-advanced-options-item> -->
    <app-advanced-options-item name="FULL_LOAD_HOURS" valueName="Yearly Energy Per Power"
      [value]="businessService.FULL_LOAD_HOURS" defaultValue=900>
    </app-advanced-options-item>
    <app-advanced-options-item name="Co2SavingsPerKwh" valueName="CO2 Savings Per Kwh"
      [value]="businessService.Co2SavingsPerKwh" defaultValue=0.187>
    </app-advanced-options-item>
    <app-advanced-options-item name="salderenTariff" valueName="Consolidated Tariff" [value]="businessService.salderenTariff"
      defaultValue=0.2>
    </app-advanced-options-item>
    <app-advanced-options-item name="SDE_UNDER_THRESHOLD" valueName="SDE Under Threshold Tariff"
      [value]="businessService.SDE_UNDER_THRESHOLD" defaultValue=7.3>
    </app-advanced-options-item>
    <app-advanced-options-item name="SDE_OVER_THRESHOLD" valueName="SDE Over Threshold Tariff"
      [value]="businessService.SDE_OVER_THRESHOLD" defaultValue=5.88>
    </app-advanced-options-item>
    <app-advanced-options-item name="parkedElectricVehicleFraction" valueName="Parked Electric Vehicle Fraction"
      [value]="businessService.parkedElectricVehicleFraction" defaultValue=0.1>
    </app-advanced-options-item>
    <app-advanced-options-item name="chargingTariff" valueName="Charging Tariff" [value]="businessService.chargingTariff"
      defaultValue=0.25>
    </app-advanced-options-item>
    <app-advanced-options-item name="averageChargingRange" valueName="Average Charging Range"
      [value]="businessService.averageChargingRange" defaultValue=20>
    </app-advanced-options-item>
    <app-advanced-options-item name="operationalDaysPerYear" valueName="Operational Days Per Year"
      [value]="businessService.operationalDaysPerYear" defaultValue=365>
    </app-advanced-options-item>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="mt-3 d-flex justify-content-center">
  <button class="button-primary btn-narrow" mat-dialog-close>Sluiten</button>
</mat-dialog-actions>

<!-- (change)="editSolarPanelPower($event)" -->

<!-- <button mat-icon-button class="business-case-edit-icon-button ml-3" (click)="toggleEditNumber()"><i
  class="fas fa-pencil-alt business-case-edit-icon"></i></button> -->

<!-- <p class="mt-0 mb-0" *ngIf="editingNumber">
    <mat-form-field class="mt-0">
      <span matPrefix>{{prefix}} </span>
      <input matInput [value]="editableNumber | number : '1.0-0' : 'nl'" (change)="editNumber($event)">
      <span matSuffix (click)="toggleEditNumber()">
        <button mat-icon-button class="business-case-edit-icon-button ml-3"><i
            class="fas fa-times business-case-edit-icon"></i></button>
      </span>
    </mat-form-field>
  </p> -->

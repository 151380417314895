import { Component, OnInit, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { CartoService } from "../../services/carto.service";
import { Router } from "@angular/router";
import { LayoutService } from "../../layout/layout.service";

@Component({
  selector: "app-landing-dialog",
  templateUrl: "./landing-dialog.component.html",
  styleUrls: ["./landing-dialog.component.css"],
})
export class LandingDialogComponent implements OnInit {
  url: number;
  landingText: string;
  segments: string[];
  landingLogo: string;
  landingLogos: string[] = [
    "../../../assets/images/pts_logo.png",
    "../../../assets/images/logo-gemeente-amsterdam.png",
    "../../../assets/images/Logo-gemeente-rotterdam.png",
    "../../../assets/images/logo-nh.png",
    "../../../assets/images/gemeente-Den-Haag-logo_0.png",
    "../../../assets/images/gemeente_groningen.png",
    "../../../assets/images/gemeente_apeldoorn.jpg",
    "../../../assets/images/provincieUtrechtLogo.png"
  ];

  constructor(
    public cartoService: CartoService,
    @Inject(DOCUMENT) private document: Document,
    public router: Router,
    public layoutService: LayoutService
  ) {
    this.segments = this.router.url.split("/");
    this.segments = this.segments.map((s) => s.toLowerCase());
  }

  ngOnInit() {
    this.cartoService.getTotals();
    this.setUrl();
  }

  setUrl() {
    if (this.segments.includes("app")) {
      this.url = 0;
    } else if (this.segments.includes("amsterdam")) {
      this.url = 1;
    }
    else if (this.segments.includes("rotterdam")) {
      this.url = 2;
    } else if (this.segments.includes("noord-holland")) {
      this.url = 3;
    } else if (this.segments.includes("den-haag")) {
      this.url = 4;
    } else if (this.segments.includes("groningen")) {
      this.url = 5;
    } else if (this.segments.includes("apeldoorn")) {
      this.url = 6;
    } else if (this.segments.includes("utrecht")) {
      this.url = 7;
    }

    this.landingText = this.getLandingText()
    this.landingLogo = this.getLandingLogo()
  }

  openPracticePage() {
    this.layoutService.showNavbar = true;
    this.router.navigateByUrl('over#practice')
  }

  private getLandingText(): string {
    let text: string = ""
    if (this.url === 1) {
      return `Amsterdam gaat voor 100% duurzame energie. Met zonnepanelen kan duurzame elektriciteit worden opgewekt in stedelijk gebied. Amsterdam is groot voorstander van dubbel ruimtegebruik, zoals op daken en boven parkeerterreinen. De Provincie Noord-Holland heeft in samenwerking met Sobolt Park the Sun ontwikkelt en geeft hiermee inzicht in de potentie van solar carports in Amsterdam. Ontdek deze potentie en doe een quickscan om geschikte parkeerplekken te vinden. Deze site geeft een overzicht van randvoorwaarden voor realisatie, investeringen en opbrengsten per locatie.<br><br>Bent u voornemens een project te ontwikkelen of te realiseren. Amsterdam ondersteunt uw initiatief graag. Voor het ontwikkelen van zonprojecten bestaan verschillende subsidies en leningen. In Amsterdam kunnen initiatiefnemers van een solar carport gebruik maken van een voordelige lening vanuit het Duurzaamheidsfonds (<a href="https://www.amsterdam.nl/duurzaamheidsfonds" target="_blank">amsterdam.nl/duurzaamheidsfonds</a>). Op Amsterdam.nl/zon vindt u ook andere acties en instrumenten van de gemeente om de uitrol van zonnepanelen te stimuleren. Heeft u vragen aan de gemeente Amsterdam over mogelijke ondersteuning stuur dan een mail met uw vraag naar <a href="mailto:klimaatneutraal@amsterdam.nl">klimaatneutraal@amsterdam.nl</a>`
    }
    else if (this.url === 2) {
      text += "In de Gemeente Rotterdam wordt hard gewerkt"
    }
    else if (this.url === 3) {
      text += "In de Provincie Noord-Holland wordt hard gewerkt"
    } else {
      text += "Samen werken we hard"
    }

    text += ` aan het realiseren van de energietransitie. Solar carports bieden een innovatieve oplossing voor het opwekken 
    van meer duurzame energie. Ontdek de potentie van solar carports in de omgeving. Welke parkeerterreinen zijn geschikt om te overdekken met 
    zonnepanelen? Hoeveel energie wek je ermee op? Hoe kun je deze duurzame energie slim gebruiken? Krijg antwoord op deze vragen en ontdek welke 
    randvoorwaarden, investeringen en opbrengsten van toepassing zijn.<br><br>`

    if (this.url === 1) {
      return `Amsterdam gaat voor 100% duurzame energie. Met zonnepanelen kan duurzame elektriciteit worden opgewekt in stedelijk gebied. 
      Amsterdam is groot voorstander van dubbel ruimtegebruik, zoals op daken en boven parkeerterreinen. De Provincie Noord-Holland heeft in 
      samenwerking met Sobolt Park the Sun ontwikkelt en geeft hiermee inzicht in de potentie van solar carports in Amsterdam. Ontdek deze 
      potentie en doe een quickscan om geschikte parkeerplekken te vinden. Deze site geeft een overzicht van randvoorwaarden voor realisatie, 
      investeringen en opbrengsten per locatie.<br><br>Bent u voornemens een project te ontwikkelen of te realiseren. Amsterdam ondersteunt uw 
      initiatief graag. Voor het ontwikkelen van zonprojecten bestaan verschillende subsidies en leningen. In Amsterdam kunnen initiatiefnemers 
      van een solar carport gebruik maken van een voordelige lening vanuit het Duurzaamheidsfonds (amsterdam.nl/duurzaamheidsfonds). Op 
      Amsterdam.nl/zon vindt u ook andere acties en instrumenten van de gemeente om de uitrol van zonnepanelen te stimuleren. Heeft u vragen aan 
      de gemeente Amsterdam over mogelijke ondersteuning stuur dan een mail met uw vraag naar klimaatneutraal@amsterdam.nl`
    }
    else if (this.url === 2) {
      text += "Daarom hielp de Gemeente Rotterdam Sobolt bij de ontwikkeling van Park the Sun. Deze handige webapplicatie is nu toegankelijk binnen de hele gemeente!"
    }

    return text
  }

  private getLandingLogo(): string {
    return this.landingLogos[this.url]
  }

}


// CartoDB ID of Provinces that can access their data
export const fullProvinceClients: number[] = [
  7, // Provincie Utrecht
  8, // Provincie Noord-Holland
  10 // Provincie Zeeland
];

export const verifiedProvinceIds: number[] = [
  1, // Provincie Groningen
  6, // Provincie Gelderland
  7, // Provincie Utrecht
  8, // Provincie Noord-Holland
  9, // Provincie Zuid-Holland
  10 // Provincie Zeeland
];

export const provinces = [
  {
    name: "Groningen",
    cartodb_id: 1,
    numParkings: 2126,
    coordinates: [6.760194684692891, 53.176154816234835]
  },
  {
    name: "Friesland",
    cartodb_id: 2,
    numParkings: 2775,
    coordinates: [5.736903705267592, 53.070122943791844]
  },
  {
    name: "Drenthe",
    cartodb_id: 3,
    numParkings: 3119,
    coordinates: [6.622315700516264, 52.862277888339776]
  },
  {
    name: "Overijssel",
    cartodb_id: 4,
    numParkings: 3957,
    coordinates: [6.4222271927164964, 52.46269261632452]
  },
  {
    name: "Flevoland",
    cartodb_id: 5,
    numParkings: 2957,
    coordinates: [5.592383573155571, 52.538106500921536]
  },
  {
    name: "Gelderland",
    cartodb_id: 6,
    numParkings: 6095,
    coordinates: [5.873401628726393, 52.05008765929688]
  },
  {
    name: "Utrecht",
    cartodb_id: 7,
    numParkings: 3575,
    coordinates: [5.163081290599913, 52.0838100215274]
  },
  {
    name: "Noord-Holland",
    cartodb_id: 8,
    numParkings: 8225,
    coordinates: [4.871838832727622, 52.60204076040791]
  },
  {
    name: "Zuid-Holland",
    cartodb_id: 9,
    numParkings: 10617,
    coordinates: [4.455646977018725, 51.94473526151071]
  },
  {
    name: "Zeeland",
    cartodb_id: 10,
    numParkings: 2370,
    coordinates: [3.8422907709658656, 51.460668114125646]
  },
  {
    name: "Noord-Brabant",
    cartodb_id: 11,
    numParkings: 7172,
    coordinates: [5.1951872127968395, 51.55819146681877]
  },
  {
    name: "Limburg",
    cartodb_id: 12,
    numParkings: 3720,
    coordinates: [5.934562165015768, 51.22285332800248]
  }
];

<!-- <div class="h-100 d-none d-md-block"> -->
<div class="h-100 d-block">
  <div class="map" #themap></div>
  <app-region-details *ngIf="!layoutService.isMobileLayout"></app-region-details>
  <app-parking-business-case *ngIf="!layoutService.isMobileLayout"></app-parking-business-case>
  <app-basemap-toggle *ngIf="!layoutService.isMobileLayout"></app-basemap-toggle>
  <app-parking-list></app-parking-list>
  <app-searchbar *ngIf="!layoutService.isMobileLayout"></app-searchbar>
  <!-- <div class="search-bar">
    <input class="search-input" [(ngModel)]="location" (keyup.enter)="getLocation()" placeholder="Zoek naar adres">
    <button class="button-primary-search ml-1" (click)="getLocation()"><i class="fas fa-search"></i></button>
  </div> -->
  <app-mobile-search *ngIf="layoutService.isMobileLayout"></app-mobile-search>
  <app-mobile-info *ngIf="layoutService.isMobileLayout"></app-mobile-info>
  <app-mobile-businesscase *ngIf="layoutService.isMobileLayout"></app-mobile-businesscase>
</div>

<!-- <div class="h-100 d-md-none">
  <app-size-warning></app-size-warning>
</div> -->
<!-- <div class="d-flex align-items-center justify-content-center h-100 w-100 mt-5">
  <h1 class="text-center w-75">
    We voeren de laatste aanpassingen door in de Park the Sun applicatie. Door onverwachte omstandigheden is de
    livegang later dan de eerder gecommuniceerde datum, 12 oktober. Aanstaande maandag, 19 oktober komt de applicatie
    online te staan.
  </h1>
</div> -->
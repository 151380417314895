<app-mobileinfo-close></app-mobileinfo-close>
<div class="container">
  <div class="row justify-content-center mt-1">
    <h6>Potentie - Totaal</h6>
  </div>
  <div class="row justify-content-center">
    <h2 class="text-center heading-underline-primary">
      <!-- {{cartoService.provinces[cartoService.selectedProvinceId].name}} -->
      {{getProvinceName()}}
    </h2>
  </div>
  <div class="row justify-content-center">
    <p class="text-center">
      Informatie voor de volledige provincie is niet beschikbaar, maar voor de
      gemeenten met een blauwe cirkel wel.
    </p>
  </div>
  <div class="row justify-content-center mb-2">
    <button class="py-1 px-4 terug-button" mat-stroked-button (click)="cartoService.zoomOutfromProvince()">
      Terug naar overzicht
    </button>
  </div>
</div>
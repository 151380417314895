import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {

  constructor(public layoutService: LayoutService) { }

  ngOnInit() {
  }

  toggleSideMenu(){
    this.layoutService.toggleSideMenu();
  }
}

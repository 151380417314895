import { Component, OnInit } from "@angular/core";
import { SEOService } from "./services/seo.service";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { filter, map, mergeMap } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "parkthesun";
  previousUrl: string;
  currentUrl: string;

  constructor(
    private seoService: SEOService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}


  ngOnInit() {
    this.seoService.setTitle("Park The Sun");

    this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {

        // Update title
        if (event["title"]) this.seoService.setTitle(event["title"]);

        // Update description meta tags
        if (event["description"])
          this.seoService.updateDescription(event["description"]);

        // Update keyword meta tags
        if (event["keywords"])
          this.seoService.updateKeywords(event["keywords"]);
      });
  }
}

<app-mobileinfo-close></app-mobileinfo-close>
<div class="container">
  <div class="row justify-content-center mt-2">
    <p *ngIf="cartoService.loadingProvinceData"
       class="text-center msg">{{
      cartoService.provinces[cartoService.selectedProvinceId].name }} wordt
      geladen...</p>
    <p *ngIf="cartoService.loadingMunicipalityData"
       class="text-center msg">Data van gemeente
      {{ cartoService.municipalities[cartoService.selectedMunicipalityId].name
      }} wordt
      geladen... </p>
  </div>
  <div class="row justify-content-center mb-3">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div class="container-fluid main-content-container background-primary">
  <div class="row justify-content-center pb-5">
    <div class="col-12 col-md-8 col-xl-6 px-4">
      <div class="row">
        <div class="col-12 d-flex flex-column align-items-center">
          <h1 class="text-center heading-underline-secondary color-light pointer mb-4">
            Wat is Park the Sun?</h1>
          <p class="color-light mb-4" style="text-align:center;">
            Wist je dat parkeerplaatsen een kansrijke locatie zijn voor zonne-energie?
            Welke locaties zijn geschikt voor solar carports? Hoeveel energie wek je dan op? Wat zijn de investeringen, opbrengsten en de terugverdientijd?
            Park the Sun geeft inzicht.
          </p>
          <button class="sobolt-button mb-4 font-family" (click)="redirectToApplicationClick()">
            Naar applicatie
          </button>
          <div class="embed-youtube">
            <iframe width="840" height="500" src="https://www.youtube.com/embed/KqJRyo8N8Vo?autoplay=0">
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center background-light py-5">
    <div class="col-12 col-md-10 col-xl-8 d-flex flex-column align-items-center px-4">
      <h1 class="text-center heading-underline-black">Hoe werkt park the sun?</h1>
    </div>
  </div>
  <div class="background-light row">
    <div class="container">
      <div class="row  pt-5 pb-5">
        <div class="col-md-4">
          <div
            class="d-flex justify-content-center align-items-center background-round"
          >
            <img
              class="icon text-center"
              src="/assets/images/zonnedakje_icon_potentialtmappedout-01.svg"
              alt="sobolt icon solarenergy"
            />
          </div>
          <p class="usp-text">
              Op basis van data en modellen biedt Park the
              Sun inzicht in de mogelijkheden van solar
              carports. Wil je echt aan de slag met de bouw?
              Dan adviseren wij aanvullend onderzoek te
              doen. Aan de uitkomsten van deze applicatie
              kunnen geen rechten worden ontleend.
          </p>
        </div>
        <div class="col-md-4">
          <div
            class="d-flex justify-content-center align-items-center background-round"
          >
            <img
              class="icon text-center"
              src="/assets/images/zonnedakje_icon_solarcarports.svg"
              alt="sobolt icon aiinspections"
            />
          </div>
          <p class="usp-text">
              Door het slimme Park the Sun algoritme te
              combineren met openbare data worden meer
              parkeerplekken gevonden dan ooit. Hier zijn we
              best trots op. Let op: het overzicht is niet 100%
              volledig. Er kunnen parkeerlocaties ontbreken.
          </p>
        </div>
        <div class="col-md-4">
          <div
            class="d-flex justify-content-center align-items-center background-round"
          >
            <img
              class="icon"
              src="/assets/images/zonnedakje_icon_trendanalysis-01.svg"
              alt="sobolt icon creativity"
            />
          </div>
          <p class="usp-text">
              De getallen en bedragen in Park the Sun zijn
              gebaseerd op data analyse en expert input.
              Inschattingen zijn zorgvuldig tot stand gekomen.
              Jaarlijks worden gegevens bijgewerkt in
              samenspraak met gemeenten.
          </p>
        </div>
      </div>
    </div>
  </div>


  <div class="row justify-content-center background-primary py-5">
    <div class="col-12 col-md-10 col-xl-8 d-flex flex-column align-items-center px-4">
      <h1 class="text-center heading-underline-white pointer" (click)="toggleForWho()">Voor wie is Park the Sun?</h1>
      <i style="color:white" [ngClass]="this.showForWho ? 'fas fa-angle-up fa-3x pointer' : 'fas fa-angle-down fa-3x pointer'"
        (click)="toggleForWho()"></i>
    </div>
  </div>
  <div class="background-light row" [ngClass]="this.showForWho ? null : 'd-none'">
    <div class="container">
      <div class="row  pt-5 pb-5">
        <div class="col-md-4">
          <div
            class="d-flex justify-content-center align-items-center background-round"
          >
            <img
              class="icon text-center"
              src="/assets/images/zonnedakje_icon_government.svg"
              alt="sobolt icon solarenergy"
            />
          </div>
          <p class="usp-title">Overheden</p>
          <p class="usp-text">
            Park the Sun biedt beleidsmakers bij
            provincies, RES-regio’s en gemeenten
            extra gereedschap. Daarmee kunnen
            weloverwogen besluiten gemaakt
            worden. De drempel voor eigenaren
            van parkeerplaatsen om solar carports
            te bouwen is lager.
          </p>
        </div>
        <div class="col-md-4">
          <div
            class="d-flex justify-content-center align-items-center background-round"
          >
            <img
              class="icon text-center"
              src="/assets/images/zonnedakje_icon_solarcarports.svg"
              alt="sobolt icon aiinspections"
            />
          </div>
          <p class="usp-title">Ondernemers</p>
          <p class="usp-text">
            Park the Sun laat je zien hoe geschikt
            een locatie is voor solar carports.
            Van randvoorwaarden en
            vergunningen tot de benodigde
            investering en opbrengsten. De
            applicatie helpt zaken stap voor stap
            op een rij te krijgen.
          </p>
        </div>
        <div class="col-md-4">
          <div
            class="d-flex justify-content-center align-items-center background-round"
          >
            <img
              class="icon"
              src="/assets/images/zonnedakje_icon_solarenergy.svg"
              alt="sobolt icon creativity"
            />
          </div>
          <p class="usp-title">Ontwikkelaars</p>
          <p class="usp-text">
            Gebruik Park the Sun om een
            parkeerlocatie in de buurt te vinden
            en investeer samen in duurzame
            energie. Als ontwikkelaar of
            energiecoöperatie kan je energie
            opwekken op plekken en hier een
            goede business case van maken. 
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="background-light row" [ngClass]="this.showForWho ? null : 'd-none'">
    <div class="container">
      <div class="row  pt-5 pb-5">
        <div class="col-md-12">
          <div
            class="d-flex justify-content-center align-items-center background-round"
          >
          <a href="/contact"><button class="button-primary mb-3 align-items-center">Contact</button></a>
          </div>
        </div>
      </div>
    </div>
  </div>  

  <div class="row justify-content-center background-light py-5">
    <div class="col-12 col-md-10 col-xl-8 d-flex flex-column align-items-center px-4">
      <h1 class="text-center heading-underline-black pointer" (click)="toggleWhatAre()">Wat zijn solar
        carports?</h1>
      <i class="" [ngClass]="this.showWhatAre ? 'fas fa-angle-up fa-3x pointer' : 'fas fa-angle-down fa-3x pointer'"
        (click)="toggleWhatAre()"></i>
      <p style="text-align:center;margin-top:20px;" [ngClass]="this.showWhatAre ? null : 'd-none'" class="col-12 col-md-10 col-xl-8 d-flex flex-column align-items-center px-4">
        Solar carports zijn met zonnepanelen bedekte overkappingen op
        parkeerplaatsen. Hierdoor is er geen extra ruimte nodig voor het
        plaatsen van zonne installaties. Je combineert het opwekken van
        zonne energie met overdekt parkeren. Dat is slim meervoudig gebruik
        van één locatie. Opgewekte energie kunnen we terugleveren, tijdelijk
        opslaan of direct gebruiken voor het laden van elektrische auto’s.
      </p>
    </div>
  </div>
  <div class="row justify-content-center background-light pb-2 pt-3" [ngClass]="this.showWhatAre ? null : 'd-none'">
    <div class="col-12 col-md-10 col-xl-8 px-4">
      <div class="row align-items-center">
        <div class="col-12 d-flex flex-column align-items-center">
          <div class="row mb-5">
            <div class="col-12 col-xl-9 background-light py-4 d-flex align-items-center justify-content-center">
              <img class="img-fluid" src="/assets/images/solar_carport_system_2.png" alt="Solar carport dubbele rij" />
            </div>
            <div class="col-12 col-xl-3 justify-content-center py-xl-5 d-block d-sm-none d-xl-block">
              <p class="">A. Carport constructie</p>
              <p class="">B. Zonnepanelen</p>
              <p class="">C. Fundering</p>
              <p class="">D. Netaansluiting</p>
              <p class="">E. Laadpaal</p>
              <p class="">F. (Elektrische) auto</p>
              <p class="">G. Verlichting</p>
              <p class="">H. Beveiliging</p>
              <p class="">I. Batterij-systeem</p>
            </div>
            <div class="col-6 d-none d-sm-block d-xl-none">
              <p class="">A. Carport constructie</p>
              <p class="">B. Zonnepanelen</p>
              <p class="">C. Fundering</p>
              <p class="">D. Netaansluiting</p>
              <p class="">E. Laadpaal</p>
            </div>
            <div class="col-6 d-none d-sm-block d-xl-none">
              <p class="">F. (Elektrische) auto</p>
              <p class="">G. Verlichting</p>
              <p class="">H. Beveiliging</p>
              <p class="">I. Batterij-systeem</p>
            </div>
          </div>
          <button class="button-primary mb-3 align-items-center" (click)="openSystemTypes()">
            Type systemen
          </button>
        </div>
        <!-- <div class="col-12 col-lg-9">
          <img class="image-car" src="/assets/images/car_battery.png" alt="Onderdelen solar carport" />
        </div>
        <div class="col-12 col-lg-3">
          <p>A. Auto</p>
          <p>B. Zonnepanelen</p>
          <p>C. Laadsysteem</p>
          <p>D. Batterijsysteem</p>
        </div> -->
      </div>
    </div>
  </div>

  
  <div class="row justify-content-center py-5">
    <div class="col-12 col-md-10 col-xl-6 d-flex flex-column align-items-center px-4">
      <h1 class="text-center heading-underline-white color-light pointer" (click)="toggleSystemTypes()">Soorten solar carports</h1>
      <i class="color-light"
        [ngClass]="this.showSystemTypes ? 'fas fa-angle-up fa-3x pointer' : 'fas fa-angle-down fa-3x pointer'"
        (click)="toggleSystemTypes()"></i>
      <p style="text-align:center;margin-top:20px;" class="col-12 col-md-12 col-xl-12 d-flex flex-column align-items-center px-4 color-light" [ngClass]="this.showSystemTypes ? null : 'd-none'">Een solar carport kan je in allerlei
        vormen en maten laten maken. Vaak wordt uitgegaan van een standaard solar carport. Deze is van staal en heeft
        een zwart / grijze kleur. Met een standaard solar carport kan je veel kWp opwekken. Mocht je juist willen gaan
        voor meer comfort, kan je jouw solar carport personaliseren. Je kan dan bijvoorbeeld kiezen voor een houten
        constructie of voor het afbeelden van jouw bedrijfslogo.</p>
    </div>
  </div>
  <div class="row justify-content-center background-light pt-4" [ngClass]="this.showSystemTypes ? null : 'd-none'">
    <div class="col-12 col-lg-6 background-light py-0 d-flex align-items-center justify-content-center order-1">
      <img class="systems-image" src="/assets/images/carport_dubbel.png" width="500" alt="Solar carport dubbele rij" />
    </div>
    <div class="col-12 col-lg-6 pt-lg-5 p-4 order-2 align-items-center">
      <div class="row">
        <div class="col-12 col-md-6">
          <p class=""><b>Standaard</b></p>
          <p class="">Stalen constructie</p>
          <p class="">Zwart / grijze kleur</p>
          <p class="">V-vorm</p>
          <p class="">Standaard hoogte personenauto</p>
          <p class="">Geen logo</p>
        </div>
        <div class="col-12 col-md-6">
          <p class=""><b>Gepersonaliseerd</b></p>
          <p class="">Stalen of houten constructie</p>
          <p class="">Kleur naar wens</p>
          <p class="">Vorm naar wens</p>
          <p class="">Maatwerk hoogte vrachtwagens</p>
          <p class="">Logo</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center background-light py-5">
    <div class="col-12 col-md-10 col-xl-6 d-flex flex-column align-items-center px-4">
      <h1 class="text-center heading-underline-black pointer" (click)="toggleChargingSystems()">Soorten laadsystemen</h1>
      <i [ngClass]="this.showChargingSystems ? 'fas fa-angle-up fa-3x pointer' : 'fas fa-angle-down fa-3x pointer'"
        (click)="toggleChargingSystems()"></i>
      <p style="text-align:center;margin-top:20px;" [ngClass]="this.showChargingSystems ? null : 'd-none'">Er komen steeds meer elektrische auto’s op de weg. Met
        een laadpunt geef je deze auto’s de mogelijkheid om lokaal opgewekte duurzame energie te tanken. Daarnaast
        bieden laadpunten de kans om de investering van een solar carport sneller terug te verdienen.</p>
    </div>
  </div>
  <div class="row justify-content-center background-light py-5" [ngClass]="this.showChargingSystems ? null : 'd-none'">
    <div class="col-12 col-md-10 col-xl-8 px-4">
      <div class="row">
        <div class="col-lg-6">
          <div class="charging-container">
            <img class="charging-image" width="300" src="/assets/images/laadsysteem1_1.png" alt="Normaal laadpunt" />
          </div>
          <p><b>Type 1:</b> Normaal laadpunt</p>
          <p><b>Vermogen:</b> 22kW</p>
          <p><b>Kosten:</b> Circa 1.000-2.000 euro</p>
          <p><b>Parkeergedrag:</b> Langere parkeerduur (meer dan 1 uur)</p>
          <p><b>Gemiddelde laadtijd:</b> 1,5 uur
            <mat-icon class="fa fa-info-circle icon-sm ml-2 pointer" placement="top-end"
              ngbPopover="Voor 80% opladen van een gemiddelde elektrische auto met een accu van 43 kWh."
              popoverTitle="Laadtijd">
            </mat-icon>
          </p>
          <p>+ Deze laadpaal heeft een lage prijs</p>
          <p>- Het opladen van een auto duurt redelijk lang</p>
        </div>
        <div class="col-lg-6">
          <div class="charging-container">
            <img class="charging-image" width="300" src="/assets/images/laadsysteem2_2.png" alt="Twee laadpunten één paal" />
          </div>
          <p><b>Type 2:</b> Twee laadpunten één paal</p>
          <p><b>Vermogen:</b> 22kW</p>
          <p><b>Kosten:</b> Circa 2.000-3.000 euro</p>
          <p><b>Parkeergedrag:</b> Langere parkeerduur (meer dan 1 uur)</p>
          <p><b>Gemiddelde laadtijd:</b> 1,5 uur
            <mat-icon class="fa fa-info-circle icon-sm ml-2 pointer" placement="top-end"
              ngbPopover="Voor 80% opladen van een gemiddelde elektrische auto met een accu van 43 kWh."
              popoverTitle="Laadtijd">
            </mat-icon>
          </p>
          <p>+ Deze laadpaal heeft per laadpunt een nog lagere prijs dan type 1</p>
          <p>+ Efficiënt ruimtegebruik doordat er 2 laadpunten aan 1 paal vastzitten</p>
          <p>- Het opladen gaat redelijk langzaam </p>
        </div>
        <div class="col-lg-6">
          <div class="charging-container">
            <img class="charging-image quick-charging" src="/assets/images/snellader_1.png" alt="Snellader" />
          </div>
          <p><b>Type 3:</b> Snellader</p>
          <p><b>Vermogen:</b> 50kW</p>
          <p><b>Kosten:</b> Circa 20.000 euro</p>
          <p><b>Parkeergedrag:</b> Middellange parkeerduur (30 - 60 minuten)</p>
          <p><b>Gemiddelde laadtijd:</b> 40 minuten
            <mat-icon class="fa fa-info-circle icon-sm ml-2 pointer" placement="top-end"
              ngbPopover="Voor 80% opladen van een gemiddelde elektrische auto met een accu van 43 kWh."
              popoverTitle="Laadtijd">
            </mat-icon>
          </p>
          <p>+ Het opladen gaat snel</p>
          <p>+ Hogere opbrengsten mogelijk per laadpaal, door snellere laadtijd</p>
          <p>- Hoge aanschafkosten</p>
        </div>
        <div class="col-lg-6">
          <div class="charging-container">
            <img class="charging-image quick-charging" src="/assets/images/snellader_2.png" alt="Ultra snellader" />
          </div>
          <p><b>Type 4:</b> Ultra snellader</p>
          <p><b>Vermogen:</b> 150kW</p>
          <p><b>Kosten:</b> Circa 60.000 euro</p>
          <p><b>Parkeergedrag:</b> Korte parkeerduur (15 - 30 minuten)</p>
          <p><b>Gemiddelde laadtijd:</b> 15 minuten
            <mat-icon class="fa fa-info-circle icon-sm ml-2 pointer" placement="top-end"
              ngbPopover="Voor 80% opladen van een gemiddelde elektrische auto met een accu van 43 kWh."
              popoverTitle="Laadtijd">
            </mat-icon>
          </p>
          <p>+ Het opladen gaat nog sneller dan bij type 3.</p>
          <p>+ Zelfs nog hogere opbrengsten mogelijk dan bij een snellader.</p>
          <p>- Nog hogere aanschafkosten.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center background-primary py-5">
    <div class="col-12 col-md-10 col-xl-8 d-flex flex-column align-items-center px-4">
      <h1 class="text-center heading-underline-white pointer" (click)="toggleRealisedSystems()">Gerealiseerde solar
        carports
      </h1>
      <i style="color:white" [ngClass]="this.showRealisedSystems ? 'fas fa-angle-up fa-3x pointer' : 'fas fa-angle-down fa-3x pointer'"
        (click)="toggleRealisedSystems()"></i>
      <div class="row" [ngClass]="this.showRealisedSystems ? null : 'd-none'">
        <div class="col-12 col-lg-6 col-xl-6 my-3">
          <h4>Bloemendaal</h4>
          <img class="image-car" src="/assets/images/realised-solarcarports/realised_solarcarport_bloemendaal.jpg"
            alt="Gerealiseerd solar carport systeem" />
          <p class="pt-3 mb-0"><b class="carport-faq-text-align">Adres:</b> Zeeweg 89,</p>
          <p><b class="carport-faq-text-align mt-0"></b> Bloemendaal</p>
          <p><b class="carport-faq-text-align">Bouwjaar:</b> juni 2020</p>
          <p><b class="carport-faq-text-align">Vermogen:</b> 2 MWp</p>
          <p><b class="carport-faq-text-align">Jaarlijkse opbrengst:</b> 2 miljoen kWh </p>
          <p><b class="carport-faq-text-align">Parkeervakken:</b> 800 </p>
          <p><b class="carport-faq-text-align">Ontwikkelaar:</b> Rooftop Energy</p>
          <p><b class="carport-faq-text-align">Onderaannemer:</b> MORRENsolar</p>
        </div>
        <div class="col-12 col-lg-6 col-xl-6 my-lg-3">
          <h4>Woerden</h4>
          <img class="image-car" src="/assets/images/realised-solarcarports/realised_solarcarport_woerden.png"
            alt="Gerealiseerd solar carport systeem" />
          <p class="pt-3"><b class="carport-faq-text-align">Adres:</b> Lepenlaan 1a, Woerden</p>
          <p><b class="carport-faq-text-align">Bouwjaar:</b> september 2019</p>
          <p><b class="carport-faq-text-align">Vermogen:</b> 280 kWp</p>
          <p><b class="carport-faq-text-align">Jaarlijkse opbrengst:</b> 250 duizend kWh </p>
          <p><b class="carport-faq-text-align">Parkeervakken:</b> 84 </p>
          <p><b class="carport-faq-text-align">Ontwikkelaar:</b> Groenleven</p>
        </div>
        <!-- <div class="col-12 col-lg-6 col-xl-6 my-3">
          <h4>Culemborg - Wijk Lanxmeer</h4>
          <img class="image-car" src="/assets/images/system3.png" alt="Gerealiseerd solar carport systeem" />
          <p class="pt-3"><b>Adres:</b> Hendrik Marsmanweg 1, Culemborg</p>
          <p><b>Bouwjaar:</b> 2019</p>
          <p><b>Vermogen:</b> 235 kWp</p>
          <p><b>Jaarlijkse opbrengst:</b> 200 duizend kWh </p>
          <p><b>Parkeervakken:</b> 68 </p>
          <p><b>Ontwikkelaar:</b> MORRENsolar</p>
        </div> -->
        <div class="col-12 col-lg-6 col-xl-6 my-3">
          <h4>Biddinghuizen</h4>
          <img class="image-car"
            src="/assets/images/realised-solarcarports/realised_solarcarport_biddinghuizen_cropped.jpg"
            alt="Gerealiseerd solar carport systeem" />
          <p class="pt-3 mb-0"><b class="carport-faq-text-align">Adres:</b> Alikruikweg 23,</p>
          <p class="mt-0"><b class="carport-faq-text-align"></b> Biddinghuizen</p>
          <p><b class="carport-faq-text-align">Opening:</b> 2021</p>
          <p><b class="carport-faq-text-align">Vermogen:</b> 35 MWp</p>
          <p><b class="carport-faq-text-align">Jaarlijkse opbrengst:</b> 32 miljoen kWh </p>
          <p><b class="carport-faq-text-align">Parkeervakken:</b> 15.000 </p>
          <p><b class="carport-faq-text-align">Ontwikkelaar:</b> Solarfields</p>
        </div>
        <div class="col-12 col-lg-6 col-xl-6 my-3">
          <h4>Heerlen</h4>
          <img class="image-car" src="/assets/images/realised-solarcarports/realised_solarcarport_heerlen_cropped.png"
            alt="Gerealiseerd solar carport systeem" />
          <p class="pt-3"><b class="carport-faq-text-align">Adres:</b> Kloosterweg 1, Heerlen</p>
          <p><b class="carport-faq-text-align">Bouwjaar:</b> 2018</p>
          <p><b class="carport-faq-text-align">Vermogen:</b> 700 kWp</p>
          <p><b class="carport-faq-text-align">Jaarlijkse opbrengst:</b> 620 duizend kWh </p>
          <p><b class="carport-faq-text-align">Parkeervakken:</b> 150 </p>
          <p id="systemTypes"><b class="carport-faq-text-align">Ontwikkelaar:</b> Rooftop Energy</p>
        </div>
      </div>
    </div>
  </div>
    <!-- <div class="row col-12 col-lg-6 p-4 order-2 align-items-center">
      <div class="row col-12 col-md-6 justify-content-center align-items-start h-50">
        <div class="col-12 col-lg-10 px-md-4 px-lg-0">
          <p class=""><b>Standaard</b></p>
          <p class="">Stalen constructie</p>
          <p class="">Zwart / grijze kleur</p>
          <p class="">V-vorm</p>
          <p class="">Standaard hoogte personenauto</p>
          <p class="">Geen logo</p>
        </div>
      </div>
      <div class="row col-12 col-lg-6 p-4 order-2 align-items-center">
        <div class="row col-12 col-md-6 justify-content-center align-items-start h-50">
          <div class="col-12 col-lg-10 px-md-4 px-lg-0">
            <p class=""><b>Gepersonaliseerd</b></p>
            <p class="">Stalen of houten constructie</p>
            <p class="">Kleur naar wens</p>
            <p class="">Vorm naar wens</p>
            <p class="">Maatwerk hoogte vrachtwagens</p>
            <p class="">Logo</p>
          </div>
        </div>
      </div>
    </div> -->

 
  <!-- <div class="row justify-content-center background-primary">
      <div class="col-12 col-md-10 col-xl-8">
        <h1 class="p-2 d-flex justify-content-center heading-underline-primary color-light pt-5">Batterij systemen</h1>
        <div class="row col-12">
          <div class="col-lg-6">
            <img class=".img-w50 mt-5 mb-5" src="/assets/images/batterijsysteem1.png" />
          </div>
          <div class="col-lg-6 mt-5">
            <p class="color-light ">Een solar carport kan in allerlei vormen en maten laten maken. Toch zijn
              er
              een aantal
              standaard systemen die
              vaak
              terugkomen. Hieronder vergelijken we 3 verschillende types.</p>
          </div>
        </div>
      </div>
    </div> -->
  <div id="practice" class="row justify-content-center background-light py-5">
    <div class="col-12 col-md-10 col-xl-6 d-flex flex-column align-items-center px-4">
      <h1 class="text-center heading-underline-black pointer" (click)="toggleInPractice()">Park the Sun
        abroad?
      </h1>
      <i [ngClass]="this.showInPractice ? 'fas fa-angle-up fa-3x pointer' : 'fas fa-angle-down fa-3x pointer'"
        (click)="toggleInPractice()"></i>
      <div style="margin-top:20px;text-align:center;" [ngClass]="showInPractice ? null : 'd-none'">
        <p class="col-12 col-md-12 col-xl-12 px-4">
          <span style="color:#F1B73F;">Parkthesun.com</span> is a full application in the Netherlands. The tooling is
          also interesting for governments and project developers in other EU
          countries and North America. Analyze the solar power potential of
          carports in an interactive map.
        </p>
        <div class="col-md-12">
          <div
            class="d-flex justify-content-center align-items-center background-round"
          >
          <a href="https://www.sobolt.com/solarsearch/"><button class="button-primary mb-3 align-items-center">Non-NL usage</button></a>
          </div>
        </div>
      <!-- <p class="color-light">Benieuwd hoe de applicatie eruit
        ziet en wat je ermee kan? In de video's hieronder is een voorbeeld uit de praktijk te zien.</p> -->
        
      <!-- <div class="embed-youtube mb-3">
        <iframe width="840" height="500" src="https://www.youtube.com/embed/kJZenDLskG8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="embed-youtube">
        <iframe width="840" height="500" src="https://www.youtube.com/embed/KSUMvRDXxxU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div> -->
      </div>
    </div>
  </div>
  
    <!-- <div class="row justify-content-center background-light py-5" [ngClass]="showInPractice ? null : 'd-none'">
      <div class="col-12 col-md-10 col-xl-8 px-4">
        <div class="row">
          <div class="col-12">
            <img class="img-fluid" src="/assets/images/pts_in_de_praktijk/pts_praktijk_1.png" alt="Normaal laadpunt" />
            <p class="pt-2">1. Als je op jouw provincie of gemeente klikt, toont de applicatie een overzicht van alle
              gedetecteerde
              parkeerterreinen. Dit zijn alle locaties met minimaal 5 parkeervakken naast elkaar. Misschien weet je al
              precies welk parkeerterrein je wil bekijken. Dan kan je daar meteen op inzoomen.
            </p>
          </div>
          <div class="col-12">
            <img class="img-fluid" src="/assets/images/pts_in_de_praktijk/pts_praktijk_2.png"
              alt="Twee laadpunten één paal" />
            <p class="pt-2">2. Misschien wil je je eerst oriënteren op meerdere locaties. Gebruik dan de filters om een
              selectie te
              maken van de parkeerterreinen die het meest interessant zijn voor jou. In dit geval zijn we bijvoorbeeld
              op zoek naar een plek waar minimaal 450 kWp opgesteld kan worden. Klik vervolgens op een locatie waar je
              je verder in wil verdiepen.</p>
          </div>
          <div class="col-12">
            <img class="img-fluid" src="/assets/images/pts_in_de_praktijk/pts_praktijk_3.png" alt="Snellader" />
            <p class="pt-2">3. We zijn in dit voorbeeld nieuwsgierig of het een goed idee is om een solar carport op het
              parkeerterrein ‘Ijsbaan de Westfries’ te plaatsen. Bovenaan toont de applicatie direct de eerste
              inschatting. Je ziet hoe goed de locatie voldoet aan de randvoorwaarden en wat de eenmalige investering
              is. Ook wordt getoond wat het verwachte jaarlijks resultaat is en de terugverdientijd. In de kaart wordt
              met zwart de schaduwwerking aangegeven. Hoe geler de kleur, hoe meer zon dat deel van het terrein
              ontvangt.</p>
          </div>
          <div class="col-12">
            <img class="img-fluid" src="/assets/images/pts_in_de_praktijk/pts_praktijk_4.png" alt="Ultra snellader" />
            <p class="pt-2">4. Klap het scherm uit (door op het icoontje rechts bovenin te klikken). Zo kan je zien waar
              deze eerste
              inschatting vandaan komt. Nu kan je stap voor stap de randvoorwaarden, investering en opbrengst bekijken.
              Een groot deel van de gegevens is al ingevuld, gebaseerd op de analyse van data of op gemiddelden. Een
              deel kan nog aangevuld worden aan de hand van jouw kennis en voorkeuren.</p>
          </div>
          <div class="col-12">
            <img class="img-fluid" src="/assets/images/pts_in_de_praktijk/pts_praktijk_5.png" alt="Snellader" />
            <p class="pt-2">5. In dit geval verwachten we bijvoorbeeld veel elektrische auto’s. Onder het kopje
              ‘Investering’ passen
              we het aantal laadpunten daarom aan van 4 naar 20 stuks. Verder weten we dat het parkeerterrein gemiddeld
              25% vol staat. Dit passen we aan onder het kopje ‘Opbrengst’. Direct zie je de eenmalige investering
              bovenaan in het scherm omhoog gaan. De terugverdientijd wordt echter lager in verband met inkomsten uit
              laden!
            </p>
          </div>
          <div class="col-12">
            <img class="img-fluid" src="/assets/images/pts_in_de_praktijk/pts_praktijk_6.png" alt="Ultra snellader" />
            <p class="pt-2">6. Na het doorlopen en aanpassen van de randvoorwaarden, investering en opbrengst kom je bij
              de stap ‘Aan
              de slag’. Deze is vooral relevant als je het parkeerterrein kansrijk genoeg vindt en besluit om een solar
              carport te gaan ontwikkelen. Je vindt hier een overzicht van een aantal belangrijke zaken om in gang te
              zetten. Let’s go!</p>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div id="practice" class="row justify-content-center background-primary py-5">
      <div class="col-12 col-md-10 col-xl-6 d-flex flex-column align-items-center px-4">
        <h1 class="text-center heading-underline-black pointer" (click)="toggleInPractice()">Park the Sun
          in de praktijk
        </h1>
      </div>
    </div>     -->

  <div class="row justify-content-center background-primary py-5">
    <div class="col-12 col-md-10 col-xl-6 d-flex flex-column align-items-center px-4">
      <h1 class="text-center heading-underline-white pointer" (click)="toggleMeerZon()">Meer zon?
      </h1>
      <i style="color:white" [ngClass]="showMeerZon ? 'fas fa-angle-up fa-3x pointer' : 'fas fa-angle-down fa-3x pointer'"
        (click)="toggleMeerZon()"></i>
      
      <div class="row" [ngClass]="this.showMeerZon ? null : 'd-none'">
        <div class="col-12 col-lg-12 col-xl-12">
          <div style="margin-top:20px;text-align:center;" [ngClass]="showMeerZon ? null : 'd-none'">
            
            <p class="col-12 col-md-12 col-xl-12 px-4">
              Benieuwd naar de voortgang en mogelijkheden van zon op daken? Zonnedakje brengt op unieke wijze 
              de realisatie en potentie in zicht. Tientallen gemeenten hebben zich al aangesloten.
            </p>
            <div class="col-12 col-md-12 col-xl-12">
              
              <div style="text-align:center">
                <a href="https://zonnedakje.nl" >
                  <img width="300" style="margin-bottom: 30px;" src="/assets/images/logo_zonnedakje.png">
                </a><br />
                <a  href="https://zonnedakje.nl"><button class="button-primary mb-3 align-items-center">Naar zonnedakje</button></a>
              </div>

            </div>
          <!-- <p class="color-light">Benieuwd hoe de applicatie eruit
            ziet en wat je ermee kan? In de video's hieronder is een voorbeeld uit de praktijk te zien.</p> -->
            
          <!-- <div class="embed-youtube mb-3">
            <iframe width="840" height="500" src="https://www.youtube.com/embed/kJZenDLskG8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="embed-youtube">
            <iframe width="840" height="500" src="https://www.youtube.com/embed/KSUMvRDXxxU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div> -->
          </div>
          
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row justify-content-center background-primary py-5">
    <div class="col-12 col-md-10 col-xl-8 d-flex flex-column flex-sm-row align-items-center justify-content-center">
      <button class="sobolt-button color-light mb-3 mb-sm-0 mr-4 ml-4" (click)="redirectToContactPageClick()">
        Neem contact op
      </button>
      <button class="sobolt-button  mr-4 ml-4" (click)="redirectToApplicationClick()">
        Aan de slag
      </button>
    </div>
  </div> -->
</div>

import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/layout/layout.service';

@Component({
  selector: 'app-mobile-search',
  templateUrl: './mobile-search.component.html',
  styleUrls: ['./mobile-search.component.scss']
})
export class MobileSearchComponent {

  public isOpen: boolean = false;

  constructor(public layoutService: LayoutService) { }

  toggleMenu(){
    this.isOpen = !this.isOpen;
  }

  closeMenu(){
    this.isOpen = false;
  }
}

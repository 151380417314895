import { Component, OnInit } from "@angular/core";
import { LayoutService } from "src/app/layout/layout.service";
import { CartoService } from "../../services/carto.service";

declare var mapboxgl: any;

@Component({
  selector: "app-basemap-toggle",
  templateUrl: "./basemap-toggle.component.html",
  styleUrls: ["./basemap-toggle.component.scss"],
})
export class BasemapToggleComponent implements OnInit {
  basemaps: any;
  layers: any;

  constructor(public cartoService: CartoService, public layoutService: LayoutService) { }

  ngOnInit() {
    this.basemaps = this.cartoService.basemaps;
  }

  handleChange(basemap) {
    this.layers = this.cartoService.map.getStyle().layers;

    this.cartoService.selectedBasemap = basemap.id;

    this.cartoService.changeBasemap(basemap.style, basemap.id, () => {
      if (basemap.id == "sun") {
        this.cartoService.showLegend = true;
      }
      else {
        this.cartoService.showLegend = false;
      }
    });
  }
}

<mat-card *ngIf="cartoService.parkingData"
  [ngClass]="(businessService.showBusinessDetails)? 'business-details-card' : 'business-details-card-no-details'">
  <div class="bcase-card-non-scrollable-section"
    [ngClass]="businessService.showBusinessDetails ? 'bcase-non-scrollable-border' : null">
    <div class="d-flex flex-row justify-content-between">
      <button [matTooltip]="shareService.tipContent" #tooltip="matTooltip" *ngIf="cartoService.parkingData"
        mat-stroked-button class="share-button-business-case" (click)="onShareClick()">
        <mat-icon svgIcon="share"></mat-icon>
      </button>
      <h2 class="text-center place-center">
        <span
          class="heading-underline-primary mb-3 text-cutoff">{{cartoService.parkingData.adrs_name || "inexistent"}}</span>
      </h2>
      <i [ngClass]="(businessService.showBusinessDetails)? 'fa fa-window-minimize pointer bcase-window-size-button' : 'fa fa-window-maximize  pointer bcase-window-size-button'"
        aria-hidden="true" (click)="toggleBusinessDetails()"></i>
    </div>
    <div class="col-12 d-flex flex-row justify-content-between">
      <p><b>Adres</b>
        <br>{{cartoService.parkingData.address ==="None" ? 'Onbekend' : cartoService.parkingData.address }}
      </p>
      <div>
        <button class="button-primary"
          (click)="cartoService.zoomOutfromParkingLot(); toggleOnBusinessDetails();">{{backButtonText()}}</button>
      </div>

    </div>
    <div class="d-flex flex-row pull-right">
      <p class="col-3">
        <b>Randvoorwaarden</b><br>{{businessService.totalSuitabilityPopovers[businessService.getTotalSuitability()-1].title}}
        <mat-icon *ngIf="businessService.getTotalSuitability() === 1" container="body"
          class="fa fa-info-circle icon-sm ml-2 pointer" placement="right"
          [ngbPopover]="businessService.totalSuitabilityPopovers[0].content"
          [popoverTitle]="businessService.totalSuitabilityPopovers[0].title">
        </mat-icon>
        <mat-icon *ngIf="businessService.getTotalSuitability() === 2" container="body"
          class="fa fa-info-circle icon-sm ml-2 pointer" placement="right"
          [ngbPopover]="businessService.totalSuitabilityPopovers[1].content"
          [popoverTitle]="businessService.totalSuitabilityPopovers[1].title">
        </mat-icon>
        <mat-icon *ngIf="businessService.getTotalSuitability() == 3" container="body"
          class="fa fa-info-circle icon-sm ml-2 pointer" placement="right"
          [ngbPopover]="businessService.totalSuitabilityPopovers[2].content"
          [popoverTitle]="businessService.totalSuitabilityPopovers[2].title">
        </mat-icon>
      </p>
      <p class="col-3"><b>Eenmalige investering</b><br>€
        {{businessService.roundTens(businessService.getFixedCosts() - businessService.getFixedRevenue(), 2) | number : '1.0-0' : 'nl' }}
      </p>
      <p class="col-3"><b>Jaarlijks resultaat</b><br>€
        {{businessService.roundTens(businessService.getYearlyRevenue() - businessService.getYearlyCosts(), 2) | number : '1.0-0' : 'nl' }}
        / jaar
      </p>
      <p class="col-3"><b>Terugverdientijd</b><br>
        {{businessService.getPaybackTime() | number : '1.0-0' : 'nl' }} jaar</p>
    </div>
  </div>

  <!-- Business overview content -->

  <ng-template #suitabilityContent><i class="fa fa-times top-right pointer"
      aria-hidden="true"></i>{{businessInfo.tooltipText.parking_suitability}}</ng-template>
  <div
    [ngClass]="(businessService.showBusinessDetails)?'bcase-card-scrollable-content':'bcase-card-scrollable-content-hidden'">
    <mat-horizontal-stepper labelPosition="bottom" [linear]="isLinear" #stepper>
      <ng-template matStepperIcon="edit">
        <i class="fas fa-circle d-none"></i>
      </ng-template>
      <ng-template matStepperIcon="defaultStep">
        <i class="fas fa-circle d-none"></i>
      </ng-template>
      <mat-step [stepControl]="firstFormGroup" [completed]="false" state="defaultStep">
        <div>
          <ng-template matStepLabel>
            <h1 class="business-case-stage-title">Randvoorwaarden</h1>
          </ng-template>
          <form [formGroup]="firstFormGroup">
            <div>
              <app-business-case-item iconClass="fas fa-compress-alt" fieldName="Totale oppervlakte"
                [statistic]="businessService.roundTens(cartoService.parkingData.area, 1) | number : '1.0-0' : 'nl'"
                [dialogHeader]="businessInfo.tooltipText.surface_title" [dialogContent]="businessInfo.tooltipText.surface_content" unit="m2"
                [flag]="businessService.getTotalAreaFlagColor()"
                [flagPopoverMessage]="businessInfo.tooltipText.flagPopover_totalArea">
              </app-business-case-item>
              <app-business-case-item iconClass="fas fa-sun" fieldName="Zonnige oppervlakte"
                [fieldentryEditableNumber]="businessService.getSunnyArea()"
                [(editableNumber)]="businessService.customSunnyArea" unit="m2"
                [flag]="businessService.getSunnyFlagColor()" [flagPopoverMessage]="businessInfo.tooltipText.flagPopover_sunnyArea"
                [dialogHeader]="businessInfo.tooltipText.sunny_surface_title" [dialogContent]="businessInfo.tooltipText.sunny_surface_content">
              </app-business-case-item>
              <ng-template [ngTemplateOutlet]="areaSolarCarportField"></ng-template>
              <app-business-case-item iconClass="fas fa-bolt" fieldName="Op te stellen vermogen" unit="kWp"
                [statistic]="businessService.getSolarParkingPower(3) | number : '1.0-0' : 'nl'"
                [dialogHeader]="tooltipText.worth_title" [dialogContent]="tooltipText.worth_content"
                [fieldentryInfoText]="businessService.getConnectionInfo()"
                [flag]="businessService.getSolarPowerFlagColor()"
                [flagPopoverMessage]="businessInfo.tooltipText.flagPopover_carportPower">
              </app-business-case-item>
              <!-- <app-business-case-item iconClass="fas fa-file-alt" fieldName="Vergunning" statistic="Uitgegeven"
                [dialogHeader]="businessInfo.tooltipText.permit_title" [dialogContent]="businessInfo.tooltipText.permit_content"
                [dialogLink]="'https://www.omgevingsloket.nl/'" [dialogLinkContent]="businessInfo.tooltipText.more_information"
                >
            </app-business-case-item> -->
              <app-business-case-item iconClass="fas fa-eye" fieldName="Beschermd stadsgezicht"
                [statistic]="businessService.isProtectedCityscape() ? 'Ja' : 'Nee'"
                [flag]="businessService.getCityscapeFlagColor()"
                [flagPopoverMessage]="businessInfo.tooltipText.flagPopover_protectedCityscape"
                [dialogHeader]="businessInfo.tooltipText.cityscape_title" [dialogContent]="businessInfo.tooltipText.cityscape_content"
                [dialogLink]="'https://www.monumenten.nl/soorten-monumenten/beschermde-stads-en-dorpsgezichten'"
                dialogLinkContent="Ga naar monumenten.nl">
              </app-business-case-item>
              <!-- <app-business-case-item iconClass="far fa-user" fieldName="Eigenaarschap" statistic="Publiek/Privaat"
                [dialogHeader]="businessInfo.tooltipText.ownership_title" [dialogContent]="businessInfo.tooltipText.ownership_content"
                >
              </app-business-case-item> -->
              <app-business-case-item *ngIf="businessService.getSolarParkingPower(3) < 160" iconClass="fas fa-plug"
                fieldName="Afstand tot netaansluiting" fieldentryButtonIconClass="fas fa-pencil-alt"
                [fieldnameTextList]="stage1LowGridDistanceText"
                [(editableNumber)]="businessService.customLowVoltageGridDistance" unit="m"
                [fieldentryEditableNumber]="businessService.getLowVoltageGridDistance()"
                [dialogHeader]="tooltipText.distance_connection_title"
                [dialogContent]="tooltipText.distance_connection_content"
                [flag]="businessService.getGridDistanceFlagColor()"
                [flagPopoverMessage]="businessInfo.tooltipText.flagPopover_gridDistance"
                [dialogLink]="'https://www.enexis.nl/zakelijk/aansluitingen/tarieven/tarievenoverzicht'"
                dialogLinkContent="Enexis" [dialogLink2]="'https://www.liander.nl/tarieven-downloads'"
                dialogLinkContent2="Liander" [dialogLink3]="'https://www.stedin.net/tarieven#elektriciteit#tarieven'"
                dialogLinkContent3="Stedin">
              </app-business-case-item>
              <app-business-case-item *ngIf="businessService.getSolarParkingPower(3) > 160" iconClass="fas fa-plug"
                fieldName="Afstand tot netaansluiting" fieldentryButtonIconClass="fas fa-pencil-alt"
                [fieldnameTextList]="stage1MidGridDistanceText"
                [(editableNumber)]="businessService.customMidVoltageGridDistance" unit="m"
                [fieldentryEditableNumber]="businessService.getMidVoltageGridDistance()"
                [dialogHeader]="tooltipText.distance_connection_title"
                [dialogContent]="tooltipText.distance_connection_content"
                [flag]="businessService.getGridDistanceFlagColor()"
                [flagPopoverMessage]="businessInfo.tooltipText.flagPopover_gridDistance"
                [dialogLink]="'https://www.enexis.nl/zakelijk/aansluitingen/tarieven/tarievenoverzicht'"
                dialogLinkContent="Enexis" [dialogLink2]="'https://www.liander.nl/tarieven-downloads'"
                dialogLinkContent2="Liander" [dialogLink3]="'https://www.stedin.net/tarieven#elektriciteit#tarieven'"
                dialogLinkContent3="Stedin">
              </app-business-case-item>
              <app-business-case-item iconClass="fas fa-tachometer-alt" fieldName="Grootte netaansluiting" unit=""
                [statistic]="businessService.getGridConnectionName()"
                [fieldentryDropdownContent]="businessService.yesNoGridDistanceDropdown"
                [(selectedDropdownValue)]="businessService.yesNoGridConnection"
                [dialogHeader]="businessInfo.tooltipText.size_connection_title" [dialogContent]="businessInfo.tooltipText.size_connection_content"
                [dialogLink]="'https://www.enexis.nl/zakelijk/aansluitingen/tarieven/tarievenoverzicht'"
                dialogLinkContent="Enexis" [dialogLink2]="'https://www.liander.nl/tarieven-downloads'"
                dialogLinkContent2="Liander" [dialogLink3]="'https://www.stedin.net/tarieven#elektriciteit#tarieven'"
                dialogLinkContent3="Stedin">
              </app-business-case-item>
              <!-- <app-business-case-item iconClass="fas fa-chart-area" fieldName="Congestie"
                                [dialogHeader]="businessInfo.tooltipText.congestion_title" [dialogContent]="businessInfo.tooltipText.congestion_content"
                [fieldentryTextList]="stage1MainsConnectionFieldentry">
              </app-business-case-item> -->
              <!-- <div class="row mt-5">
                <div class="col-1"></div>
                <div class="col-11 d-flex flex-column mb-3">
                  <p class="business-case-fields">Vergunning en Bestemming op aanvraag verkrijgbaar</p>
                </div>
              </div> -->
            </div>
          </form>
          <div class="row mt-4">
            <div class="col-6">
              <div class="text-left">
                <!-- <button (click)="openOptionsDialog()" class="button-primary">Meer instellingen</button> -->
              </div>
            </div>
            <div class="col-6 align-self-end">
              <div class="text-right">
                <button matStepperNext class="bcase-next-stage-btn button-primary-small">Volgende</button>
              </div>
            </div>
          </div>
        </div>
      </mat-step>

      <mat-step [completed]="false" state="defaultStep">
        <div>
          <ng-template matStepLabel>
            <h1 class="business-case-stage-title">Investering</h1>
          </ng-template>
          <div>
            <div class="row business-case-row">
              <div class="col-7">
                <p><b>Eenmalige investering</b></p>
              </div>
              <div class="col-5">
                <p><b>€ {{businessService.roundTens(businessService.getFixedCosts(), 2) | number : '1.0-0' : 'nl'}}</b>
                </p>
              </div>
            </div>
            <app-business-case-item iconClass="fas fa-solar-panel" fieldName="Solar carport"
              [statistic]="businessService.roundTens(businessService.getSolarCarportCosts(),2) | number : '1.0-0' : 'nl'"
              prefix="€" [dropdownContent]="businessService.solarCarportOptions"
              [(selectedDropdownValue)]="businessService.solarCarportType" [templateRef]="solarCarportDialog"
              [fieldentryInfoText]="getSolarCarportInfoText()">
            </app-business-case-item>
            <!-- Fundering -->
            <app-business-case-item iconClass="fas fa-tools" [fieldName]="businessInfo.tooltipText.fundering_title"
              [statistic]="businessService.roundTens(businessService.getFunderingCosts(),2) | number : '1.0-0' : 'nl'"
              prefix="€" [dropdownContent]="businessService.funderingOptions"
              [(selectedDropdownValue)]="businessService.funderingOption" [dialogHeader]="businessInfo.tooltipText.fundering_title"
              [dialogContent]="businessInfo.tooltipText.fundering_content">
            </app-business-case-item>
            <app-business-case-item iconClass="fas fa-plug" fieldName="Netaansluiting"
              [fieldnameEditableNumber]="stage2ConnectionfieldnameEditableNumber" prefix="€"
              [statistic]="businessService.roundTens(businessService.getFixedGridCosts(),2) | number : '1.0-0' : 'nl'"
              [dialogHeader]="businessInfo.tooltipText.cost_connection_title" [dialogContent]="businessInfo.tooltipText.cost_connection_content"
              [dialogContent2]="businessInfo.tooltipText.cost_connection_content2"
              [dialogLink]="'https://www.enexis.nl/zakelijk/aansluitingen/tarieven/tarievenoverzicht'"
              dialogLinkContent="Enexis" [dialogLink2]="'https://www.liander.nl/tarieven-downloads'"
              dialogLinkContent2="Liander" [dialogLink3]="'https://www.stedin.net/tarieven#elektriciteit#tarieven'"
              dialogLinkContent3="Stedin" [fieldnameEditableText]="stage2ConnectionFieldnameEditableText">
            </app-business-case-item>
            <app-business-case-item iconClass=" fas fa-file-invoice" [fieldName]="businessInfo.tooltipText.leges_title"
              [statistic]="businessService.roundTens(businessService.getLegesCosts(),2) | number : '1.0-0' : 'nl'"
              prefix="€" [dialogHeader]="businessInfo.tooltipText.leges_title" [dialogContent]="businessInfo.getLegesPopupText()">
            </app-business-case-item>
            <app-business-case-item iconClass=" fas fa-search" fieldName="Vaste projectkosten & vooronderzoek"
              [fieldentryEditableNumber]="businessService.roundTens(businessService.getResearchCosts(),2)" prefix="€"
              [(editableNumber)]="businessService.customResearchCosts" [dialogHeader]="businessInfo.tooltipText.research_title"
              [dialogCustomHtml]="businessInfo.tooltipText.research_content">
            </app-business-case-item>

            <app-business-case-item iconClass="fas fa-charging-station" fieldName="Laadpunten"
              [statistic]="businessService.roundTens(businessService.getChargerCosts(),2) | number : '1.0-0' : 'nl'"
              prefix="€" [(selectedDropdownValue)]="businessService.chargerType"
              [dropdownContent]="businessService.chargerOptions"
              [fieldnameEditableNumber]="businessService.getNumChargers()" fieldnameEditableNumberUnit="stuks"
              [(editableFieldnameNumber)]="businessService.customNumChargers" [templateRef]="laadpuntenDialog">
            </app-business-case-item>
            <!-- Verlichting -->
            <app-business-case-item iconClass=" far fa-lightbulb" [fieldName]="businessInfo.tooltipText.light_title"
              [dropdownContent]="businessService.lightOptions" prefix="€"
              [(selectedDropdownValue)]="businessService.lightType"
              [statistic]="businessService.roundTens(businessService.getLightCosts(),2) | number : '1.0-0' : 'nl'"
              [dialogHeader]="businessInfo.tooltipText.light_title" [dialogCustomHtml]="businessInfo.tooltipText.light_content">
            </app-business-case-item>
            <app-business-case-item iconClass="fas fa-shield-alt" fieldName="Beveiliging"
              [statistic]="businessService.roundTens(businessService.getSecurityCosts(),2) | number : '1.0-0' : 'nl'"
              prefix="€" [dropdownContent]="businessService.securityOptions"
              [(selectedDropdownValue)]="businessService.securityType" [dialogHeader]="businessInfo.tooltipText.cost_security_title"
              [dialogContent]="businessInfo.tooltipText.cost_security_content">
            </app-business-case-item>
            <ng-template [ngTemplateOutlet]="batteryCostField"></ng-template>
            <br>
            <div class="row business-case-row">
              <div class="col-7">
                <p><b>Jaarlijkse investering</b></p>
              </div>
              <div class="col-5">
                <p><b>€ {{businessService.roundTens(businessService.getYearlyCosts() ,2) | number : '1.0-0' : 'nl'}}</b>
                </p>
              </div>
            </div>
            <app-business-case-item iconClass="fas fa-plug" fieldName="Netaansluiting"
              [fieldnameEditableNumber]="stage2ConnectionfieldnameEditableNumber" prefix="€" unit="per jaar"
              [statistic]="businessService.roundTens(businessService.getYearlyGridCosts(),1) | number : '1.0-0' : 'nl'"
              [dialogHeader]="businessInfo.tooltipText.cost_connection_title" [dialogContent]="businessInfo.tooltipText.cost_connection_content"
              [dialogContent2]="businessInfo.tooltipText.cost_connection_content2"
              [dialogLink]="'https://www.enexis.nl/zakelijk/aansluitingen/tarieven/tarievenoverzicht'"
              dialogLinkContent="Enexis" [dialogLink2]="'https://www.liander.nl/tarieven-downloads'"
              dialogLinkContent2="Liander" [dialogLink3]="'https://www.stedin.net/tarieven#elektriciteit#tarieven'"
              dialogLinkContent3="Stedin" [fieldnameEditableText]="stage2ConnectionFieldnameEditableText">
            </app-business-case-item>
            <app-business-case-item iconClass="fas fa-sign" fieldName="Huur/pacht kosten"
              [statistic]="businessService.roundTens(businessService.getYearlyRentCosts(),1) | number : '1.0-0' : 'nl'"
              prefix="€" [fieldnameTextList]="stage2RentText" unit="per jaar"
              [fieldnameEditableNumber]="businessService.defaultRentTariff" fieldnameEditableNumberUnit="€/m2 per jaar"
              [(editableFieldnameNumber)]="businessService.customRentTariff"
              [dialogHeader]="businessInfo.tooltipText.cost_rent_title" [dialogContent]="businessInfo.tooltipText.cost_rent_content">
            </app-business-case-item>
            <app-business-case-item iconClass="fas fa-toolbox" fieldName="Onderhoud"
              [fieldentryEditableNumber]="businessService.roundTens(businessService.getYearlyMaintenanceCosts(), 2)"
              prefix="€" [(editableNumber)]="businessService.customMaintenanceCosts" unit="per jaar"
              [dialogHeader]="businessInfo.tooltipText.cost_maintenance_title"
              [dialogContent]="businessInfo.tooltipText.cost_maintenance_content">
            </app-business-case-item>
            <!-- <app-business-case-item iconClass="fas fa-wrench" fieldName="Onderhoud"
              fieldentryButtonIconClass="fas fa-pencil-alt" [fieldentryTextList]="stage2SearchFieldentry">
            </app-business-case-item> -->
            <app-business-case-item iconClass="fas fa-briefcase-medical" fieldName="Verzekering"
              [fieldentryEditableNumber]="businessService.roundTens(businessService.getYearlyInsuranceCosts(),2)"
              prefix="€" unit="per jaar" [(editableNumber)]="businessService.customYearlyInsuranceCosts"
              [dialogHeader]="businessInfo.tooltipText.cost_insurance_title" [dialogContent]="businessInfo.tooltipText.cost_insurance_content">
            </app-business-case-item>
            <!-- <app-business-case-item iconClass="fas fa-briefcase-medical" fieldName="Verzekering"
              fieldentryButtonIconClass="fas fa-pencil-alt" [fieldentryTextList]="stage2SearchFieldentry">
            </app-business-case-item> -->
          </div>
          <div class="row mt-4">
            <div class="col-6">
              <div class="text-left">
                <!-- <button (click)="openOptionsDialog()" class="button-primary">Meer instellingen</button> -->
              </div>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <button matStepperPrevious class="button-primary-small">Vorige</button>
              <button matStepperNext class="button-primary-small">Volgende</button>
            </div>
          </div>
        </div>
      </mat-step>

      <mat-step [completed]="false" state="defaultStep">
        <div>
          <ng-template matStepLabel>
            <h1 class="business-case-stage-title">Opbrengst</h1>
          </ng-template>
          <div>
            <div class="row business-case-row">
              <div class="col-7">
                <p><b>Jaarlijkse opbrengsten</b></p>
              </div>
              <div class="col-5">
                <p><b>€
                    {{businessService.roundTens(businessService.getYearlyRevenue() ,2) | number : '1.0-0' : 'nl'}}</b>
                </p>
              </div>
            </div>

            <app-business-case-item *ngIf="businessService.subsidyOption == 0" iconClass="fas fa-money-bill-alt" fieldName="Landelijke subsidie"
              [statistic]="businessService.roundTens(businessService.getYearlySubsidyRevenue(),2) | number : '1.0-0' : 'nl'"
              [dropdownContent]="businessService.subsidyDropdown" [dialogHeader]="tooltipText.subsidy_title"
              [(selectedDropdownValue)]="businessService.subsidyOption" [dialogContent]="tooltipText.subsidy_content"

              prefix="€" unit="per jaar"
              [dialogLink]="'https://www.rvo.nl/subsidie-en-financieringswijzer/stimulering-duurzame-energieproductie-en-klimaattransitie-sde/aanvragen-sde'"
              dialogLinkContent="Stimulering Duurzame Energie, SDE++"
              [dialogLink2]="'https://www.rvo.nl/subsidie-en-financieringswijzer/sce'"
              dialogLinkContent2="Subsidieregeling Coöperatieve Energieopwekking, SCE">
            </app-business-case-item>
            
           <app-business-case-item *ngIf="businessService.subsidyOption == 1" iconClass="fas fa-money-bill-alt" fieldName="Landelijke subsidie"
              [statistic]="businessService.roundTens(businessService.getYearlySubsidyRevenue(),2) | number : '1.0-0' : 'nl'"
              [dropdownContent]="businessService.subsidyDropdown" [dialogHeader]="tooltipText.subsidy_title"
              [(selectedDropdownValue)]="businessService.subsidyOption" [dialogContent]="tooltipText.subsidy_content"


              [dropdownContent2]="businessService.SDE_OPTIONS"
              [(selectedDropdownValue2)]="businessService.sdeSubsidyPhase" [dialogHeader]="tooltipText.sdeSubsidie"
              [dialogContent]="tooltipText.sdePhases"


              prefix="€" unit="per jaar"
              [dialogLink]="'https://www.rvo.nl/subsidie-en-financieringswijzer/stimulering-duurzame-energieproductie-en-klimaattransitie-sde/aanvragen-sde'"
              dialogLinkContent="Stimulering Duurzame Energie, SDE++"
              [dialogLink2]="'https://www.rvo.nl/subsidie-en-financieringswijzer/sce'"
              dialogLinkContent2="Subsidieregeling Coöperatieve Energieopwekking, SCE">
            </app-business-case-item>


            <app-business-case-item *ngIf="businessService.subsidyOption == 2" iconClass="fas fa-money-bill-alt" fieldName="Landelijke subsidie"
            [statistic]="businessService.roundTens(businessService.getYearlySubsidyRevenue(),2) | number : '1.0-0' : 'nl'"
            [dropdownContent]="businessService.subsidyDropdown" [dialogHeader]="tooltipText.subsidy_title"
            [(selectedDropdownValue)]="businessService.subsidyOption" [dialogContent]="tooltipText.subsidy_content"
            [fieldentryInfoText]="businessService.getPaybackWarning()"
            
            [dropdownContent2]="businessService.SCE_OPTIONS"
            [(selectedDropdownValue2)]="businessService.sceConnection" [dialogHeader]="tooltipText.sceSubsidie"
            [dialogContent]="tooltipText.sceConnections"


            prefix="€" unit="per jaar"
            [dialogLink]="'https://www.rvo.nl/subsidie-en-financieringswijzer/stimulering-duurzame-energieproductie-en-klimaattransitie-sde/aanvragen-sde'"
            dialogLinkContent="Stimulering Duurzame Energie, SDE++"
            [dialogLink2]="'https://www.rvo.nl/subsidie-en-financieringswijzer/sce'"
            dialogLinkContent2="Subsidieregeling Coöperatieve Energieopwekking, SCE">
          </app-business-case-item>

            <app-business-case-item iconClass="fas fa-piggy-bank" [fieldName]="tooltipText.other_subsidies_title"
              [fieldentryEditableNumber]="businessService.getYearlyOtherSubsidy() | number : '1.0-0' : 'nl'"
              [(editableNumber)]="businessService.customYearlyOtherSubsidy" prefix="€" unit=" per jaar"
              [dialogHeader]="businessInfo.tooltipText.other_subsidies_title"
              [dialogCustomHtml]="businessInfo.tooltipText.other_subsidies_content">
            </app-business-case-item>
            <app-business-case-item iconClass="fas fa-battery-three-quarters" fieldName="Opgewekte energie"
              [statistic]="businessService.roundTens(businessService.getYearlyEnergy(3),2) | number : '1.0-0' : 'nl'"
              [dialogHeader]="businessInfo.tooltipText.energy_title" [dialogContent]="businessInfo.tooltipText.energy_content"
              unit="kWh per jaar">
            </app-business-case-item>
            <!-- Terugleveren aan het net -->
            <app-business-case-item iconClass="fas fa-bolt" [fieldName]="businessInfo.tooltipText.return_title"
              [statistic]="businessService.roundTens(businessService.getYearlyGridSalesRevenue(),2) | number : '1.0-0' : 'nl'"
              [fieldnameTextList]="stage3GridSalesFieldnameText" prefix="€" unit="per jaar"
              [fieldnameEditableNumber]="businessService.getGridSalesTariff()" fieldnameEditableNumberUnit="ct/kWh"
              [(editableFieldnameNumber)]="businessService.gridSalesCustomTariff"
              [dialogHeader]="businessInfo.tooltipText.return_title" [dialogContent]="businessInfo.tooltipText.return_content">
            </app-business-case-item>
            <app-business-case-item iconClass="fas fa-plug" [fieldName]="businessInfo.tooltipText.ownuse_title"
              [statistic]="businessService.roundTens(businessService.getOwnUseRevenue(),2) | number : '1.0-0' : 'nl'"
              prefix="€" [dropdownContent]="businessService.ownUsePercentageOptions" unit="per jaar"
              [(selectedDropdownValue)]="businessService.ownUseFraction" [dialogHeader]="tooltipText.ownuse_title"
              [fieldnameEditableNumber]="businessService.getOwnUseKwhTariff()*100" fieldnameEditableNumberUnit="ct/kWh"
              [fieldnameTextList]="stage3OwnUseFieldnameText" [dialogContent]="tooltipText.ownuse_content"
              [fieldentryInfoText]="tooltipText.own_usage_info"
              [(editableFieldnameNumber)]="businessService.ownUseKwhCustomTariff">
            </app-business-case-item>
            <!-- [(editableFieldnameNumber)]="businessService.customNumChargers" [templateRef]="laadpuntenDialog"> -->

            <app-business-case-item iconClass="fas fa-charging-station" fieldName="Laden"
              [statistic]="businessService.roundTens(businessService.getYearlyChargingRevenue(),1) | number : '1.0-0' : 'nl'"
              prefix="€" [dropdownContent]="businessService.parkingTimeOptions" unit="per jaar"
              [(selectedDropdownValue)]="businessService.parkingTime" [fieldentryInfoText]="getChargingInfoText()"
              [dropdownContent2]="businessService.parkingCoverageOptions"
              [(selectedDropdownValue2)]="businessService.parkingCoverage" [dialogHeader]="businessInfo.tooltipText.charge_title"
              [dialogContent]="businessInfo.tooltipText.charge_content">
            </app-business-case-item>
            <app-business-case-item *ngIf="businessService.getSolarParkingPower(3) < 15" iconClass="fas fa-calculator"
              fieldName="Salderen" [statistic]="businessService.getYearlySalderenRevenue() | number : '1.0-0' : 'nl'"
              prefix="€" [(selectedDropdownValue)]="businessService.salderenDefaultKwhUsage" unit=" per jaar"
              [dropdownContent]="businessService.salderenOptions" [fieldnameTextList]="stage3SalderenFieldnameText"
              [fieldnameEditableNumber]="businessService.salderenDefaultKwhUsage"
              fieldnameEditableNumberUnit="kWh per jaar"
              [(editableFieldnameNumber)]="businessService.salderenCustomKwhUsage"
              [fieldentryInfoText]="businessInfo.tooltipText.netting_info" [dialogHeader]="businessInfo.tooltipText.netting_title"
              [dialogContent]="businessInfo.tooltipText.netting_content"
              [dialogLink]="'https://www.rijksoverheid.nl/actueel/nieuws/2019/04/26/salderingsregeling-verlengd-tot-2023'"
              dialogLinkContent="De salderingsregeling blijft tot 1 januari 2023 bestaan en wordt daarna afgebouwd. ">
            </app-business-case-item>
            <app-business-case-item *ngIf="businessService.getSolarParkingPower(3) > 15" iconClass="fas fa-calculator"
              prefix="€" statistic="0" fieldName="Salderen" [fieldentryInfoText]="businessInfo.tooltipText.no_netting_info"
              [dialogHeader]="businessInfo.tooltipText.netting_title" [dialogContent]="businessInfo.tooltipText.netting_content"
              [dialogLink]="'https://www.rijksoverheid.nl/actueel/nieuws/2019/04/26/salderingsregeling-verlengd-tot-2023'"
              dialogLinkContent="De salderingsregeling blijft tot 1 januari 2023 bestaan en wordt daarna afgebouwd.">
            </app-business-case-item>
            <app-business-case-item iconClass="fas fa-parking" fieldName="Overdekt parkeren"
              [statistic]="businessService.roundTens(businessService.getYearlyExtraParkingRevenue(),1) | number : '1.0-0' : 'nl'"
              prefix="€" [fieldnameTextList]="stage3ExtraParkingFieldnameText" unit="per jaar"
              [fieldnameEditableNumber]="businessService.getExtraParkingTariff()" fieldnameEditableNumberUnit="ct/uur"
              [(editableFieldnameNumber)]="businessService.extraParkingCustomTariff"
              [dialogHeader]="businessInfo.tooltipText.indoor_title" [dialogContent]="businessInfo.tooltipText.indoor_content">
            </app-business-case-item>
            <app-business-case-item iconClass="fas fa-leaf" fieldName="Vermeden CO2 uitstoot" unit="kg CO2 per jaar"
              [statistic]="businessService.roundTens(businessService.getCo2Savings(), 2) | number : '1.0-0' : 'nl'"
              [dialogHeader]="businessInfo.tooltipText.co2_title" [dialogContent]="businessInfo.tooltipText.co2_content"
              [dialogLink]="'https://www.pbl.nl/sites/default/files/downloads/pbl-2020-eindadvies-basisbedragen-sde-plus-plus-2020_3526_27-02-2020.pdf'"
              dialogLinkContent="Meer informatie over dit kengetal">
            </app-business-case-item>
            <app-business-case-item iconClass="fas fa-car-battery" [fieldName]="businessInfo.tooltipText.revenue_battery_title"
            [statistic]="businessService.roundTens(businessService.getYearlyBatteryRevenue(),1) | number : '1.0-0' : 'nl'"
            prefix="€" unit=" per jaar" [fieldentryInfoText]="businessInfo.getBatteryRevenueInfoText()"
            [dialogHeader]="businessInfo.tooltipText.revenue_battery_title"
            [dialogCustomHtml]="businessInfo.tooltipText.revenue_battery_content">
          </app-business-case-item>
          </div>
          <div class="row business-case-row">
            <div class="col-7">
              <p><b>Eenmalige opbrengsten</b></p>
            </div>
            <div class="col-5">
              <p><b>€
                  {{businessService.roundTens(businessService.getFixedRevenue() ,2) | number : '1.0-0' : 'nl'}}</b>
              </p>
            </div>
          </div>
          <app-business-case-item iconClass="fas fa-piggy-bank" [fieldName]="businessInfo.tooltipText.other_subsidies_title"
            [fieldentryEditableNumber]="businessService.defaultFixedOtherSubsidy | number : '1.0-0' : 'nl'"
            [(editableNumber)]="businessService.customFixedOtherSubsidy" prefix="€"
            [dialogHeader]="businessInfo.tooltipText.other_subsidies_title" [dialogCustomHtml]="businessInfo.tooltipText.other_subsidies_content">
          </app-business-case-item>
          <div class="row mt-4">
            <div class="col-6">
              <div class="text-left">
                <!-- <button (click)="openOptionsDialog()" class="button-primary">Meer instellingen</button> -->
              </div>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <button matStepperPrevious class="button-primary-small">Vorige</button>
              <button matStepperNext class="button-primary-small">Volgende</button>
            </div>
          </div>
        </div>
      </mat-step>

      <mat-step [completed]="false" state="defaultStep">
        <div>
          <ng-template matStepLabel>
            <h1 class="business-case-stage-title">Aan de slag</h1>
          </ng-template>
          <div>
            <p>
              Ben je tevreden met de gegevens & keuzes in stap 1 t/m 3? Dan ben je klaar om echt aan de slag te gaan!
              Zet de volgende belangrijke acties in gang:
            </p>
            <div class="row mt-4">
              <div class="col-1">
                <i class="fas fa-money-bill-alt icon-sm-dark"></i>
              </div>
              <div class="col-5">
                <p style="font-weight: bold;">Subsidies aanvragen</p>
                <p class="business-case-links"
                  (click)="openDialog(tooltipText.apply_SDE_title, tooltipText.apply_SDE_content, 
                  'https://www.rvo.nl/subsidie-en-financieringswijzer/stimulering-duurzame-energieproductie-en-klimaattransitie-sde/aanvragen-sde', 
                  'SDE++', 
                  undefined, 
                  undefined, 
                  undefined, 
                  'https://www.rvo.nl/subsidie-en-financieringswijzer/energie-investeringsaftrek-eia', 
                  'Energie-investeringsaftrek (EIA)',
                  'https://www.rvo.nl/subsidie-en-financieringswijzer/sce', 
                  'SCE' )">
                  Lees meer</p>
              </div>
              <div class="col-1">
                <i class="fas fa-bolt icon-sm-dark"></i>
              </div>
              <div class="col-5">
                <p style="font-weight: bold;">Netbeheerder checken</p>
                <p class="business-case-links"
                  (click)="openDialog(businessInfo.tooltipText.checkNetworkOperatorTitle, businessInfo.tooltipText.checkNetworkOperatorContent, 'https://www.netbeheernederland.nl/_contentediting/files/files/EN_Elektriciteit-2019-Legenda.pdf', 'op de kaart van Netbeheer Nederland', undefined, undefined, undefined, undefined, undefined, undefined, undefined)">
                  Lees meer</p>
              </div>
              <div class="col-1">
                <i class="fas fa-stamp icon-sm-dark"></i>
              </div>
              <div class="col-5">
                <p style="font-weight: bold;">Omgevingsvergunning aanvragen</p>
                <p class="business-case-links"
                  (click)="openDialog(businessInfo.tooltipText.apply_permit_title, businessInfo.tooltipText.apply_permit_content, 'https://www.omgevingsloket.nl/', 'Kijk op de website van het Omgevingsloket voor meer informatie', undefined, undefined, undefined, undefined, undefined, undefined, undefined)">
                  Lees meer</p>
              </div>
              <div class="col-1">
                <i class="fas fa-house-user icon-sm-dark"></i>
              </div>
              <div class="col-5">
                <p style="font-weight: bold;">Participatieproject opstarten</p>
                <p class="business-case-links"
                  (click)="openDialog(businessInfo.tooltipText.startParticipationProjectTitle, businessInfo.tooltipText.startParticipationProjectContent, 'https://departicipatiecoalitie.nl/wat-is-de-pc/', 'www.departicipatiecoalitie.nl', undefined, undefined, undefined, undefined, undefined, undefined, undefined)">
                  Lees meer</p>
              </div>
              <div class="col-1">
                <i class="fas fa-handshake icon-sm-dark"></i>
              </div>
              <div class="col-5">
                <p style="font-weight: bold;"> Energiecoöperatie aanhaken</p>
                <p class="business-case-links" (click)="openEnergyCooperationDialog()">Lees meer</p>
              </div>
              <div class="col-1">
                <i class="fas fa-wrench icon-sm-dark"></i>
              </div>
              <div class="col-5">
                <p style="font-weight: bold;">Ontwikkelaar aanhaken</p>
                <p class="business-case-links"
                  (click)="openDialog(businessInfo.tooltipText.installer_title, businessInfo.tooltipText.installer_content, 'https://hollandsolar.nl/leden?marktsegment=0--1c-zon-op-land-zonneparken-op-land&productdienst=0--4-projectontwikkelaar-project-financiering', 'Overzicht van bij branchevereniging aangesloten ontwikkelaars', undefined, undefined, undefined, undefined, undefined, undefined, undefined)">
                  Lees meer</p>
              </div>
              <div class="col-1">
                <i class="fas fa-users icon-sm-dark"></i>
              </div>
              <div class="col-5">
                <p style="font-weight: bold;">Omwonenden betrekken</p>
                <p class="business-case-links"
                  (click)="openDialog(businessInfo.tooltipText.localResidentsTitle, businessInfo.tooltipText.localResidentsContent, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)">
                  Lees meer</p>
              </div>
              <div class="col-1">
                <i class="fas fa-piggy-bank icon-sm-dark"></i>
              </div>
              <div class="col-5">
                <p style="font-weight: bold;">Financiering regelen</p>
                <p class="business-case-links" (click)="openFinanceDialog()">Lees meer</p>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 d-flex justify-content-end">
              <button (click)="exportPDF()" class="button-primary mr-auto">Exporteer PDF</button>
              <button matStepperPrevious class="button-primary-small">Vorige</button>
            </div>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
  <!-- </div> -->
</mat-card>

<ng-template #solarCarportDialog>
  <h2 class="text-center text-cutoff-nowrap heading-underline-primary">{{businessInfo.tooltipText.solar_carport_title}}</h2>
  <div mat-dialog-content>
    <p>{{businessInfo.tooltipText.solar_carport_content}}
    </p>
    <div class="row justify-content-center background-light pb-4">
      <div class="col-6 align-items-center">
        <p class=""><b>Standaard</b></p>
        <p class="">Stalen constructie</p>
        <p class="">Zwart / grijze kleur</p>
        <p class="">V-vorm</p>
        <p class="">Standaard hoogte personenauto</p>
        <p class="">Geen logo</p>
      </div>
      <div class="col-6 align-items-center">
        <p class=""><b>Gepersonaliseerd</b></p>
        <p class="">Stalen of houten constructie</p>
        <p class="">Kleur naar wens</p>
        <p class="">Vorm naar wens</p>
        <p class="">Maatwerk hoogte vrachtwagens</p>
        <p class="">Logo</p>
      </div>
      <div class="col-12 background-light d-flex align-items-center justify-content-center">
        <img class="systems-image" src="/assets/images/landing-kaart-06.png" alt="Solar carport dubbele rij" />
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="mt-3 d-flex justify-content-center">
    <button (click)="dialog.closeAll()" class="button-primary btn-narrow">Sluiten</button>
  </div>
</ng-template>

<ng-template #laadpuntenDialog>
  <h2 class="text-center heading-underline-primary">{{businessInfo.tooltipText.cost_charging_title}}</h2>
  <div mat-dialog-content>
    <p>{{businessInfo.tooltipText.cost_charging_content}}
    </p>
    <div class="row justify-content-center background-light py-5">
      <div class="col-12 col-md-10 col-xl-8 px-4">
        <div class="row">
          <div class="col-lg-6">
            <img class="charging-image" src="/assets/images/laadsysteem1.png" alt="Normaal laadpunt" />
            <p><b>Type 1:</b> Normaal laadpunt</p>
            <p><b>Vermogen:</b> 22kW</p>
            <p><b>Kosten:</b> Circa 1.000-2.000 euro</p>
            <p><b>Parkeergedrag:</b> Langere parkeerduur (meer dan 1 uur)</p>
            <p><b>Gemiddelde laadtijd:</b> 1,5 uur
              <mat-icon class="fa fa-info-circle icon-sm ml-2 pointer" placement="right"
                ngbPopover="Voor 80% opladen van een gemiddelde elektrische auto met een accu van 43 kWh."
                popoverTitle="Laadtijd">
              </mat-icon>
            </p>
            <p>+ Deze laadpaal heeft een lage prijs</p>
            <p>- Het opladen van een auto duurt redelijk lang</p>
          </div>
          <div class="col-lg-6">
            <img class="charging-image" src="/assets/images/laadsysteem2.png" alt="Twee laadpunten één paal" />
            <p><b>Type 2:</b> Twee laadpunten één paal</p>
            <p><b>Vermogen:</b> 22kW</p>
            <p><b>Kosten:</b> Circa 2.000-3.000 euro</p>
            <p><b>Parkeergedrag:</b> Langere parkeerduur (meer dan 1 uur)</p>
            <p><b>Gemiddelde laadtijd:</b> 1,5 uur
              <mat-icon class="fa fa-info-circle icon-sm ml-2 pointer" placement="right"
                ngbPopover="Voor 80% opladen van een gemiddelde elektrische auto met een accu van 43 kWh."
                popoverTitle="Laadtijd">
              </mat-icon>
            </p>
            <p>+ Deze laadpaal heeft per laadpunt een nog lagere prijs dan type 1</p>
            <p>+ Efficiënt ruimtegebruik doordat er 2 laadpunten aan 1 paal vastzitten</p>
            <p>- Het opladen gaat redelijk langzaam </p>
          </div>
          <div class="col-lg-6">
            <img class="charging-image" src="/assets/images/laadsysteem3.png" alt="Snellader" />
            <p><b>Type 3:</b> Snellader</p>
            <p><b>Vermogen:</b> 50kW</p>
            <p><b>Kosten:</b> Circa 20.000 euro</p>
            <p><b>Parkeergedrag:</b> Middellange parkeerduur 30-60 minuten</p>
            <p><b>Gemiddelde laadtijd:</b> 40 minuten
              <mat-icon class="fa fa-info-circle icon-sm ml-2 pointer" placement="right"
                ngbPopover="Voor 80% opladen van een gemiddelde elektrische auto met een accu van 43 kWh."
                popoverTitle="Laadtijd">
              </mat-icon>
            </p>
            <p>+ Het opladen gaat snel</p>
            <p>+ Hogere opbrengsten mogelijk per laadpaal, door snellere laadtijd</p>
            <p>- Hoge aanschafkosten</p>
          </div>
          <div class="col-lg-6">
            <img class="charging-image" src="/assets/images/laadsysteem4.png" alt="Ultra snellader" />
            <p><b>Type 4:</b> Ultra snellader</p>
            <p><b>Vermogen:</b> 150kW</p>
            <p><b>Kosten:</b> Circa 60.000 euro</p>
            <p><b>Parkeergedrag:</b> Korte parkeerduur (30 minuten)</p>
            <p><b>Gemiddelde laadtijd:</b> 15 minuten
              <mat-icon class="fa fa-info-circle icon-sm ml-2 pointer" placement="right"
                ngbPopover="Voor 80% opladen van een gemiddelde elektrische auto met een accu van 43 kWh."
                popoverTitle="Laadtijd">
              </mat-icon>
            </p>
            <p>+ Het opladen gaat nog sneller dan bij type 3.</p>
            <p>+ Zelfs nog hogere opbrengsten mogelijk dan bij een snellader.</p>
            <p>- Nog hogere aanschafkosten.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="mt-3 d-flex justify-content-center">
    <button (click)="dialog.closeAll()" class="button-primary btn-narrow">Sluiten</button>
  </div>
</ng-template>

<ng-template #areaSolarCarportField>
  <div class="row business-case-row">
    <div class="col-1"><i class="fas fa-solar-panel"></i></div>
    <div class="col-6">
      <h3 class="business-case-fields">Oppervlakte solar carport
        <mat-icon *ngIf="businessService.getSolarCarportAreaFlagColor() === 1" class="ml-2 fa fa-flag flag-red pointer"
          aria-hidden="true" placement="bottom" [ngbPopover]="businessInfo.tooltipText.flagPopover_carportArea" popoverTitle="Slecht"
          container="body">
        </mat-icon>
        <mat-icon *ngIf="businessService.getSolarCarportAreaFlagColor() === 2"
          class="ml-2 fa fa-flag flag-orange  pointer" aria-hidden="true" placement="bottom"
          [ngbPopover]="businessInfo.tooltipText.flagPopover_carportArea" popoverTitle="Middelmatig" container="body">
        </mat-icon>
        <mat-icon *ngIf="businessService.getSolarCarportAreaFlagColor() === 3"
          class="ml-2 fa fa-flag flag-green  pointer" aria-hidden="true" placement="bottom"
          [ngbPopover]="businessInfo.tooltipText.flagPopover_carportArea" popoverTitle="Goed" container="body">
        </mat-icon>
      </h3>
      <p class="business-case-links"
        (click)="openDialog(businessInfo.tooltipText.build_surface_title, businessInfo.tooltipText.build_surface_content, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)">
        Lees meer</p>
      <mat-form-field class="input-wide">
        <mat-label>{{businessService.carportAreaOptions.hintText}}</mat-label>
        <mat-select [(value)]="businessService.carportAreaType"
          (selectionChange)="businessService.editCarportArea($event)">
          <mat-option *ngFor="let item of businessService.carportAreaOptions.items" [value]="item.value">
            {{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mt-0 input-wide" *ngIf="businessService.carportAreaType == 2">
        <input type="number" matInput placeholder="Hoeveel zonnig oppervlakte overkappen? (%)"
          [value]="(businessService.buildableAreaPercentage * 100) |  number : '1.0-0' : 'nl'"
          (change)="buildableAreaInputChange($event)" onwheel="this.blur()">
        <span matSuffix>%</span>
      </mat-form-field>
    </div>
    <div class="col-5">
      {{businessService.roundTens(businessService.getCarportArea(),1) | number : '1.0-0' : 'nl'}}
      m2</div>
  </div>
</ng-template>

<ng-template #batteryCostField>
  <div class="row business-case-row">
    <div class="col-1"><i class="fas fa-solar-panel"></i></div>
    <div class="col-6">
      <h3 class="business-case-fields">Batterij systemen</h3>
      <p class="business-case-links"
        (click)="openDialog(businessInfo.tooltipText.cost_battery_title, businessInfo.tooltipText.cost_battery_content, 'https://www.tennet.org/bedrijfsvoering/Systeemgegevens_voorbereiding/Aangeboden_regel_en_reservevermogen/index.aspx', 'Meer informatie over het gebruiken van de batterij als reservevermogen', undefined, undefined, undefined, undefined, undefined, undefined)">
        Lees meer</p>
      <mat-form-field class="input-wide">
        <mat-label>{{businessService.batteryCostOptions.hintText}}</mat-label>
        <mat-select [(value)]="businessService.batteryType"
          (selectionChange)="businessService.editBatteryCost($event)">
          <mat-option *ngFor="let item of businessService.batteryCostOptions.items" [value]="item.value">
            {{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mt-0 input-wide" *ngIf="businessService.batteryType == (businessService.batteryCostOptions.items.length-1)">
        <input type="number" matInput placeholder="Hoeveel opslag is gewenst? (kWh)"
          [value]="businessService.batterySize |  number : '1.0-0' : 'nl'"
          (change)="batterySizeInputChange($event)" onwheel="this.blur()">
        <span matSuffix>kWh</span>
      </mat-form-field>
    </div>
    <div class="col-5">
      € {{businessService.roundTens(businessService.getBatteryCosts(),1) | number : '1.0-0' : 'nl'}}
      </div>
  </div>
</ng-template>
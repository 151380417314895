export interface RegionData {
  name: string;
  cartodb_id: number;
  numParkings?: number;
  coordinates: number[];
  province_id: string
}

export interface ParkingData {
  id: number;
  address: string;
  adrs_name?: string;
  area: number;
  sunny_area: number;
  pc_sn_area: number;
  suit_area: number;
  cap_kwp: number;
  energy_mwh: number;
  protected: string;
  griddist_m: number;
  rejected?: boolean;
  public_own?: number;
}

export interface MapFilters {
  powerFilter: number;
  areaFilter: number;
}

export class MapConfig {
  filters: any = {
    powerFilter: undefined,
    areaFilter: undefined,
  };

  location: any = {
    center: undefined,
    zoom: undefined,
    municipalityId: undefined,
    provinceId: undefined,
  };

  businessCase: any = {
    parkingData: undefined,
    businessValues: undefined,
  };

  constructor(filters?: any, location?: any, businessCase?: any) {
    this.filters = filters;
    this.location = location;
    this.businessCase = businessCase;
  }
}

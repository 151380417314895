<nav *ngIf="layoutService.showNavbar" class="header navbar navbar-expand-lg navbar-light bg-white">
  <a class="navbar-brand" [routerLink]="''"><img class="logo-small" src="/assets/images/pts_logo.png" /></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"
    aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>


  <div class="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo02">
    <ul class="navbar-nav mt-2 mt-lg-0">
      <li class="menu-item nav-item mx-2 mx-xl-3" *ngFor="let item of layoutService.menuItems">
        <a class="topmenu-link nav-link header-link" [routerLink]="item.routerLink" [queryParams]="item.routerLink === 'app' ? {skipdialog: 'true'} : {}" routerLinkActive="active"
          data-target="#navbarTogglerDemo02" data-toggle="collapse">{{ item.name }}</a>
      </li>
    </ul>
  </div>

  <div *ngIf="layoutService.inApplication">
    <button type="button" class="close z-index-1 top-right-menu icon-md-dark" aria-label="Close"
      *ngIf="layoutService.showNavbar" (click)="layoutService.toggleNavbar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- <div *ngIf="layoutService.inApplication && layoutService.showNavbar"><i
      class="fa fa-times icon-md-dark top-right-menu z-index-1" aria-hidden="true"
      (click)="layoutService.toggleNavbar()"></i>
  </div> -->
</nav>

<div *ngIf="layoutService.inApplication">
  <button type="button" class="close z-index-1 top-right-menu icon-md-dark" aria-label="Close"
    *ngIf="layoutService.showNavbar" (click)="layoutService.toggleNavbar()">
    <span aria-hidden="true">&times;</span>
  </button>
  <button class="navbar-toggler custom-toggler top-right-menu z-index-1" type="button"
    (click)="layoutService.toggleNavbar()" *ngIf="!layoutService.showNavbar">
    <!-- <i class="top-right-menu z-index-1 fa fa-bars icon-md-dark"></i> -->
    <span class="navbar-toggler-icon"></span>
  </button>
</div>

<!-- <i class=" top-right-menu z-index-1 fal fa-bars icon-md-dark" (click)="layoutService.toggleNavbar()"></i> -->

<!-- <i class="top-right-menu z-index-1 fa fa-bars icon-md-dark" (click)="layoutService.toggleNavbar()"></i> -->
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AboutComponent } from "./about/about.component";
import { ContactComponent } from "./contact/contact.component";
import { LandingComponent } from "./landing/landing.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { SharedComponentsModule } from "../shared-components/shared-components.module";
import { MatIconModule } from '@angular/material/icon';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FaqComponent } from './faq/faq.component';

@NgModule({
  declarations: [
    AboutComponent,
    ContactComponent,
    LandingComponent,
    NotFoundComponent,
    FaqComponent,
  ],
  imports: [CommonModule, SharedComponentsModule, MatIconModule, NgbModule],
  providers: [AboutComponent],
})
export class PagesModule { }

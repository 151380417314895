<!-- <mat-button-toggle-group name="fontStyle" aria-label="Font Style" *ngIf="cartoService.parkingData"> -->
<mat-button-toggle-group [ngClass]="!layoutService.isMobileLayout && 'basemap-toggle'" [(ngModel)]="cartoService.selectedBasemap">
  <mat-button-toggle class="basemap-toggle-button" [ngClass]="layoutService.isMobileLayout && 'mobile-toggle-button'" checked="true"
    [disabled]="basemap.id == 'sun' && !cartoService.parkingData" *ngFor="let basemap of basemaps; let i = index"
    (change)="handleChange(basemap)" [value]="basemap.id">
    <span
      matTooltip="Deze kaartlaag is op dit zoom-niveau nog niet beschikbaar. Klik op een specifieke parkeerplek om de zonnekaart te zien."
      [matTooltipDisabled]="basemap.id !== 'sun' || cartoService.parkingData">
      {{ basemap.name }}
    </span>
  </mat-button-toggle>
</mat-button-toggle-group>

<mat-card *ngIf="cartoService.showLegend" class="legend-card">
<!-- <mat-card class="legend-card"> -->
  <mat-card-content class="legend-content">
      <div class="d-flex align-items-center flex-column">
        <p>Schaduw</p>
        <div class="grad1"></div>
        <p class="pt-3">Zon</p>
      </div>
  </mat-card-content>
</mat-card>

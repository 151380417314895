import { Injectable } from "@angular/core";
import { CartoService } from "./carto.service";
import { BusinessCaseService } from "src/app/services/business-case.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Clipboard } from "@angular/cdk/clipboard";
import { nanoid } from "nanoid";
import { MapConfig } from "./carto.interface";

declare var firebase: any;

@Injectable({
  providedIn: "root",
})
export class ShareService {
  private collectionName: string = "config-links";
  public tipContent: string =
    "Klik om een link naar deze configuratie te delen!";

  constructor(
    public cartoService: CartoService,
    public businessService: BusinessCaseService,
    private clipboard: Clipboard,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar
  ) { }

  initShareIcon() {
    this.iconRegistry.addSvgIcon(
      "share",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/material-icons/share.svg"
      )
    );
  }

  share(data: MapConfig) {
    
    // Log event
    let prov = null;
    let mun = null;
    console.log(this.cartoService.selectedProvinceId > 0)
    console.log(this.cartoService.selectedMunicipalityId)
    if(this.cartoService.selectedProvinceId > 0){
      prov = this.cartoService.provCodeToName(this.cartoService.selectedProvinceId);
    } 
    if(this.cartoService.selectedMunicipalityId > 0){
      mun = this.cartoService.munCodeToName(this.cartoService.selectedMunicipalityId + this.cartoService.cartoMunicipalityStartId);
    } 
    let parking_id = null;
    let parking_address = null;
    if(this.cartoService.parkingData){
      let parking = this.cartoService.parkingData;
      parking_id = parking.id;
      parking_address = parking.address;
    }

    this.cartoService.analytics.logEvent('share_mapview', {
      gemeente: mun,
      province: prov,
      parking_id: parking_id,
      address: parking_address,
      custom_event_type: 'share_mapview'
    })


    let hash = nanoid(this.cartoService.hashSize); // create random string of 21 chars

    this.openSnackBar("Link wordt gemaakt", "Afwijzen");

    this.writeDbPromise(hash, data)
      .then(() => {
        this.copyLink(hash);

        return;
      })
      .catch((err) => {
        console.error("failed to insert", err);

        this.openSnackBar("Error", "Afwijzen");
        return;
      });
  }

  shareCurrentFilters() {
    let currentFilters = this.getMapFilters();

    let location = this.getMapLocation();

    let mapConfig: MapConfig = new MapConfig(
      currentFilters,
      location,
      undefined
    );

    this.share(mapConfig);
  }

  writeDbPromise(hash, data) {
    let writeDb = firebase.app().functions('europe-west3').httpsCallable("write");

    return writeDb({
      collection: this.collectionName,
      doc: hash,
      data: data,
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  copyLink(hash) {
    let link = `${window.location.host}/${hash}`;

    this.clipboard.copy(link);

    this.openSnackBar("Link gekopieerd naar het klembord", "Afwijzen");
  }

  shareBusinessCase() {
    let businessCase = this.getBusinessCaseData();

    let location = this.getMapLocation();

    let mapConfig: MapConfig = new MapConfig(undefined, location, businessCase);

    this.share(mapConfig);
  }

  getBusinessCaseData() {
    return {
      parkingData: this.cartoService.parkingData,
      businessValues: this.getBusinessValues()
    };
  }

  getMapFilters() {
    return {
      powerFilter: this.cartoService.powerFilter,
      areaFilter: this.cartoService.areaFilter,
      gridDistanceFilter: this.cartoService.gridDistanceFilter,
      protectedFilter: this.cartoService.protectedFilter
    }
  }

  getMapLocation() {
    return {
      zoom: this.cartoService.map.getZoom(),
      center: this.cartoService.map.getCenter(),
      municipalityId: this.cartoService.selectedMunicipalityId,
      provinceId: this.cartoService.selectedProvinceId,
    }
  }

  getBusinessValues() {
    let data = {};

    // First page
    data["customSunnyArea"] = this.businessService.customSunnyArea;
    data["buildableAreaPercentage"] = this.businessService.buildableAreaPercentage;

    // Afstand netaansluiting
    data["customLowVoltageGridDistance"] = this.businessService.customLowVoltageGridDistance;
    data["customMidVoltageGridDistance"] = this.businessService.customMidVoltageGridDistance;
    data["yesNoGridConnection"] = this.businessService.yesNoGridConnection;

    // console.log(data);

    data["stage1GridDistanceOption"] = this.businessService.stage1GridDistanceOption;

    // Second page
    data["customResearchCosts"] = this.businessService.customResearchCosts;
    data["funderingOption"] = this.businessService.funderingOption;
    data["solarCarportType"] = this.businessService.solarCarportType;
    data["chargerType"] = this.businessService.chargerType;
    data["customNumChargers"] = this.businessService.customNumChargers;
    data["customRentTariff"] = this.businessService.customRentTariff;
    data["securityType"] = this.businessService.securityType;
    data["customMaintenanceCosts"] = this.businessService.customMaintenanceCosts;
    data["customYearlyInsuranceCosts"] = this.businessService.customYearlyInsuranceCosts;
    data["batteryType"] = this.businessService.batteryType;
    data["batterySize"] = this.businessService.batterySize;


    // Third page
    data["subsidyOption"] = this.businessService.subsidyOption;
    data["customYearlyOtherSubsidy"] = this.businessService.customYearlyOtherSubsidy;
    data["salderenDefaultKwhUsage"] = this.businessService.salderenDefaultKwhUsage;
    data["salderenCustomKwhUsage"] = this.businessService.salderenCustomKwhUsage;
    data["gridSalesDefaultTariff"] = this.businessService.gridSalesDefaultTariff;
    data["gridSalesCustomTariff"] = this.businessService.gridSalesCustomTariff;
    data["parkingTime"] = this.businessService.parkingTime;
    data["parkingCoverage"] = this.businessService.parkingCoverage;
    data["extraParkingDefaultTariff"] = this.businessService.extraParkingDefaultTariff;
    data["extraParkingCustomTariff"] = this.businessService.extraParkingCustomTariff;
    data["customFixedOtherSubsidy"] = this.businessService.customFixedOtherSubsidy;
    data["ownUseFraction"] = this.businessService.ownUseFraction;
    data["sceConnection"] = this.businessService.sceConnection;
    data["sdeSubsidyPhase"] = this.businessService.sdeSubsidyPhase;

    return data;
  }
}

import { Component, OnInit } from "@angular/core";
import { LayoutService } from "../../layout/layout.service";
import { team } from "./team";
import { partners } from "./partners";

declare var firebase: any;

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  meetingPlanned: boolean = false;
  analytics: any = firebase.analytics();

  team = team;
  partners = partners;

  constructor(private layoutService: LayoutService) {}

  captureOutboundLink = (url) => {
    this.analytics.logEvent("outbound_click", {
      custom_outbound_url: url,
    });
  };

  ngOnInit() {
    this.layoutService.checkMapView();
    this.layoutService.inApplication = false;
    this.layoutService.showNavbar = true;
  }

  public planAMeeting() {
    this.meetingPlanned = !this.meetingPlanned;
  }
}

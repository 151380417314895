<div class="container-fluid main-content-container">
  <div class="row justify-content-center background-light">
    <div class="col-12 col-md-8 col-xl-6 px-4 mt-5 mb-5">
      <div class="row">
        <div class="col-12 d-flex flex-column align-items-center">
          <h1 class="text-center pointer" (click)="toggleAlgemeen()">
            <span class="heading-underline-black">Algemeen</span></h1>
          <i [ngClass]="this.showAlgemeen ? 'fas fa-angle-up fa-3x pointer' : 'fas fa-angle-down fa-3x pointer'"
            (click)="toggleAlgemeen()"></i>
          <div [ngClass]="this.showAlgemeen ? null : 'd-none'">
            <h3 class="pointer bold-hover mb-3" (click)="toggleWhatIs()">
              Wat is Park the Sun?</h3>
            <p [ngClass]="this.showWhatIs ? null : 'd-none'">
              Park the Sun is een openbare webtool gericht op solar carports. Door middel van slimme modellen krijg je inzicht in de mogelijkheden van solar carports op parkeerterreinen. 
              </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleByWho()">
              Door wie is Park the Sun gemaakt?</h3>
            <p [ngClass]="this.showByWho ? null : 'd-none'">De eerste versie van Park the Sun is ontwikkeld door Sobolt in nauwe samenwerking met Provincie Noord-Holland en Gemeente Rotterdam.
            </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleWhyIs()">
              Waarom is Park the Sun gemaakt?</h3>
            <p [ngClass]="this.showWhyIs ? null : 'd-none'">Park the Sun is ontwikkeld vanuit de behoefte om meer
              inzicht te hebben in locaties om zonne
              energie op te wekken. Het opwekken van energie via parkeerterreinen is een mooie manier om een locatie
              meervoudig te gebruiken. Dit willen we graag stimuleren!
            </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleForWho()">
              Voor wie is Park the Sun?</h3>
            <p [ngClass]="this.showForWho ? null : 'd-none'">Park the Sun is eigenlijk voor iedereen die Park the Sun
              wil gebruiken, de tool is openbaar
              toegankelijk. Wel zijn er bepaalde doelgroepen voor wie Park the Sun extra interessant is. Dit zijn
              overheden, bedrijven, solar carport ontwikkelaars en energie coöperaties.
              Op de <a href="about">over-pagina</a> wordt meer uitleg gegeven over deze doelgroepen.
            </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleIsInRegion()">
              Park the Sun is in mijn regio nog niet openbaar toegankelijk. Wat kan ik doen?</h3>
            <p [ngClass]="this.showIsInRegion ? null : 'd-none'">Leuk! Als je werkzaam bent bij een provincie of
              gemeente, neem dan <a href="contact">contact</a> met ons op. Ben je
              dat niet, informeer dan bij je gemeente of zij al op de hoogte zijn van Park the Sun. Wij kunnen dan met
              je
              gemeente in gesprek om te bespreken of Park the Sun past.
            </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleWhyInRegion()">
              Waarom is Park the Sun in mijn regio openbaar toegankelijk?</h3>
            <p [ngClass]="this.showWhyInRegion ? null : 'd-none'">Dat komt omdat wij samenwerken met jouw provincie en /
              of gemeente. De analyse voor de gebieden
              waarmee wij samenwerken, zijn openbaar toegankelijk. Op die manier heeft de Park the Sun een groot bereik.
              Zo kan Park the Sun bijvoorbeeld dienen als communicatiemiddel tussen verschillende partijen, zoals tussen
              een gemeente, energiecoöperatie en een ontwikkelaar. Het hebben van een openbare tool is dan wel zo
              makkelijk!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center background-primary">
    <div class="col-12 col-md-8 col-xl-6 px-4  mt-5 mb-5">
      <div class="row">
        <div class="col-12 d-flex flex-column align-items-center">
          <h1 class="text-center pointer color-light" (click)="toggleSolarCarports()">
            <span class="heading-underline-white">Solar carports</span></h1>
          <i class="color-light"
            [ngClass]="this.showSolarCarports ? 'fas fa-angle-up fa-3x pointer' : 'fas fa-angle-down fa-3x pointer'"
            (click)="toggleSolarCarports()"></i>
          <div class="color-light" [ngClass]="this.showSolarCarports ? null : 'd-none'">
            <h3 class="pointer bold-hover mb-3" (click)="toggleWhatAre()">
              Wat zijn solar carports?</h3>
            <p [ngClass]="this.showWhatAre ? null : 'd-none'">Solar carports zijn overkappingen op parkeerterreinen met
              ingebouwde zonnepanelen. Op deze manier kan je grootschalig energie opwekken. Wil je meer weten? Kijk dan
              op onze <a href="about">over-pagina</a>.</p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleWhoBuilds()">
              Wie bouwen solar carports?</h3>
            <p [ngClass]="this.showWhoBuilds ? null : 'd-none'">Bij de bouw van een solar carport zijn vaak 3 groepen
              betrokken; de opdrachtverlener, projectontwikkelaar en onderaannemers. De opdrachtverlener kan de eigenaar
              van het parkeerterrein, zoals een bedrijf, zijn. Maar ook een energiecoöperatie of gemeente kan het
              initiatief nemen om aan de slag te gaan met een (vaak publiek) parkeerterrein.
              De bouw van solar carports wordt gecoördineerd door projectontwikkelaars. Vaak werken zij met
              onderaannemers die verantwoordelijk zijn voor een specifiek deel van de werkzaamheden, bijvoorbeeld de
              staalconstructie of grondwerkzaamheden.
              Goed om te weten is dat een leverancier van zonnepanelen (op dak) vaak niet dezelfde partijen zijn als de
              solar carport ontwikkelaars. In Nederland zijn nu circa 5 tot 10 solar carport ontwikkelaars actief.
            </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleWhySolarCarports()">
              Waarom richt Park the Sun zich op solar carports?</h3>
            <p [ngClass]="this.showWhySolarCarports ? null : 'd-none'">We richten ons op solar carports omdat wij dit
              een mooie
              manier vinden om grootschalig zonne energie op te wekken. Door het plaatsen van solar carports ontzien we
              de natuur- en landbouwgrond. Ook gebruiken we zo een ruimte meervoudig: opwekken van duurzame energie en
              parkeren. We weten dat het concept solar carports innovatief en nieuw is. We zetten daarom graag alle
              informatie op één plek!
            </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleIWant()">
              Ik wil een solar carport op mijn parkeerterrein. En nu?</h3>
            <p [ngClass]="this.showIWant ? null : 'd-none'">Blijkt uit de Park the Sun applicatie dat de investering
              zich terugverdient? Mooi! Het is handig om snel een solar carport ontwikkelaar aan te haken. Met deze
              partij kan je samen je solar carport ontwerpen, de vergunningen en subsidies aanvragen. Het is ook
              belangrijk om direct je gemeente en netbeheerder te betrekken om bijvoorbeeld aan te haken op
              bestemmingsplannen. Benieuwd wat er nog meer bij komt kijken? Kijk bij 'Aan de slag' in de <a
                href="app">applicatie</a>.
            </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleHowLong()">
              Hoe lang duurt de bouw van een solar carport gemiddeld?</h3>
            <p [ngClass]="this.showHowLong ? null : 'd-none'">Het realiseren van een solar carport duurt langer dan
              het plaatsen van panelen op dak. Dit komt omdat er een vergunningstraject, grondonderzoek, mogelijke en
              een complexe bouw aan vooraf gaat. De duur van de bouw is afhankelijk van de grootte van het project.
              Gemiddeld duurt de totale bouw van een solar carport 1-3 jaar.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center background-light">
    <div class="col-12 col-md-8 col-xl-6 px-4  mt-5 mb-5">
      <div class="row">
        <div class="col-12 d-flex flex-column align-items-center">
          <h1 class="text-center pointer" (click)="toggleApplicatie()">
            <span class="heading-underline-black">Applicatie - overzicht gebieden</span></h1>
          <i [ngClass]="this.showApplicatie ? 'fas fa-angle-up fa-3x pointer' : 'fas fa-angle-down fa-3x pointer'"
            (click)="toggleApplicatie()"></i>
          <div class="" [ngClass]="this.showApplicatie ? null : 'd-none'">
            <h3 class="pointer bold-hover mb-3" (click)="toggleSomeAreas()">
              Bij sommige gebieden op de kaart staat een vraagteken. Waarom?</h3>
            <p [ngClass]="this.showSomeAreas ? null : 'd-none'">Dit komt omdat deze gebieden (provincies/gemeenten) nog
              niet aangesloten zijn bij Park the Sun. Dit betekent dat de analyse voor deze gebieden nog niet in te zien
              is via de applicatie. </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleNumbersBlueCircles()">
              Wat betekenen de getallen in de blauwe rondjes?</h3>
            <p [ngClass]="this.showNumbersBlueCircles ? null : 'd-none'">De getallen binnen de blauwe rondjes zijn het
              aantal
              parkeerterreinen per gemeente. Wij tellen een parkeerterrein mee zodra het bestaat uit minimaal 5
              parkeervakken naast elkaar.
            </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleHowDetect()">
              Hoe worden parkeerterreinen gedetecteerd?</h3>
            <p [ngClass]="this.showHowDetect ? null : 'd-none'">We combineren verschillende databronnen om
              parkeerterreinen te detecteren. Uniek hierin is dat wij ook luchtfoto's analyseren met AI modellen om
              parkeerterreinen te herkennen. De uitkomsten van deze analyse zijn te zien in het overzicht.
            </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleWhatAnalyse()">
              Welke parkeerterreinen worden getoond en geanalyseerd? </h3>
            <p [ngClass]="this.showWhatAnalyse ? null : 'd-none'">Zodra een provincie of gemeente met ons samenwerkt,
              analyseren wij het gebied. Wij tonen alle parkeerterreinen van minimaal 5 parkeervakken naast elkaar.
              Hierbij maken wij geen onderscheid tussen publieke en private parkeerterreinen.
            </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleWhatNotAnalyse()">
              Welke parkeerterreinen worden niet getoond en geanalyseerd?</h3>
            <p [ngClass]="this.showWhatNotAnalyse ? null : 'd-none'">Parkeergarages, parkeerterreinen op daken,
              toekomstige /
              te bouwen parkeerterreinen worden niet getoond op het overzicht.
            </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleHowFind()">
              Hoe vind ik een parkeerterrein dat geschikt is voor een solar carport?</h3>
            <p [ngClass]="this.showHowFind ? null : 'd-none'">Heb je het overzicht voor je? Klik dan op het gebied
              waarbinnen je een parkeerterrein wil zoeken. Je ziet nu alle parkeerterreinen als zwarte vakken op de
              kaart. In het overzicht rechts zie je hoeveel locaties dit er zijn. OOk kan je in dit overzicht filteren
              binnen locaties, bijvoorbeeld op de afstand tot het net. Voor meer instructies, kijk op <a
                href="about">over-pagina</a>.
            </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleSunArea()">
              Wat is de zon-weergave van de kaart?</h3>
            <p [ngClass]="this.showSunArea ? null : 'd-none'">De zon-weergave van de kaart is de schaduwanalyse van het
              gebied. Op basis van een slim algoritme bepalen we de gemiddelde zoninstraling op elk punt van het
              parkeerterrein. Dit doen we op basis van een 3D model. Staat er bijvoorbeeld een hoog gebouw naast het
              terrein, dan zal je op de zon-weergave zien dat dit schaduw veroorzaakt.
            </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleAreaNotVisible()">
              Het gebied waarin ik geïnteresseerd ben, is niet zichtbaar in de tool. Hoe komt dat? </h3>
            <p [ngClass]="this.showAreaNotVisible ? null : 'd-none'">Dat komt waarschijnlijk omdat dit gebied niet in
              een
              provincie / gemeente ligt die Park the Sun heeft aangeschaft. Wij zetten de analyse online van de gebieden
              die met ons samenwerken.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center background-primary">
    <div class="col-12 col-md-8 col-xl-6 px-4 mt-5 mb-5">
      <div class="row">
        <div class="col-12 d-flex flex-column align-items-center">
          <h1 class="text-center pointer color-light" (click)="toggleDetails()">
            <span class="heading-underline-white">Applicatie - details parkeerterrein</span></h1>
          <i class="color-light"
            [ngClass]="this.showDetails ? 'fas fa-angle-up fa-3x pointer' : 'fas fa-angle-down fa-3x pointer'"
            (click)="toggleDetails()"></i>
          <div class="color-light" style="max-width: 100%;" [ngClass]="this.showDetails ? null : 'd-none'">
            <h3 class="pointer bold-hover mb-3" (click)="toggleWhereFrom()">
              Waar komt de informatie over het parkeerterrein vandaan?</h3>
            <p [ngClass]="this.showWhereFrom ? null : 'd-none'">De getoonde informatie, getallen en bedragen in Park the
              Sun zijn deels gebaseerd op data analyses en deels op zorgvuldig tot stand gekomen inschattingen. Deze
              inschattingen hebben we bijvoorbeeld gemaakt op basis van marktinformatie van solar carport experts en
              overheden. </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleIWantNumbers()">
              Ik wil graag een van de getallen of bedragen binnen randvoorwaarden/investeringen/opbrengsten zelf
              aanpassen. Hoe doe ik dat? </h3>
            <p [ngClass]="this.showIWantNumbers ? null : 'd-none'">Je kan een bedrag of getal aanpassen als er een
              potloodje naast het invulvak staat. Wil je bijvoorbeeld meer laadpalen plaatsen dan het vooringevulde
              aantal? Pas dit dan aan door op het potloodje te klikken en de juiste waarde in te vullen.
              Staat het potloodje er niet? Dan hebben wij de berekening voor je gedaan. Het getal of bedrag is dan een
              vast gegeven. Wel is het mogelijk voor een aantal van deze vaste gegevens om veranderd te worden in de
              geavanceerde instellingen.
            </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleIHave()">
              Ik heb de investeringen, opbrengsten en terugverdientijd berekend met de tool. Wat nu?</h3>
            <p [ngClass]="this.showIHave ? null : 'd-none'">Goed bezig! Wil je nu aan de slag met het daadwerkelijk
              realiseren van je solar carport? Dan is het handig om je berekening in de vorm van een rapport te hebben.
              Druk bij ‘aan de slag’ op de knop om een rapport te laten maken van je berekende investeringen,
              opbrengsten en terugverdientijd. Binnen het ‘aan de slag’ gedeelte zie je ook welke andere stappen
              belangrijk zijn in het traject na Park the Sun.
            </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleHelpSolarCarport()">
              Ik wil graag hulp om aan de slag te gaan met mijn solar carport. Kan ik via Park the Sun direct een solar
              carport laten bouwen?</h3>
            <p [ngClass]="this.showHelpSolarCarport ? null : 'd-none'">Nee, niet direct. Wij zijn geen solar carport
              ontwikkelaars. Wel kan je altijd contact met ons opnemen als je verdere vragen hebt! Op de
              <a href="contact">contact-pagina</a> staan onze gegevens.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center background-light">
    <div class="col-12 col-md-8 col-xl-6 px-4 mt-5 mb-5">
      <div class="row">
        <div class="col-12 d-flex flex-column align-items-center">
          <h1 class="text-center pointer" (click)="toggleOther()">
            <span class="heading-underline-black">Overig</span></h1>
          <i [ngClass]="this.showOther ? 'fas fa-angle-up fa-3x pointer' : 'fas fa-angle-down fa-3x pointer'"
            (click)="toggleOther()"></i>
          <div [ngClass]="this.showOther ? null : 'd-none'">
            <h3 class="pointer bold-hover mb-3" (click)="toggleDeveloper()">
              Ik ben een solar carport ontwikkelaar. Staan jullie open voor een samenwerking? </h3>
            <p [ngClass]="this.showDeveloper ? null : 'd-none'">Samenwerken is wat ons betreft altijd een goed idee! We
              maken graag kennis met je. Op de <a class="text-blue" href="contact">contact-pagina</a> vind je onze
              gegevens. </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleOwner()">
              Ik ben eigenaar van meerdere parkeerterreinen buiten de gebieden die nu in de tool staan. Ik ben wel erg
              benieuwd naar de analyse! Wat is er mogelijk? </h3>
            <p [ngClass]="this.showOwner ? null : 'd-none'">Ben je eigenaar van (een bedrijfsketen met) meerdere
              parkeerterreinen in Nederland? Tof! Neem even contact met ons op. Je vindt onze gegevens op de
              <a class="text-blue" href="contact">contact-pagina</a>.
            </p>
            <h3 class="pointer bold-hover mb-3" (click)="toggleIsItPossible()">
              Is het mogelijk om voor mijn gemeente specifieke wensen in de Park the Sun tool te laten bouwen?</h3>
            <p [ngClass]="this.showIsItPossible ? null : 'd-none'">Ja! Op aanvraag kunnen we voor provincies en
              gemeenten extra functionaliteiten in de tool bouwen. Denk bijvoorbeeld aan het verwerken van
              bestemmingsplannen in de tool. We vertellen je er graag meer over. Onze gegevens staan op de
              <a class="text-blue" href="contact">contact-pagina</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

import { Injectable } from '@angular/core';
import { BusinessCaseService } from './business-case.service';
import { CartoService } from './carto.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessCaseInfoService {

  public stage2ConnectionFieldnameEditableText = "Gebaseerd op afstand";
  public tooltipText;

  constructor(public businessService: BusinessCaseService, public cartoService: CartoService) 
  { 
    this.cartoService.getJSON().subscribe((data) => {
      this.tooltipText = data;
    });
  }

  public getSolarCarportInfoText(): string {
    const carportKwp = this.businessService.getSolarParkingPower(3);
    return `Op basis van het op te stellen vermogen van ${Math.round(carportKwp)} kWp`
  }

  public getChargingInfoText(): string {
    if (this.businessService.chargerType === 0 || this.businessService.getNumChargers() === 0) {
      return 'Je hebt geen laadpalen gekozen. Ga terug naar de investering om laadpalen toe te voegen'
    }

    const chargerText = this.businessService.chargerOptions.items[this.businessService.chargerType].name;

    let numParkedElectricCars = Math.round(this.businessService.numParkedElectricCars())
    let numChargingPoints = this.businessService.numChargingPoints()
    let hint1: string = ""
    let hint2: string = ""
    if (numParkedElectricCars > numChargingPoints) {
      hint1 = `Tip: je kan overwegen hier meer laadpunten te plaatsen. `
    } else if (numParkedElectricCars < numChargingPoints) {
      hint1 = `Tip: je kan overwegen hier minder laadpunten te plaatsen. `
    } else {
      hint2 = " Goed bezig!"
    }

    let numElectricCarText: string = "";
    if (numParkedElectricCars < 1) {
      numElectricCarText = "minder dan 1 elektrische auto tegelijkertijd parkeert"
    } else if (numParkedElectricCars < 2) {
      numElectricCarText = `1 elektrische auto tegelijkertijd parkeert`
    } else {
      numElectricCarText = `${numParkedElectricCars} elektrische auto's tegelijkertijd parkeren`
    }

    return `${hint1}We verwachten dat hier gemiddeld ${numElectricCarText}.
    Je hebt gekozen voor ${numChargingPoints} laadpunten.${hint2} Nog een tip: parkeren auto's hier kort? Overweeg een snellader.
    `
  }

  public getBatteryRevenueInfoText(): string {
    if(this.businessService.batteryType === 0) {
      return `Je hebt geen batterijsysteem gekozen. Wil je een batterijsysteem toevoegen? Ga dan terug naar de investeringen.`
    }
    
    return `Er wordt vanuit gegaan dat ${Math.round(this.businessService.fractionOfBatteryUsedForTrading*100)}% van het batterijsysteem (${this.businessService.batterySize* this.businessService.fractionOfBatteryUsedForTrading} kWh) wordt gebruikt als reservevermogen om te handelen.`
  }

  public getLegesPopupText(): string {
    // Specific popup for Rotterdam
    if (this.cartoService.selectedMunicipalityId === 193) {
      return this.tooltipText.leges_lees_meer_rotterdam
    } else {
      return this.tooltipText.leges_lees_meer
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { LayoutService } from "../../layout/layout.service";
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { CartoService } from '../../services/carto.service';

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"]
})
export class AboutComponent implements OnInit {
  public systems: [
    {
      imagePath: "/assets/images/realised-solarcarports/realised_solarcarport_bloemendaal.jpg",
      title: "Zeeweg - Bloemendaal",
      address: "Zeeweg 89, Bloemendaal",
      buildyear: 2020,
      power: "2MWp",
      yearlyRevenue: "2 miljoen kWh",
      parkingSpaces: 800,
      developer: "MORRENsolar"
    },
    {
      imagePath: "/assets/images/realised-solarcarports/realised_solarcarport_woerden.png",
      title: "Lidl - Woerden",
      address: "Lepenlaan 1a, Woerden",
      buildyear: 2019,
      power: "280KWp",
      yearlyRevenue: "250 duizend kWh",
      parkingSpaces: 84,
      developer: "Groenleven"
    },
    {
      imagePath: "/assets/images/realised-solarcarports/realised_solarcarport_culemborg.png",
      title: "Culemborg - wijk Lanxmeer",
      address: "Hendrik Marsmanweg 1, Culemborg",
      buildyear: 2019,
      power: "235 kWp",
      yearlyRevenue: "200 duizend kWh",
      parkingSpaces: 68,
      developer: "MORRENsolar"
    }
  ]
  private underDevelopmentMessage: string = "De applicatie is nog in ontwikkeling. Wil je bericht ontvangen als Park de Sun echt live is? Ga dan naar de doe mee pagina."

  public showWatIs: boolean = false;
  public showForWho: boolean = false;
  public showWhatAre: boolean = false;
  public showRealisedSystems: boolean = false;
  public showSystemTypes: boolean = false;
  public showChargingSystems: boolean = false;
  public showInPractice: boolean = false;
  public showMeerZon: boolean = false;

  constructor(
    private layoutService: LayoutService,
    private router: Router,
    public cartoService: CartoService
  ) {
  }

  ngOnInit() {
    this.checkRoute()
    this.layoutService.checkMapView();
    this.layoutService.inApplication = false;
    this.layoutService.showNavbar = true;
  }

  redirectToContactPageClick() {
    this.router.navigate(['contact'])
  }

  redirectToApplicationClick() {
    this.router.navigate(["/app"], {queryParams:{skipdialog: "true"}});
  }

  toggleWhatIs() {
    this.showWatIs = !this.showWatIs
  }

  toggleForWho() {
    this.showForWho = !this.showForWho
  }

  toggleWhatAre() {
    this.showWhatAre = !this.showWhatAre
  }

  toggleRealisedSystems() {
    this.showRealisedSystems = !this.showRealisedSystems
  }

  toggleMeerZon() {
    this.showMeerZon = !this.showMeerZon
  }

  toggleSystemTypes() {
    this.showSystemTypes = !this.showSystemTypes
  }
  showNav
  toggleChargingSystems() {
    this.showChargingSystems = !this.showChargingSystems
  }

  toggleInPractice() {
    this.showInPractice = !this.showInPractice
  }

  checkRoute() {
    const urlSegments: string[] = this.router.url.split("#")
    // We are looking for this kind of URL /over#practice to make sure the right section is opened
    if (urlSegments.length < 2) {
      return
    } else if (urlSegments[1] === "practice") {
      this.toggleInPractice()
      document.getElementById('practice').scrollIntoView();
    } else {
      return
    }
  }

  openSystemTypes() {
    this.showWhatAre = false
    this.showSystemTypes = true
    document.getElementById("systemTypes").scrollIntoView();
  }
}

<div class="d-flex flex-row mb-3 align-items-center">
  <h3 class="col-6 business-case-fields mb-0">{{valueName}}</h3>
  <p class="col-4 mt-0 mb-0" *ngIf="!editItem">
    {{value}}
    <button mat-icon-button class="col-1 business-case-edit-icon-button ml-3" (click)="toggleEditItem()"><i
        class="fas fa-pencil-alt business-case-edit-icon"></i></button>
    <button *ngIf="value != defaultValue" mat-icon-button class="col-1 business-case-edit-icon-button ml-3"
      (click)="resetValue()"><i class="fas fa-times business-case-edit-icon"></i></button>
  </p>
  <p class="col-5 mt-0 mb-0" *ngIf="editItem">
    <mat-form-field class="mt-0">
      <span matPrefix>{{prefix}} </span>
      <input matInput [value]="value" (change)="changeItem($event)">
      <span matSuffix (click)="toggleEditItem()">
        <button mat-icon-button class="business-case-edit-icon-button ml-3"><i
            class="fas fa-times business-case-edit-icon"></i></button>
      </span>
    </mat-form-field>
  </p>
</div>

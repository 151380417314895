import { Component, OnInit, Input, EventEmitter, Output, TemplateRef } from '@angular/core';
import { BusinessCaseService } from "../../services/business-case.service";
import { CartoService } from "../../services/carto.service";
import { MatDialog } from '@angular/material/dialog';
import { DialogContentComponent } from '../dialog-content/dialog-content.component';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';

@Component({
  selector: 'app-business-case-item',
  templateUrl: './business-case-item.component.html',
  styleUrls: ['./business-case-item.component.css']
})
export class BusinessCaseItemComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() disabledText: string = "In ontwikkeling";
  @Input() noLeesMeer: boolean = false;
  @Input() iconClass: string;
  @Input() fieldentryButtonIconClass: string;
  @Input() fieldnameButtonIconClass: string;
  @Input() fieldName: string;
  @Input() statistic: any;
  @Input() unit: string;
  @Input() prefix: string;
  @Input() scrollBar: {};
  // @Input() powerField: {items: any[]};
  @Input() fieldentryDropdownContent: { hintText: string, items: any[] };
  @Input() dropdownContent: { hintText: string, items: any[] };
  @Input() dropdownContent2: { hintText: string, items: any[] };
  @Input() fieldnameTextList: string[];
  @Input() fieldnameEditableNumber: number;
  @Input() fieldnameEditableNumberUnit: string;
  @Input() fieldentryTextList: any[];
  @Input() fieldentryEditableNumber: number;
  @Input() fieldentryEditableNumber2: number;
  @Input() fieldentryInfoText: string;

  editingNumber: boolean = false;
  editingFieldnameNumber: boolean = false;
  @Input() editableNumber: number;
  @Output() editableNumberChange = new EventEmitter<number>();
  @Input() editableFieldnameNumber: number;
  @Output() editableFieldnameNumberChange = new EventEmitter<number>();
  @Input() flag: number;
  @Input() flagPopoverMessage: string;

  @Input() dialogHeader: string;
  @Input() dialogContent: string;
  @Input() dialogContent2: string;
  @Input() dialogLink: string;
  @Input() dialogLinkContent: string;
  @Input() dialogLink2: string;
  @Input() dialogLinkContent2: string;
  @Input() dialogLink3: string;
  @Input() dialogLinkContent3: string;
  @Input() dialogLink4: string;
  @Input() dialogLinkContent4: string;
  @Input() dialogImage: string;
  @Input() dialogCustomHtml: TemplateRef<any>;

  @Input() templateRef: TemplateRef<any>;

  // fieldentrySelectedDropdownValue: string;

  @Input() selectedDropdownValue: number;
  @Output() selectedDropdownValueChange = new EventEmitter<number>();
  @Input() selectedDropdownValue2: number;
  @Output() selectedDropdownValue2Change = new EventEmitter<number>();



  constructor(
    public businessService: BusinessCaseService,
    public cartoService: CartoService,
    public dialog: MatDialog,
    private dom: DomSanitizer
  ) { }

  ngOnInit() {
  }

  isNumber(val): boolean {
    return typeof val === "number";
  }

  isString(val): boolean {
    return typeof val === "string";
  }

  public toggleEditNumber() {
    if (this.editingNumber) {
      return
    } else {
      this.editableNumber = this.fieldentryEditableNumber
      this.editingNumber = true;
    }
  }

  public resetEditNumber() {
    if (!this.editingNumber) {
      return
    } else {
      this.editableNumberChange.emit(undefined)
      this.editingNumber = false;
    }
  }

  public toggleEditFieldnameNumber() {
    if (this.editingFieldnameNumber) {
      return
    }
    else {
      this.editableFieldnameNumber = this.fieldnameEditableNumber
      this.editingFieldnameNumber = !this.editingFieldnameNumber;
    }
  }

  public resetEditFieldnameNumber() {
    if (!this.editingFieldnameNumber) {
      return
    } else {
      this.editableFieldnameNumberChange.emit(undefined)
      this.editingFieldnameNumber = !this.editingFieldnameNumber;
    }
  }



  public editNumber(event) {
    this.editableNumberChange.emit(parseInt(event.target.value.replace('.', '')))
  }

  public editFieldnameNumber(event) {
    this.editableFieldnameNumberChange.emit(parseFloat(event.target.value.replace(/,/g, '.')))
  }

  public editDropdownValue(event) {
    this.selectedDropdownValueChange.emit(event.value)
  }

  public editDropdownValue2(event) {
    this.selectedDropdownValue2Change.emit(event.value)
  }

  openDialog() {

    if (this.templateRef != undefined) {
      this.dialog.open(this.templateRef);
    }
    else if (this.templateRef == undefined) {
      this.dialog.open(DialogContentComponent, {
        data: {
          header: this.dialogHeader,
          content: this.dialogContent,
          content2: this.dialogContent2,
          link: this.dialogLink,
          linkContent: this.dialogLinkContent,
          link2: this.dialogLink2,
          linkContent2: this.dialogLinkContent2,
          link3: this.dialogLink3,
          linkContent3: this.dialogLinkContent3,
          link4: this.dialogLink4,
          linkContent4: this.dialogLinkContent4,
          image: this.dialogImage,
          customHtml: this.dom.sanitize(SecurityContext.HTML, this.dialogCustomHtml)
        }
      })
    }

  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./header/header.component";
import { SharedComponentsModule } from "../shared-components/shared-components.module";
import { MainViewComponent } from './main-view/main-view.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from "@angular/material/divider";


@NgModule({
  declarations: [HeaderComponent, MainViewComponent, TopNavComponent, SidemenuComponent],
  imports: [CommonModule, SharedComponentsModule, MatButtonModule, MatSidenavModule, MatDividerModule],
  exports: [MainViewComponent]
})
export class LayoutModule { }

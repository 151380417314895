export const partners = [
  {
    link: "https://www.rotterdam.nl/",
    image: "../../../assets/images/logo-gemeente-rotterdam-narrow.png",
    name: "gemeente Rotterdam",
  },
  {
    link: "https://www.noord-holland.nl",
    image: "../../../assets/images/Logo-provincie-noord-holland.png",
    name: "provincie Noord-Holland",
  },
  {
    link: "https://www.provincie-utrecht.nl",
    image: "../../../assets/images/provincieUtrechtLogo.png",
    name: "provincie Utrecht",
  },
  {
    link: "https://www.amsterdam.nl",
    image: "../../../assets/images/logo-gemeente-amsterdam.png",
    name: "gemeente Amsterdam",
  },
  {
    link: "https://www.denhaag.nl",
    image: "../../../assets/images/gemeente-Den-Haag-logo_0.png",
    name: "gemeente Den Haag",
  },
  {
    link: "https://gemeente.groningen.nl",
    image: "../../../assets/images/gemeente_groningen.png",
    name: "gemeente Groningen",
  },
  {
    link: "https://www.provincie.drenthe.nl",
    image: "../../../assets/images/logo-provincie-drenthe.png",
    name: "provincie Drenthe",
  },
  {
    link: "https://www.odveluweijssel.nl",
    image: "../../../assets/images/logo-veluwe.png",
    name: "omgevingsdienst Veluwe",
  },
];
